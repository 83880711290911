// @flow
import addDays from "date-fns/addDays";
import formatDate from "date-fns/format";
import parseDate from "date-fns/parse";
import parseISODate from "date-fns/parseISO";
import diffDays from "date-fns/differenceInCalendarDays";
import { readFileAsText } from "./file";

/**
 * This UDO utils are for reading, parsing and patching of UDO JSONs from ADC.
 */

type UdoGlucoseReading = {
  timestamp: string,
  extendedProperties: {
    factoryTimestamp: string,
    ...
  },
  valueInMgPerDl: number
};

export type UdoJson = {
  measurementLog: {
    scheduledContinuousGlucoseEntries: UdoGlucoseReading[],
    unscheduledContinuousGlucoseEntries: UdoGlucoseReading[],
    ...
  },
  ...
};

const timestampFormat = "yyyy-MM-dd HH:mm:ss";
const factoryTimestampFormat = "yyyy-MM-dd'T'HH:mm:ss";

export async function readUdoJson(udoJsonField: FileList): Promise<UdoJson> {
  const udoJsonFile = udoJsonField.length ? udoJsonField[0] : null;
  let parsedJson = null;

  if (!udoJsonFile) {
    throw Error("File is not selected");
  } else {
    let jsonString = "";

    try {
      jsonString = await readFileAsText(udoJsonFile);
    } catch (e) {
      throw Error(`Unable to read selected file ${udoJsonFile.name}`);
    }

    try {
      parsedJson = JSON.parse(jsonString);
    } catch (e) {
      throw Error(
        `Unable to parse JSON in file ${udoJsonFile.name}, please provide valid JSON`
      );
    }
  }

  return parsedJson;
}

/**
 * @param {UdoGlucoseReading} entry - glucose entry of UDO JSON
 * @param {number} daysIncrement - how many days to add to both `timestamp` and `factoryTimestamp`
 */
export function patchGlucoseEntry(
  entry: UdoGlucoseReading,
  daysIncrement: number
): UdoGlucoseReading {
  const timestamp = parseDate(
    entry.timestamp,
    timestampFormat,
    new Date(entry.timestamp)
  );
  const factoryTimestamp = parseISODate(
    entry.extendedProperties.factoryTimestamp
  );

  return {
    ...entry,
    timestamp: formatDate(addDays(timestamp, daysIncrement), timestampFormat),
    extendedProperties: {
      ...entry.extendedProperties,
      factoryTimestamp: formatDate(
        addDays(factoryTimestamp, daysIncrement),
        factoryTimestampFormat
      )
    }
  };
}

export function patchUdoJsonWithDate(udoJson: UdoJson, startDate: Date) {
  const { measurementLog } = udoJson;
  const {
    scheduledContinuousGlucoseEntries,
    unscheduledContinuousGlucoseEntries
  } = measurementLog;
  const firstScheduledEntry = scheduledContinuousGlucoseEntries[0];
  const firstUnscheduledEntry = unscheduledContinuousGlucoseEntries[0];

  const scheduledDaysDiff = diffDays(
    startDate,
    parseISODate(firstScheduledEntry.extendedProperties.factoryTimestamp)
  );
  const unscheduledDaysDiff = diffDays(
    startDate,
    parseISODate(firstUnscheduledEntry.extendedProperties.factoryTimestamp)
  );

  return {
    ...udoJson,
    measurementLog: {
      ...measurementLog,
      scheduledContinuousGlucoseEntries: scheduledContinuousGlucoseEntries.map<UdoGlucoseReading>(
        entry => patchGlucoseEntry(entry, scheduledDaysDiff)
      ),
      unscheduledContinuousGlucoseEntries: unscheduledContinuousGlucoseEntries.map<UdoGlucoseReading>(
        entry => patchGlucoseEntry(entry, unscheduledDaysDiff)
      )
    }
  };
}

export async function parseAndPatchUdoJson(
  worker: Worker,
  udoJson: FileList,
  startDate: Date
): Promise<UdoJson> {
  const parsedUDO = await readUdoJson(udoJson);

  return await new Promise<UdoJson>((resolve, reject) => {
    const onMessage = (event: any) => {
      const outputUDO: UdoJson = event.data;
      removeListeners();
      resolve(outputUDO);
    };
    const onError = (eventError: MessageEvent) => {
      removeListeners();
      reject(eventError);
    };
    const removeListeners = () => {
      worker.removeEventListener("message", onMessage);
      worker.removeEventListener("error", onError);
    };
    worker.addEventListener("message", onMessage);
    worker.addEventListener("error", onError);

    worker.postMessage([parsedUDO, startDate]);
  });
}
