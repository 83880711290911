// @flow
import * as React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";

type Props = {
  children?: React.Node,
  className?: string,
  wide?: boolean
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: ({ wide }) => theme.spacing(3, wide ? 4 : 6, 8)
  }
}));

export default function PageLayout(props: Props) {
  const { children, className } = props;
  const classes = useStyles(props);
  return (
    <Container maxWidth="lg" classes={classes} className={className}>
      {children}
    </Container>
  );
}
