// @flow
import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { pdfjs, Document, Page } from 'react-pdf';
import BackArrow from "../components/BackArrow";
import webappLegalNotices from "../assets/legal/webapp-legal-notices.pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useContainerStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    maxWidth: "80%",
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    height: "100vh"
  },
}));
const usePaperStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    padding: theme.spacing(5),
    overflow: "scroll",
    height: "80vh"
  },
  elevation0: {
    boxShadow: "0 10px 40px 0 rgba(0, 0, 0, 0.2)"
  }
}));

export default function LegacyNoticePage() {
  const paperClasses = usePaperStyles();
  const containerClasses = useContainerStyles();
  const [numPages, setNumPages] = useState(null);
  const history = useHistory();
  const { t } = useTranslation();
  
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  
  return (
    <Container maxWidth="lg" classes={containerClasses}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <IconButton onClick={() => history.go(-1)}>
          <BackArrow />
        </IconButton>
        <Typography component="h1" variant="h5">
          {t("actions.back")}
        </Typography>
      </Grid>
      <Paper elevation={0} classes={paperClasses}>
        <Document
          file={webappLegalNotices}
          onLoadSuccess={onDocumentLoadSuccess}
          renderMode="svg"
        >
          {Array.apply(null, Array(numPages))
            .map((x, i)=> i + 1)
            .map((page, index) => <Page size="A5" key={index} pageNumber={page}/>)}
        </Document>
      </Paper>
    </Container>
  );
}
