// @flow
import * as React from "react";
import { getHcp as getHcpAction } from "../../actions/hcpActions";
import { useSelector, useDispatch } from "react-redux";
import type { State } from "../../reducers/types";

const { useEffect } = React;

export function useHcpDataEffect(hcpId: string) {
  const dispatch = useDispatch();
  const selectedHcp = useSelector(({ hcp }: State) => hcp.entities[hcpId]);

  useEffect(() => {
    if (!selectedHcp && hcpId) {
      dispatch(getHcpAction(hcpId));
    }
  }, [dispatch, selectedHcp, hcpId]);
}
