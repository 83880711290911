// @flow
import UserListActions from "./UserListActions";
import { rolesMap } from "../../constants/roles";
import i18n from "../../i18n";

import type { VirtualizedColumnProps as ColumnProps } from "../../components/Table/types";

const usersListColumns: ColumnProps[] = [
  {
    dataKey: "firstName",
    align: "left",
    label: "fields.common.firstName",
    dataQa: "firstNameSorting",
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  {
    dataKey: "lastName",
    align: "left",
    label: "fields.common.lastName",
    dataQa: "lastNameSorting",
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  {
    dataKey: "role",
    label: "fields.common.role",
    align: "left",
    dataQa: "roleSorting",
    sortable: true,
    cellDataGetter: ({ rowData }) => i18n.t(rolesMap.get(rowData.role)) || "",
    width: 50,
    flexGrow: 1
  },
  {
    dataKey: "uiActions",
    label: "",
    cellRenderer: UserListActions,
    width: 50,
    flexGrow: 1
  }
];

export default usersListColumns;
