// @flow
import { takeLatest, call, put } from "redux-saga/effects";
import { normalize, schema } from "normalizr";
import { fetchData } from "./fetchSaga";
import {
  getHcpListSuccess,
  getHcpListError,
  getMoreHcpListSuccess,
  getMoreHcpListError,
  getHcpSuccess,
  getHcpError
} from "../actions/hcpActions";
import {
  HCP_LIST_REQUEST,
  HCP_LIST_MORE_REQUEST,
  HCP_GET_REQUEST
} from "../constants/actionTypes";
import { ASC } from "../constants/sorting";

import type { Saga } from "redux-saga";
import type {
  HcpPaginatedResponse,
  HcpGetRequestAction,
  HcpListRequestAction,
  HcpListMoreRequestAction
} from "../actions/types/hcpActions";
import type {
  PaginationPayload,
  RawPaginatedResponse
} from "../actions/types/generic";
import type { State, HcpEntity } from "../reducers/types";
import type { Result } from "./fetchSaga";
import type { APIError } from "../utils/errors";

type PaginatedResult = {
  response?: HcpPaginatedResponse,
  error?: APIError<*>
};
const hcpEntity = new schema.Entity("hcp");
const resultSchema = { items: new schema.Array(hcpEntity) };
const ENDPOINT = "healthcare-professional";

export function selectHcpData({ hcp, user }: State, hcpId: string) {
  return {
    selectedHcp: hcp.entities[hcpId],
    userRole: user.role
  };
}

export function* fetchHcpList(payload: PaginationPayload): PaginatedResult {
  let {
    take = 30,
    sortDirection: direction = ASC.toUpperCase(),
    sortBy: orderBy,
    skip = 0
  } = payload;

  const result: Result<
    PaginationPayload,
    RawPaginatedResponse<HcpEntity>
  > = yield call(fetchData, {
    method: "get",
    url: ENDPOINT,
    params: {
      take,
      skip,
      orderBy: orderBy || undefined,
      direction: direction ? direction.toUpperCase() : undefined
    }
  });

  const { response, error } = result;
  let normalizedResponse;

  if (response) {
    normalizedResponse = normalize(
      { ...response.data, ...payload },
      resultSchema
    );
  }

  return { response: normalizedResponse, error };
}

export function* getHcpList(action: HcpListRequestAction): Saga<void> {
  const { response, error }: PaginatedResult = yield call(
    fetchHcpList,
    action.payload
  );

  if (response) {
    yield put(getHcpListSuccess(response));
  }

  if (error) {
    yield put(getHcpListError(error));
  }
}

export function* getMoreHcp(action: HcpListMoreRequestAction): Saga<void> {
  const { response, error }: PaginatedResult = yield call(
    fetchHcpList,
    action.payload
  );

  if (response) {
    yield put(getMoreHcpListSuccess(response));
  }

  if (error) {
    yield put(getMoreHcpListError(error));
  }
}

export function* getHcp(action: HcpGetRequestAction): Saga<void> {
  const { response, error }: Result<*, HcpEntity> = yield call(fetchData, {
    method: "get",
    url: `${ENDPOINT}/${action.payload.hcpId}`
  });

  if (response) {
    yield put(getHcpSuccess(response.data));
  }

  if (error) {
    yield put(getHcpError(error));
  }
}

export function* hcpSaga(): Saga<void> {
  yield takeLatest(HCP_LIST_REQUEST, getHcpList);
  yield takeLatest(HCP_LIST_MORE_REQUEST, getMoreHcp);
  yield takeLatest(HCP_GET_REQUEST, getHcp);
}
