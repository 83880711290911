// @flow
import {
  HCP_LIST_REQUEST,
  HCP_LIST_SUCCESS,
  HCP_LIST_ERROR,
  HCP_LIST_MORE_REQUEST,
  HCP_LIST_MORE_SUCCESS,
  HCP_LIST_MORE_ERROR,
  HCP_GET_REQUEST,
  HCP_GET_SUCCESS,
  HCP_GET_ERROR,
  LOGOUT_DONE
} from "../constants/actionTypes";
import { ASC } from "../constants/sorting";

import type { Action } from "../actions/types";
import type { HcpState } from "./types";

const initialState: HcpState = {
  total: 0,
  idsList: [],
  entities: {},
  sortDirection: ASC,
  sortBy: "name",
  listLoading: false,
  moreRequested: false,
  listError: null,
  hcpDataLoading: false,
  hcpDataError: null
};

export default function hcpReducer(
  state: HcpState = initialState,
  action: Action
): HcpState {
  switch (action.type) {
    case HCP_LIST_REQUEST: {
      const { sortBy, sortDirection } = action.payload;

      return {
        ...state,
        total: 0,
        idsList: [],
        entities: {},
        sortBy: sortBy ? sortBy : state.sortBy,
        sortDirection: sortDirection
          ? sortDirection.toLowerCase()
          : state.sortDirection,
        listLoading: true
      };
    }
    case HCP_LIST_SUCCESS: {
      const { entities, result } = action.payload;
      const { items, total, sortBy, sortDirection } = result;
      const idsList = [...items, ...Array(total).slice(items.length)];

      return {
        ...state,
        total,
        idsList,
        entities: {
          ...state.entities,
          ...entities.hcp
        },
        sortBy: sortBy ? sortBy : state.sortBy,
        sortDirection: sortDirection
          ? sortDirection.toLowerCase()
          : state.sortDirection,
        listLoading: false
      };
    }
    case HCP_LIST_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        listLoading: false,
        listError: error
      };
    }
    case HCP_LIST_MORE_REQUEST: {
      return {
        ...state,
        moreRequested: true
      };
    }
    case HCP_LIST_MORE_SUCCESS: {
      const { result, entities } = action.payload;
      const { items, total, skip, take } = result;
      const idsList = [
        ...state.idsList.slice(0, skip),
        ...items,
        ...state.idsList.slice(skip + take)
      ];

      return {
        ...state,
        idsList,
        entities: {
          ...state.entities,
          ...entities.hcp
        },
        total,
        moreRequested: false
      };
    }
    case HCP_LIST_MORE_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        moreRequested: false,
        listError: error
      };
    }
    case HCP_GET_REQUEST: {
      return {
        ...state,
        hcpDataLoading: true,
        hcpDataError: null
      };
    }
    case HCP_GET_SUCCESS: {
      const hcpEntity = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          [hcpEntity.id]: hcpEntity
        },
        hcpDataLoading: false
      };
    }
    case HCP_GET_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        hcpDataLoading: false,
        hcpDataError: error
      };
    }
    case LOGOUT_DONE:
      return initialState;
    default:
      return state;
  }
}
