// @flow
import {
  DOSING_STRATEGY_GET_REQUEST,
  DOSING_STRATEGY_GET_SUCCESS,
  DOSING_STRATEGY_GET_ERROR,
  DOSING_STRATEGY_SAVE_REQUEST,
  DOSING_STRATEGY_SAVE_OPTIMISTIC_SUCCESS,
  DOSING_STRATEGY_SAVE_DONE,
  DOSE_ADJUSTMENT_SAVE_REQUEST,
  SLIDING_SCALE_SAVE_SUCCESS,
  CORRECTION_FACTOR_SAVE_SUCCESS,
  REMOVE_DOSE_ADJUSTMENT_SUCCESS,
  SLIDING_SCALE_SAVE_ERROR,
  CORRECTION_FACTOR_SAVE_ERROR,
  REMOVE_DOSE_ADJUSTMENT_ERROR,
  FIXED_DOSE_SAVE_REQUEST,
  FIXED_DOSE_SAVE_ERROR,
  FIXED_DOSE_SAVE_SUCCESS
} from "../constants/actionTypes";

import type {
  DosingStrategySavePayload,
  GetDosingStrategyAction,
  GetDosingStrategySuccessAction,
  GetDosingStrategyErrorAction,
  SaveDosingStrategyAction,
  SaveDosingStrategyOptimisticSuccessAction,
  SaveDosingStrategyDoneAction,
  FixedDoseSaveRequestAction,
  FixedDoseSaveSuccessAction,
  FixedDoseSaveErrorAction,
  DoseAdjustmentSaveRequestAction,
  SlidingScaleSaveSuccessAction,
  SlidingScaleSaveErrorAction,
  CorrectionFactorSaveSuccessAction,
  CorrectionFactorSaveErrorAction,
  RemoveDoseAdjustmentSuccessAction,
  RemoveDoseAdjustmentErrorAction
} from "./types/dosingStrategyActions";
import type { APIErrors } from "../utils/errors";
import type { DosingStrategyEntity } from "../reducers/types";

export const getDosingStrategy = (
  patientId: string
): GetDosingStrategyAction => ({
  type: DOSING_STRATEGY_GET_REQUEST,
  payload: {
    patientId
  }
});

export const getDosingStrategySuccess = (
  dosingStrategy: DosingStrategyEntity
): GetDosingStrategySuccessAction => ({
  type: DOSING_STRATEGY_GET_SUCCESS,
  payload: dosingStrategy
});

export const getDosingStrategyError = (
  error: APIErrors<*>
): GetDosingStrategyErrorAction => ({
  type: DOSING_STRATEGY_GET_ERROR,
  payload: {
    error
  }
});

export const saveDosingStrategy = (
  dosingStrategy: DosingStrategySavePayload
): SaveDosingStrategyAction => ({
  type: DOSING_STRATEGY_SAVE_REQUEST,
  payload: dosingStrategy
});

export const saveDosingStrategyOptimisticSuccess = (
  dosingStrategy: DosingStrategySavePayload
): SaveDosingStrategyOptimisticSuccessAction => ({
  type: DOSING_STRATEGY_SAVE_OPTIMISTIC_SUCCESS,
  payload: dosingStrategy
});

export const saveDosingStrategyDone = (
  dosingStrategyId: ?string
): SaveDosingStrategyDoneAction => ({
  type: DOSING_STRATEGY_SAVE_DONE,
  payload: { dosingStrategyId }
});

export const saveFixedDoseRequest = (
  dosingStrategy: DosingStrategySavePayload
): FixedDoseSaveRequestAction => ({
  type: FIXED_DOSE_SAVE_REQUEST,
  payload: dosingStrategy
});

export const saveFixedDoseSuccess = (
  dosingStrategy: DosingStrategyEntity
): FixedDoseSaveSuccessAction => ({
  type: FIXED_DOSE_SAVE_SUCCESS,
  payload: dosingStrategy
});

export const saveFixedDoseError = (
  error: APIErrors<*>
): FixedDoseSaveErrorAction => ({
  type: FIXED_DOSE_SAVE_ERROR,
  payload: { error }
});

export const saveDoseAdjustmentRequest = (
  dosingStrategy: DosingStrategySavePayload
): DoseAdjustmentSaveRequestAction => ({
  type: DOSE_ADJUSTMENT_SAVE_REQUEST,
  payload: dosingStrategy
});

export const saveSlidingScaleSuccess = (
  dosingStrategy: DosingStrategyEntity
): SlidingScaleSaveSuccessAction => ({
  type: SLIDING_SCALE_SAVE_SUCCESS,
  payload: dosingStrategy
});

export const saveCorrectionFactorSuccess = (
  dosingStrategy: DosingStrategyEntity
): CorrectionFactorSaveSuccessAction => ({
  type: CORRECTION_FACTOR_SAVE_SUCCESS,
  payload: dosingStrategy
});

export const removeDoseAdjustmentSuccess = (
  dosingStrategy: DosingStrategyEntity
): RemoveDoseAdjustmentSuccessAction => ({
  type: REMOVE_DOSE_ADJUSTMENT_SUCCESS,
  payload: dosingStrategy
});

export const saveSlidingScaleError = (
  error: APIErrors<*>
): SlidingScaleSaveErrorAction => ({
  type: SLIDING_SCALE_SAVE_ERROR,
  payload: { error }
});
export const saveCorrectionFactorError = (
  error: APIErrors<*>
): CorrectionFactorSaveErrorAction => ({
  type: CORRECTION_FACTOR_SAVE_ERROR,
  payload: { error }
});
export const removeDoseAdjustmentError = (
  error: APIErrors<*>
): RemoveDoseAdjustmentErrorAction => ({
  type: REMOVE_DOSE_ADJUSTMENT_ERROR,
  payload: { error }
});
