// @flow
import * as React from "react";
import { useSelector } from "react-redux";
import { isHcp, isResearcher } from "../selectors/user";

import type { State, UserState } from "../reducers/types";

export default function withRestriction<Config: *>(
  WrappedComponent: React.ComponentType<Config>,
  accessor: (user: UserState) => boolean
): React.ComponentType<Config> {
  return function RestrictedComponent(props: Config) {
    const user = useSelector(({ user }: State) => user);

    return accessor(user) ? <WrappedComponent {...props} /> : null;
  };
}

export const HcpFragment = withRestriction<*>(React.Fragment, isHcp);
export const ResearcherFragment = withRestriction<*>(
  React.Fragment,
  isResearcher
);
