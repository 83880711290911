// @flow
import * as React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import NegativeButton from "../../components/NegativeButton";

import {
  deletePatient,
  closeDeletePatientDialog
} from "../../actions/patientsActions";

import type { State } from "../../reducers/types";
import { useTranslation } from "react-i18next";

const { useCallback } = React;

export default function DeletePatientModal(props: *) {
  const { t } = useTranslation();
  const { open, patient, patientId } = useSelector(({ patients }: State) => {
    const {
      showDeleteConfirm: open,
      confirmDeleteFor: patientId,
      patientsEntities
    } = patients;

    return {
      patientId,
      open,
      patient: patientsEntities[patientId]
    };
  }, shallowEqual);
  const dispatch = useDispatch();
  const closeModal = useCallback(() => {
    dispatch(closeDeletePatientDialog(patientId));
  }, [dispatch, patientId]);
  const confirmPatientDelete = useCallback(() => {
    closeModal();
    dispatch(deletePatient(patientId, true));
  }, [closeModal, dispatch, patientId]);

  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle>
        {t("pages.deletePatient.title")} {patient ? `#${patient.subjectNumber}` : ""}
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="body2" color="textSecondary" component="p">
          {t("pages.deletePatient.confirmation")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} data-qa="cancelButton">{t("actions.cancel")}</Button>
        <NegativeButton color="primary" onClick={confirmPatientDelete} data-qa="deleteButton">
          {t("actions.delete")}
        </NegativeButton>
      </DialogActions>
    </Dialog>
  );
}
