// @flow
import mapValues from "lodash/mapValues";
import min from "lodash/min";
import max from "lodash/max";
import startOfDay from "date-fns/startOfDay";
import fromUnixTime from "date-fns/fromUnixTime";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import differenceInMinutes from "date-fns/differenceInMinutes";
import { hoursToMs, msToHours } from "../../../utils/time";
import i18n from "../../../i18n";
import type { GlucoseReadingWithMealEvent, HeartRateItem, MealEvent, StepItem } from "../../../reducers/types";

export const chartMargins = {
  right: 15
};
export const chartBordersStyle = {
  bottom: { fill: "#fff" },
  left: { fill: "#fff" },
  right: { fill: "#fff" },
  top: { fill: "#fff" }
};

export const AROUND_MEAL = "AROUND_MEAL";
export const HOURS_24 = "24_HOURS";
export const ABS_VALUES = "ABS";
export const DELTA_VALUES = "DELTA";

export const ABS_X_DOMAIN = [0, hoursToMs(24)]; // from 0 to 24 hours per day
export const DELTA_X_DOMAIN = [hoursToMs(-2), hoursToMs(6)]; // from -2 hours till +6 hours
export const ABS_X_TICK_VALUES = [
  0,
  2,
  4,
  6,
  8,
  10,
  12,
  14,
  16,
  18,
  20,
  22,
  24
].map<number>(hoursToMs);
export const DELTA_X_TICK_VALUES = [-2, 0, 2, 4, 6].map<number>(hoursToMs);
export const MINIMUM_MINUTES_GAP = 17; // minimum time gap between chart points to show line break on the chart
export const xTickFormatAbs = (
  value: number,
  index: number,
  scale: *,
  tickTotal: number
) => {
  const hours = msToHours(value);
  if (i18n.language !== "en-US") {
    return (hours).toFixed(0);
  }
  const suffix = hours >= 12 && hours < 24 ? "PM" : "AM";
  let tick = (hours % 12).toFixed(0);

  tick = tick === "0" ? "12" : tick;

  return tick === "12" ? `${tick}${suffix}` : tick;
};
export const xTickFormatDelta = (
  value: number,
  index: number,
  scale: *,
  tickTotal: number
) => {
  const hours = `${value > 0 ? "+" : ""}${msToHours(value).toFixed(0)}`;

  return hours;
};
export const xAccessor = (
  d: GlucoseReadingWithMealEvent | StepItem | HeartRateItem
) => {
  const date = fromUnixTime(d.time);
  return differenceInMilliseconds(date, startOfDay(date));
};
export const yGlucoseTraceAccesssor = (d: GlucoseReadingWithMealEvent) =>
  d.value;
export const yMealEventAccessor = (d: GlucoseReadingWithMealEvent) =>
  d.mealEventId ? d.value : null;
export const isSameMealOption = (mealEvent: ?MealEvent, mealOptionId: string) =>
  mealEvent ? mealEvent.mealOption.id === mealOptionId : false;
export const yStepsAccessor = (d: StepItem) => d.steps;
export const yHeartRateAccessor = (d: HeartRateItem) => d.value;

export const getDomain = (
  series: { x: number, y: number }[],
  axis: "x" | "y"
) => {
  const values = series.map(item => item[axis]);

  return [min(values), max(values)];
};
export const padDomain = (domain: number[], percentage: number = 20) => {
  const [min, max] = domain;
  const range = max - min;
  const delta = (range * percentage) / 100;

  return [Math.floor(min - delta), Math.ceil(max + delta)];
};

export function getAccessor<T>(
  accessor: (d: T) => number,
  deltaMode: boolean,
  referenceItem: T | void
): (d: T) => number {
  const refData = Boolean(referenceItem) ? referenceItem : null;
  const refValue = refData ? accessor(refData) : NaN;

  if (deltaMode && refData) {
    return (d: T) => accessor(d) - refValue;
  }

  return accessor;
}

export function getGlucoseNull(d: { x: number, y: number }) {
  return d.y !== null;
}

export function getHeartRateNull(d: HeartRateItem) {
  return d.value !== null;
}

export function mapGlucoseReadings(args: {
  readingsHash: { [dateString: string]: GlucoseReadingWithMealEvent[] },
  mealEvents: { [id: string]: MealEvent },
  mealOptionId: string,
  xDeltaMode: boolean,
  yDeltaMode: boolean
}): {
  [dateString: string]: Array<{
    ...$Exact<GlucoseReadingWithMealEvent>,
    x: number,
    y: number
  }>
} {
  const {
    readingsHash,
    mealEvents,
    mealOptionId,
    xDeltaMode,
    yDeltaMode
  } = args;

  return mapValues(
    readingsHash,
    (readings: GlucoseReadingWithMealEvent[], dateString: string) => {
      const readingWithMealEvent = readings.find(reading => {
        const { mealEventId } = reading;
        return isSameMealOption(mealEvents[mealEventId || ""], mealOptionId);
      });
      const getX = getAccessor<GlucoseReadingWithMealEvent>(
        xAccessor,
        xDeltaMode,
        readingWithMealEvent
      );
      const getY = getAccessor<GlucoseReadingWithMealEvent>(
        yGlucoseTraceAccesssor,
        yDeltaMode,
        readingWithMealEvent
      );

      return readings.reduce((result, reading, i, readings) => {
        const nextReading = readings[i + 1];

        result.push({
          ...reading,
          x: getX(reading),
          y: getY(reading)
        });

        if (nextReading) {
          const minutesDiff = Math.abs(
            differenceInMinutes(
              fromUnixTime(reading.time),
              fromUnixTime(nextReading.time)
            )
          );

          if (minutesDiff > MINIMUM_MINUTES_GAP) {
            // Insert fake empty measurement to indicate line break on the chart
            result.push({
              time: reading.time,
              value: null,
              mealEventId: null,
              x: getX(reading),
              y: null
            });
          }
        }

        return result;
      }, []);
    }
  );
}

export function mapHeartRateSeries(series: Array<HeartRateItem>) {
  return series.reduce((result, item, i, series) => {
    const nextItem = series[i + 1];

    result.push(item);

    if (nextItem) {
      const minutesDiff = Math.abs(
        differenceInMinutes(
          fromUnixTime(item.time),
          fromUnixTime(nextItem.time)
        )
      );

      if (minutesDiff > MINIMUM_MINUTES_GAP) {
        // Insert fake empty measurement to indicate line break on the chart
        result.push({
          time: item.time,
          value: null
        });
      }
    }

    return result;
  }, []);
}
