// @flow
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  PROFILE_INFO_REQUEST,
  PROFILE_INFO_ERROR,
  PROFILE_INFO_SUCCESS,
  LOGOUT,
  LOGOUT_DONE,
  PROFILE_LANGUAGE_REQUEST,
  PROFILE_LANGUAGE_SUCCESS,
  PROFILE_LANGUAGE_ERROR,
} from "../constants/actionTypes";
import type { UserState } from "./types";
import type { Action } from "../actions/types";

export const initialState: UserState = {
  userId: "",
  hcpId: null,
  role: -1,
  token: "",
  userName: "",
  email: "",
  userPicture: null,
  firstName: "",
  lastName: "",
  authenticating: false,
  isAuthenticated: false,
  loginError: null,
  profileError: null,
  loadingProfile: false,
  profileLoaded: false,
  profileLanguageError: null,
};

export default function userReducer(
  state: UserState = initialState,
  action: Action
): UserState {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        loginError: null,
        profileLoaded: false,
        authenticating: true
      };
    }
    case LOGIN_SUCCESS: {
      const { token, userId } = action.payload;

      return {
        ...state,
        userId,
        token,
        isAuthenticated: !!token,
        authenticating: false
      };
    }
    case LOGIN_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        loginError: error,
        isAuthenticated: false,
        authenticating: false
      };
    }
    case PROFILE_INFO_REQUEST: {
      return {
        ...state,
        profileError: null,
        loadingProfile: true
      };
    }
    case PROFILE_INFO_SUCCESS: {
      const {
        role,
        userId,
        hcpId,
        userName,
        userPicture,
        firstName,
        locale,
        lastName
      } = action.payload;
      return {
        ...state,
        role,
        userId,
        hcpId: hcpId || null,
        userName,
        userPicture,
        firstName,
        lastName,
        locale,
        profileError: null,
        profileLoaded: Boolean(userId) && Boolean(userName),
        loadingProfile: false
      };
    }
    case PROFILE_INFO_ERROR: {
      const { error } = action.payload;
      const {
        role,
        userId,
        hcpId,
        userName,
        userPicture,
        firstName,
        lastName
      } = initialState;

      return {
        ...state,
        role,
        userId,
        hcpId,
        userName,
        userPicture,
        firstName,
        lastName,
        profileError: error,
        profileLoaded: false,
        loadingProfile: false
      };
    }
    case PROFILE_LANGUAGE_REQUEST: {
      return {
        ...state,
        profileLoaded: false,
        loadingProfile: true,
      };
    }
    case PROFILE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        profileLoaded: true,
        loadingProfile: false,
      };
    }
    case PROFILE_LANGUAGE_ERROR: {
      const { error } = action.payload;
  
      return {
        ...state,
        profileLoaded: false,
        loadingProfile: false,
        profileLanguageError: error,
      };
    }
    case LOGOUT:
    case LOGOUT_DONE:
      return initialState;
    default:
      return state;
  }
}
