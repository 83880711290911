// @flow
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import BuildIcon from "@material-ui/icons/Build";
import makeStyles from "@material-ui/core/styles/makeStyles";

import PaperPage from "./PaperPage";

import { HOME_PATH } from "../constants/routes";

import type { ContextRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  h1: {
    fontSize: theme.typography.pxToRem(192),
    marginBottom: theme.spacing(1),
    color: theme.palette.text.disabled
  },
  h2: {
    fontSize: theme.typography.pxToRem(28),
    marginBottom: theme.spacing(2)
  },
  paragraph: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(22),
    color: theme.palette.text.disabled,
    marginBottom: theme.spacing(4),
    width: "100%"
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));

const { useEffect } = React;

export default function PageStub(props: ContextRouter) {
  const { match, location } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    console.log("Location Pathname: %s", location.pathname);
    console.log("Params: %o", match.params);
    console.log("Exact: %s", match.isExact);
    console.log("Match path: %s", match.path);
  }, [location.pathname, match.isExact, match.params, match.path]);

  return (
    <PaperPage>
      <Typography variant="h1" className={classes.h1}>
        <BuildIcon fontSize="inherit" />
      </Typography>
      <Typography variant="h2" className={classes.h2}>
        {t("components.pageStub.title")}
      </Typography>
      <Typography component="p" variant="body2" className={classes.paragraph}>
        {t("components.pageStub.description")}
      </Typography>
      <Button
        color="primary"
        variant="contained"
        component={RouterLink}
        to={HOME_PATH}
      >
        <HomeIcon className={classes.buttonIcon} />
        {t("actions.goHome")}
      </Button>
    </PaperPage>
  );
}
