// @flow
const USER_ID_PARAM = ":userId";
const HCP_ID_PARAM = ":hcpId";
const PATIENT_ID_PARAM = ":patientId";
const MEAL_ID_PARAM = ":mealId";

export const HOME_PATH = "/";
export const LOGIN_PATH = "/login";
export const LOGOUT_PATH = "/logout";
export const WEBAPP_LEGAL_NOTICES_PATH = "/legalnoticesweb";
export const MOBILE_LEGAL_NOTICES_PATH = "/legalnoticesmobile";

export const GLUCOSE_EMULATION_PATH = "/glucose-upload";

/* Admin Routes */
export const getUserDetailsPath = (userId: string) =>
  `${ADMIN_USER_LIST_PATH}/${userId}`;
export const getUserEditPath = (userId: string) =>
  `${getUserDetailsPath(userId)}/edit`;

export const ADMIN_HOME_PATH = `/admin`;
export const ADMIN_USER_LIST_PATH = `${ADMIN_HOME_PATH}/users`;
export const ADMIN_USER_CREATE_PATH = `${ADMIN_USER_LIST_PATH}/create`;
export const ADMIN_USER_DETAILS_PATH = getUserDetailsPath(USER_ID_PARAM);
export const ADMIN_USER_EDIT_PATH = getUserEditPath(USER_ID_PARAM);

/* Routes for Researcher user */
export const getHcpPatientsPath = (hcpId: string) =>
  `${HCP_LIST_PATH}/${hcpId}/patients`;
export const HCP_LIST_PATH = "/hcp";
export const HCP_PATIENTS_LIST_PATH = getHcpPatientsPath(HCP_ID_PARAM);

/* Routes for HCP user */
export const getPatientDetailsPath = (patientId: string) =>
  `${PATIENTS_LIST_PATH}/${patientId}`;
export const getPatientEditPath = (patientId: string) =>
  `${getPatientDetailsPath(patientId)}/edit`;
export const getPatientDosingPath = (patientId: string) =>
  `${getPatientDetailsPath(patientId)}/dosing-strategies`;
export const getPatientMealMenuPath = (patientId: string) =>
  `${getPatientDetailsPath(patientId)}/meal`;
export const getPatientMealCreatePath = (patientId: string) =>
  `${getPatientMealMenuPath(patientId)}/create`;
export const getPatientMealDetailsPath = (patientId: string, mealId: string) =>
  `${getPatientMealMenuPath(patientId)}/${mealId}`;
export const getPatientMealEditPath = (patientId: string, mealId: string) =>
  `${getPatientMealDetailsPath(patientId, mealId)}/edit`;
export const getPatientMealAnalysisPath = (patientId: string, mealId: string) =>
  `${getPatientMealDetailsPath(patientId, mealId)}/analysis`;

export const PATIENTS_LIST_PATH = `/patients`;
export const PATIENT_CREATE_PATH = `${PATIENTS_LIST_PATH}/create`;
export const PATIENT_DETAILS_PATH = getPatientDetailsPath(PATIENT_ID_PARAM);
export const PATIENT_EDIT_PATH = getPatientEditPath(PATIENT_ID_PARAM);
export const PATIENT_DOSING_PATH = getPatientDosingPath(PATIENT_ID_PARAM);
export const PATIENT_MEAL_MENU_PATH = getPatientMealMenuPath(PATIENT_ID_PARAM);
export const PATIENT_MEAL_CREATE_PATH = getPatientMealCreatePath(
  PATIENT_ID_PARAM
);
export const PATIENT_MEAL_DETAILS_PATH = getPatientMealDetailsPath(
  PATIENT_ID_PARAM,
  MEAL_ID_PARAM
);
export const PATIENT_MEAL_EDIT_PATH = getPatientMealEditPath(
  PATIENT_ID_PARAM,
  MEAL_ID_PARAM
);
export const PATIENT_MEAL_ANALYSIS_PATH = getPatientMealAnalysisPath(
  PATIENT_ID_PARAM,
  MEAL_ID_PARAM
);
