// @flow
import * as React from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { isHcp, isResearcher } from "../../../selectors/user";
import {
  getHcpPatientsPath,
  PATIENTS_LIST_PATH,
  HOME_PATH
} from "../../../constants/routes";

import type { ContextRouter } from "react-router-dom";

import type { State } from "../../../reducers/types";

type Props = {
  ...$Exact<ContextRouter>,
  disabled: boolean,
  className?: string
};

export default withRouter(function OkButton(props: Props) {
  const { className, match, disabled } = props;
  const { t } = useTranslation();
  const patientId = match.params.patientId || "";
  const to = useSelector(({ user, patients }: State) => {
    const isHcpUser = isHcp(user);
    const isResearcherUser = isResearcher(user);
    const selectedPatient = patients.patientsEntities[patientId];
    const hcpId =
      match.params.hcpId || (selectedPatient ? selectedPatient.hcpId : "");

    if (isHcpUser) {
      return PATIENTS_LIST_PATH;
    }

    if (isResearcherUser) {
      return getHcpPatientsPath(hcpId);
    }

    return HOME_PATH;
  });

  return (
    <Button
      component={RouterLink}
      className={className}
      to={to}
      variant="contained"
      color="primary"
      disabled={disabled}
    >
      {t("actions.ok")}
    </Button>
  );
});
