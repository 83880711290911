// @flow
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { HOME_PATH } from "../constants/routes";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  h1: {
    fontSize: theme.typography.pxToRem(192),
    marginBottom: theme.spacing(1)
  },
  h2: {
    fontSize: theme.typography.pxToRem(28),
    marginBottom: theme.spacing(2)
  },
  paragraph: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(22),
    color: theme.palette.text.disabled,
    marginBottom: theme.spacing(4),
    width: "100%"
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));
const useContainerStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    marginTop: theme.spacing(13)
  },
  maxWidthLg: {
    maxWidth: 720
  }
}));
const usePaperStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexFlow: "column",
    textAlign: "center",
    alignItems: "center",
    padding: theme.spacing(5, 19, 7)
  },
  elevation0: {
    boxShadow: "0 10px 40px 0 rgba(0, 0, 0, 0.2)"
  }
}));

export default function NotFoundPage() {
  const classes = useStyles();
  const paperClasses = usePaperStyles();
  const containerClasses = useContainerStyles();
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg" classes={containerClasses}>
      <Paper elevation={0} classes={paperClasses}>
        <Typography variant="h1" className={classes.h1}>
          404
        </Typography>
        <Typography variant="h2" className={classes.h2}>
          {t("pages.notFound.title")}
        </Typography>
        <Typography component="p" variant="body2" className={classes.paragraph}>
          {t("pages.notFound.description")}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          component={RouterLink}
          to={HOME_PATH}
        >
          <HomeIcon className={classes.buttonIcon} />
          {t("actions.goHome")}
        </Button>
      </Paper>
    </Container>
  );
}
