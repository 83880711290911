import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import enUS from "./en-US.json";
import enGB from "./en-GB.json";
import de from "./de.json";
import fr from "./fr.json";

i18n
  .use(detector)
  .init({
    resources: {
      "en-US": enUS,
      "en-GB": enGB,
      "de": de,
      "fr": fr
    },
    fallbackLng: "en-US",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;