// @flow
import React from "react";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import RetryIcon from "@material-ui/icons/Autorenew";
import ExitIcon from "@material-ui/icons/ExitToApp";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import makeStyles from "@material-ui/core/styles/makeStyles";

import PaperPage from "../components/PaperPage";
import { logout, getProfileInfo } from "../actions/userActions";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  h1: {
    fontSize: theme.typography.pxToRem(192),
    marginBottom: theme.spacing(1),
    color: theme.palette.error.main
  },
  h2: {
    fontSize: theme.typography.pxToRem(28),
    marginBottom: theme.spacing(2),
    color: theme.palette.error.main
  },
  paragraph: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(22),
    marginBottom: theme.spacing(4),
    width: "100%"
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));

export default function ErrorPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  return (
    <PaperPage>
      <Typography variant="h1" className={classes.h1}>
        <ErrorIcon fontSize="inherit" />
      </Typography>
      <Typography variant="h2" className={classes.h2}>
        {t("pages.profileErrorPage.title")}
      </Typography>
      <Typography component="p" variant="body2" className={classes.paragraph}>
        {t("pages.profileErrorPage.description")}
      </Typography>
      <Grid container>
        <Grid item xs>
          <Button
            color="primary"
            variant="contained"
            onClick={() => dispatch(getProfileInfo())}
          >
            <RetryIcon className={classes.buttonIcon} />
            {t("actions.retry")}
          </Button>
        </Grid>
        <Grid item xs>
          <Button variant="contained" onClick={() => dispatch(logout())}>
            <ExitIcon className={classes.buttonIcon} />
            {t("actions.logout")}
          </Button>
        </Grid>
      </Grid>
    </PaperPage>
  );
}
