// @flow
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

export default createMuiTheme({
  palette: {
    secondary: { main: "#00b8d4", dark: "#00838f", contrastText: "#fff" },
    primary: { main: "#ff6d00", contrastText: "#fff" },
    error: { main: "#c92100" },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "#212121",
      disabled: "#757575"
    },
    divider: "#bcbcbc",
    action: {
      hover: "#f4f4f4"
    }
  },
  overrides: {
    MuiButton: {
      text: {
        color: "#212121"
      }
    },
    MuiCheckbox: {
      root: {
        color: "#707070"
      }
    },
    MuiInputAdornment: {
      root: {
        "& .MuiTypography-root": {
          fontSize: 14,
          color: "#757575"
        }
      }
    },
    MuiInputLabel: {
      root: {
        color: "#757575"
      },
      marginDense: {},
      outlined: {
        "&$marginDense": {
          transform: "translate(14px, 16px) scale(1)"
        }
      }
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderColor: "#bcbcbc"
        }
      }
    },
    MuiOutlinedInput: {
      inputMarginDense: {
        paddingTop: 14.5,
        paddingBottom: 14.5
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.4)"
      }
    },
    MuiSwitch: {
      switchBase: {
        color: "#f1f1f1"
      }
    }
  }
});
