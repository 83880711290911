// @flow
import * as React from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import compact from "lodash";
import { Link as RouterLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";

import VirtualizedTable, {
  MuiVirtualizedTable
} from "../../components/Table/VirtualizedTable";
import PageLayout from "../../components/PageLayout";
import Disclaimer from "../../components/Disclaimer";
import ResearcherBreadcrumbs from "../Researcher/ResearcherBreadcrumbs";
import TimePeriodFilter from "./TimePeriodFilter";
import DeletePatientModal from "./DeletePatientModal";
import ForgetDeviceConfirmModal from "./ForgetDeviceConfirmModal";
import DownloadSubjectDataModal from "./DownloadSubjectDataModal";
import patientsColumns from "./patientsColumns";
import withRestriction from "../withRestriction";
import {
  getPatientsList,
  getMorePatientsList
} from "../../actions/patientsActions";
import { PATIENT_CREATE_PATH } from "../../constants/routes";
import { isHcp, isResearcher } from "../../selectors/user";

import type { ContextRouter } from "react-router-dom";
import type { WithStyles } from "@material-ui/core";
import type { SortOrderShortEnum } from "../../constants/sorting";
import type { State as AppState, PatientEntity } from "../../reducers/types";

type PatientItem = PatientEntity;
type OwnProps = {|
  ...$Exact<WithStyles>,
  ...ContextRouter,
  ...$Exact<WithTranslation>
|};

type ConnectedProps = $ReadOnly<{|
  loading: boolean,
  moreLoading: boolean,
  sortBy: string,
  sortDirection: SortOrderShortEnum,
  patientIdsList: (?string)[],
  patientsEntities: {
    [patientId: string]: PatientEntity
  },
  patientsTotal: number,
  error: string | null,
  patientsDeleting: string[],
  hcpId: string,
  timePeriod: number,
  pageTitle: string
|}>;

type ConnectedActions = {|
  +getPatientsList: typeof getPatientsList,
  +getMorePatientsList: typeof getMorePatientsList
|};

type Props = {
  ...OwnProps,
  ...ConnectedProps,
  ...ConnectedActions
};

type Config = React.Config<Props, {||}>;
type OwnConfig = React.Config<OwnProps, {||}>;

const useStyles: Function = withStyles(theme => ({
  page: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column"
  },
  disclaimer: {
    margin: theme.spacing(-2, 0, 0),
    textAlign: "center",
    color: theme.palette.primary.main
  },
  header: {
    marginBottom: theme.spacing(3)
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  tableContainer: {
    flex: "1",
    minHeight: 200
  },
  filterDropdown: {
    width: 180,
    marginBottom: theme.spacing(2)
  },
  tableHeader: {
    fontSize: theme.typography.pxToRem(10),
    lineHeight: 1.1
  }
}));

const mapStateToProps = (
  { hcp, patients, user }: AppState,
  ownProps: $ReadOnly<OwnProps>
): ConnectedProps => {
  const {
    patientsLoading: loading,
    patientIdsList,
    patientsEntities,
    patientsTotal,
    morePatientsRequested: moreLoading,
    sortBy,
    sortDirection,
    timePeriod,
    patientsError: error,
    patientsDeleting
  } = patients;
  const { t } = ownProps;
  const { entities: hcpEntities } = hcp;
  const isHcpUser = isHcp(user);
  const isResearcherUser = isResearcher(user);
  const { hcpId: hcpIdParam } = ownProps.match.params;
  const hcpId = (!hcpIdParam && isHcpUser ? user.hcpId : hcpIdParam) || "";
  const selectedHcp = hcpEntities[hcpId];

  return {
    loading,
    patientIdsList,
    patientsEntities,
    patientsTotal,
    moreLoading,
    sortBy,
    sortDirection,
    timePeriod,
    error: error ? error.title : "",
    patientsDeleting,
    hcpId,
    pageTitle:
      isResearcherUser && selectedHcp
        ? `${selectedHcp.name} ${t("pages.patientList.title")}`
        : t("pages.patientList.title")
  };
};

const HcpFab = withRestriction(Fab, isHcp);

export class PatientsList extends React.Component<Props> {
  static pageSize = 30;

  tableRef = React.createRef<MuiVirtualizedTable>();

  componentDidMount() {
    const { sortBy, sortDirection, hcpId, timePeriod } = this.props;

    this.props.getPatientsList({
      skip: 0,
      take: PatientsList.pageSize,
      sortBy,
      sortDirection,
      hcpId,
      timePeriod
    });
  }

  handleSort = (sortBy: string, sortDirection: SortOrderShortEnum) => {
    const { hcpId, timePeriod: period } = this.props;
    const tableRef = this.tableRef.current;

    if (tableRef) {
      tableRef.scrollToRow(0);
      tableRef.resetLoadMoreRowsCache();
    }

    this.props.getPatientsList({
      skip: 0,
      take: PatientsList.pageSize,
      sortBy,
      sortDirection,
      hcpId,
      period
    });
  };

  handleFilterChange = (value: number) => {
    const { sortBy, sortDirection, hcpId } = this.props;

    this.props.getPatientsList({
      skip: 0,
      take: PatientsList.pageSize,
      sortBy,
      sortDirection,
      hcpId,
      period: value
    });
  };

  loadMoreRows = ({
    startIndex,
    stopIndex
  }: {
    startIndex: number,
    stopIndex: number
  }) => {
    const {
      loading,
      getMorePatientsList,
      patientIdsList,
      patientsTotal,
      sortBy,
      sortDirection,
      hcpId,
      timePeriod: period
    } = this.props;

    if (loading || compact(patientIdsList).length >= patientsTotal) {
      return;
    }

    getMorePatientsList({
      take: stopIndex - startIndex + 1,
      skip: startIndex,
      sortBy,
      sortDirection,
      hcpId,
      period
    });
  };

  rowGetter = ({
    index
  }: {
    index: number
  }): PatientItem | { loading: boolean } => {
    const { patientIdsList, patientsEntities } = this.props;
    const patientId = patientIdsList[index];
    const patient = patientId ? patientsEntities[patientId] : undefined;

    if (patient) {
      return patient;
    }

    return { loading: true };
  };

  isRowLoaded = ({ index }: { index: number }) => {
    return !!this.props.patientIdsList[index];
  };

  isUserDeleting(patientId: string) {
    return this.props.patientsDeleting.includes(patientId);
  }

  render() {
    const {
      classes,
      loading,
      error,
      patientsTotal,
      sortBy,
      sortDirection,
      timePeriod,
      pageTitle
    } = this.props;

    return (
      <PageLayout className={classes.page}>
        <Disclaimer className={classes.disclaimer} />
        <Grid container alignItems="flex-end" spacing={2}>
          <Grid item xs={6}>
            <ResearcherBreadcrumbs />
            <Typography component="h1" variant="h5" className={classes.header}>
              {pageTitle}
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <TimePeriodFilter
              className={classes.filterDropdown}
              value={timePeriod}
              onChange={this.handleFilterChange}
            />
          </Grid>
        </Grid>
        <Paper className={classes.tableContainer}>
          <VirtualizedTable
            classes={{ headCell: classes.tableHeader }}
            headerHeight={64}
            ref={this.tableRef}
            loading={loading}
            error={!!error}
            infiniteLoad
            minimumBatchSize={PatientsList.pageSize}
            threshold={PatientsList.pageSize + 10}
            loadMoreRows={this.loadMoreRows}
            isRowLoaded={this.isRowLoaded}
            rowCount={patientsTotal}
            rowGetter={this.rowGetter}
            rowKey="id"
            columns={patientsColumns}
            sortDirection={sortDirection}
            sortBy={sortBy}
            onSort={this.handleSort}
          />
        </Paper>
        <HcpFab
          component={RouterLink}
          className={classes.fab}
          color="primary"
          to={PATIENT_CREATE_PATH}
          data-qa="createButton"
        >
          <AddIcon />
        </HcpFab>
        <DeletePatientModal />
        <ForgetDeviceConfirmModal />
        <DownloadSubjectDataModal />
      </PageLayout>
    );
  }
}

export default compose(
  useStyles,
  withTranslation(),
  connect<Config, OwnConfig, _, _, _, _>(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        getPatientsList,
        getMorePatientsList
      },
      dispatch
    )
  )
)(PatientsList);
