// @flow
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPatient,
  clearPatientFormErrors
} from "../../actions/patientsActions";

import type { State } from "../../reducers/types";

export function usePatientDataEffect(patientId: string) {
  const dispatch = useDispatch();
  const shouldFetchPatient = useSelector(({ patients }: State) => {
    const {
      patientsEntities,
      patientDataLoading: loading,
      getPatientError: error
    } = patients;
    const loaded = Boolean(patientsEntities[patientId]);

    return !error && !loading && !loaded && Boolean(patientId);
  });

  useEffect(() => {
    if (shouldFetchPatient) {
      dispatch(getPatient(patientId));
    }
  }, [dispatch, patientId, shouldFetchPatient]);
}

export function useClearFormErrors() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearPatientFormErrors());
  }, [dispatch]);
}
