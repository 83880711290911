// @flow
import getUnixTime from "date-fns/getUnixTime";
import orderBy from "lodash/orderBy";
import pick from "lodash/pick";
import {
  DOSING_STRATEGY_GET_REQUEST,
  DOSING_STRATEGY_GET_SUCCESS,
  DOSING_STRATEGY_GET_ERROR,
  DOSING_STRATEGY_SAVE_REQUEST,
  DOSING_STRATEGY_SAVE_OPTIMISTIC_SUCCESS,
  DOSING_STRATEGY_SAVE_DONE,
  FIXED_DOSE_SAVE_SUCCESS,
  FIXED_DOSE_SAVE_ERROR,
  SLIDING_SCALE_SAVE_SUCCESS,
  CORRECTION_FACTOR_SAVE_SUCCESS,
  REMOVE_DOSE_ADJUSTMENT_SUCCESS,
  SLIDING_SCALE_SAVE_ERROR,
  CORRECTION_FACTOR_SAVE_ERROR,
  REMOVE_DOSE_ADJUSTMENT_ERROR,
  LOGOUT_DONE
} from "../constants/actionTypes";
import { NO_ADJUSTMENT } from "../constants/dosing";
import { ASC } from "../constants/sorting";

import type { DosingStrategyState, CorrectionFactor } from "./types";
import type { Action } from "../actions/types";

const initialState: DosingStrategyState = {
  morningBasalDose: 0,
  eveningBasalDose: 0,
  breakfast: 0,
  lunch: 0,
  dinner: 0,
  snack: 0,
  doseAdjustmentType: NO_ADJUSTMENT,
  correctionFactor: null,
  slidingScale: [],
  id: "",
  patientId: "",
  createdAt: null,
  offset: 0,
  saving: false,
  loading: false,
  loadingError: null,
  fixedDoseError: null,
  doseAdjustmentError: null
};

function orderSlidingScales(slidingScale) {
  return orderBy(
    slidingScale,
    ["glucoseRangeLow", "glucoseRangeHigh"],
    [ASC, ASC]
  );
}

function pickCorrectionFactor(correctionFactor): CorrectionFactor | null {
  return correctionFactor
    ? pick(correctionFactor, ["correctionFactor", "targetGlucose"])
    : null;
}

export default function dosingStrategyReducer(
  state: DosingStrategyState = initialState,
  action: Action
): DosingStrategyState {
  switch (action.type) {
    case DOSING_STRATEGY_GET_REQUEST: {
      const { patientId } = action.payload;
      const isDifferentPatient = patientId !== state.patientId;

      return {
        ...state,
        ...(isDifferentPatient ? initialState : {}),
        loading: true,
        loadingError: null
      };
    }
    case DOSING_STRATEGY_GET_SUCCESS: {
      const {
        offset,
        createdAt,
        morningBasalDose,
        eveningBasalDose,
        breakfast,
        lunch,
        dinner,
        snack,
        doseAdjustmentType,
        correctionFactor,
        slidingScale,
        id,
        patientId
      } = action.payload;
      return {
        ...state,
        morningBasalDose,
        eveningBasalDose,
        breakfast,
        lunch,
        dinner,
        snack,
        doseAdjustmentType,
        correctionFactor: pickCorrectionFactor(correctionFactor),
        slidingScale: orderSlidingScales(slidingScale),
        id,
        patientId,
        offset,
        createdAt: typeof createdAt === "number" ? createdAt : state.createdAt,
        loading: false
      };
    }
    case DOSING_STRATEGY_GET_ERROR: {
      const { error: loadingError } = action.payload;
      return {
        ...state,
        loading: false,
        loadingError
      };
    }
    case DOSING_STRATEGY_SAVE_REQUEST: {
      const { patientId } = action.payload;
      const createdAt = state.createdAt || getUnixTime(new Date());

      return {
        ...state,
        createdAt,
        patientId,
        saving: true,
        fixedDoseError: null,
        doseAdjustmentError: null
      };
    }
    case DOSING_STRATEGY_SAVE_OPTIMISTIC_SUCCESS: {
      /**
       * This action triggers state update to apply new values of dosing strategy
       * before actual server response (optimistic rendering)
       **/
      const {
        patientId,
        morningBasalDose,
        eveningBasalDose,
        breakfast,
        lunch,
        dinner,
        snack,
        doseAdjustmentType,
        slidingScale,
        correctionFactor
      } = action.payload;

      return {
        ...state,
        patientId,
        morningBasalDose,
        eveningBasalDose,
        breakfast,
        lunch,
        dinner,
        snack,
        doseAdjustmentType,
        slidingScale,
        correctionFactor
      };
    }
    case DOSING_STRATEGY_SAVE_DONE: {
      return {
        ...state,
        saving: false
      };
    }
    case FIXED_DOSE_SAVE_SUCCESS: {
      const {
        createdAt,
        morningBasalDose,
        eveningBasalDose,
        breakfast,
        lunch,
        dinner,
        snack,
        doseAdjustmentType,
        id,
        patientId
      } = action.payload;

      return {
        ...state,
        id,
        patientId,
        createdAt: typeof createdAt === "number" ? createdAt : state.createdAt,
        morningBasalDose,
        eveningBasalDose,
        breakfast,
        lunch,
        dinner,
        snack,
        doseAdjustmentType
      };
    }
    case SLIDING_SCALE_SAVE_SUCCESS: {
      const { createdAt, slidingScale, id, patientId } = action.payload;

      return {
        ...state,
        id,
        patientId,
        createdAt: typeof createdAt === "number" ? createdAt : state.createdAt,
        slidingScale: orderSlidingScales(slidingScale)
      };
    }
    case CORRECTION_FACTOR_SAVE_SUCCESS: {
      const { id, patientId, createdAt, correctionFactor } = action.payload;

      return {
        ...state,
        id,
        patientId,
        createdAt: typeof createdAt === "number" ? createdAt : state.createdAt,
        correctionFactor: pickCorrectionFactor(correctionFactor)
      };
    }
    case REMOVE_DOSE_ADJUSTMENT_SUCCESS: {
      const {
        createdAt,
        morningBasalDose,
        eveningBasalDose,
        breakfast,
        lunch,
        dinner,
        snack,
        id,
        patientId
      } = action.payload;
      return {
        ...state,
        createdAt: typeof createdAt === "number" ? createdAt : state.createdAt,
        morningBasalDose,
        eveningBasalDose,
        breakfast,
        lunch,
        dinner,
        snack,
        id,
        patientId,
        doseAdjustmentType: NO_ADJUSTMENT,
        correctionFactor: null,
        slidingScale: []
      };
    }
    case FIXED_DOSE_SAVE_ERROR: {
      const { error: fixedDoseError } = action.payload;

      return {
        ...state,
        fixedDoseError
      };
    }
    case SLIDING_SCALE_SAVE_ERROR:
    case CORRECTION_FACTOR_SAVE_ERROR:
    case REMOVE_DOSE_ADJUSTMENT_ERROR: {
      const { error: doseAdjustmentError } = action.payload;

      return {
        ...state,
        doseAdjustmentError
      };
    }
    case LOGOUT_DONE:
      return initialState;
    default:
      return state;
  }
}
