// @flow
import {
  call,
  put,
  select,
  take,
  fork,
  takeLatest,
  race
} from "redux-saga/effects";
import history from "../history";
import { fetchData } from "./fetchSaga";
import {
  LOGIN_REQUEST,
  LOGOUT,
  REQUEST_UNAUTHORIZED,
  PROFILE_INFO_REQUEST,
  PROFILE_LANGUAGE_REQUEST,
} from "../constants/actionTypes";
import {
  logoutDone,
  profileInfoSuccess,
  profileInfoError,
  getProfileInfo,
  loginSuccess,
  loginError,
  setProfileLanguageSuccess,
  setProfileLanguageError
} from "../actions/userActions";
import {
  showNotification
} from "../actions/notificationsActions";
import { setItem, getItem, removeItem } from "../utils/storage";
import { TOKEN_KEY, USER_ID_KEY } from "../constants/storageKeys";
import { HOME_PATH, LOGIN_PATH } from "../constants/routes";

import type { Saga } from "redux-saga";
import type { Action } from "../actions/types";
import type {
  LoginRequestAction,
  LoginRequestPayload,
  LoginSuccessPayload,
  ProfileInfoSuccessPayload,
  ProfileLanguageRequestAction,
} from "../actions/types/userActions";
import { APIError } from "../utils/errors";
import i18n from "../i18n";
import type { Result } from "./fetchSaga";
import type { State } from "../reducers/types";

export function selectUserState({ user }: State) {
  return user;
}

export function filterLogoutActions(action: Action): boolean {
  if (action.type === REQUEST_UNAUTHORIZED) {
    const { error } = action.payload;
    const type = error instanceof APIError ? error.type : "Error";

    return type !== "InvalidCredentials";
  }

  return action.type === LOGOUT;
}

export function* fetchProfileInfo(): Saga<void> {
  const { response, error }: Result<*, ProfileInfoSuccessPayload> = yield call(
    fetchData,
    {
      url: "account/current-user",
      method: "get"
    }
  );

  if (response) {
    i18n.changeLanguage(response.data.locale);
    yield put(profileInfoSuccess(response.data));
  }
  if (error) {
    yield put(profileInfoError({ error }));
  }
}

export function* setProfileLanguage(action: ProfileLanguageRequestAction): Saga<void> {
  const { locale } = action.payload;
  const { response, error }: Result<*, { }> = yield call(
    fetchData,
    {
      url: "account/settings",
      method: "post",
      data: {
        locale,
      }
    }
  );
  
  if (response) {
    i18n.changeLanguage(locale);
    yield put(setProfileLanguageSuccess());
  }
  if (error) {
    yield put(setProfileLanguageError({ error }));
  }
}

export function* authenticate(payload: {
  username: string,
  password: string
}): Saga<boolean> {
  const { username, password } = payload;

  const {
    response,
    error
  }: Result<LoginRequestPayload, LoginSuccessPayload> = yield call(fetchData, {
    url: "account/authenticate",
    method: "post",
    data: {
      username,
      password
    }
  });

  if (response) {
    yield call(setItem, TOKEN_KEY, response.data.token);
    yield call(setItem, USER_ID_KEY, response.data.userId);
    yield put(loginSuccess(response.data));

    return true;
  }

  if (error) {
    yield put(
      loginError({
        error
      })
    );

    return false;
  }

  return false;
}

/**
 * Effect to handle logging out
 */
export function* logout(): Saga<void> {
  yield fork(logoutRequest);
  yield call(removeItem, TOKEN_KEY);
  yield call(history.push, LOGIN_PATH);
  yield put(logoutDone());
}

export function* logoutRequest(): Saga<void> {
  const token = getItem(TOKEN_KEY) || "";
  const {error} = yield call(
    fetchData,
    {
      url: "account/logout",
      method: "post",
    },
    token
  );
  
  if (error) {
    yield put(showNotification({
      key: Date.now().toString(),
      text: error.toString(),
      variant: 'error'
    }));
  }
}

/* Login flow */
export function* loginFlow(): Saga<void> {
  while (true) {
    const loginAction: LoginRequestAction = yield take(LOGIN_REQUEST);
    const { username, password } = loginAction.payload;

    const raceState = yield race({
      authenticate: call(authenticate, {
        username,
        password
      }),
      logout: take(filterLogoutActions)
    });

    if (raceState.authenticate) {
      yield call(history.push, HOME_PATH);
      yield put(getProfileInfo());
    }
  }
}

/* Logout flow */
export function* logoutFlow(): Saga<void> {
  while (true) {
    yield take(filterLogoutActions);
    yield call(logout);
  }
}

export function* initializeUserFlow(): Saga<void> {
  /* Restore previous session if possible */
  const token = getItem(TOKEN_KEY) || "";
  const userId = getItem(USER_ID_KEY) || "";

  if (token) {
    yield put(loginSuccess({ token, userId }));
  }

  const user = yield select(selectUserState);

  if (user.isAuthenticated && !user.profileLoaded) {
    yield put(getProfileInfo());
  }
}

export function* userSaga(): Saga<void> {
  yield fork(loginFlow);
  yield fork(logoutFlow);
  yield takeLatest(PROFILE_INFO_REQUEST, fetchProfileInfo);
  yield takeLatest(PROFILE_LANGUAGE_REQUEST, setProfileLanguage);

  yield call(initializeUserFlow);
}
