// @flow
export const LOW_ACTIVITY = 0;
export const NORMAL_ACTIVITY = 1;
export const HIGH_ACTIVITY = 2;

export type ActivityIntensity =
  | typeof LOW_ACTIVITY
  | typeof NORMAL_ACTIVITY
  | typeof HIGH_ACTIVITY;

export const activitiesMap = new Map<ActivityIntensity, string>([
  [LOW_ACTIVITY, "Low"],
  [NORMAL_ACTIVITY, "Normal"],
  [HIGH_ACTIVITY, "High"]
]);
