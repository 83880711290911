// @flow
import * as React from "react";
import TextField from "@material-ui/core/TextField";
import wrapWithField from "./decorateWithField";

type Props = {
  ...React.ElementConfig<typeof TextField>,
  name: string,
  accept?: string,
  multiple?: boolean,
  onChange: (name: string) => (files: FileList | null) => any
};

const { useCallback, useMemo } = React;

function FileField(props: Props) {
  const {
    value, // Value is extracted, due to file input doesn't receive it programmatically
    onChange,
    accept,
    multiple,
    ...restProps
  } = props;
  const changeHandler = useMemo(() => onChange(props.name), [
    onChange,
    props.name
  ]);
  const handleChange = useCallback(
    function(evt: SyntheticInputEvent<*>) {
      changeHandler(evt.target.files);
    },
    [changeHandler]
  );

  return (
    <TextField
      type="file"
      InputLabelProps={{ shrink: true }}
      inputProps={{ accept, multiple }}
      onChange={handleChange}
      {...restProps}
    />
  );
}

FileField.defaultProps = {
  multiple: false
};

export default wrapWithField<FileList | null, Props>(FileField);
