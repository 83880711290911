// @flow
import * as React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PanePaper from "./PanePaper";
import GlucoseChart from "./GlucoseChart";
import StepsChart from "./StepsChart";
import HeartRateChart from "./HeartRateChart";
import TracePicker from "./TracePicker";

import {
  AROUND_MEAL,
  HOURS_24,
  ABS_VALUES,
  DELTA_VALUES
} from "./chartHelpers";

import { useStyles as useCommonStyles } from "./styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

type Props = {
  fullHeight?: boolean
};

const useStyles = makeStyles(theme => ({
  controlLabel: {
    cursor: "pointer"
  },
  chartContainer: {
    overflow: "hidden"
  }
}));
const { useState, useCallback } = React;

export default function GlucoseTracePane(props: Props) {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles(props);
  const classes = useStyles(props);
  const [xAxisMode, setXAxisMode] = useState(AROUND_MEAL);
  const [yAxisMode, setYAxisMode] = useState(ABS_VALUES);
  const handleXAxisModeChange = useCallback((evt: SyntheticInputEvent<*>) => {
    setXAxisMode(evt.target.value);
  }, []);
  const handleYAxisModeChange = useCallback(() => {
    setYAxisMode(yAxisMode === DELTA_VALUES ? ABS_VALUES : DELTA_VALUES);
  }, [yAxisMode]);

  return (
    <PanePaper fullHeight={props.fullHeight}>
      <Typography
        className={commonClasses.paneHeader}
        component="h2"
        variant="h6"
        color="textSecondary"
      >
        {t("pages.mealAnalysis.glucoseTraceGraphs")}
      </Typography>
      <Grid container alignItems="center" spacing={5}>
        <Grid item lg={8} md={6} xs={12}>
          <TextField
            select
            label={t("pages.mealAnalysis.xAxis")}
            variant="outlined"
            margin="normal"
            fullWidth
            value={xAxisMode}
            onChange={handleXAxisModeChange}
          >
            <MenuItem value={AROUND_MEAL}>{t("pages.mealAnalysis.aroundMeal")}</MenuItem>
            <MenuItem value={HOURS_24}>{t("pages.mealAnalysis.24hours")}</MenuItem>
          </TextField>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <InputLabel
            htmlFor="yAxisMode"
            shrink
            className={classes.controlLabel}
          >
            {t("pages.mealAnalysis.glucoseValue")}
          </InputLabel>
          <Grid
            component="label"
            container
            alignItems="center"
            className={classes.controlLabel}
          >
            <Grid item>{t("pages.mealAnalysis.absolute")}</Grid>
            <Grid item>
              <Switch
                id="yAxisMode"
                checked={yAxisMode === DELTA_VALUES}
                onChange={handleYAxisModeChange}
              />
            </Grid>
            <Grid item>{t("pages.mealAnalysis.delta")}</Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TracePicker />
        </Grid>
      </Grid>
      <div className={clsx("glucose-trace-chart", classes.chartContainer)}>
        <GlucoseChart xAxisMode={xAxisMode} yAxisMode={yAxisMode} />
      </div>
      <div className={clsx("steps-chart", classes.chartContainer)}>
        <StepsChart xAxisMode={xAxisMode} />
      </div>
      <div className={clsx("heart-rate-chart", classes.chartContainer)}>
        <HeartRateChart xAxisMode={xAxisMode} />
      </div>
    </PanePaper>
  );
}
