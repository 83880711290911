// @flow
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import RestaurantIcon from '@material-ui/icons/Restaurant';
import AlarmIcon from '@material-ui/icons/Alarm';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import FavoriteIcon from '@material-ui/icons/Favorite';
import MoneyIcon from '@material-ui/icons/Money';
import AssignmentIcon from "@material-ui/icons/Assignment";
import SaveIcon from "@material-ui/icons/Save";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import makeStyles from "@material-ui/core/styles/makeStyles";

import {
  fetchPatientActivities,
  fetchPatientAnswers,
  closeDownloadDataDialog,
  fetchPatientBolusInsulin,
  fetchPatientBasalInsulin,
  fetchPatientGlucose,
  fetchPatientHeartRate, fetchPatientStepCounts
} from "../../actions/patientsActions";

import type { State } from "../../reducers/types";
import { useTranslation } from "react-i18next";

type SaveButtonProps = { loading?: boolean, onClick?: Function };

const { useCallback } = React;

const useButtonStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative"
  },
  progressCircle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

function SaveButton(props: SaveButtonProps) {
  const { loading, onClick } = props;
  const { t } = useTranslation();
  const classes = useButtonStyles(props);
  return (
    <div className={classes.wrapper}>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<SaveIcon />}
        disabled={loading}
        onClick={onClick}
      >
        {t("actions.save")}
      </Button>
      {loading ? (
        <CircularProgress className={classes.progressCircle} size={24} />
      ) : null}
    </div>
  );
}

export default function DownloadSubjectDataModal(props: *) {
  const { t } = useTranslation();
  const open = useSelector(
    ({ patients }: State) => patients.showDownloadDataDialog
  );
  const subjectNumber = useSelector(
    ({ patients }: State) => patients.downloadDataFor
  );
  const answersLoading = useSelector(
    ({ patients }: State) => patients.answersLoading
  );
  const activitiesLoading = useSelector(
    ({ patients }: State) => patients.activitiesLoading
  );
  const bolusInsulinLoading = useSelector(
    ({ patients }: State) => patients.bolusInsulinLoading
  );
  const basalInsulinLoading = useSelector(
    ({ patients }: State) => patients.basalInsulinLoading
  );
  const glucoseLoading = useSelector(
    ({ patients }: State) => patients.glucoseLoading
  );
  const heartRateLoading = useSelector(
    ({ patients }: State) => patients.heartRateLoading
  );
  const stepCountsLoading = useSelector(
    ({ patients }: State) => patients.stepCountsLoading
  );
  const dispatch = useDispatch();
  const closeModal = useCallback(() => {
    dispatch(closeDownloadDataDialog());
  }, [dispatch]);
  const onSaveAnswersClick = useCallback(
    () => dispatch(fetchPatientAnswers(subjectNumber)),
    [dispatch, subjectNumber]
  );
  const onSaveActivitiesClick = useCallback(
    () => dispatch(fetchPatientActivities(subjectNumber)),
    [dispatch, subjectNumber]
  );
  const onSaveBolusInsulinClick = useCallback(
    () => dispatch(fetchPatientBolusInsulin(subjectNumber)),
    [dispatch, subjectNumber]
  );
  const onSaveBasalInsulinClick = useCallback(
    () => dispatch(fetchPatientBasalInsulin(subjectNumber)),
    [dispatch, subjectNumber]
  );
  const onSaveGlucoseClick = useCallback(
    () => dispatch(fetchPatientGlucose(subjectNumber)),
    [dispatch, subjectNumber]
  );
  const onSaveHeartRateClick = useCallback(
    () => dispatch(fetchPatientHeartRate(subjectNumber)),
    [dispatch, subjectNumber]
  );
  const onSaveStepCountsClick = useCallback(
    () => dispatch(fetchPatientStepCounts(subjectNumber)),
    [dispatch, subjectNumber]
  );

  return (
    <Dialog open={open} fullWidth maxWidth={"sm"} onClose={closeModal}>
      <DialogTitle>
        {t("pages.downloadSubjectData.title")} {subjectNumber ? `#${subjectNumber}` : ""}
      </DialogTitle>
      <DialogContent>
        <List>
          <ListItem>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary={t("pages.downloadSubjectData.reconciliationAnswers")} />
            <ListItemSecondaryAction>
              <SaveButton
                loading={answersLoading}
                onClick={onSaveAnswersClick}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DirectionsRunIcon />
            </ListItemIcon>
            <ListItemText primary={t("pages.downloadSubjectData.activity")} />
            <ListItemSecondaryAction>
              <SaveButton
                loading={activitiesLoading}
                onClick={onSaveActivitiesClick}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <RestaurantIcon />
            </ListItemIcon>
            <ListItemText primary={t("pages.downloadSubjectData.bolusInsulin")} />
            <ListItemSecondaryAction>
              <SaveButton
                loading={bolusInsulinLoading}
                onClick={onSaveBolusInsulinClick}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AlarmIcon />
            </ListItemIcon>
            <ListItemText primary={t("pages.downloadSubjectData.basalInsulin")} />
            <ListItemSecondaryAction>
              <SaveButton
                loading={basalInsulinLoading}
                onClick={onSaveBasalInsulinClick}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ShowChartIcon />
            </ListItemIcon>
            <ListItemText primary={t("pages.downloadSubjectData.glucose")} />
            <ListItemSecondaryAction>
              <SaveButton
                loading={glucoseLoading}
                onClick={onSaveGlucoseClick}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FavoriteIcon />
            </ListItemIcon>
            <ListItemText primary={t("pages.downloadSubjectData.heartRate")} />
            <ListItemSecondaryAction>
              <SaveButton
                loading={heartRateLoading}
                onClick={onSaveHeartRateClick}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <MoneyIcon />
            </ListItemIcon>
            <ListItemText primary={t("pages.downloadSubjectData.stepCounts")} />
            <ListItemSecondaryAction>
              <SaveButton
                loading={stepCountsLoading}
                onClick={onSaveStepCountsClick}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>{t("actions.cancel")}</Button>
      </DialogActions>
    </Dialog>
  );
}
