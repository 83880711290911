// @flow
import {
  NO_ADJUSTMENT,
  SLIDING_SCALE,
  CORRECTION_FACTOR
} from "../../../constants/dosing";

import type { FormikBag } from "formik";
import type { Values, FormConfig } from "./types";

export function mapPropsToValues(props: FormConfig): $Shape<Values> {
  let {
    morningBasalDose = 0,
    eveningBasalDose = 0,
    breakfast = 0,
    lunch = 0,
    dinner = 0,
    snack = 0,
    doseAdjustmentType = NO_ADJUSTMENT,
    correctionFactor: correctionFactorAdjustment = null,
    slidingScale = []
  } = props.dosingStrategy || {};

  const { targetGlucose = 0, correctionFactor = 0 } = correctionFactorAdjustment
    ? correctionFactorAdjustment
    : {};

  return {
    morningBasalDose: morningBasalDose.toString(),
    eveningBasalDose: eveningBasalDose.toString(),
    breakfast: breakfast.toString(),
    lunch: lunch.toString(),
    dinner: dinner.toString(),
    snack: snack.toString(),
    doseAdjustmentType: doseAdjustmentType.toString(),
    slidingScale: slidingScale.length
      ? slidingScale.map(item => {
          const {
            glucoseRangeLow = 0,
            glucoseRangeHigh = 0,
            breakfastDose = 0,
            lunchDose = 0,
            dinnerDose = 0
          } = item;

          return {
            glucoseRangeLow: glucoseRangeLow.toString(),
            glucoseRangeHigh: glucoseRangeHigh.toString(),
            breakfastDose: breakfastDose.toString(),
            lunchDose: lunchDose.toString(),
            dinnerDose: dinnerDose.toString()
          };
        })
      : [
          {
            glucoseRangeLow: "1",
            glucoseRangeHigh: "",
            breakfastDose: "",
            lunchDose: "",
            dinnerDose: ""
          }
        ],
    correctionFactor: {
      targetGlucose: targetGlucose.toString(),
      correctionFactor: correctionFactor.toString()
    }
  };
}

export function handleSubmit(
  values: Values,
  formikBag: FormikBag<FormConfig, Values>
) {
  const { props } = formikBag;

  if (props.readOnly) {
    return;
  }

  let {
    morningBasalDose,
    eveningBasalDose,
    breakfast,
    lunch,
    dinner,
    snack,
    doseAdjustmentType,
    correctionFactor: { targetGlucose, correctionFactor } = {},
    slidingScale
  } = values;

  doseAdjustmentType = parseInt(doseAdjustmentType, 10);

  const transformedValues = {
    morningBasalDose: parseFloat(morningBasalDose),
    eveningBasalDose: parseFloat(eveningBasalDose),
    breakfast: parseFloat(breakfast),
    lunch: parseFloat(lunch),
    dinner: parseFloat(dinner),
    snack: parseFloat(snack),
    doseAdjustmentType,
    slidingScale:
      doseAdjustmentType === SLIDING_SCALE
        ? slidingScale.map(item => ({
            glucoseRangeLow: parseInt(item.glucoseRangeLow, 10),
            glucoseRangeHigh: parseInt(item.glucoseRangeHigh, 10),
            breakfastDose: parseFloat(item.breakfastDose),
            lunchDose: parseFloat(item.lunchDose),
            dinnerDose: parseFloat(item.dinnerDose)
          }))
        : [],
    correctionFactor:
      doseAdjustmentType === CORRECTION_FACTOR
        ? {
            targetGlucose: parseFloat(targetGlucose),
            correctionFactor: parseFloat(correctionFactor)
          }
        : null
  };

  return props.onSubmit(transformedValues, formikBag);
}
