// @flow
import { call, put, select, takeLatest } from "redux-saga/effects";
import { fetchData } from "./fetchSaga";
import { GLUCOSE_UPLOAD_REQUEST } from "../constants/actionTypes";
import {
  uploadGlucoseSuccess,
  uploadGlucoseError
} from "../actions/glucoseUploadActions";

import type { Saga } from "redux-saga";
import type {
  GlucoseUploadRequestAction,
  GlucoseUploadPayload
} from "../actions/types/glucoseUploadActions";
import type { Result } from "./fetchSaga";
import type { State } from "../reducers/types";

export function* uploadGlucose(action: GlucoseUploadRequestAction): Saga<void> {
  const { SubjectId, UDO } = action.payload;
  const token: string = yield select(
    (state: State): string => state.user.token
  );
  const { response, error }: Result<GlucoseUploadPayload, *> = yield call(
    fetchData,
    {
      url: "glucose-upload",
      method: "post",
      data: { SubjectId, UDO, AccessToken: token }
    }
  );

  if (response) {
    yield put(uploadGlucoseSuccess());
  }
  if (error) {
    yield put(uploadGlucoseError(error));
  }
}

export function* glucoseUploadSaga(): Saga<void> {
  yield takeLatest(GLUCOSE_UPLOAD_REQUEST, uploadGlucose);
}
