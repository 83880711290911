// @flow
import i18next from "i18next";
import { mealTypes, mealCategories, DRINK } from "../../../constants/meal";
import MealOptionActions from "./MealOptionActions";

import type { VirtualizedColumnProps as ColumnProps } from "../../../components/Table/types";

export default ([
  {
    dataKey: "name",
    align: "left",
    sortable: true,
    label: "pages.mealMenuList.mealName",
    width: 150,
    flexGrow: 1
  },
  {
    dataKey: "mealCategory",
    align: "left",
    sortable: true,
    label: "pages.mealMenuList.mealCategory",
    width: 50,
    flexGrow: 1,
    cellDataGetter: ({ rowData, dataKey }) =>
      i18next.t(mealCategories.get(rowData[dataKey]) || "")
  },
  {
    dataKey: "mealType",
    align: "left",
    sortable: true,
    label: "pages.mealMenuList.mealType",
    width: 50,
    flexGrow: 1,
    cellDataGetter: ({ rowData, dataKey }) => {
      if (rowData["mealCategory"] === DRINK) {
        return "";
      }

      return i18next.t(mealTypes.get(rowData[dataKey]) || "");
    }
  },
  {
    dataKey: "count",
    align: "center",
    sortable: true,
    label: "pages.mealMenuList.totalNumberOfMealEvents",
    width: 100,
    flexGrow: 1
  },
  {
    dataKey: "actions",
    align: "left",
    width: 20,
    flexGrow: 1,
    cellRenderer: MealOptionActions
  }
]: ColumnProps[]);
