// @flow
import {
  MEAL_ANALYSIS_GET_REQUEST,
  MEAL_ANALYSIS_GET_SUCCESS,
  MEAL_ANALYSIS_GET_ERROR,
  MEAL_ANALYSIS_SELECT_TRACE,
  MEAL_ANALYSIS_SELECT_MEAL_EVENT
} from "../constants/actionTypes";

import type {
  MealAnalysisRequestAction,
  MealAnalysisSuccessAction,
  MealAnalysisErrorAction,
  SelectTraceAction,
  SelectMealEventAction,
  MealAnalysisPayload,
  MealAnalysisNormalizedResponse
} from "./types/mealAnalysisActions";
import type { APIErrors } from "../utils/errors";

export const getMealAnalysis = (
  payload: MealAnalysisPayload
): MealAnalysisRequestAction => ({
  type: MEAL_ANALYSIS_GET_REQUEST,
  payload
});
export const getMealAnalysisSuccess = (
  payload: MealAnalysisNormalizedResponse
): MealAnalysisSuccessAction => ({
  type: MEAL_ANALYSIS_GET_SUCCESS,
  payload
});
export const getMealAnalysisError = (
  error: APIErrors<*>
): MealAnalysisErrorAction => ({
  type: MEAL_ANALYSIS_GET_ERROR,
  payload: { error }
});

export const selectTrace = (selectedTrace: string): SelectTraceAction => ({
  type: MEAL_ANALYSIS_SELECT_TRACE,
  payload: {
    selectedTrace
  }
});

export const selectMealEvent = (
  mealEventId: string
): SelectMealEventAction => ({
  type: MEAL_ANALYSIS_SELECT_MEAL_EVENT,
  payload: {
    mealEventId
  }
});
