// @flow
import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles(theme => ({
  headerContainer: {
    marginBottom: theme.spacing(2)
  },
  paneHeader: {
    fontWeight: "normal"
  }
}));

export const useEmptyViewStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 0)
  },
  message: {
    fontSize: "1.2rem"
  }
}));
