// @flow
import * as React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

type Props = {
  title: string,
  value: ?string,
  className?: string
};

const useStyles = makeStyles(theme => ({
  title: (props: Props) => ({
    color: Boolean(props.value) ? "inherit" : theme.palette.text.disabled
  })
}));

export default function OtherNoteRow(props: Props) {
  const classes = useStyles(props);
  const { title, value, className } = props;

  return (
    <Grid container className={className}>
      <Grid item xs={6}>
        <Typography component="span" variant="body1" className={classes.title}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography component="p" variant="body1">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
}
