// @flow
import * as React from "react";
import { useSelector, shallowEqual } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PanePaper from "./PanePaper";
import When from "../../../components/When";

import type { State } from "../../../reducers/types";
import { useTranslation } from "react-i18next";

type Props = {
  patientId: string,
  mealOptionId: string
};

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.text.disabled,
    lineHeight: "1.33rem"
  },
  value: {
    color: theme.palette.text.secondary,
    fontWeight: "normal"
  }
}));

export default function MealOptionPane(props: Props) {
  const { patientId, mealOptionId } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  const {
    patient,
    patientLoading,
    mealOption,
    mealOptionLoading
  } = useSelector(({ patients, mealOptions }: State) => {
    const { patientsEntities, patientDataLoading: patientLoading } = patients;
    const {
      entities: mealOptionEntities,
      loading: mealOptionLoading
    } = mealOptions;

    return {
      patient: patientsEntities[patientId],
      patientLoading,
      mealOption: mealOptionEntities[mealOptionId],
      mealOptionLoading
    };
  }, shallowEqual);

  const patientLoaded = !patientLoading && Boolean(patient);
  const mealOptionLoaded = !mealOptionLoading && Boolean(mealOption);
  const subjectNumber = patientLoaded ? `#${patient.subjectNumber}` : "";

  return (
    <PanePaper>
      <Grid container spacing={1}>
        <Grid item xs={4} md={2}>
          <Typography
            className={classes.title}
            component="span"
            display="block"
            variant="caption"
          >
            {t("fields.common.subjectNumber")}
          </Typography>
        </Grid>
        <Grid item xs={4} md={8}>
          <Typography
            className={classes.title}
            component="span"
            display="block"
            variant="caption"
          >
            {t("pages.mealAnalysis.mealOption")}
          </Typography>
        </Grid>
        <Grid item xs={4} md={2}>
          <Typography
            className={classes.title}
            component="span"
            display="block"
            variant="caption"
          >
            {t("pages.mealAnalysis.totalMealEvents")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4} md={2}>
          <Typography
            className={classes.value}
            component="span"
            display="block"
            variant="h6"
            noWrap
            title={subjectNumber}
          >
            <When condition={patientLoaded}>{() => subjectNumber}</When>
            <When condition={patientLoading} fallback={"\u00A0"}>
              <Skeleton component="span" height={16} width="80%" />
            </When>
          </Typography>
        </Grid>
        <Grid item xs={4} md={8}>
          <Typography
            className={classes.value}
            component="span"
            display="block"
            variant="h6"
          >
            <When condition={mealOptionLoaded}>{() => mealOption.name}</When>
            <When condition={mealOptionLoading} fallback={"\u00A0"}>
              <Skeleton component="span" height={16} width="80%" />
            </When>
          </Typography>
        </Grid>
        <Grid item xs={4} md={2}>
          <Typography
            className={classes.value}
            component="span"
            display="block"
            variant="h6"
          >
            <When condition={mealOptionLoaded}>{() => mealOption.count}</When>
            <When condition={mealOptionLoading} fallback={"\u00A0"}>
              <Skeleton component="span" height={16} width="80%" />
            </When>
          </Typography>
        </Grid>
      </Grid>
    </PanePaper>
  );
}
