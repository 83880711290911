// @flow
import * as React from "react";
import uniqBy from "lodash/uniqBy";
import { connect, getIn } from "formik";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from 'react-i18next';

import type { FormikProps } from "formik";

type CustomRadioGroupProps = WithTranslation & React.ElementConfig<typeof FormControl> & {
  name: string,
  groupLabel?: string,
  helperText?: string,
  row?: boolean,
  disabled?: boolean,
  items: Array<{
    value: string,
    label: string,
    labelPlacement?: string,
    RadioProps?: React.ElementConfig<typeof Radio>
  }>,
  formik: $ReadOnly<{
    ...FormikProps<{ [key: string]: any }>,
    handleChange(field: string): (e: SyntheticInputEvent<*>) => void,
    handleBlur(field: string): (e: SyntheticEvent<*>) => void
  }>,
  groupLabelClasses?: { [key: string]: string },
  groupClasses?: { [key: string]: string },
  helperTextClasses?: { [key: string]: string },
  controlLabelClasses?: { [key: string]: string },
  radioClasses?: { [key: string]: string }
};

const useStyles = withStyles(theme => ({
  groupLabel: {
    color: theme.palette.text.disabled,
    marginBottom: 10
  }
}));

class CustomRadioGroup extends React.Component<CustomRadioGroupProps> {
  static defaultProps: $Shape<CustomRadioGroupProps> = {
    row: false,
    helperText: ""
  };

  render() {
    const {
      name: fieldName,
      row,
      groupLabel,
      helperText,
      items,
      formik,
      groupLabelClasses,
      groupClasses,
      helperTextClasses,
      controlLabelClasses,
      radioClasses,
      classes,
      disabled,
      t,
      ...restProps
    } = this.props;
    const { errors, touched, values, handleChange, handleBlur } = formik;
    const uniqItems = uniqBy(items, "value");
    const error = getIn(errors, fieldName);
    const touch = getIn(touched, fieldName);
    const checkedValue = getIn(values, fieldName);
    const hasError = Boolean(touch && error);

    const radios = uniqItems.map(fieldItem => {
      const { value, label, labelPlacement, RadioProps } = fieldItem;

      return (
        <FormControlLabel
          key={`control_${value}`}
          value={value}
          control={<Radio classes={radioClasses} {...RadioProps} />}
          label={t(label)}
          labelPlacement={labelPlacement}
          classes={controlLabelClasses}
          disabled={disabled}
        />
      );
    });

    const helperTextChildren = hasError ? (
      <FormHelperText classes={helperTextClasses}>{error}</FormHelperText>
    ) : helperText ? (
      <FormHelperText classes={helperTextClasses}>{helperText}</FormHelperText>
    ) : null;

    return (
      <FormControl {...restProps} component="fieldset" error={hasError}>
        {groupLabel ? (
          <FormLabel
            className={classes && classes.groupLabel}
            classes={groupLabelClasses}
            component="legend"
          >
            {t(groupLabel)}
          </FormLabel>
        ) : null}
        <RadioGroup
          name={fieldName}
          value={checkedValue}
          onChange={handleChange}
          onBlur={handleBlur}
          classes={groupClasses}
          row={row}
        >
          {radios}
        </RadioGroup>
        {helperTextChildren}
      </FormControl>
    );
  }
}

export const RadioGroupField = useStyles(connect(withTranslation()(CustomRadioGroup)));
