// @flow
import * as React from "react";
import clsx from "clsx";
import MuiTableCell from "@material-ui/core/TableCell";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Link as RouterLink } from "react-router-dom";

import { getPatientMealAnalysisPath } from "../../../constants/routes";

import type { CellProps } from "../../../components/Table/types";
import { useTranslation } from "react-i18next";

type Props = {
  ...CellProps
};

function stopPropagation(e: SyntheticEvent<*>) {
  e.stopPropagation();
}

const { useState, useCallback } = React;
const useStyles = makeStyles(theme => ({
  cell: {
    "&.MuiTableCell-root": {
      paddingRight: 0
    }
  }
}));

export default function MealOptionActions(props: Props) {
  const { className, style, rowData } = props;
  const { t } = useTranslation();
  const { id: mealOptionId, patientId } = rowData;
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = useCallback((evt: SyntheticEvent<HTMLButtonElement>) => {
    setAnchorEl(evt.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <MuiTableCell
      component="div"
      align="right"
      variant="body"
      className={clsx(className, classes.cell)}
      style={style}
      onClick={stopPropagation}
    >
      <IconButton color="primary" onClick={openMenu}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={closeMenu}>
        <MenuItem
          component={RouterLink}
          to={getPatientMealAnalysisPath(patientId, mealOptionId)}
        >
          {t("pages.mealMenuList.actions.analysis")}
        </MenuItem>
      </Menu>
    </MuiTableCell>
  );
}
