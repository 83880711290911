// @flow
import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import LoaderOverlay from "../components/LoaderOverlay";
import ErrorPage from "./ErrorPage";
import { LOGIN_PATH } from "../constants/routes";
import { isAuthenticated } from "../selectors/user";

import type { ContextRouter, LocationShape } from "react-router-dom";
import type { State, UserState } from "../reducers/types";
import type { RoleEnum } from "../constants/roles";
import { isMobile } from "react-device-detect";
import NotSupportedPage from "./NotSupportedPage";

type DefaultProps = {|
  accessor: (user: UserState) => boolean,
  redirectTo: string | LocationShape
|};

type OwnProps = {|
  ...DefaultProps,
  ...React.ElementConfig<typeof Route>
|};

type Props = {
  ...OwnProps,
  user: $Shape<UserState>
};

type Config = React.Config<Props, DefaultProps>;
type OwnConfig = React.Config<OwnProps, DefaultProps>;

const mapStateToProps = (state: State) => {
  const { user } = state;

  return {
    user: {
      userId: user.userId,
      userName: user.userName,
      role: user.role,
      authenticating: user.authenticating,
      isAuthenticated: user.isAuthenticated,
      loadingProfile: user.loadingProfile,
      profileLoaded: user.profileLoaded,
      profileError: user.profileError
    }
  };
};

class RestrictedRoute extends React.Component<Props, *> {
  static defaultProps: DefaultProps = {
    redirectTo: LOGIN_PATH,
    accessor: () => true
  };

  renderRoute = (routerProps: ContextRouter) => {
    if (isMobile) {
      return <NotSupportedPage />
    }
    
    const {
      render,
      component: Component,
      redirectTo,
      accessor,
      user
    } = this.props;
    const isAllowed = accessor(user);
    const profileInfoReady = !user.loadingProfile && user.profileLoaded;
    const loading =
      user.authenticating || (user.isAuthenticated && !profileInfoReady);

    if (user.profileError) {
      return <ErrorPage />;
    }

    if (loading) {
      return <LoaderOverlay open={loading} />;
    }

    if (isAllowed) {
      if (Component) return <Component {...routerProps} />;
      if (render) return render(routerProps);
    }

    return <Redirect to={redirectTo} />;
  };

  render() {
    const {
      render,
      component,
      redirectTo,
      accessor,
      user,
      ...rest
    } = this.props;

    return <Route render={this.renderRoute} {...rest} />;
  }
}

const ConnectedRestrictedRoute = connect<Config, OwnConfig, _, _, _, _>(
  mapStateToProps
)(RestrictedRoute);

export const AuthenticatedRoute = (props: $Diff<OwnProps, DefaultProps>) => (
  <ConnectedRestrictedRoute
    {...props}
    accessor={isAuthenticated}
    redirectTo={LOGIN_PATH}
  />
);

export const MobileRestrictedRoute = (props: $Diff<OwnProps, DefaultProps>) => {
  if (isMobile) {
    return <NotSupportedPage />
  }
  
  return (
    <Route {...props} />
  );
}

export const RoleRoute = (props: {
  ...OwnConfig,
  userRole: RoleEnum | RoleEnum[]
}) => {
  const { userRole: allowedRole, redirectTo, ...restProps } = props;
  const accessor = user => {
    if (isAuthenticated(user)) {
      return Array.isArray(allowedRole)
        ? allowedRole.includes(user.role)
        : allowedRole === user.role;
    }

    return false;
  };

  return (
    <ConnectedRestrictedRoute
      {...restProps}
      accessor={accessor}
      redirectTo={redirectTo}
    />
  );
};

export default ConnectedRestrictedRoute;
