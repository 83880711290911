// @flow
import * as React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import WarningIcon from "@material-ui/icons/WarningOutlined";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";

type Props = {
  loading: boolean,
  error: boolean,
  errorMessage?: string,
  emptyMessage?: string
};

const useStyles = makeStyles(theme => ({
  root: props => ({
    paddingTop: theme.spacing(2),
    height: "100%",
    display: "flex",
    ...(props.loading
      ? {
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }
      : {})
  }),
  messageContainer: {
    color: props =>
      props.error ? theme.palette.error.main : theme.palette.text.disabled
  },
  icon: {
    lineHeight: 1,
    fontSize: "3.75rem"
  },
  message: {}
}));

function EmptyView(props: Props) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { loading, error, emptyMessage = "", errorMessage = "" } = props;

  let content = null;

  if (loading) {
    content = <CircularProgress />;
  } else {
    content = (
      <Grid
        container
        className={classes.messageContainer}
        alignContent="center"
        alignItems="center"
        justify="center"
        spacing={2}
      >
        <Grid item>
          {error ? (
            <WarningIcon className={classes.icon} />
          ) : (
            <InfoIcon className={classes.icon} />
          )}
        </Grid>
        <Grid item>
          <Typography variant="h5" component="h3" className={classes.message}>
            {error ? t(errorMessage) : t(emptyMessage)}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Container maxWidth="md" className={classes.root}>
      {content}
    </Container>
  );
}

EmptyView.defaultProps = {
  loading: false,
  error: false,
  emptyMessage: "components.table.emptyMessage",
  errorMessage: "components.table.errorMessage"
};

export default EmptyView;
