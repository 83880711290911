// @flow
import * as React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Formik,
  Form,
  Field,
  UppercaseTextField,
  FileField,
  KeyboardDatePicker
} from "../components/Form";
import PageLayout from "../components/PageLayout";
import { uploadGlucose } from "../actions/glucoseUploadActions";
import { parseAndPatchUdoJson } from "../utils/udo";
import UDOPatchWorker from "../utils/udo.worker.js";
import { Yup, SubjectNumberSchema } from "../utils/validation";

import type { State } from "../reducers/types";
import type { UdoJson } from "../utils/udo";
import { useTranslation } from "react-i18next";

type FormFields = {
  subjectNumber: string,
  startDate: string,
  udoJson: FileList
};

const useStyles = makeStyles(theme => ({
  actions: {
    padding: theme.spacing(2, 0)
  }
}));

const initialValues = {
  subjectNumber: "",
  startDate: null,
  udoJson: null
};
const GlucoseUploadSchema = Yup.object({
  subjectNumber: SubjectNumberSchema.required(),
  startDate: Yup.date()
    .nullable()
    .required(),
  udoJson: Yup.mixed()
    .required()
    .test({
      name: "isFileSelected",
      message: "pages.glucoseUpload.fileNotSelectedError",
      test: (value: FileList | null) => {
        return value && value.length ? Boolean(value[0]) : false;
      }
    })
    .test({
      name: "isJsonFile",
      message: "pages.glucoseUpload.isNotJsonError",
      test: (value: FileList | null) => {
        if (!value || !value.length) return false;

        const file = value[0];
        return file.type === "application/json";
      }
    })
});

const { useCallback } = React;
const patchWorker = new UDOPatchWorker();

export default function GlucoseUploadPage(props: *) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { uploadingError, uploading, uploadSucceed } = useSelector(
    ({ glucoseUpload }: State) => ({
      uploadingError: glucoseUpload.error && (glucoseUpload.error.data?.errors[0]?.messages[0] || glucoseUpload.error.message),
      uploading: glucoseUpload.uploading,
      uploadSucceed: glucoseUpload.succeed
    }),
    shallowEqual
  );
  const handleSubmit = useCallback(
    async function(values: FormFields, actions) {
      const { subjectNumber, udoJson, startDate } = values;
      let UDO: ?UdoJson = null;

      try {
        UDO = await parseAndPatchUdoJson(
          patchWorker,
          udoJson,
          new Date(startDate)
        );
      } catch (error) {
        let message = error.message;
  
        actions.setFieldError("udoJson", t(message.replace('Uncaught Error: ', '')));
        actions.setSubmitting(false);
        return;
      }

      const payload = {
        SubjectId: subjectNumber,
        UDO
      };

      dispatch(uploadGlucose(payload));
      actions.setSubmitting(false);
    },
    [dispatch, t]
  );

  return (
    <PageLayout>
      <Typography component="h1" variant="h5">
        {t("pages.glucoseUpload.title")}
      </Typography>
      <Container maxWidth="sm">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={GlucoseUploadSchema}
          render={({ isSubmitting, isValid }) => (
            <Form noValidate>
              <Field
                component={UppercaseTextField}
                type="text"
                name="subjectNumber"
                id="subjectNumber"
                label={t("fields.common.subjectNumber")}
                required
                variant="outlined"
                margin="normal"
                fullWidth
                color="secondary"
              />
              <Field
                component={KeyboardDatePicker}
                type="text"
                name="startDate"
                id="startDate"
                format="MM/dd/yyyy"
                minDate="2019-08-20"
                disableFuture
                showTodayButton
                label={t("fields.glucoseUpload.startDate")}
                required
                inputVariant="outlined"
                margin="normal"
                fullWidth
                color="secondary"
              />
              <Field
                component={FileField}
                type="file"
                name="udoJson"
                id="udoJson"
                label={t("fields.glucoseUpload.udoJson")}
                required
                variant="outlined"
                margin="normal"
                fullWidth
                color="secondary"
                accept="application/json"
                multiple={false}
              />
              {(isSubmitting || uploading) && <LinearProgress />}
              {uploadingError && (
                <Typography variant="body2" color="error">
                  {uploadingError}
                </Typography>
              )}
              {uploadSucceed && (
                <Typography variant="body1" color="primary">
                  {t("pages.glucoseUpload.uploadSuccess")}
                </Typography>
              )}
              <Grid
                container
                spacing={2}
                justify="flex-end"
                className={classes.actions}
              >
                <Grid item>
                  <Button type="reset" variant="contained">
                    {t("actions.reset")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || uploading}
                  >
                    {t("actions.send")}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        />
      </Container>
    </PageLayout>
  );
}
