// @flow
export const HCP_ROLE = 0;
export const ADMIN_ROLE = 1;
export const RESEARCHER_ROLE = 2;
export const PATIENT_ROLE = 3;

export type RoleEnum =
  | typeof HCP_ROLE
  | typeof ADMIN_ROLE
  | typeof RESEARCHER_ROLE
  | typeof PATIENT_ROLE;

export const rolesMap = new Map<RoleEnum, string>([
  [HCP_ROLE, "roles.hcp"],
  [ADMIN_ROLE, "roles.admin"],
  [RESEARCHER_ROLE, "roles.researcher"],
  [PATIENT_ROLE, "roles.patient"]
]);
