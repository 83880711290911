// @flow
import * as React from "react";
import MuiTextField from "@material-ui/core/TextField";
import { getIn } from "formik";

import type { FieldProps } from "formik";
import { useTranslation } from "react-i18next";

type Props = {
  ...React.ElementConfig<typeof MuiTextField>,
  ...FieldProps<number | string>,
  disabled: boolean,
  readOnly: boolean,
  min?: number,
  max?: number,
  step?: number,
  precision: number,
  helperText: string,
};

export default function NumberField(props: Props) {
  const {
    field, // { name, value, onChange, onBlur }
    form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    helperText: helperTextProp,
    readOnly,
    disabled,
    step,
    min,
    max,
    precision,
    InputProps,
    ...restProps
  } = props;
  const { t } = useTranslation();
  const { onBlur, name, value } = field;
  const error = getIn(errors, name);
  const touch = getIn(touched, name);
  const hasError = Boolean(touch && error);
  const helperText = hasError ? error : helperTextProp;
  const nativeInputProps = {
    min,
    max,
    step
  };

  function handleBlur(evt: SyntheticInputEvent<*>) {
    const stringValue = typeof value === "number" ? value.toString() : value;
    const parsedValue = parseFloat(stringValue);
    if (!readOnly) {
      setFieldValue(
        name,
        Number.isNaN(parsedValue) ? "" : parsedValue.toFixed(precision)
      );
    }
    onBlur(evt);
  }

  return (
    <MuiTextField
      error={hasError}
      helperText={t(helperText)}
      {...field}
      disabled={disabled}
      onBlur={handleBlur}
      InputProps={{
        ...InputProps,
        readOnly,
        inputProps: nativeInputProps
      }}
      {...restProps}
    />
  );
}

NumberField.defaultProps = {
  precision: 0
};
