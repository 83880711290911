// @flow

export const NO_ADJUSTMENT = 0;
export const SLIDING_SCALE = 1;
export const CORRECTION_FACTOR = 2;

export type DoseAdjustmentEnum =
  | typeof NO_ADJUSTMENT
  | typeof SLIDING_SCALE
  | typeof CORRECTION_FACTOR;

export const doseAdjustmentTypes = new Map<DoseAdjustmentEnum, string>([
  [NO_ADJUSTMENT, "No Adjustment"],
  [SLIDING_SCALE, "Sliding Scale"],
  [CORRECTION_FACTOR, "Correction Factor"]
]);
