// @flow
import * as React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {
  mealTimes,
  MORNING,
  MID_DAY,
  EVENING,
  NIGHT
} from "../../../constants/meal";

import type { MealTime } from "../../../constants/meal";
import { useTranslation } from "react-i18next";

type Props = {
  value: MealTime | null,
  onChange: (value: MealTime | null) => any
};

const mealTimeOptions = [null, MORNING, MID_DAY, EVENING, NIGHT].map(value => {
  const mealTimeItem = typeof value === "number" ? mealTimes.get(value) : null;

  return {
    label: mealTimeItem
      ? `mealTimes.${mealTimeItem.name.toLowerCase()}`
      : "filters.no",
    value: typeof value === "number" ? value : ""
  };
});
const { useCallback } = React;

export default function MealTimeFilter(props: Props) {
  const { value, onChange } = props;
  const { t } = useTranslation();

  const handleChange = useCallback(
    (evt: SyntheticInputEvent<*>) => {
      const { value } = evt.target;

      onChange(typeof value === "number" ? value : null);
    },
    [onChange]
  );

  return (
    <TextField
      select
      label={t("pages.mealAnalysis.mealTime")}
      margin="dense"
      variant="outlined"
      fullWidth
      value={typeof value === "number" ? value : ""}
      onChange={handleChange}
    >
      {mealTimeOptions.map((option, i) => (
        <MenuItem key={i} value={option.value}>
          {t(option.label)}
        </MenuItem>
      ))}
    </TextField>
  );
}
