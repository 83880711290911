// @flow
import * as React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { mealSizes, LESS, NORMAL, LARGE } from "../../../constants/meal";

import type { MealSize } from "../../../constants/meal";
import { useTranslation } from "react-i18next";

type Props = {
  value: MealSize | null,
  onChange: (value: MealSize | null) => any
};

const mealSizeOptions = [null, LESS, NORMAL, LARGE].map(value => {
  const mealSize = typeof value === "number" ? mealSizes.get(value) : null;

  return {
    label: mealSize ? mealSize : "filters.no",
    value: typeof value === "number" ? value : ""
  };
});
const { useCallback } = React;

export default function MealSizeFilter(props: Props) {
  const { value, onChange } = props;
  const { t } = useTranslation();

  const handleChange = useCallback(
    (evt: SyntheticInputEvent<*>) => {
      const { value } = evt.target;

      onChange(typeof value === "number" ? value : null);
    },
    [onChange]
  );

  return (
    <TextField
      select
      label={t("pages.mealAnalysis.mealSize")}
      margin="dense"
      variant="outlined"
      fullWidth
      value={typeof value === "number" ? value : ""}
      onChange={handleChange}
    >
      {mealSizeOptions.map((option, i) => (
        <MenuItem key={i} value={option.value}>
          {t(option.label)}
        </MenuItem>
      ))}
    </TextField>
  );
}
