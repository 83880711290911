// @flow

export function readFileAsText(file: File, encoding?: string) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsText(file, encoding);
    reader.onload = evt => resolve(evt.target.result);
    reader.onerror = evt => reject(evt.target.error);
    reader.onabort = evt => reject(evt.target.error);
  });
}
