// @flow
import * as React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import type { ContextRouter } from "react-router-dom";

import AppBar from "./AppBar";
import { LOGIN_PATH } from "../constants/routes";
import packageJson from "../../package.json";
import Notifications from "../components/Notifications";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    display: "flex",
    flexDirection: "column"
  },
  appVersion: {
    position: "absolute",
    right: 0,
    bottom: 0,
    margin: theme.spacing(0, 1)
  }
}));

type Props = {
  ...ContextRouter,
  children?: React.Node
};

export default function AppLayout(props: Props) {
  const { location } = props;
  const classes = useStyles();
  const showAppBar = location.pathname !== LOGIN_PATH;
  const versionParts = [
    packageJson.version,
    process.env.REACT_APP_BUILD_NUMBER
  ].filter((str: ?string) => str && str.length);

  return (
    <div className={classes.root}>
      {showAppBar && <AppBar />}
      <Notifications />
      <main className={classes.content}>
        {showAppBar && <div className={classes.appBarSpacer} />}
        {props.children}
      </main>
      <Typography variant="caption" className={classes.appVersion}>
        v.{versionParts.join("-")}
      </Typography>
    </div>
  );
}
