// @flow
import getUnixTime from "date-fns/getUnixTime";
import { normalize, schema } from "normalizr";
import { takeLatest, call, put } from "redux-saga/effects";
import { fetchData } from "./fetchSaga";
import { MEAL_ANALYSIS_GET_REQUEST } from "../constants/actionTypes";
import { mealTimes } from "../constants/meal";
import {
  getMealAnalysisSuccess,
  getMealAnalysisError
} from "../actions/mealAnalysisActions";
import { getDateWithOffset } from "../utils/time";

import type {
  MealAnalysisRequestAction,
  MealAnalysisRawResponse
} from "../actions/types/mealAnalysisActions";
import type { MealSize } from "../constants/meal";
import type { OtherNotesEnum } from "../constants/otherNotes";
import type { Saga } from "redux-saga";
import type { Result } from "./fetchSaga";

type RequestParams = {
  mealOptionId: string,
  timeRange?: number,
  mealSize?: MealSize | null,
  otherNotesType?: OtherNotesEnum[]
};

const resultSchema = {
  mealEvents: new schema.Array(new schema.Entity("mealEvents")),
  otherNotes: new schema.Array(new schema.Entity("otherNotes"))
};

export function* getMealAnalysis(
  action: MealAnalysisRequestAction
): Saga<void> {
  const {
    mealOptionId,
    timeRange,
    mealSize,
    otherNotesType,
    mealTime
  } = action.payload;

  const {
    response,
    error
  }: Result<RequestParams, MealAnalysisRawResponse> = yield call(fetchData, {
    method: "get",
    url: "meal-analysis",
    params: {
      mealOptionId,
      timeRange,
      mealSize,
      otherNotesType
    }
  });

  if (response) {
    const data = response.data;
    // Patch dates to match patient TZ
    data.mealEvents = data.mealEvents.map(event => ({
      ...event,
      date: getDateWithOffset(
        getUnixTime(new Date(event.date)),
        data.offset
      ).toISOString()
    }));
    data.glucoseReadings = data.glucoseReadings.map(reading => ({
      ...reading,
      time: getUnixTime(getDateWithOffset(reading.time, data.offset))
    }));
    data.stepsSeries = data.stepsSeries.map(step => ({
      ...step,
      time: getUnixTime(getDateWithOffset(step.time, data.offset))
    }));
    data.heartRateSeries = data.heartRateSeries.map(item => ({
      ...item,
      time: getUnixTime(getDateWithOffset(item.time, data.offset))
    }));
    data.otherNotes = data.otherNotes.map(item => ({
      ...item,
      time: getUnixTime(getDateWithOffset(item.time, data.offset))
    }));

    const mealTimeValues =
      typeof mealTime === "number" ? mealTimes.get(mealTime) : null;
    const mealEvents = mealTimeValues
      ? data.mealEvents.filter(mealEvent => {
          const eventDate = new Date(mealEvent.date);
          return mealTimeValues.comparator(eventDate.getHours());
        })
      : data.mealEvents;
    const normalizedResponse = normalize({ ...data, mealEvents }, resultSchema);

    yield put(getMealAnalysisSuccess(normalizedResponse));
  }

  if (error) {
    yield put(getMealAnalysisError(error));
  }
}

export function* mealAnalysisSaga(): Saga<void> {
  yield takeLatest(MEAL_ANALYSIS_GET_REQUEST, getMealAnalysis);
}
