// @flow
import * as React from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import compact from "lodash";
import { Link as RouterLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";

import VirtualizedTable, {
  MuiVirtualizedTable
} from "../../components/Table/VirtualizedTable";
import PageLayout from "../../components/PageLayout";
import DeleteUserModal from "./DeleteUserModal";
import columns from "./usersListColumns";
import { getUsersList, getMoreUsers } from "../../actions/adminActions";
import { ADMIN_USER_CREATE_PATH } from "../../constants/routes";

import type { ContextRouter } from "react-router-dom";
import type { WithStyles } from "@material-ui/core";
import type { SortOrderShortEnum } from "../../constants/sorting";
import type { State as AppState, UserEntity } from "../../reducers/types";

type UserItem = { ...UserEntity, displayName: string };
type OwnProps = {|
  ...$Exact<WithTranslation>,
  ...$Exact<WithStyles>,
  ...ContextRouter
|};

type ConnectedProps = {|
  +loading: boolean,
  +moreLoading: boolean,
  +sortBy: string,
  +sortDirection: SortOrderShortEnum,
  +userIdsList: (?string)[],
  +userEntities: {
    +[userId: string]: UserEntity
  },
  +usersTotal: number,
  +error: string,
  +usersDeleting: string[]
|};

type ConnectedActions = {|
  +getUsersList: typeof getUsersList,
  +getMoreUsers: typeof getMoreUsers
|};

type Props = {
  ...OwnProps,
  ...ConnectedProps,
  ...ConnectedActions
};

type Config = React.Config<Props, {||}>;
type OwnConfig = React.Config<OwnProps, {||}>;

const useStyles: Function = withStyles(theme => ({
  page: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column"
  },
  header: {
    marginBottom: theme.spacing(3)
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  tableContainer: {
    flex: "1",
    minHeight: 200
  }
}));

const mapStateToProps = ({ admin }: AppState): ConnectedProps => ({
  loading: admin.usersLoading,
  userIdsList: admin.userIdsList,
  userEntities: admin.userEntities,
  usersTotal: admin.usersTotal,
  moreLoading: admin.moreUsersRequested,
  sortBy: admin.sortBy,
  sortDirection: admin.sortDirection,
  error: admin.usersError ? admin.usersError.title : "",
  usersDeleting: admin.usersDeleting
});

export class UsersList extends React.Component<Props> {
  static pageSize = 30;

  tableRef = React.createRef<MuiVirtualizedTable>();

  componentDidMount() {
    const { sortBy, sortDirection } = this.props;

    this.props.getUsersList({
      skip: 0,
      take: UsersList.pageSize,
      sortBy,
      sortDirection
    });
  }

  handleSort = (sortBy: string, sortDirection: SortOrderShortEnum) => {
    const tableRef = this.tableRef.current;

    if (tableRef) {
      tableRef.scrollToRow(0);
      tableRef.resetLoadMoreRowsCache();
    }

    this.props.getUsersList({
      skip: 0,
      take: UsersList.pageSize,
      sortBy,
      sortDirection
    });
  };

  loadMoreRows = ({
    startIndex,
    stopIndex
  }: {
    startIndex: number,
    stopIndex: number
  }) => {
    const {
      loading,
      getMoreUsers,
      userIdsList,
      usersTotal,
      sortBy,
      sortDirection
    } = this.props;

    if (loading || compact(userIdsList).length >= usersTotal) {
      return;
    }

    getMoreUsers({
      take: stopIndex - startIndex + 1,
      skip: startIndex,
      sortBy,
      sortDirection
    });
  };

  rowGetter = ({
    index
  }: {
    index: number
  }): UserItem | { loading: boolean } => {
    const { userIdsList, userEntities } = this.props;
    const userId = userIdsList[index];
    const user = userId ? userEntities[userId] : undefined;

    if (user) {
      return { ...user, displayName: `${user.firstName} ${user.lastName}` };
    }

    return { loading: true };
  };

  isRowLoaded = ({ index }: { index: number }) => {
    return !!this.props.userIdsList[index];
  };

  isUserDeleting(userId: string) {
    return this.props.usersDeleting.includes(userId);
  }

  render() {
    const {
      classes,
      loading,
      error,
      usersTotal,
      sortBy,
      sortDirection,
      t
    } = this.props;

    return (
      <PageLayout className={classes.page}>
        <Typography component="h1" variant="h5" className={classes.header}>
          {t("pages.userList.title")}
        </Typography>
        <Paper className={classes.tableContainer}>
          <VirtualizedTable
            ref={this.tableRef}
            loading={loading}
            error={!!error}
            infiniteLoad
            minimumBatchSize={UsersList.pageSize}
            threshold={UsersList.pageSize + 10}
            loadMoreRows={this.loadMoreRows}
            isRowLoaded={this.isRowLoaded}
            rowCount={usersTotal}
            rowGetter={this.rowGetter}
            rowKey="userId"
            columns={columns}
            sortDirection={sortDirection}
            sortBy={sortBy}
            onSort={this.handleSort}
          />
        </Paper>
        <Fab
          component={RouterLink}
          className={classes.fab}
          color="primary"
          to={ADMIN_USER_CREATE_PATH}
          data-qa="createButton"
        >
          <AddIcon />
        </Fab>
        <DeleteUserModal />
      </PageLayout>
    );
  }
}

export default compose(
  useStyles,
  connect<Config, OwnConfig, _, _, _, _>(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        getUsersList,
        getMoreUsers
      },
      dispatch
    )
  ),
  withTranslation()
)(UsersList);
