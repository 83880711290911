// @flow
import {
  HCP_LIST_REQUEST,
  HCP_LIST_SUCCESS,
  HCP_LIST_ERROR,
  HCP_LIST_MORE_REQUEST,
  HCP_LIST_MORE_SUCCESS,
  HCP_LIST_MORE_ERROR,
  HCP_GET_REQUEST,
  HCP_GET_SUCCESS,
  HCP_GET_ERROR
} from "../constants/actionTypes.js";

import type {
  HcpListRequestAction,
  HcpListSuccessAction,
  HcpListErrorAction,
  HcpListMoreRequestAction,
  HcpListMoreSuccessAction,
  HcpListMoreErrorAction,
  HcpGetRequestAction,
  HcpGetSuccessAction,
  HcpGetErrorAction,
  HcpPaginatedResponse
} from "./types/hcpActions";
import type { PaginationPayload } from "./types/generic";
import type { HcpEntity } from "../reducers/types";
import type { APIErrors } from "../utils/errors";

export const getHcpList = (
  payload: PaginationPayload
): HcpListRequestAction => ({
  type: HCP_LIST_REQUEST,
  payload
});
export const getHcpListSuccess = (
  payload: HcpPaginatedResponse
): HcpListSuccessAction => ({
  type: HCP_LIST_SUCCESS,
  payload
});
export const getHcpListError = (error: APIErrors<*>): HcpListErrorAction => ({
  type: HCP_LIST_ERROR,
  payload: { error }
});
export const getMoreHcpList = (
  payload: PaginationPayload
): HcpListMoreRequestAction => ({
  type: HCP_LIST_MORE_REQUEST,
  payload
});
export const getMoreHcpListSuccess = (
  payload: HcpPaginatedResponse
): HcpListMoreSuccessAction => ({
  type: HCP_LIST_MORE_SUCCESS,
  payload
});
export const getMoreHcpListError = (
  error: APIErrors<*>
): HcpListMoreErrorAction => ({
  type: HCP_LIST_MORE_ERROR,
  payload: { error }
});
export const getHcp = (hcpId: string): HcpGetRequestAction => ({
  type: HCP_GET_REQUEST,
  payload: { hcpId }
});
export const getHcpSuccess = (payload: HcpEntity): HcpGetSuccessAction => ({
  type: HCP_GET_SUCCESS,
  payload
});
export const getHcpError = (error: APIErrors<*>): HcpGetErrorAction => ({
  type: HCP_GET_ERROR,
  payload: { error }
});
