// @flow
import * as React from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import compact from "lodash";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";

import VirtualizedTable, {
  MuiVirtualizedTable
} from "../../components/Table/VirtualizedTable";
import PageLayout from "../../components/PageLayout";
import hcpColumns from "./hcpColumns";
import { getHcpList, getMoreHcpList } from "../../actions/hcpActions";
import { getHcpPatientsPath } from "../../constants/routes";

import type { ContextRouter } from "react-router-dom";
import type { WithStyles } from "@material-ui/core";
import type { SortOrderShortEnum } from "../../constants/sorting";
import type { State as AppState, HcpEntity } from "../../reducers/types";

type HcpItem = HcpEntity;
type OwnProps = {|
  ...$Exact<WithTranslation>,
  ...$Exact<WithStyles>,
  ...ContextRouter
|};

type ConnectedProps = $Exact<
  $ReadOnly<{
    loading: boolean,
    moreLoading: boolean,
    sortBy: string,
    sortDirection: SortOrderShortEnum,
    idsList: (?string)[],
    entities: {
      [hcpId: string]: HcpEntity
    },
    total: number,
    error: string | null
  }>
>;

type ConnectedActions = {|
  +getHcpList: typeof getHcpList,
  +getMoreHcpList: typeof getMoreHcpList
|};

type Props = {
  ...OwnProps,
  ...ConnectedProps,
  ...ConnectedActions
};

type Config = React.Config<Props, {||}>;
type OwnConfig = React.Config<OwnProps, {||}>;

const useStyles: Function = withStyles(theme => ({
  page: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column"
  },
  header: {
    marginBottom: theme.spacing(3)
  },
  tableContainer: {
    flex: "1",
    minHeight: 200
  }
}));

const mapStateToProps = (
  { hcp, user }: AppState,
  ownProps: $ReadOnly<OwnProps>
): ConnectedProps => {
  const {
    listLoading: loading,
    idsList,
    entities,
    total,
    moreRequested: moreLoading,
    sortBy,
    sortDirection,
    listError: error
  } = hcp;

  return {
    loading,
    idsList,
    entities,
    total,
    moreLoading,
    sortBy,
    sortDirection,
    error: error ? error.title : ""
  };
};

export class HcpList extends React.Component<Props> {
  static pageSize = 30;

  tableRef = React.createRef<MuiVirtualizedTable>();

  componentDidMount() {
    const { sortBy, sortDirection } = this.props;

    this.props.getHcpList({
      skip: 0,
      take: HcpList.pageSize,
      sortBy,
      sortDirection
    });
  }

  handleSort = (sortBy: string, sortDirection: SortOrderShortEnum) => {
    const tableRef = this.tableRef.current;

    if (tableRef) {
      tableRef.scrollToRow(0);
      tableRef.resetLoadMoreRowsCache();
    }

    this.props.getHcpList({
      skip: 0,
      take: HcpList.pageSize,
      sortBy,
      sortDirection
    });
  };

  handleRowClick = (args: {
    event: SyntheticEvent<HTMLElement, MouseEvent>,
    index: number,
    rowData: $Shape<HcpEntity>
  }) => {
    const { id: hcpId } = args.rowData ? args.rowData : {};

    if (this.isRowLoaded(args)) {
      this.props.history.push(getHcpPatientsPath(hcpId));
    }
  };

  loadMoreRows = ({
    startIndex,
    stopIndex
  }: {
    startIndex: number,
    stopIndex: number
  }) => {
    const {
      loading,
      getMoreHcpList,
      idsList,
      total,
      sortBy,
      sortDirection
    } = this.props;

    if (loading || compact(idsList).length >= total) {
      return;
    }

    getMoreHcpList({
      take: stopIndex - startIndex + 1,
      skip: startIndex,
      sortBy,
      sortDirection
    });
  };

  rowGetter = ({
    index
  }: {
    index: number
  }): HcpItem | { loading: boolean } => {
    const { idsList, entities } = this.props;
    const hcpId = idsList[index];
    const hcp = hcpId ? entities[hcpId] : undefined;

    if (hcp) {
      return hcp;
    }

    return { loading: true };
  };

  isRowLoaded = ({ index }: { index: number }) => {
    return !!this.props.idsList[index];
  };

  render() {
    const {
      classes,
      loading,
      error,
      total,
      sortBy,
      sortDirection,
      t
    } = this.props;

    return (
      <PageLayout className={classes.page}>
        <Typography component="h1" variant="h5" className={classes.header}>
          {t("pages.hcpList.title")}
        </Typography>
        <Paper className={classes.tableContainer}>
          <VirtualizedTable
            ref={this.tableRef}
            loading={loading}
            error={!!error}
            infiniteLoad
            minimumBatchSize={HcpList.pageSize}
            threshold={HcpList.pageSize + 10}
            loadMoreRows={this.loadMoreRows}
            isRowLoaded={this.isRowLoaded}
            rowCount={total}
            rowGetter={this.rowGetter}
            rowKey="id"
            columns={hcpColumns}
            sortDirection={sortDirection}
            sortBy={sortBy}
            onSort={this.handleSort}
            onRowClick={this.handleRowClick}
          />
        </Paper>
      </PageLayout>
    );
  }
}

export default compose(
  useStyles,
  withTranslation(),
  connect<Config, OwnConfig, _, _, _, _>(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        getHcpList,
        getMoreHcpList
      },
      dispatch
    )
  )
)(HcpList);
