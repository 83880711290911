// @flow
import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION
} from "../constants/actionTypes";

import type { NotificationsState } from "./types";
import type { Action } from "../actions/types";

const initialState: NotificationsState = {
  notifications: []
};

export default function notificationsReducer(
  state: NotificationsState = initialState,
  action: Action
): NotificationsState {
  switch (action.type) {
    case SHOW_NOTIFICATION: {
      let notification = action.payload;

      return {
        ...state,
        notifications: state.notifications.concat(notification)
      };
    }
    case HIDE_NOTIFICATION: {
      let { key: removeKey } = action.payload;
    
      return {
        ...state,
        notifications: state.notifications.filter(({key}) => key !== removeKey)
      };
    }
    default:
      return state;
  }
}
