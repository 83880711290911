// @flow
import * as React from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useContainerStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    marginTop: theme.spacing(13)
  },
  maxWidthLg: {
    maxWidth: 720
  }
}));
const usePaperStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexFlow: "column",
    textAlign: "center",
    alignItems: "center",
    padding: theme.spacing(5, 19, 7),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3, 0, 5)
    }
  },
  elevation0: {
    boxShadow: "0 10px 40px 0 rgba(0, 0, 0, 0.2)"
  }
}));

export default function PaperPage(props: { children?: React.Node }) {
  const paperClasses = usePaperStyles();
  const containerClasses = useContainerStyles();

  return (
    <Container maxWidth="lg" classes={containerClasses}>
      <Paper elevation={0} classes={paperClasses}>
        {props.children}
      </Paper>
    </Container>
  );
}
