// @flow
import * as React from "react";
import clsx from "clsx";
import flatMap from "lodash/flatMap";
import throttle from "lodash/throttle";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  FlexibleWidthXYPlot as XYPlot,
  LineSeries,
  CustomSVGSeries,
  MarkSeries,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  Borders,
  Hint
} from "react-vis";
import EmptyView from "../../../components/Table/EmptyView";
import GlucoseHint from "./GlucoseHint";
import { renderMealIcon } from "./MealEventIcon";
import { useEmptyViewStyles } from "./styles";
import { useGlucoseReadingsData, useDataLoadState } from "./hooks";
import {
  AROUND_MEAL,
  HOURS_24,
  ABS_VALUES,
  DELTA_VALUES,
  ABS_X_DOMAIN,
  DELTA_X_DOMAIN,
  ABS_X_TICK_VALUES,
  DELTA_X_TICK_VALUES,
  xTickFormatAbs,
  xTickFormatDelta,
  isSameMealOption,
  chartBordersStyle,
  chartMargins,
  getDomain,
  padDomain,
  getGlucoseNull
} from "./chartHelpers";
import {
  selectGlucoseChartState,
  isGlucoseChartStateEqual
} from "../../../selectors/mealAnalysis";
import { selectTrace } from "../../../actions/mealAnalysisActions";
import { getDateStringFromUnixTime } from "../../../utils/time";

import type { GlucoseReadingWithMealEvent } from "../../../reducers/types";
import { useTranslation } from "react-i18next";

type Props = {
  xAxisMode: typeof AROUND_MEAL | typeof HOURS_24,
  yAxisMode: typeof DELTA_VALUES | typeof ABS_VALUES
};

type MealEventPoint = {
  x: number,
  y: number,
  time: number, // UNIX time in seconds
  mealEventId: ?string
};

const seriesStyle = {
  pointerEvents: "none"
};
const { useState, useEffect, useCallback, useMemo } = React;
const useStyles = makeStyles(theme => ({
  emptyContainer: {
    height: 300
  }
}));

export default function GlucoseChart(props: Props) {
  const { xAxisMode, yAxisMode } = props;
  const { t } = useTranslation();
  const xDeltaMode = xAxisMode === AROUND_MEAL;
  const yDeltaMode = yAxisMode === DELTA_VALUES;

  // Style hooks
  const classes = useStyles(props);
  const emptyViewClasses = useEmptyViewStyles();

  // State hooks
  const [
    hoveredReading,
    setHoveredReading
  ] = useState<GlucoseReadingWithMealEvent | null>(null);
  const dispatch = useDispatch();
  const { loading, error } = useDataLoadState();
  const {
    mealOptionId,
    mealEvents,
    orderedDates,
    traceColors,
    selectedTraceDate
  } = useSelector(selectGlucoseChartState, isGlucoseChartStateEqual);
  const glucoseSeriesHash = useGlucoseReadingsData(xDeltaMode, yDeltaMode);
  const glucosePoints = useMemo(
    () =>
      flatMap(orderedDates, dateKey => {
        const readings = glucoseSeriesHash[dateKey] || [];

        return readings;
      }),
    [orderedDates, glucoseSeriesHash]
  );
  const xDomain = xDeltaMode ? DELTA_X_DOMAIN : ABS_X_DOMAIN;
  const yDomain = useMemo(() => padDomain(getDomain(glucosePoints, "y"), 20), [
    glucosePoints
  ]);
  const isEmpty = !glucosePoints.length;

  // Event handlers
  const handleMealEventClick = useCallback(
    (row: MealEventPoint) => {
      const { mealEventId } = row;

      if (isSameMealOption(mealEvents[mealEventId || ""], mealOptionId)) {
        const traceDate = getDateStringFromUnixTime(row.time);
        dispatch(selectTrace(traceDate));
      }
    },
    [dispatch, mealEvents, mealOptionId]
  );
  const handleHover = useMemo(
    // Throttling is used to reduce "set state" calls
    () =>
      throttle(
        (reading: GlucoseReadingWithMealEvent) => setHoveredReading(reading),
        100
      ),
    []
  );
  const handleBlur = useCallback(() => {
    setHoveredReading(null);
  }, []);

  // Cancel throttled callbacks on unmount
  useEffect(() => handleHover.cancel(), [handleHover]);

  if (isEmpty) {
    return (
      <EmptyView
        classes={{
          ...emptyViewClasses,
          root: clsx(emptyViewClasses.root, classes.emptyContainer)
        }}
        loading={loading}
        error={!!error}
        emptyMessage={t("pages.mealAnalysis.emptyGlucoseData")}
        errorMessage={t("pages.mealAnalysis.unableToDrawGlucoseTraces")}
      />
    );
  }

  return (
    <XYPlot
      height={300}
      xType="linear"
      yType="linear"
      margin={chartMargins}
      xDomain={xDomain}
      yDomain={yDeltaMode ? yDomain : [0, yDomain[1]]}
      onMouseLeave={handleBlur}
    >
      <VerticalGridLines
        tickValues={xDeltaMode ? DELTA_X_TICK_VALUES : ABS_X_TICK_VALUES}
      />
      <HorizontalGridLines tickTotal={11} />
      {orderedDates.map(dateKey => [
        <LineSeries
          key={`${dateKey}_glucose`}
          data={glucoseSeriesHash[dateKey]}
          getNull={getGlucoseNull}
          stroke={traceColors[dateKey]}
          style={seriesStyle}
          strokeWidth={selectedTraceDate === dateKey ? 3 : 1}
        />,
        <CustomSVGSeries
          key={`${dateKey}_events`}
          data={glucoseSeriesHash[dateKey]
            .filter(d => d.mealEventId)
            .map<MealEventPoint>(d => ({
              x: d.x,
              y: d.y || 0,
              fill: isSameMealOption(
                mealEvents[d.mealEventId || ""],
                mealOptionId
              )
                ? traceColors[dateKey]
                : "#a8a8af",
              time: d.time,
              mealEventId: d.mealEventId,
              onClick: handleMealEventClick
            }))}
          getNull={getGlucoseNull}
          customComponent={renderMealIcon}
        />
      ])}
      <MarkSeries
        data={glucosePoints}
        getNull={getGlucoseNull}
        opacity={0}
        onNearestXY={handleHover}
        style={seriesStyle}
      />
      {hoveredReading ? (
        <MarkSeries
          data={[hoveredReading]}
          getNull={getGlucoseNull}
          fill="transparent"
          stroke={traceColors[getDateStringFromUnixTime(hoveredReading.time)]}
          strokeWidth={2}
          style={seriesStyle}
        />
      ) : null}
      <Borders style={chartBordersStyle} />
      <XAxis
        title={t("pages.mealAnalysis.time")}
        position="start"
        tickValues={xDeltaMode ? DELTA_X_TICK_VALUES : ABS_X_TICK_VALUES}
        tickFormat={xDeltaMode ? xTickFormatDelta : xTickFormatAbs}
      />
      <YAxis title="mg/dL" tickTotal={11} />
      {hoveredReading ? (
        <Hint value={hoveredReading}>
          <GlucoseHint reading={hoveredReading} />
        </Hint>
      ) : null}
    </XYPlot>
  );
}
