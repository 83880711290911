// @flow
import React from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  h2: {
    fontSize: theme.typography.pxToRem(28),
    marginBottom: theme.spacing(2),
    lineHeight: 1.5
  },
  paragraph: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(22),
    color: theme.palette.text.disabled,
    width: "100%"
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));
const useContainerStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    maxWidth: "80%",
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    height: "100vh"
  },
}));
const usePaperStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexFlow: "column",
    textAlign: "center",
    alignItems: "center",
    padding: theme.spacing(5)
  },
  elevation0: {
    boxShadow: "0 10px 40px 0 rgba(0, 0, 0, 0.2)"
  }
}));

export default function NotSupportedPage() {
  const classes = useStyles();
  const paperClasses = usePaperStyles();
  const containerClasses = useContainerStyles();
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg" classes={containerClasses}>
      <Paper elevation={0} classes={paperClasses}>
        <Typography variant="h2" className={classes.h2}>
          {t("pages.notSupported.title")}
        </Typography>
        <Typography component="p" variant="body2" className={classes.paragraph}>
          {t("pages.notSupported.description")}
        </Typography>
      </Paper>
    </Container>
  );
}
