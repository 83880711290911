// @flow
import { takeLatest, select, call, put } from "redux-saga/effects";
import isEqual from "lodash/isEqual";
import {
  DOSING_STRATEGY_GET_REQUEST,
  DOSING_STRATEGY_SAVE_REQUEST
} from "../constants/actionTypes";
import {
  getDosingStrategySuccess,
  getDosingStrategyError,
  saveDosingStrategyDone,
  saveFixedDoseRequest,
  saveFixedDoseSuccess,
  saveFixedDoseError,
  saveDoseAdjustmentRequest,
  saveSlidingScaleSuccess,
  saveCorrectionFactorSuccess,
  removeDoseAdjustmentSuccess,
  saveSlidingScaleError,
  saveCorrectionFactorError,
  removeDoseAdjustmentError,
  saveDosingStrategyOptimisticSuccess
} from "../actions/dosingStrategyActions";
import { fetchData } from "./fetchSaga";
import {
  selectStrategyValues,
  getFixedDoseValues,
  selectStrategyId,
  selectPatientId
} from "../selectors/dosingStrategy";
import {
  NO_ADJUSTMENT,
  CORRECTION_FACTOR,
  SLIDING_SCALE
} from "../constants/dosing";

import type { DoseAdjustmentEnum } from "../constants/dosing";
import type {
  GetDosingStrategyAction,
  SaveDosingStrategyAction
} from "../actions/types/dosingStrategyActions";
import type {
  DosingStrategyEntity,
  SlidingScaleItem,
  CorrectionFactor
} from "../reducers/types";
import type { Saga } from "redux-saga";
import type { Result } from "./fetchSaga";

type FixedDoseParams = {
  patientId: string,
  morningBasalDose: number,
  eveningBasalDose: number,
  breakfast: number,
  lunch: number,
  dinner: number,
  snack: number,
  doseAdjustmentType: DoseAdjustmentEnum
};

type SlidingScaleParams = {
  dosingStrategyId: string,
  slidingScales: Array<SlidingScaleItem>
};

type CorrectionFactorParams = {
  ...CorrectionFactor,
  dosingStrategyId: string
};

const ENDPOINT_URL = "dosing-strategy";

export function* getDosingStrategy(
  action: GetDosingStrategyAction
): Saga<void> {
  const { patientId } = action.payload;

  const { response, error }: Result<*, DosingStrategyEntity> = yield call(
    fetchData,
    {
      method: "get",
      url: `${ENDPOINT_URL}/${patientId}`
    }
  );

  if (response) {
    yield put(getDosingStrategySuccess(response.data));
  }

  if (error) {
    yield put(getDosingStrategyError(error));
  }
}

export function* saveFixedDose(
  fixedDose: FixedDoseParams
): Saga<DosingStrategyEntity | null> {
  // Call save new strategy, obtain new id
  const {
    response,
    error
  }: Result<FixedDoseParams, DosingStrategyEntity> = yield call(fetchData, {
    method: "post",
    url: ENDPOINT_URL,
    data: fixedDose
  });

  if (response) {
    yield put(saveFixedDoseSuccess(response.data));
    return response.data;
  }

  if (error) {
    yield put(saveFixedDoseError(error));
  }

  return null;
}

export function* saveSlidingScales(
  dosingStrategyId: string,
  slidingScales: Array<SlidingScaleItem>
): Saga<void> {
  const {
    response,
    error
  }: Result<SlidingScaleParams, DosingStrategyEntity> = yield call(fetchData, {
    method: "post",
    url: `${ENDPOINT_URL}/sliding-scales`,
    data: {
      dosingStrategyId,
      slidingScales
    }
  });

  if (response) {
    yield put(saveSlidingScaleSuccess(response.data));
  }

  if (error) {
    yield put(saveSlidingScaleError(error));
  }
}

export function* saveCorrectionFactor(
  dosingStrategyId: string,
  correctionFactor: CorrectionFactor
): Saga<void> {
  const data: CorrectionFactorParams = {
    ...correctionFactor,
    dosingStrategyId
  };

  const {
    response,
    error
  }: Result<CorrectionFactorParams, DosingStrategyEntity> = yield call(
    fetchData,
    {
      method: "post",
      url: `${ENDPOINT_URL}/correction-factor`,
      data
    }
  );

  if (response) {
    yield put(saveCorrectionFactorSuccess(response.data));
  }

  if (error) {
    yield put(saveCorrectionFactorError(error));
  }
}

export function* removeDoseAdjustment(dosingStrategyId: string): Saga<void> {
  const { response, error }: Result<*, DosingStrategyEntity> = yield call(
    fetchData,
    {
      method: "POST",
      url: `${ENDPOINT_URL}/${dosingStrategyId}/remove-dose-adjustment`
    }
  );

  if (response) {
    yield put(removeDoseAdjustmentSuccess(response.data));
  }

  if (error) {
    yield put(removeDoseAdjustmentError(error));
  }
}

export function* saveDosingStrategy(
  action: SaveDosingStrategyAction
): Saga<void> {
  let dosingStrategyId = yield select(selectStrategyId);
  const patientId = yield select(selectPatientId);
  const currentStrategyValues = yield select(selectStrategyValues);
  const newStrategyValues = action.payload;
  const currentFixedDose = getFixedDoseValues(currentStrategyValues);
  const newFixedDose = getFixedDoseValues(newStrategyValues);

  const hasFixedDoseChanged = !isEqual(currentFixedDose, newFixedDose);
  const isSameDoseAdjustment =
    currentStrategyValues.doseAdjustmentType ===
    newStrategyValues.doseAdjustmentType;
  const hasSlidingScaleChanged = !isEqual(
    currentStrategyValues.slidingScale,
    newStrategyValues.slidingScale
  );
  const hasCorrectionFactorChanged = !isEqual(
    currentStrategyValues.correctionFactor,
    newStrategyValues.correctionFactor
  );
  const shouldUpdateFixedDose = hasFixedDoseChanged || !isSameDoseAdjustment;

  // Put preliminary success action to have optimistic rendering
  yield put(saveDosingStrategyOptimisticSuccess(action.payload));

  if (shouldUpdateFixedDose) {
    yield put(saveFixedDoseRequest(action.payload));

    // Call save new strategy, obtain new id
    const result = yield call(saveFixedDose, {
      patientId,
      doseAdjustmentType: newStrategyValues.doseAdjustmentType,
      ...newFixedDose
    });

    if (result) {
      dosingStrategyId = result.id;
    }
  }

  if (
    shouldUpdateFixedDose ||
    hasSlidingScaleChanged ||
    hasCorrectionFactorChanged
  ) {
    yield put(saveDoseAdjustmentRequest(action.payload));

    if (newStrategyValues.doseAdjustmentType === SLIDING_SCALE) {
      // Call save sliding scales with latest dosing strategy id
      yield call(
        saveSlidingScales,
        dosingStrategyId,
        newStrategyValues.slidingScale
      );
    }

    if (
      newStrategyValues.doseAdjustmentType === CORRECTION_FACTOR &&
      newStrategyValues.correctionFactor
    ) {
      // Call correction factor save with latest dosing strategy id
      yield call(
        saveCorrectionFactor,
        dosingStrategyId,
        newStrategyValues.correctionFactor
      );
    }

    if (newStrategyValues.doseAdjustmentType === NO_ADJUSTMENT) {
      // Call removing of adjustment with latest dosing strategy id
      yield call(removeDoseAdjustment, dosingStrategyId);
    }
  }

  yield put(saveDosingStrategyDone(dosingStrategyId));
}

export function* dosingSaga(): Saga<void> {
  yield takeLatest(DOSING_STRATEGY_GET_REQUEST, getDosingStrategy);
  yield takeLatest(DOSING_STRATEGY_SAVE_REQUEST, saveDosingStrategy);
}
