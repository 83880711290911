// @flow
import * as React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";

type Props = {
  value: number | null,
  onChange: (value: number | null) => any
};

const timeRangeOptions = [7, 15, 30, 60, 90, 0].map(value => {
  return {
    label: value === 0 ? "timePeriods.all" : `timePeriods.${value}d`,
    value
  };
});
const { useCallback } = React;

export default function TimeRangeFilter(props: Props) {
  const { value, onChange } = props;
  const { t } = useTranslation();

  const handleChange = useCallback(
    (evt: SyntheticInputEvent<*>) => {
      const value = parseInt(evt.target.value, 10);

      onChange(value > 0 ? value : null);
    },
    [onChange]
  );

  return (
    <TextField
      select
      label={t("pages.mealAnalysis.timeRange")}
      margin="dense"
      variant="outlined"
      fullWidth
      value={value || 0}
      onChange={handleChange}
    >
      {timeRangeOptions.map((option, i) => (
        <MenuItem key={i} value={option.value}>
          {t(option.label)}
        </MenuItem>
      ))}
    </TextField>
  );
}
