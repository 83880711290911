// @flow
import * as React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  width?: number | string,
  height?: number | string,
  className?: string,
  viewBox?: string
};

function AppLogo(props: Props) {
  const { width, height, className, viewBox } = props;
  const { t } = useTranslation();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      className={className}
    >
      <defs>
        <filter
          id="a"
          width="123.4%"
          height="182.1%"
          x="-11.7%"
          y="-41.1%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="4"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.29 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g
        fill="#FFF"
        fillRule="evenodd"
        filter="url(#a)"
        transform="translate(0 -4)"
      >
        <text
          fontFamily="Roboto-Regular, Roboto"
          fontSize="45"
          letterSpacing="-.55"
        >
          <tspan x="72" y="45">
            {t("appTitle")}
          </tspan>
        </text>
        <path d="M8.216 8.667h39.568a4 4 0 0 1 4 4v26.666a4 4 0 0 1-4 4H12.216a4 4 0 0 1-4-4V25.667a4 4 0 0 1 4-4h28.325v5.777H13.838v10.112h32.324V14.444H8.216V8.667z" />
      </g>
    </svg>
  );
}

AppLogo.defaultProps = {
  width: "197",
  height: "52",
  viewBox: "0 0 197 52"
};

export default AppLogo;
