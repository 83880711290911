// @flow
import { takeLatest, call, put } from "redux-saga/effects";
import { normalize, schema } from "normalizr";
import { fetchData } from "./fetchSaga";
import history from "../history";
import {
  USERS_LIST_REQUEST,
  USERS_LIST_MORE_REQUEST,
  USER_CREATE_REQUEST,
  GET_USER_REQUEST,
  USER_UPDATE_REQUEST,
  DELETE_USER_REQUEST
} from "../constants/actionTypes";
import { ADMIN_HOME_PATH } from "../constants/routes";
import {
  usersListLoaded,
  usersListFailed,
  moreUsersLoaded,
  moreUsersFailed,
  createUserSuccess,
  createUserFailed,
  updateUserSuccess,
  updateUserFailed,
  getUserSuccess,
  getUserError,
  deleteUserSuccess,
  deleteUserError,
  openDeleteConfirmDialog
} from "../actions/adminActions";

import type { Saga } from "redux-saga";

import type {
  PaginationPayload,
  RawPaginatedResponse
} from "../actions/types/generic";
import type {
  UsersListRequestAction,
  UsersListMoreRequestAction,
  CreateUserAction,
  UpdateUserAction,
  UserCreatedPayload,
  GetUserAction,
  DeleteUserAction,
  UpdateUserPayload
} from "../actions/types/adminActions";
import type { RoleEnum } from "../constants/roles";
import type { UserEntity } from "../reducers/types";
import type { Result } from "./fetchSaga";

type CreateUserData = {|
  role: RoleEnum,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  confirmPassword: string
|};

type UpdateUserData = {|
  ...UpdateUserPayload,
  userPicture: ?string
|};

const user = new schema.Entity("users", undefined, {
  idAttribute: "userId"
});
const resultSchema = { items: new schema.Array(user) };

export function* fetchUsers(
  payload: PaginationPayload
): Saga<Result<PaginationPayload, RawPaginatedResponse<UserEntity>>> {
  const { skip, take, sortBy, sortDirection } = payload;

  const {
    response,
    error
  }: Result<PaginationPayload, RawPaginatedResponse<UserEntity>> = yield call(
    fetchData,
    {
      method: "get",
      url: "account/users",
      params: {
        take,
        skip,
        orderBy: sortBy || undefined,
        direction: sortDirection ? sortDirection.toUpperCase() : undefined
      }
    }
  );

  return { response, error };
}

export function* fetchUsersList(action: UsersListRequestAction): Saga<void> {
  const {
    response,
    error
  }: Result<PaginationPayload, RawPaginatedResponse<UserEntity>> = yield call(
    fetchUsers,
    action.payload
  );

  if (response) {
    yield put(
      usersListLoaded(
        normalize({ ...response.data, ...action.payload }, resultSchema)
      )
    );
  }

  if (error) {
    yield put(usersListFailed(error));
  }
}

export function* fetchMoreUsers(
  action: UsersListMoreRequestAction
): Saga<void> {
  const {
    response,
    error
  }: Result<PaginationPayload, RawPaginatedResponse<UserEntity>> = yield call(
    fetchUsers,
    action.payload
  );

  if (response) {
    yield put(
      moreUsersLoaded(
        normalize({ ...response.data, ...action.payload }, resultSchema)
      )
    );
  }

  if (error) {
    yield put(moreUsersFailed(error));
  }
}

export function* createUser(action: CreateUserAction): Saga<void> {
  const { firstName, lastName, email, ...restParams } = action.payload;
  const data: CreateUserData = {
    ...restParams,
    firstName: firstName.trim(),
    lastName: lastName.trim(),
    email: email.trim()
  };

  const {
    response,
    error
  }: Result<CreateUserData, UserCreatedPayload> = yield call(fetchData, {
    method: "post",
    url: "account/create",
    data
  });

  if (response) {
    yield put(createUserSuccess(response.data));
    yield call(history.push, ADMIN_HOME_PATH);
  }

  if (error) {
    yield put(createUserFailed(error));
  }
}

export function* updateUser(action: UpdateUserAction): Saga<void> {
  const {
    firstName,
    lastName,
    email,
    userId,
    password,
    confirmPassword
  } = action.payload;
  const data: UpdateUserData = {
    userId,
    firstName: firstName.trim(),
    lastName: lastName.trim(),
    email: email.trim(),
    password,
    confirmPassword,
    userPicture: null
  };

  const { response, error }: Result<UpdateUserData, UserEntity> = yield call(
    fetchData,
    {
      method: "post",
      url: "account/update",
      data
    }
  );

  if (response) {
    yield put(updateUserSuccess(response.data));
    yield call(history.push, ADMIN_HOME_PATH);
  }

  if (error) {
    yield put(updateUserFailed(error));
  }
}

export function* fetchUserData(action: GetUserAction): Saga<void> {
  const { userId } = action.payload;
  const { response, error }: Result<*, UserEntity> = yield call(fetchData, {
    method: "get",
    url: `account/${userId}`
  });

  if (response) {
    yield put(getUserSuccess(response.data));
  }

  if (error) {
    yield put(getUserError(error));
  }
}

export function* deleteUser(action: DeleteUserAction): Saga<void> {
  // TODO: Implement delete user saga
  const { userId, confirmed } = action.payload;

  if (!confirmed) {
    yield put(openDeleteConfirmDialog(userId));

    return;
  }

  const { response, error }: Result<*, *> = yield call(fetchData, {
    url: `account/${userId}`,
    method: "delete"
  });

  if (response) {
    yield put(deleteUserSuccess(userId));
  }

  if (error) {
    yield put(deleteUserError(error, userId));
  }
}

export function* adminSaga(): Saga<void> {
  yield takeLatest(USERS_LIST_REQUEST, fetchUsersList);
  yield takeLatest(USERS_LIST_MORE_REQUEST, fetchMoreUsers);
  yield takeLatest(USER_CREATE_REQUEST, createUser);
  yield takeLatest(USER_UPDATE_REQUEST, updateUser);
  yield takeLatest(GET_USER_REQUEST, fetchUserData);
  yield takeLatest(DELETE_USER_REQUEST, deleteUser);
}
