// @flow
import * as React from "react";
import TableRow from "@material-ui/core/TableRow";
import LinearProgress from "@material-ui/core/LinearProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";

type Props = {
  style?: Object,
  className?: string
};

const useStyles = makeStyles(theme => ({
  loadingRow: {
    flexGrow: 1
  }
}));

const StyledLinearProgress = withStyles(theme => ({
  colorPrimary: {
    backgroundColor: theme.palette.grey[300]
  },
  barColorPrimary: {
    backgroundColor: theme.palette.grey[100]
  }
}))(LinearProgress);

export default function LoadingRow(props: Props) {
  const { className, style } = props;
  const classes = useStyles();

  return (
    <TableRow component="div" style={style} className={className}>
      <StyledLinearProgress className={classes.loadingRow} thickness={4} />
    </TableRow>
  );
}
