// @flow
import * as React from "react";
import noop from "lodash/noop";
import MuiTableCell from "@material-ui/core/TableCell";
import withStyles from "@material-ui/core/styles/withStyles";

import type { WithStyles } from "@material-ui/core";
import type { CellProps } from "./types";

const useStyles = withStyles(theme => ({
  alignLeft: {
    justifyContent: "left"
  },
  alignRight: {
    justifyContent: "right"
  },
  alignCenter: {
    justifyContent: "center"
  }
}));

type Props = {|
  ...$Exact<WithStyles>,
  ...CellProps
|};

class TableCell extends React.Component<Props> {
  static defaultProps = {
    onClick: noop
  };

  handleClick = (event: SyntheticEvent<HTMLElement, MouseEvent>) => {
    const {
      cellData,
      dataKey,
      rowIndex,
      rowData,
      columnData,
      onClick
    } = this.props;

    if (onClick) {
      onClick({ event, cellData, rowData, dataKey, columnData, rowIndex });
    }
  };

  render() {
    const { cellData, classes, align, className, style } = this.props;

    return (
      <MuiTableCell
        classes={classes}
        className={className}
        style={style}
        component="div"
        variant="body"
        align={align}
        onClick={this.handleClick}
      >
        {cellData}
      </MuiTableCell>
    );
  }
}

export default useStyles(TableCell);
