// @flow
import * as React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

type Props = {
  row: {
    x: number | string,
    y: number | string,
    size: number,
    fill?: string,
    onClick?: Function
  },
  positionInPixels: {
    x: number,
    y: number
  },
  style: {
    [key: string]: *
  },
  positionFunctions: {
    x(v: *): number,
    y(v: *): number
  }
};

const useStyles = makeStyles(theme => ({
  icon: {
    cursor: "pointer"
  }
}));
const { useCallback } = React;

export default function MealEventIcon(props: Props) {
  const { row } = props;
  const { fill, onClick } = row;
  const classes = useStyles(props);

  const handleClick = useCallback(
    (e: SyntheticEvent<*>) => {
      if (typeof onClick === "function") {
        onClick(row);
      }
    },
    [onClick, row]
  );

  return (
    <path
      className={classes.icon}
      onClick={handleClick}
      fill={fill}
      transform="translate(-9, -9)"
      d="M16.5 14a1.5 1.5 0 0 1 0 3h-15a1.5 1.5 0 0 1 0-3h15zM9 1c.731 0 1.324.635 1.322 1.419 0 .408-.163.775-.421 1.033C13.896 3.951 17 7.712 17 12.28c0 .242-.01.483-.027.721-3.958 0-6.216-.002-9.25-.003l-2.76-.001c.02-.232.036-.463.07-.691.047-.63.157-1.243.27-1.844.075-.292.144-.586.206-.876l.268-.829c.214-.52.42-1.034.695-1.471l.19-.336c.075-.1.149-.2.22-.298.148-.193.269-.402.425-.57.312-.326.909-1.307.27-.927-.64.38-1.096.75-1.288.937-.091.099-.186.197-.28.298l-.253.344c-.36.454-.66.995-.93 1.575-.12.297-.238.602-.36.907-.096.314-.187.634-.27.959-.138.65-.273 1.319-.336 1.985-.034.28-.05.559-.066.836h-2.77A10.18 10.18 0 0 1 1 12.28c0-4.567 3.104-8.328 7.098-8.827a1.456 1.456 0 0 1-.421-1.033C7.677 1.635 8.27 1 9 1z"
    />
  );
}

export function renderMealIcon(
  row: $PropertyType<Props, "row">,
  positionInPixels: $PropertyType<Props, "positionInPixels">,
  style: $PropertyType<Props, "style">,
  positionFunctions: $PropertyType<Props, "positionFunctions">
) {
  return (
    <MealEventIcon
      row={row}
      positionInPixels={positionInPixels}
      style={style}
      positionFunctions={positionFunctions}
    />
  );
}
