// @flow
import axios from "axios";

import type { Axios } from "axios";

const baseURL = process.env.REACT_APP_BACKEND_URL || "";

const client: Axios = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json"
  }
});

export default client;
