// @flow
import without from "lodash/without";
import {
  PATIENT_CREATE_REQUEST,
  PATIENT_UPDATE_REQUEST,
  PATIENT_DELETE_REQUEST,
  PATIENT_CREATE_SUCCESS,
  PATIENT_UPDATE_SUCCESS,
  PATIENT_DELETE_SUCCESS,
  PATIENT_CREATE_ERROR,
  PATIENT_UPDATE_ERROR,
  PATIENT_DELETE_ERROR,
  PATIENT_DELETE_CONFIRM_DIALOG,
  PATIENT_GET_REQUEST,
  PATIENTS_LIST_REQUEST,
  PATIENTS_LIST_MORE_REQUEST,
  PATIENT_GET_SUCCESS,
  PATIENTS_LIST_SUCCESS,
  PATIENTS_LIST_MORE_SUCCESS,
  PATIENT_GET_ERROR,
  PATIENTS_LIST_ERROR,
  PATIENTS_LIST_MORE_ERROR,
  LOGOUT_DONE,
  CLEAR_PATIENT_FORM_ERRORS,
  REMOVE_DEVICE_CONFIRM_DIALOG,
  DOWNLOAD_SUBJECT_DATA_DIALOG,
  PATIENT_FETCH_ANSWERS_REQUEST,
  PATIENT_FETCH_ANSWERS_SUCCESS,
  PATIENT_FETCH_ANSWERS_ERROR,
  PATIENT_FETCH_ACTIVITIES_REQUEST,
  PATIENT_FETCH_ACTIVITIES_SUCCESS,
  PATIENT_FETCH_ACTIVITIES_ERROR,
  PATIENT_FETCH_BOLUS_INSULIN_REQUEST,
  PATIENT_FETCH_BOLUS_INSULIN_SUCCESS,
  PATIENT_FETCH_BOLUS_INSULIN_ERROR,
  PATIENT_FETCH_BASAL_INSULIN_REQUEST,
  PATIENT_FETCH_BASAL_INSULIN_SUCCESS,
  PATIENT_FETCH_BASAL_INSULIN_ERROR,
  PATIENT_FETCH_GLUCOSE_REQUEST,
  PATIENT_FETCH_GLUCOSE_SUCCESS,
  PATIENT_FETCH_GLUCOSE_ERROR,
  PATIENT_FETCH_HEART_RATE_REQUEST,
  PATIENT_FETCH_HEART_RATE_SUCCESS,
  PATIENT_FETCH_HEART_RATE_ERROR,
  PATIENT_FETCH_STEP_COUNTS_REQUEST,
  PATIENT_FETCH_STEP_COUNTS_SUCCESS,
  PATIENT_FETCH_STEP_COUNTS_ERROR
} from "../constants/actionTypes";
import { ASC } from "../constants/sorting";

import type { PatientsState } from "./types";
import type { Action } from "../actions/types";

const initialState: PatientsState = {
  patientsTotal: 0,
  patientIdsList: [],
  patientsEntities: {},
  sortDirection: ASC,
  sortBy: "subjectNumber",
  timePeriod: 0,
  patientsLoading: false,
  morePatientsRequested: false,
  patientDataLoading: false,
  patientsError: null,
  createPatientError: null,
  updatePatientError: null,
  getPatientError: null,
  patientCreating: null,
  patientUpdating: null,
  patientsDeleting: [],
  deletePatientError: null,
  confirmDeleteFor: "",
  showDeleteConfirm: false,
  showForgetDeviceConfirm: false,
  confirmForgetDeviceFor: "",
  showDownloadDataDialog: false,
  downloadDataFor: "",
  answersLoading: false,
  activitiesLoading: false,
  bolusInsulinLoading: false,
  basalInsulinLoading: false,
  glucoseLoading: false,
  heartRateLoading: false,
  stepCountsLoading: false,
  answersSavingError: null,
  activitiesSavingError: null,
  bolusInsulinSavingError: null,
  basalInsulinSavingError: null,
  glucoseSavingError: null,
  heartRateSavingError: null,
  stepCountsSavingError: null
};

export default function patientsReducer(
  state: PatientsState = initialState,
  action: Action
): PatientsState {
  switch (action.type) {
    case PATIENT_CREATE_REQUEST: {
      const {
        subjectNumber,
        password,
      } = action.payload;
      return {
        ...state,
        createPatientError: null,
        patientCreating: {
          subjectNumber,
          hcpId: "",
          id: "",
          password,
          avgBolusDose: 0,
          avgBasalDose: 0,
          eodCompletion: 0,
          glucoseCapture: 0,
          doseChangedTimes: 0,
          createdAt: new Date().toISOString(),
          deletable: false,
          canEditSubjectNumber: false,
          daysInStudy: 0
        }
      };
    }
    case PATIENT_CREATE_SUCCESS: {
      const { id } = action.payload;
      return {
        ...state,
        patientCreating: null,
        patientsTotal: state.patientsTotal + 1,
        patientsEntities: {
          [id]: {
            ...action.payload
          }
        }
      };
    }
    case PATIENT_CREATE_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        patientCreating: null,
        createPatientError: error
      };
    }
    case PATIENT_UPDATE_REQUEST: {
      const { patientId, subjectNumber } = action.payload;
      const patientEntity = state.patientsEntities[patientId];

      return {
        ...state,
        updatePatientError: null,
        patientUpdating: {
          ...patientEntity,
          subjectNumber
        }
      };
    }
    case PATIENT_UPDATE_SUCCESS: {
      const { patientsEntities } = state;
      const { id } = action.payload;
      const patientEntity = patientsEntities[id];

      return {
        ...state,
        patientUpdating: null,
        patientsEntities: {
          ...patientsEntities,
          [id]: {
            ...patientEntity,
            ...action.payload
          }
        }
      };
    }
    case PATIENT_UPDATE_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        patientUpdating: null,
        updatePatientError: error
      };
    }
    case CLEAR_PATIENT_FORM_ERRORS: {
      const { createPatientError, updatePatientError } = initialState;
      return { ...state, createPatientError, updatePatientError };
    }
    case PATIENT_DELETE_REQUEST: {
      const { patientsDeleting } = state;
      const { patientId } = action.payload;

      return {
        ...state,
        deletePatientError: null,
        patientsDeleting: patientsDeleting.includes(patientId)
          ? patientsDeleting
          : [...patientsDeleting, patientId]
      };
    }
    case PATIENT_DELETE_SUCCESS: {
      const { patientsDeleting, patientIdsList, patientsTotal } = state;
      const { patientId } = action.payload;
      const patientsEntities = {
        ...state.patientsEntities
      };

      delete patientsEntities[patientId];

      return {
        ...state,
        patientsTotal: Math.max(patientsTotal - 1, 0),
        patientsEntities,
        patientIdsList: without(patientIdsList, patientId),
        patientsDeleting: without(patientsDeleting, patientId)
      };
    }
    case PATIENT_DELETE_ERROR: {
      const { patientsDeleting } = state;
      const { error, patientId } = action.payload;

      return {
        ...state,
        patientsDeleting: without(patientsDeleting, patientId),
        deletePatientError: error
      };
    }
    case PATIENT_DELETE_CONFIRM_DIALOG: {
      const { patientsDeleting } = state;
      const { show, patientId } = action.payload;

      return {
        ...state,
        patientsDeleting: without(patientsDeleting, patientId),
        confirmDeleteFor: patientId,
        showDeleteConfirm: show
      };
    }
    case REMOVE_DEVICE_CONFIRM_DIALOG: {
      const { show, subjectNumber } = action.payload;
      return {
        ...state,
        showForgetDeviceConfirm: show,
        confirmForgetDeviceFor: subjectNumber
      };
    }
    case PATIENT_GET_REQUEST: {
      return {
        ...state,
        getPatientError: null,
        patientDataLoading: true
      };
    }
    case PATIENT_GET_SUCCESS: {
      const { patientsEntities } = state;
      const patientEntity = action.payload;

      return {
        ...state,
        patientDataLoading: false,
        patientsEntities: {
          ...patientsEntities,
          [patientEntity.id]: patientEntity
        }
      };
    }
    case PATIENT_GET_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        patientDataLoading: false,
        getPatientError: error
      };
    }
    case PATIENTS_LIST_REQUEST: {
      let { sortBy, sortDirection, period: timePeriod } = action.payload;

      sortBy = sortBy ? sortBy : state.sortBy;
      sortDirection = sortDirection
        ? sortDirection.toLowerCase()
        : state.sortDirection;

      return {
        ...state,
        sortBy,
        sortDirection,
        timePeriod,
        patientsTotal: 0,
        patientIdsList: [],
        patientsEntities: {},
        patientsLoading: true
      };
    }
    case PATIENTS_LIST_SUCCESS: {
      const { result, entities } = action.payload;
      let { items, total, sortBy, sortDirection, period: timePeriod } = result;
      const patientIdsList = [...items, ...Array(total).slice(items.length)];

      sortBy = sortBy ? sortBy : state.sortBy;
      sortDirection = sortDirection
        ? sortDirection.toLowerCase()
        : state.sortDirection;

      return {
        ...state,
        sortBy,
        sortDirection,
        timePeriod,
        patientIdsList,
        patientsEntities: {
          ...state.patientsEntities,
          ...entities.patients
        },
        patientsTotal: total,
        patientsLoading: false
      };
    }
    case PATIENTS_LIST_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        patientsLoading: false,
        patientsError: error
      };
    }
    case PATIENTS_LIST_MORE_REQUEST: {
      return {
        ...state,
        morePatientsRequested: true
      };
    }
    case PATIENTS_LIST_MORE_SUCCESS: {
      const { result, entities } = action.payload;
      const { items, total, skip, take } = result;
      const patientIdsList = [
        ...state.patientIdsList.slice(0, skip),
        ...items,
        ...state.patientIdsList.slice(skip + take)
      ];

      return {
        ...state,
        patientIdsList,
        patientsEntities: {
          ...state.patientsEntities,
          ...entities.patients
        },
        patientsTotal: total,
        morePatientsRequested: false
      };
    }
    case PATIENTS_LIST_MORE_ERROR: {
      return {
        ...state,
        morePatientsRequested: false
      };
    }
    case DOWNLOAD_SUBJECT_DATA_DIALOG: {
      const { subjectNumber, show } = action.payload;
      return {
        ...state,
        showDownloadDataDialog: show,
        downloadDataFor: show ? subjectNumber : state.downloadDataFor
      };
    }
    case PATIENT_FETCH_ANSWERS_REQUEST: {
      return {
        ...state,
        answersLoading: true,
        answersSavingError: null
      };
    }
    case PATIENT_FETCH_ANSWERS_SUCCESS: {
      return {
        ...state,
        answersLoading: false
      };
    }
    case PATIENT_FETCH_ANSWERS_ERROR: {
      return {
        ...state,
        answersLoading: false,
        answersSavingError: action.payload.error
      };
    }
    case PATIENT_FETCH_ACTIVITIES_REQUEST: {
      return {
        ...state,
        activitiesLoading: true,
        activitiesSavingError: null
      };
    }
    case PATIENT_FETCH_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        activitiesLoading: false
      };
    }
    case PATIENT_FETCH_ACTIVITIES_ERROR: {
      return {
        ...state,
        activitiesLoading: false,
        activitiesSavingError: action.payload.error
      };
    }
    case PATIENT_FETCH_BOLUS_INSULIN_REQUEST: {
      return {
        ...state,
        bolusInsulinLoading: true,
        bolusInsulinSavingError: null
      };
    }
    case PATIENT_FETCH_BOLUS_INSULIN_SUCCESS: {
      return {
        ...state,
        bolusInsulinLoading: false
      };
    }
    case PATIENT_FETCH_BOLUS_INSULIN_ERROR: {
      return {
        ...state,
        bolusInsulinLoading: false,
        bolusInsulinSavingError: action.payload.error
      };
    }
    case PATIENT_FETCH_BASAL_INSULIN_REQUEST: {
      return {
        ...state,
        basalInsulinLoading: true,
        basalInsulinSavingError: null
      };
    }
    case PATIENT_FETCH_BASAL_INSULIN_SUCCESS: {
      return {
        ...state,
        basalInsulinLoading: false
      };
    }
    case PATIENT_FETCH_BASAL_INSULIN_ERROR: {
      return {
        ...state,
        basalInsulinLoading: false,
        basalInsulinSavingError: action.payload.error
      };
    }
    case PATIENT_FETCH_GLUCOSE_REQUEST: {
      return {
        ...state,
        glucoseLoading: true,
        glucoseSavingError: null
      };
    }
    case PATIENT_FETCH_GLUCOSE_SUCCESS: {
      return {
        ...state,
        glucoseLoading: false
      };
    }
    case PATIENT_FETCH_GLUCOSE_ERROR: {
      return {
        ...state,
        glucoseLoading: false,
        glucoseSavingError: action.payload.error
      };
    }
    case PATIENT_FETCH_HEART_RATE_REQUEST: {
      return {
        ...state,
        heartRateLoading: true,
        heartRateSavingError: null
      };
    }
    case PATIENT_FETCH_HEART_RATE_SUCCESS: {
      return {
        ...state,
        heartRateLoading: false
      };
    }
    case PATIENT_FETCH_HEART_RATE_ERROR: {
      return {
        ...state,
        heartRateLoading: false,
        heartRateSavingError: action.payload.error
      };
    }
    case PATIENT_FETCH_STEP_COUNTS_REQUEST: {
      return {
        ...state,
        stepCountsLoading: true,
        stepCountsSavingError: null
      };
    }
    case PATIENT_FETCH_STEP_COUNTS_SUCCESS: {
      return {
        ...state,
        stepCountsLoading: false
      };
    }
    case PATIENT_FETCH_STEP_COUNTS_ERROR: {
      return {
        ...state,
        stepCountsLoading: false,
        stepCountsSavingError: action.payload.error
      };
    }
    case LOGOUT_DONE: {
      return initialState;
    }
    default:
      return state;
  }
}
