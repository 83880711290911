// @flow
import * as React from "react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import { getIn } from "formik";
import last from "lodash/last";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { FastField, FieldArray, NumberField } from "../../../components/Form";

import type { Values } from "./types";
import type { FieldArrayRenderProps } from "formik";
import { useTranslation } from "react-i18next";

type Props = {
  readOnly?: boolean
};

const useStyles = makeStyles(theme => ({
  row: {
    "&:last-child": {
      marginBottom: theme.spacing(3)
    },
    "&:nth-last-child(2)": {
      marginBottom: theme.spacing(4)
    }
  },
  fieldCell: {
    maxWidth: 214
  },
  verticalDivider: {
    width: 0,
    borderLeft: "3px dotted rgba(0, 0, 0, 0.2)",
    backgroundColor: "transparent"
  },
  verticalDividerCell: {
    padding: theme.spacing(2, 0, 1)
  },
  addButton: {
    backgroundColor: "#0091ea",
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.3)",
    "&:hover": {
      backgroundColor: "#01579b"
    }
  },
  deleteButtonCell: {
    width: 80
  },
  deleteButton: {
    marginTop: theme.spacing(2)
  }
}));

const shrinkLabel = { shrink: true };

function SlidingScaleFields(
  props: FieldArrayRenderProps<Values> & { readOnly: boolean }
) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { name: fieldName, handleRemove, handlePush, readOnly, form } = props;
  const { values, errors, touched } = form;
  const { slidingScale } = values;
  const fieldArrayError = getIn(errors, fieldName);
  const fieldArrayTouched = getIn(touched, fieldName);
  const hasError = Boolean(fieldArrayError) && !isEmpty(fieldArrayError);

  // TODO: Find out why so many re-renders on add row and delete row

  const children =
    slidingScale && slidingScale.length
      ? slidingScale.map((item, i, list) => {
          const itemPath = `${fieldName}[${i}]`;
          const isLastRow = i === list.length - 1;
          const isRowDisabled = !isLastRow && list.length > 1;
          const isRangeLowDisabled = isLastRow && list.length > 1;
          const isDeleteEnabled = !readOnly && list.length > 1;
          const showDeleteButton = isDeleteEnabled && isLastRow;

          return (
            <Grid
              container
              spacing={1}
              className={classes.row}
              key={!isRowDisabled ? itemPath : `${itemPath}-disabled`}
              justify="space-between"
            >
              <Grid item xs={6} md={2} className={classes.fieldCell}>
                <FastField
                  name={`${itemPath}.glucoseRangeLow`}
                  component={NumberField}
                  type="number"
                  readOnly={readOnly}
                  disabled={isRowDisabled || isRangeLowDisabled}
                  min={0}
                  max={999}
                  step={1}
                  precision={0}
                  label={t("pages.dosingStrategy.slidingScaleForm.glucoseRangeLow")}
                  variant="outlined"
                  margin="normal"
                  color="secondary"
                  fullWidth
                  autoComplete="new-value"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">mg/dL</InputAdornment>
                    )
                  }}
                  InputLabelProps={shrinkLabel}
                />
              </Grid>
              <Grid item xs={6} md={2} className={classes.fieldCell}>
                <FastField
                  name={`${itemPath}.glucoseRangeHigh`}
                  component={NumberField}
                  type="number"
                  readOnly={readOnly}
                  disabled={isRowDisabled}
                  min={0}
                  max={999}
                  step={1}
                  precision={0}
                  label={t("pages.dosingStrategy.slidingScaleForm.glucoseRangeHigh")}
                  variant="outlined"
                  margin="normal"
                  color="secondary"
                  fullWidth
                  autoComplete="new-value"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">mg/dL</InputAdornment>
                    )
                  }}
                  InputLabelProps={shrinkLabel}
                />
              </Grid>
              <Grid item className={classes.verticalDividerCell}>
                <Divider
                  component="div"
                  className={classes.verticalDivider}
                  orientation="vertical"
                />
              </Grid>
              <Grid item xs={3} md={2} className={classes.fieldCell}>
                <FastField
                  name={`${itemPath}.breakfastDose`}
                  component={NumberField}
                  type="number"
                  readOnly={readOnly}
                  disabled={isRowDisabled}
                  step={0.1}
                  min={0}
                  max={999}
                  precision={1}
                  label={t("pages.dosingStrategy.slidingScaleForm.breakfastDose")}
                  variant="outlined"
                  margin="normal"
                  color="secondary"
                  fullWidth
                  autoComplete="new-value"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{t("units.insulinUnits")}</InputAdornment>
                    )
                  }}
                  InputLabelProps={shrinkLabel}
                />
              </Grid>
              <Grid item xs={3} md={2} className={classes.fieldCell}>
                <FastField
                  name={`${itemPath}.lunchDose`}
                  component={NumberField}
                  type="number"
                  readOnly={readOnly}
                  disabled={isRowDisabled}
                  step={0.1}
                  min={0}
                  max={999}
                  precision={1}
                  label={t("pages.dosingStrategy.slidingScaleForm.lunchDose")}
                  variant="outlined"
                  margin="normal"
                  color="secondary"
                  fullWidth
                  autoComplete="new-value"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{t("units.insulinUnits")}</InputAdornment>
                    )
                  }}
                  InputLabelProps={shrinkLabel}
                />
              </Grid>
              <Grid item xs={3} md={2} className={classes.fieldCell}>
                <FastField
                  name={`${itemPath}.dinnerDose`}
                  component={NumberField}
                  type="number"
                  readOnly={readOnly}
                  disabled={isRowDisabled}
                  step={0.1}
                  min={0}
                  max={999}
                  precision={1}
                  label={t("pages.dosingStrategy.slidingScaleForm.dinnerDose")}
                  variant="outlined"
                  margin="normal"
                  color="secondary"
                  fullWidth
                  autoComplete="new-value"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{t("units.insulinUnits")}</InputAdornment>
                    )
                  }}
                  InputLabelProps={shrinkLabel}
                />
              </Grid>
              <Grid
                item
                className={clsx(isDeleteEnabled && classes.deleteButtonCell)}
              >
                {showDeleteButton && (
                  <IconButton
                    className={classes.deleteButton}
                    onClick={handleRemove(i)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          );
        })
      : [];

  if (!readOnly) {
    const lastItem = last(slidingScale);
    const prevGlucoseRangeHigh = lastItem
      ? parseFloat(lastItem.glucoseRangeHigh)
      : NaN;
    const nextGlucoseRangeLow =
      slidingScale.length && !Number.isNaN(prevGlucoseRangeHigh)
        ? `${prevGlucoseRangeHigh + 1}`
        : "";
    const itemToPush = {
      glucoseRangeLow: nextGlucoseRangeLow,
      glucoseRangeHigh: "",
      breakfastDose: "",
      lunchDose: "",
      dinnerDose: ""
    };

    children.push(
      <Grid key="add_row" className={classes.row} container justify="flex-end">
        <Grid item container justify="flex-end">
          <Fab
            className={classes.addButton}
            color="primary"
            size="small"
            variant="extended"
            disabled={hasError}
            onClick={handlePush(itemToPush)}
          >
            <AddIcon />
            {t("pages.dosingStrategy.slidingScaleForm.addRow")}
          </Fab>
        </Grid>
      </Grid>
    );
  }

  if (fieldArrayTouched && hasError && typeof fieldArrayError === "string") {
    children.push(
      <FormHelperText key={`${fieldName}_error`} error>
        {fieldArrayError}
      </FormHelperText>
    );
  }

  return children;
}

export default function SlidingScaleForm(props: Props) {
  const { readOnly } = props;

  return (
    <FieldArray
      name="slidingScale"
      render={arrayHelpers => (
        <SlidingScaleFields {...arrayHelpers} readOnly={readOnly} />
      )}
    />
  );
}
