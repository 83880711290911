// @flow
import React from "react";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';

type Props = {
  className?: string
};

function Disclaimer(props: Props) {
  const { t } = useTranslation();
  
  return (
    <Typography
      variant="subtitle1"
      className={props.className}
      data-qa="disclaimer"
    >
      <Typography component="b" variant="subtitle2">
        {t("components.disclaimer.caution")}
      </Typography>{" "}
      {t("components.disclaimer.disclaimerText")}
    </Typography>
  );
}

export default Disclaimer;
