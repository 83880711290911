// @flow
import * as React from "react";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";

type Props = {
  ...$Exact<React.ElementConfig<typeof Paper>>,
  fullHeight?: boolean
};

export default withStyles(theme => ({
  root: (props: Props) => ({
    padding: theme.spacing(2, 3),
    marginBottom: theme.spacing(3),
    ...(props.fullHeight
      ? {
          flexGrow: 0,
          maxWidth: "100%",
          flexBasis: "100%"
        }
      : {})
  })
}))(({ fullHeight, ...props }: Props) => <Paper {...props} />);
