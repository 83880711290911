// @flow
import * as React from "react";
import TextField from "@material-ui/core/TextField";
import wrapWithField from "./decorateWithField";

type Props = {
  ...React.ElementConfig<typeof TextField>
};

export function SelectField(props: Props) {
  return <TextField select {...props} />;
}

export default wrapWithField<string | number, Props>(SelectField);
