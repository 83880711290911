// @flow
import { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { mapGlucoseReadings } from "./chartHelpers";
import { selectDataLoadState } from "../../../selectors/mealAnalysis";
import { getMealAnalysis } from "../../../actions/mealAnalysisActions";

import type { MealEventsFiltersPayload } from "../../../actions/types/mealAnalysisActions";
import type { State } from "../../../reducers/types";

export function useMealAnalysisEffect(
  mealOptionId: string,
  filters: $Shape<MealEventsFiltersPayload> = {}
) {
  const dispatch = useDispatch();
  const { timeRange, mealTime, mealSize, otherNotesType } = filters;

  useEffect(() => {
    dispatch(
      getMealAnalysis({
        mealOptionId,
        timeRange,
        mealTime,
        mealSize,
        otherNotesType
      })
    );
  }, [dispatch, mealOptionId, timeRange, mealTime, mealSize, otherNotesType]);
}

export const useGlucoseReadingsData = (
  xDeltaMode: boolean,
  yDeltaMode: boolean
) => {
  const mealOptionId = useSelector(
    ({ mealAnalysis }: State) => mealAnalysis.mealOptionId
  );
  const mealEvents = useSelector(
    ({ mealAnalysis }: State) => mealAnalysis.mealEvents
  );
  const glucoseSeries = useSelector(
    ({ mealAnalysis }: State) => mealAnalysis.glucoseSeries
  );

  return mapGlucoseReadings({
    readingsHash: glucoseSeries,
    mealEvents,
    mealOptionId,
    xDeltaMode,
    yDeltaMode
  });
};

export const useDataLoadState = () => {
  return useSelector(selectDataLoadState, shallowEqual);
};
