// @flow
import * as React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import NegativeButton from "../../../components/NegativeButton";

import {
  deleteMealOption,
  closeDeleteMealOptionDialog
} from "../../../actions/mealOptionActions";

import type { State } from "../../../reducers/types";
import { useTranslation } from "react-i18next";

const { useCallback } = React;

export default function DeleteMealModal(props: *) {
  const { t } = useTranslation();
  const { open, mealOption, mealOptionId, subjectNumber } = useSelector(
    ({ mealOptions, patients }: State) => {
      const {
        showDeleteConfirm: open,
        confirmDeleteFor: mealOptionId,
        entities: mealOptionsEntities
      } = mealOptions;
      const mealOption = mealOptionsEntities[mealOptionId];
      const patientId = mealOption ? mealOption.patientId : "";
      const patient = patients.patientsEntities[patientId];

      return {
        mealOptionId,
        open,
        mealOption,
        subjectNumber: patient ? patient.subjectNumber : ""
      };
    },
    shallowEqual
  );
  const dispatch = useDispatch();
  const mealOptionName = mealOption ? mealOption.name : t("pages.deleteMeal.thisMealOption");

  const closeModal = useCallback(() => {
    dispatch(closeDeleteMealOptionDialog(mealOptionId));
  }, [dispatch, mealOptionId]);
  const confirmMealOptionDelete = useCallback(() => {
    closeModal();
    dispatch(deleteMealOption(mealOptionId, true));
  }, [closeModal, dispatch, mealOptionId]);

  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle>
        {t("pages.deleteMeal.title")} {mealOption ? `${mealOption.name}` : t("mealCategories.meal")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="body2" color="textSecondary" component="p">
          {t("pages.deleteMeal.confirmation", {mealOptionName, patient: subjectNumber})}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>{t("actions.cancel")}</Button>
        <NegativeButton color="primary" onClick={confirmMealOptionDelete}>
          {t("actions.delete")}
        </NegativeButton>
      </DialogActions>
    </Dialog>
  );
}
