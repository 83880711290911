import * as React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { FastField, TextField } from "../../../components/Form";
import { useTranslation } from "react-i18next";

type Props = {
  readOnly?: boolean
};

const useStyles = makeStyles(theme => ({
  longAdornment: {
    flexBasis: 110
  }
}));

export default function CorrectionFactorForm(props: Props) {
  const { readOnly } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <FastField
        name="correctionFactor.targetGlucose"
        component={TextField}
        type="text"
        label={t("pages.dosingStrategy.correctionFactorForm.targetGlucose")}
        variant="outlined"
        margin="normal"
        color="secondary"
        fullWidth
        autoComplete="new-value"
        InputProps={{
          endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
          readOnly
        }}
      />
      <FastField
        name="correctionFactor.correctionFactor"
        component={TextField}
        type="text"
        label={t("pages.dosingStrategy.correctionFactorForm.correctionFactor")}
        variant="outlined"
        margin="normal"
        color="secondary"
        fullWidth
        autoComplete="new-value"
        InputProps={{
          endAdornment: (
            <InputAdornment className={classes.longAdornment} position="end">
              mg/dL {t("pages.dosingStrategy.correctionFactorForm.perUnit")}
            </InputAdornment>
          ),
          readOnly
        }}
      />
    </React.Fragment>
  );
}
