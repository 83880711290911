// @flow
import * as React from "react";
import MuiTextField from "@material-ui/core/TextField";
import wrapWithField from "./decorateWithField";

type Props = {
  ...React.ElementConfig<typeof MuiTextField>,
  name: string,
  onChange(name: string): (val: SyntheticInputEvent<*> | string) => any
};

const { useRef, useState, useEffect } = React;

function UppercaseTextField(props: Props) {
  const { onChange, value, ...restProps } = props;
  const changeHandler = onChange(props.name);
  const [selectionStart, setSelectionStart] = useState(null);
  const [selectionEnd, setSelectionEnd] = useState(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current && selectionStart !== null && selectionEnd !== null) {
      inputRef.current.setSelectionRange(selectionStart, selectionEnd);
    }
  }, [inputRef, selectionStart, selectionEnd]);

  function handleChange(evt: SyntheticInputEvent<*>) {
    const input = evt.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;

    changeHandler(input.value.toUpperCase());
    setSelectionStart(start);
    setSelectionEnd(end);
  }

  return (
    <MuiTextField
      inputRef={inputRef}
      onChange={handleChange}
      value={value}
      {...restProps}
    />
  );
}

export default wrapWithField<string | number, Props>(UppercaseTextField);
