// @flow
import * as React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from 'react-i18next';

type Props = {
  items: Array<{
    label: string,
    path?: string,
    className?: string
  }>,
  className?: string,
  classes: {
    +[name: string]: string
  } | void
};

const useStyles = withStyles(theme => ({
  root: {
    fontSize: theme.typography.pxToRem(10),
    marginBottom: theme.spacing(2)
  },
  li: {
    color: theme.palette.text.disabled
  },
  separator: {
    color: theme.palette.primary.main,
    "&:nth-last-child(2)": {
      color: theme.palette.text.disabled
    }
  }
}));

function CustomBreadcrumbs(props: Props) {
  const { items, className, classes } = props;
  const { t } = useTranslation();
  
  const children = items.map((item, i, items) => {
    const { label, path, className } = item;
    const key = `${path || ""}_${i}`;

    if (i === items.length - 1) {
      return (
        <Typography variant="inherit" className={className} key={key}>
          {t(label)}
        </Typography>
      );
    }

    return (
      <Link data-qa="breadcrumbs" component={RouterLink} key={key} to={path}>
        {t(label)}
      </Link>
    );
  });

  return (
    <Breadcrumbs
      separator=">"
      classes={classes}
      className={className}
    >
      {children}
    </Breadcrumbs>
  );
}

CustomBreadcrumbs.defaultProps = {
  items: []
};

export default useStyles(CustomBreadcrumbs);
