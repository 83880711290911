// @flow
import {
  MEAL_OPTION_CREATE_REQUEST,
  MEAL_OPTION_UPDATE_REQUEST,
  MEAL_OPTION_DELETE_REQUEST,
  MEAL_OPTION_CREATE_SUCCESS,
  MEAL_OPTION_UPDATE_SUCCESS,
  MEAL_OPTION_DELETE_SUCCESS,
  MEAL_OPTION_CREATE_ERROR,
  MEAL_OPTION_UPDATE_ERROR,
  MEAL_OPTION_DELETE_ERROR,
  MEAL_OPTION_DELETE_CONFIRM_DIALOG,
  MEAL_OPTION_GET_REQUEST,
  MEAL_OPTIONS_LIST_REQUEST,
  MEAL_OPTIONS_LIST_MORE_REQUEST,
  MEAL_OPTION_GET_SUCCESS,
  MEAL_OPTIONS_LIST_SUCCESS,
  MEAL_OPTIONS_LIST_MORE_SUCCESS,
  MEAL_OPTION_GET_ERROR,
  MEAL_OPTIONS_LIST_ERROR,
  MEAL_OPTIONS_LIST_MORE_ERROR,
  CLEAR_MEAL_OPTION_FORM_ERRORS
} from "../constants/actionTypes";
import type {
  MealOptionCreateRequestAction,
  MealOptionUpdateRequestAction,
  MealOptionDeleteRequestAction,
  MealOptionCreateSuccessAction,
  MealOptionUpdateSuccessAction,
  MealOptionDeleteSuccessAction,
  MealOptionCreateErrorAction,
  MealOptionUpdateErrorAction,
  MealOptionDeleteErrorAction,
  MealOptionDeleteConfirmDialogAction,
  MealOptionGetRequestAction,
  MealOptionsListRequestAction,
  MealOptionsListMoreRequestAction,
  MealOptionGetSuccessAction,
  MealOptionsListSuccessAction,
  MealOptionsListMoreSuccessAction,
  MealOptionGetErrorAction,
  MealOptionsListErrorAction,
  MealOptionsListMoreErrorAction,
  ClearMealOptionFormErrorsAction,
  CreateMealOptionPayload,
  UpdateMealOptionPayload,
  MealOptionsPaginationPayload,
  MealOptionsPaginatedResponse
} from "./types/mealOptionActions";
import type { APIErrors } from "../utils/errors";
import type { MealOptionEntity } from "../reducers/types";

export const createMealOption = (
  payload: CreateMealOptionPayload
): MealOptionCreateRequestAction => ({
  type: MEAL_OPTION_CREATE_REQUEST,
  payload
});
export const updateMealOption = (
  payload: UpdateMealOptionPayload
): MealOptionUpdateRequestAction => ({
  type: MEAL_OPTION_UPDATE_REQUEST,
  payload
});
export const deleteMealOption = (
  mealOptionId: string,
  confirmed: boolean = false
): MealOptionDeleteRequestAction => ({
  type: MEAL_OPTION_DELETE_REQUEST,
  payload: {
    mealOptionId,
    confirmed
  }
});
export const createMealOptionSuccess = (
  payload: MealOptionEntity
): MealOptionCreateSuccessAction => ({
  type: MEAL_OPTION_CREATE_SUCCESS,
  payload
});
export const updateMealOptionSuccess = (
  payload: MealOptionEntity
): MealOptionUpdateSuccessAction => ({
  type: MEAL_OPTION_UPDATE_SUCCESS,
  payload
});
export const deleteMealOptionSuccess = (
  mealOptionId: string
): MealOptionDeleteSuccessAction => ({
  type: MEAL_OPTION_DELETE_SUCCESS,
  payload: { mealOptionId }
});
export const createMealOptionError = (
  error: APIErrors<*>
): MealOptionCreateErrorAction => ({
  type: MEAL_OPTION_CREATE_ERROR,
  payload: { error }
});
export const updateMealOptionError = (
  error: APIErrors<*>
): MealOptionUpdateErrorAction => ({
  type: MEAL_OPTION_UPDATE_ERROR,
  payload: { error }
});
export const deleteMealOptionError = (
  error: APIErrors<*>,
  mealOptionId: string
): MealOptionDeleteErrorAction => ({
  type: MEAL_OPTION_DELETE_ERROR,
  payload: { error, mealOptionId }
});

export const openDeleteMealOptionDialog = (
  mealOptionId: string
): MealOptionDeleteConfirmDialogAction => ({
  type: MEAL_OPTION_DELETE_CONFIRM_DIALOG,
  payload: {
    show: true,
    mealOptionId
  }
});
export const closeDeleteMealOptionDialog = (
  mealOptionId: string
): MealOptionDeleteConfirmDialogAction => ({
  type: MEAL_OPTION_DELETE_CONFIRM_DIALOG,
  payload: {
    show: false,
    mealOptionId
  }
});

export const getMealOption = (
  mealOptionId: string
): MealOptionGetRequestAction => ({
  type: MEAL_OPTION_GET_REQUEST,
  payload: { mealOptionId }
});
export const getMealOptionsList = (
  payload: MealOptionsPaginationPayload
): MealOptionsListRequestAction => ({
  type: MEAL_OPTIONS_LIST_REQUEST,
  payload
});
export const getMoreMealOptionsList = (
  payload: MealOptionsPaginationPayload
): MealOptionsListMoreRequestAction => ({
  type: MEAL_OPTIONS_LIST_MORE_REQUEST,
  payload
});
export const getMealOptionSuccess = (
  payload: MealOptionEntity
): MealOptionGetSuccessAction => ({
  type: MEAL_OPTION_GET_SUCCESS,
  payload
});
export const getMealOptionsListSuccess = (
  payload: MealOptionsPaginatedResponse
): MealOptionsListSuccessAction => ({
  type: MEAL_OPTIONS_LIST_SUCCESS,
  payload
});
export const getMoreMealOptionsListSuccess = (
  payload: MealOptionsPaginatedResponse
): MealOptionsListMoreSuccessAction => ({
  type: MEAL_OPTIONS_LIST_MORE_SUCCESS,
  payload
});
export const getMealOptionError = (
  error: APIErrors<*>
): MealOptionGetErrorAction => ({
  type: MEAL_OPTION_GET_ERROR,
  payload: { error }
});
export const getMealOptionsListError = (
  error: APIErrors<*>
): MealOptionsListErrorAction => ({
  type: MEAL_OPTIONS_LIST_ERROR,
  payload: { error }
});
export const getMoreMealOptionsListError = (
  error: APIErrors<*>
): MealOptionsListMoreErrorAction => ({
  type: MEAL_OPTIONS_LIST_MORE_ERROR,
  payload: { error }
});

export const clearMealOptionFormErrors = (): ClearMealOptionFormErrorsAction => ({
  type: CLEAR_MEAL_OPTION_FORM_ERRORS
});
