// @flow
import * as React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  loader: {
    pointerEvents: "none",
    outline: "none"
  }
});

type Props = {
  ...React.ElementConfig<typeof Modal>,
  ProgressProps?: React.ElementConfig<typeof CircularProgress>
};

export default function LoaderOverlay(props: Props) {
  const { ProgressProps, ...modalProps } = props;
  const classes = useStyles();

  return (
    <Modal {...modalProps} className={classes.root}>
      <CircularProgress {...ProgressProps} className={classes.loader} />
    </Modal>
  );
}
