// @flow
import type { VirtualizedColumnProps as ColumnProps } from "../../components/Table/types";

const hcpColumns: ColumnProps[] = [
  {
    dataKey: "name",
    sortable: true,
    label: "pages.hcpList.hcpName",
    align: "left",
    width: 100,
    flexGrow: 2
  },
  {
    dataKey: "patientCount",
    sortable: true,
    label: "pages.hcpList.patientCount",
    align: "center",
    width: 50,
    flexGrow: 1
  }
];

export default hcpColumns;
