// @flow
import * as React from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import withRestriction from "../withRestriction";
import { isHcp } from "../../selectors/user";
import { PATIENTS_LIST_PATH } from "../../constants/routes";

type OwnProps = React.ElementConfig<typeof Breadcrumbs>;
type Props = {
  ...$Exact<OwnProps>
};

const { useMemo } = React;

function HcpBreadcrumbs(props: Props) {
  const { items: propItems, ...rest } = props;
  const items = useMemo(
    () => [
      { label: "pages.patientList.title", path: PATIENTS_LIST_PATH },
      ...(propItems || [])
    ],
    [propItems]
  );

  return <Breadcrumbs items={items} {...rest} />;
}

HcpBreadcrumbs.defaultProps = {
  items: []
};

export default withRestriction<Props>(HcpBreadcrumbs, isHcp);
