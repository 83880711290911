// @flow
import * as React from "react";
import { withSnackbar } from 'notistack';
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import type { State } from "../reducers/types";
import { hideNotification } from "../actions/notificationsActions";

type OwnProps = {|
  enqueueSnackbar: Function,
|};

type ConnectedProps = {|
  notifications: Array<{
    text: string,
    key: string,
    variant: 'default' | 'error' | 'success' | 'warning' | 'info'
  }>,
|};

type ConnectedActions = {|
  +hideNotification: typeof hideNotification
|};

type Props = {|
  ...OwnProps,
  ...ConnectedProps,
  ...ConnectedActions
|};

type Config = React.Config<Props, {||}>;
type OwnConfig = React.Config<OwnProps, {||}>;

class Notifications extends React.Component<Props> {
  render() {
    const { notifications, hideNotification, enqueueSnackbar } = this.props;
    notifications.forEach((notification) => {
      enqueueSnackbar(notification.text, {
        variant: notification.variant,
        onClose: () => {
          hideNotification({key: notification.key})
        }
      })
    })
  
    return null;
  }
}

const mapStateToProps = ({ notifications }: State): ConnectedProps => ({
  notifications: notifications.notifications
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    hideNotification
  },
  dispatch
)

export default compose(
  connect<Config, OwnConfig, _, _, _, _>(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(withSnackbar(Notifications));