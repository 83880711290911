// @flow
import * as React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

import When from "../../components/When";
import Breadcrumbs from "../../components/Breadcrumbs";
import PageLayout from "../../components/PageLayout";
import { useValidationErrors } from "../../components/Form/hooks";
import UserForm from "./UserForm";
import { ADMIN_HOME_PATH } from "../../constants/routes";
import { updateUser } from "../../actions/adminActions";
import { useGetUserEffect, useClearFormErrors } from "./hooks";
import { APIError } from "../../utils/errors";

import type { ContextRouter } from "react-router-dom";
import type { State } from "../../reducers/types";
import { useTranslation } from "react-i18next";

type Props = { ...ContextRouter };

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(3)
  },
  actions: {
    padding: theme.spacing(2, 0, 2)
  }
}));
const { useRef, useCallback } = React;
const breadcrumbsItems = [
  { label: "pages.userList.title", path: ADMIN_HOME_PATH },
  { label: "pages.editUser.title" }
];

export default function EditUser(props: Props) {
  const { params } = props.match;
  const { t } = useTranslation();
  const userId = params.userId || "";

  // Hooks
  const classes = useStyles(props);
  const {
    user,
    isUpdating,
    userDataError,
    userDataLoading,
    error
  } = useSelector(
    ({ admin }: State) => ({
      user: admin.userEntities[userId],
      isUpdating: Boolean(admin.userUpdating),
      userDataError: admin.userDataError,
      error: admin.updateUserError,
      userDataLoading: admin.userDataLoading
    }),
    shallowEqual
  );
  const isUserLoaded = Boolean(user);
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const isValidationError = error instanceof APIError && error.hasFieldErrors;

  useClearFormErrors();
  useGetUserEffect(userId, isUserLoaded);
  useValidationErrors(formRef.current, error);

  const onSubmit = useCallback(
    (values, actions) => {
      const { email, firstName, lastName, password, confirmPassword } = values;

      actions.setSubmitting(false);

      dispatch(
        updateUser({
          userId,
          email,
          firstName,
          lastName,
          password,
          confirmPassword
        })
      );
    },
    [userId, dispatch]
  );

  return (
    <PageLayout>
      <Breadcrumbs items={breadcrumbsItems} />
      <Typography component="h1" variant="h5" className={classes.header}>
        {t("pages.editUser.title")}
      </Typography>
      <Container maxWidth="sm">
        <UserForm editMode user={user} onSubmit={onSubmit} formRef={formRef}>
          <When condition={isUpdating}>
            <LinearProgress />
          </When>
          <When condition={!!error}>
            {() => (
              <Typography variant="body2" color="error">
                <When condition={isValidationError}>
                  {t("pages.editUser.fieldsInvalidMessage")}
                </When>
                <When condition={!isValidationError}>
                  {t("pages.editUser.updateErrorMessage")} {error && error.title}
                </When>
              </Typography>
            )}
          </When>
          <When condition={!!userDataError}>
            <Typography variant="body2" color="error">
              {t("pages.editUser.getDataError")}
            </Typography>
          </When>
          <Grid
            container
            justify="flex-end"
            spacing={2}
            className={classes.actions}
          >
            <Grid item>
              <Button
                color="inherit"
                component={RouterLink}
                to={ADMIN_HOME_PATH}
                data-qa="cancelButton"
              >
                {t("actions.cancel")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isUpdating || !isUserLoaded || userDataLoading}
                data-qa="saveButton"
              >
                {t("actions.save")}
              </Button>
            </Grid>
          </Grid>
        </UserForm>
      </Container>
    </PageLayout>
  );
}
