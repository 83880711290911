// @flow
import fromUnixTime from "date-fns/fromUnixTime";

import type {
  State,
  DosingStrategyData,
  DosingStrategyEntity
} from "../reducers/types";

export function selectStrategyId({ dosingStrategy }: State): string {
  return dosingStrategy.id;
}
export function selectStrategyCreatedDate({
  dosingStrategy
}: State): Date | null {
  return typeof dosingStrategy.createdAt === "number"
    ? fromUnixTime(dosingStrategy.createdAt)
    : null;
}
export function selectPatientId({ dosingStrategy }: State): string {
  return dosingStrategy.patientId;
}

export function selectStrategyValues({
  dosingStrategy
}: State): DosingStrategyData {
  const {
    morningBasalDose,
    eveningBasalDose,
    breakfast,
    lunch,
    dinner,
    snack,
    doseAdjustmentType,
    correctionFactor,
    slidingScale
  } = dosingStrategy;

  return {
    morningBasalDose,
    eveningBasalDose,
    breakfast,
    lunch,
    dinner,
    snack,
    doseAdjustmentType,
    correctionFactor,
    slidingScale
  };
}

export function getFixedDoseValues(entity: $Shape<DosingStrategyEntity>) {
  const {
    morningBasalDose,
    eveningBasalDose,
    breakfast,
    lunch,
    dinner,
    snack
  } = entity;

  return {
    morningBasalDose,
    eveningBasalDose,
    breakfast,
    lunch,
    dinner,
    snack
  };
}
