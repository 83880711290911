// @flow
import * as React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

type Props = $Diff<
  React.ElementConfig<typeof SvgIcon>,
  { children: React.Node }
>;

export default function LogoIcon(props: Props) {
  return (
    <SvgIcon viewBox="0 0 63 52" {...props}>
      <path
        transform="translate(2 0)"
        d="M8.216 8.667h39.568a4 4 0 0 1 4 4v26.666a4 4 0 0 1-4 4H12.216a4 4 0 0 1-4-4V25.667a4 4 0 0 1 4-4h28.325v5.777H13.838v10.112h32.324V14.444H8.216V8.667z"
      />
    </SvgIcon>
  );
}
