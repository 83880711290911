// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { userSaga } from "./userSaga";
import { adminSaga } from "./adminSaga";
import { patientsSaga } from "./patientsSaga";
import { subjectDataSaga } from "./subjectDataSaga";
import { mealOptionsSaga } from "./mealOptionsSaga";
import { dosingSaga } from "./dosingSaga";
import { mealAnalysisSaga } from "./mealAnalysisSaga";
import { glucoseUploadSaga } from "./glucoseUploadSaga";
import { hcpSaga } from "./hcpSaga";
import { logger } from "./loggerSaga";

export default function* rootSaga(): Saga<void> {
  const devSagas = process.env.NODE_ENV !== "production" ? [call(logger)] : [];
  const sagas = [
    call(userSaga),
    call(adminSaga),
    call(patientsSaga),
    call(mealOptionsSaga),
    call(dosingSaga),
    call(mealAnalysisSaga),
    call(hcpSaga),
    call(glucoseUploadSaga),
    call(subjectDataSaga),
    ...devSagas
  ];

  yield all(sagas);
}
