// @flow
import * as React from "react";
import {
  DatePicker as MuiDatePicker,
  KeyboardDatePicker as MuiKeyboardDatePicker,
} from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import enUSLocale from "date-fns/locale/en-US";
import enGBLocale from "date-fns/locale/en-GB";
import frLocale from "date-fns/locale/fr";
import deLocale from "date-fns/locale/de";
import i18n from "i18next";
import wrapWithField from "./decorateWithField";
import { useTranslation } from "react-i18next";

const localeMap = {
  enUS: enUSLocale,
  enGB: enGBLocale,
  fr: frLocale,
  de: deLocale,
};

export const DatePicker = wrapWithField<
  any,
  React.ElementConfig<typeof MuiDatePicker>
>(function DatePicker(props) {
  const { onChange, onBlur, ...rest } = props;
  const { t } = useTranslation();
  
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
      <MuiDatePicker
        onChange={onChange(props.name)}
        onBlur={onBlur(props.name)}
        cancelLabel={t("actions.cancel")}
        okLabel={t("actions.ok")}
        todayLabel={t("fields.datePicker.today")}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
});

export const KeyboardDatePicker = wrapWithField<
  any,
  React.ElementConfig<typeof MuiKeyboardDatePicker>
>(function KeyboardDatePicker(props) {
  const { onChange, onBlur, ...rest } = props;
  const { t } = useTranslation();
  
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
      <MuiKeyboardDatePicker
        onChange={onChange(props.name)}
        onBlur={onBlur(props.name)}
        cancelLabel={t("actions.cancel")}
        okLabel={t("actions.ok")}
        todayLabel={t("fields.datePicker.today")}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
});
