// @flow
import * as React from "react";
import { useSelector, shallowEqual } from "react-redux";
import fromUnixTime from "date-fns/fromUnixTime";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PanePaper from "./PanePaper";
import OtherNoteRow from "./OtherNoteRow";
import SelectedTrace from "./SelectedTrace";
import { useStyles as useCommonStyles } from "./styles";
import { activitiesMap } from "../../../constants/activity";
import { formatDate } from "../../../utils/time";

import type { State } from "../../../reducers/types";
import { useTranslation } from "react-i18next";

type Props = {};

const useStyles = makeStyles(theme => ({
  headerContainer: {
    marginBottom: theme.spacing(4)
  },
  row: {
    padding: theme.spacing(2, 0)
  }
}));

export default function OtherNotesPane(props: Props) {
  const commonClasses = useCommonStyles(props);
  const classes = useStyles(props);
  const { t } = useTranslation();
  const notes = useSelector(({ mealAnalysis }: State) => {
    const {
      otherNotesItems,
      otherNotesByDate,
      selectedTraceDate
    } = mealAnalysis;
    const otherNotesId = otherNotesByDate[selectedTraceDate] || "";

    return otherNotesItems[otherNotesId] || null;
  }, shallowEqual);

  return notes ? (
    <PanePaper>
      <Grid
        className={classes.headerContainer}
        container
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={12} md={9}>
          <Typography
            className={commonClasses.paneHeader}
            component="h2"
            variant="h6"
            color="textSecondary"
          >
            {t("pages.mealAnalysis.otherNotesPane.title")}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body1" component="span">
            {formatDate(fromUnixTime(notes.time), "p P")}
          </Typography>
        </Grid>
        <Grid item xs={6} md={1} container justify="flex-end">
          <SelectedTrace />
        </Grid>
      </Grid>
      <Divider />
      <Grid container className={classes.row}>
        <Typography variant="subtitle2">{t("pages.mealAnalysis.otherNotesPane.noteName")}</Typography>
      </Grid>
      <Divider />
      <OtherNoteRow
        className={classes.row}
        title={t("pages.mealAnalysis.otherNotesPane.stress")}
        value={notes.stress}
      />
      <Divider />
      <OtherNoteRow
        className={classes.row}
        title={t("pages.mealAnalysis.otherNotesPane.illness")}
        value={notes.illness}
      />
      <Divider />
      <OtherNoteRow
        className={classes.row}
        title={t("pages.mealAnalysis.otherNotesPane.activity")}
        value={
          notes.activities !== null ? activitiesMap.get(notes.activities) : null
        }
      />
    </PanePaper>
  ) : null;
}
