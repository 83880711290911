// @flow
import {
  LOGIN_REQUEST,
  LOGOUT,
  PROFILE_INFO_REQUEST,
  PROFILE_INFO_SUCCESS,
  PROFILE_INFO_ERROR,
  PROFILE_LANGUAGE_REQUEST,
  PROFILE_LANGUAGE_SUCCESS,
  PROFILE_LANGUAGE_ERROR,
  LOGOUT_DONE,
  LOGIN_SUCCESS,
  LOGIN_ERROR
} from "../constants/actionTypes";
import type {
  LoginRequestAction,
  LoginSuccessPayload,
  LoginSuccessAction,
  LoginErrorAction,
  LogoutAction,
  LogoutDoneAction,
  SetProfileLanguagePayload,
  ProfileLanguageRequestAction,
  ProfileInfoRequestAction,
  ProfileInfoSuccessAction,
  ProfileLanguageSuccessAction,
  ProfileInfoErrorAction,
  ProfileLanguageErrorAction,
  ProfileInfoSuccessPayload
} from "./types/userActions";
import type { ErrorPayload } from "./types/generic";

export const loginRequest = (
  username: string,
  password: string
): LoginRequestAction => ({
  type: LOGIN_REQUEST,
  payload: { username, password }
});

export const loginSuccess = (
  payload: LoginSuccessPayload
): LoginSuccessAction => ({ type: LOGIN_SUCCESS, payload });
export const loginError = (payload: ErrorPayload): LoginErrorAction => ({
  type: LOGIN_ERROR,
  payload
});

export const logout = (): LogoutAction => ({ type: LOGOUT });
export const logoutDone = (): LogoutDoneAction => ({ type: LOGOUT_DONE });

export const getProfileInfo = (): ProfileInfoRequestAction => ({
  type: PROFILE_INFO_REQUEST
});

export const profileInfoSuccess = (
  payload: ProfileInfoSuccessPayload
): ProfileInfoSuccessAction => ({
  type: PROFILE_INFO_SUCCESS,
  payload
});
export const profileInfoError = (
  payload: ErrorPayload
): ProfileInfoErrorAction => ({
  type: PROFILE_INFO_ERROR,
  payload
});

export const setProfileLanguage = (payload: SetProfileLanguagePayload): ProfileLanguageRequestAction => ({
  type: PROFILE_LANGUAGE_REQUEST,
  payload
});

export const setProfileLanguageSuccess = (): ProfileLanguageSuccessAction => ({
  type: PROFILE_LANGUAGE_SUCCESS,
});

export const setProfileLanguageError = (
  payload: ErrorPayload
): ProfileLanguageErrorAction => ({
  type: PROFILE_LANGUAGE_ERROR,
  payload
});
