// @flow

// Notifications
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";

// Requests
export const REQUEST_START = "REQUEST_START";
export const REQUEST_SUCCESS = "REQUEST_SUCCESS";
export const REQUEST_ERROR = "REQUEST_ERROR";
export const REQUEST_UNAUTHORIZED = "REQUEST_UNAUTHORIZED";
export const REQUEST_FORBIDDEN = "REQUEST_FORBIDDEN";

// Authentication and Authorization
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS: "LOGIN_SUCCESS" = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const PROFILE_INFO_REQUEST = "PROFILE_INFO_REQUEST";
export const PROFILE_INFO_SUCCESS = "PROFILE_INFO_SUCCESS";
export const PROFILE_INFO_ERROR = "PROFILE_INFO_ERROR";
export const LOGOUT = "LOGOUT";
export const LOGOUT_DONE = "LOGOUT_DONE";

// User settings
export const PROFILE_LANGUAGE_REQUEST = "PROFILE_LANGUAGE_REQUEST";
export const PROFILE_LANGUAGE_SUCCESS = "PROFILE_LANGUAGE_SUCCESS";
export const PROFILE_LANGUAGE_ERROR = "PROFILE_LANGUAGE_ERROR";

// Admin - Web Users CRUD
export const USERS_LIST_REQUEST = "USERS_LIST_REQUEST";
export const USERS_LIST_SUCCESS = "USERS_LIST_SUCCESS";
export const USERS_LIST_ERROR = "USERS_LIST_ERROR";
export const USERS_LIST_MORE_REQUEST = "USERS_LIST_MORE_REQUEST";
export const USERS_LIST_MORE_SUCCESS = "USERS_LIST_MORE_SUCCESS";
export const USERS_LIST_MORE_ERROR = "USERS_LIST_MORE_ERROR";
export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_ERROR = "USER_CREATE_ERROR";
export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_ERROR = "USER_UPDATE_ERROR";
export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const CLEAR_USER_FORM_ERRORS = "CLEAR_USER_FORM_ERRORS";
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";
export const DELETE_USER_CONFIRM_DIALOG = "DELETE_USER_CONFIRM_DIALOG";

// HCP/Researcher - Patients CRUD
export const PATIENT_CREATE_REQUEST = "PATIENT_CREATE_REQUEST";
export const PATIENT_UPDATE_REQUEST = "PATIENT_UPDATE_REQUEST";
export const PATIENT_DELETE_REQUEST = "PATIENT_DELETE_REQUEST";
export const PATIENT_CREATE_SUCCESS = "PATIENT_CREATE_SUCCESS";
export const PATIENT_UPDATE_SUCCESS = "PATIENT_UPDATE_SUCCESS";
export const PATIENT_DELETE_SUCCESS = "PATIENT_DELETE_SUCCESS";
export const PATIENT_CREATE_ERROR = "PATIENT_CREATE_ERROR";
export const PATIENT_UPDATE_ERROR = "PATIENT_UPDATE_ERROR";
export const PATIENT_DELETE_ERROR = "PATIENT_DELETE_ERROR";
export const PATIENT_DELETE_CONFIRM_DIALOG = "PATIENT_DELETE_CONFIRM_DIALOG";
export const PATIENT_GET_REQUEST = "PATIENT_GET_REQUEST";
export const PATIENTS_LIST_REQUEST = "PATIENTS_LIST_REQUEST";
export const PATIENTS_LIST_MORE_REQUEST = "PATIENTS_LIST_MORE_REQUEST";
export const PATIENT_GET_SUCCESS = "PATIENT_GET_SUCCESS";
export const PATIENTS_LIST_SUCCESS = "PATIENTS_LIST_SUCCESS";
export const PATIENTS_LIST_MORE_SUCCESS = "PATIENTS_LIST_MORE_SUCCESS";
export const PATIENT_GET_ERROR = "PATIENT_GET_ERROR";
export const PATIENTS_LIST_ERROR = "PATIENTS_LIST_ERROR";
export const PATIENTS_LIST_MORE_ERROR = "PATIENTS_LIST_MORE_ERROR";
export const CLEAR_PATIENT_FORM_ERRORS = "CLEAR_PATIENT_FORM_ERRORS";
export const PATIENT_REMOVE_DEVICE_REQUEST = "PATIENT_REMOVE_DEVICE_REQUEST";
export const PATIENT_REMOVE_DEVICE_SUCCESS = "PATIENT_REMOVE_DEVICE_SUCCESS";
export const PATIENT_REMOVE_DEVICE_ERROR = "PATIENT_REMOVE_DEVICE_ERROR";
export const REMOVE_DEVICE_CONFIRM_DIALOG = "REMOVE_DEVICE_CONFIRM_DIALOG";
export const PATIENT_FETCH_ANSWERS_REQUEST = "PATIENT_FETCH_ANSWERS_REQUEST";
export const PATIENT_FETCH_ANSWERS_SUCCESS = "PATIENT_FETCH_ANSWERS_SUCCESS";
export const PATIENT_FETCH_ANSWERS_ERROR = "PATIENT_FETCH_ANSWERS_ERROR";
export const PATIENT_FETCH_ACTIVITIES_REQUEST =
  "PATIENT_FETCH_ACTIVITIES_REQUEST";
export const PATIENT_FETCH_ACTIVITIES_SUCCESS =
  "PATIENT_FETCH_ACTIVITIES_SUCCESS";
export const PATIENT_FETCH_ACTIVITIES_ERROR = "PATIENT_FETCH_ACTIVITIES_ERROR";
export const PATIENT_FETCH_BOLUS_INSULIN_REQUEST = "PATIENT_FETCH_BOLUS_INSULIN_REQUEST";
export const PATIENT_FETCH_BOLUS_INSULIN_SUCCESS = "PATIENT_FETCH_BOLUS_INSULIN_SUCCESS";
export const PATIENT_FETCH_BOLUS_INSULIN_ERROR = "PATIENT_FETCH_BOLUS_INSULIN_ERROR";
export const PATIENT_FETCH_BASAL_INSULIN_REQUEST = "PATIENT_FETCH_BASAL_INSULIN_REQUEST";
export const PATIENT_FETCH_BASAL_INSULIN_SUCCESS = "PATIENT_FETCH_BASAL_INSULIN_SUCCESS";
export const PATIENT_FETCH_BASAL_INSULIN_ERROR = "PATIENT_FETCH_BASAL_INSULIN_ERROR";
export const PATIENT_FETCH_GLUCOSE_REQUEST = "PATIENT_FETCH_GLUCOSE_REQUEST";
export const PATIENT_FETCH_GLUCOSE_SUCCESS = "PATIENT_FETCH_GLUCOSE_SUCCESS";
export const PATIENT_FETCH_GLUCOSE_ERROR = "PATIENT_FETCH_GLUCOSE_ERROR";
export const PATIENT_FETCH_HEART_RATE_REQUEST = "PATIENT_FETCH_HEART_RATE_REQUEST";
export const PATIENT_FETCH_HEART_RATE_SUCCESS = "PATIENT_FETCH_HEART_RATE_SUCCESS";
export const PATIENT_FETCH_HEART_RATE_ERROR = "PATIENT_FETCH_HEART_RATE_ERROR";
export const PATIENT_FETCH_STEP_COUNTS_REQUEST = "PATIENT_FETCH_STEP_COUNTS_REQUEST";
export const PATIENT_FETCH_STEP_COUNTS_SUCCESS = "PATIENT_FETCH_STEP_COUNTS_SUCCESS";
export const PATIENT_FETCH_STEP_COUNTS_ERROR = "PATIENT_FETCH_STEP_COUNTS_ERROR";
export const DOWNLOAD_SUBJECT_DATA_DIALOG = "DOWNLOAD_SUBJECT_DATA_DIALOG";

// Researcher - HCP Read
export const HCP_LIST_REQUEST = "HCP_LIST_REQUEST";
export const HCP_LIST_SUCCESS = "HCP_LIST_SUCCESS";
export const HCP_LIST_ERROR = "HCP_LIST_ERROR";
export const HCP_LIST_MORE_REQUEST = "HCP_LIST_MORE_REQUEST";
export const HCP_LIST_MORE_SUCCESS = "HCP_LIST_MORE_SUCCESS";
export const HCP_LIST_MORE_ERROR = "HCP_LIST_MORE_ERROR";
export const HCP_GET_REQUEST = "HCP_GET_REQUEST";
export const HCP_GET_SUCCESS = "HCP_GET_SUCCESS";
export const HCP_GET_ERROR = "HCP_GET_ERROR";

// Meal Options CRUD
export const MEAL_OPTION_CREATE_REQUEST = "MEAL_OPTION_CREATE_REQUEST";
export const MEAL_OPTION_UPDATE_REQUEST = "MEAL_OPTION_UPDATE_REQUEST";
export const MEAL_OPTION_DELETE_REQUEST = "MEAL_OPTION_DELETE_REQUEST";
export const MEAL_OPTION_CREATE_SUCCESS = "MEAL_OPTION_CREATE_SUCCESS";
export const MEAL_OPTION_UPDATE_SUCCESS = "MEAL_OPTION_UPDATE_SUCCESS";
export const MEAL_OPTION_DELETE_SUCCESS = "MEAL_OPTION_DELETE_SUCCESS";
export const MEAL_OPTION_CREATE_ERROR = "MEAL_OPTION_CREATE_ERROR";
export const MEAL_OPTION_UPDATE_ERROR = "MEAL_OPTION_UPDATE_ERROR";
export const MEAL_OPTION_DELETE_ERROR = "MEAL_OPTION_DELETE_ERROR";
export const MEAL_OPTION_DELETE_CONFIRM_DIALOG =
  "MEAL_OPTION_DELETE_CONFIRM_DIALOG";
export const MEAL_OPTION_GET_REQUEST = "MEAL_OPTION_GET_REQUEST";
export const MEAL_OPTIONS_LIST_REQUEST = "MEAL_OPTIONS_LIST_REQUEST";
export const MEAL_OPTIONS_LIST_MORE_REQUEST = "MEAL_OPTIONS_LIST_MORE_REQUEST";
export const MEAL_OPTION_GET_SUCCESS = "MEAL_OPTION_GET_SUCCESS";
export const MEAL_OPTIONS_LIST_SUCCESS = "MEAL_OPTIONS_LIST_SUCCESS";
export const MEAL_OPTIONS_LIST_MORE_SUCCESS = "MEAL_OPTIONS_LIST_MORE_SUCCESS";
export const MEAL_OPTION_GET_ERROR = "MEAL_OPTION_GET_ERROR";
export const MEAL_OPTIONS_LIST_ERROR = "MEAL_OPTIONS_LIST_ERROR";
export const MEAL_OPTIONS_LIST_MORE_ERROR = "MEAL_OPTIONS_LIST_MORE_ERROR";
export const CLEAR_MEAL_OPTION_FORM_ERRORS = "CLEAR_MEAL_OPTION_FORM_ERRORS";

// Dosing Strategy
export const DOSING_STRATEGY_GET_REQUEST = "DOSING_STRATEGY_GET_REQUEST";
export const DOSING_STRATEGY_GET_SUCCESS = "DOSING_STRATEGY_GET_SUCCESS";
export const DOSING_STRATEGY_GET_ERROR = "DOSING_STRATEGY_GET_ERROR";
export const DOSING_STRATEGY_SAVE_REQUEST = "DOSING_STRATEGY_SAVE_REQUEST";
export const DOSING_STRATEGY_SAVE_OPTIMISTIC_SUCCESS =
  "DOSING_STRATEGY_SAVE_OPTIMISTIC_SUCCESS";
export const DOSING_STRATEGY_SAVE_DONE = "DOSING_STRATEGY_SAVE_DONE";
export const FIXED_DOSE_SAVE_REQUEST = "FIXED_DOSE_SAVE_REQUEST";
export const FIXED_DOSE_SAVE_SUCCESS = "FIXED_DOSE_SAVE_SUCCESS";
export const FIXED_DOSE_SAVE_ERROR = "FIXED_DOSE_SAVE_ERROR";
export const DOSE_ADJUSTMENT_SAVE_REQUEST = "DOSE_ADJUSTMENT_SAVE_REQUEST";
export const SLIDING_SCALE_SAVE_SUCCESS = "SLIDING_SCALE_SAVE_SUCCESS";
export const CORRECTION_FACTOR_SAVE_SUCCESS = "CORRECTION_FACTOR_SAVE_SUCCESS";
export const REMOVE_DOSE_ADJUSTMENT_SUCCESS = "REMOVE_DOSE_ADJUSTMENT_SUCCESS";
export const SLIDING_SCALE_SAVE_ERROR = "SLIDING_SCALE_SAVE_ERROR";
export const CORRECTION_FACTOR_SAVE_ERROR = "CORRECTION_FACTOR_SAVE_ERROR";
export const REMOVE_DOSE_ADJUSTMENT_ERROR = "REMOVE_DOSE_ADJUSTMENT_ERROR";

// Meal Analysis
export const MEAL_ANALYSIS_GET_REQUEST = "MEAL_ANALYSIS_GET_REQUEST";
export const MEAL_ANALYSIS_GET_SUCCESS = "MEAL_ANALYSIS_GET_SUCCESS";
export const MEAL_ANALYSIS_GET_ERROR = "MEAL_ANALYSIS_GET_ERROR";
export const MEAL_ANALYSIS_SELECT_TRACE = "MEAL_ANALYSIS_SELECT_TRACE";
export const MEAL_ANALYSIS_SELECT_MEAL_EVENT =
  "MEAL_ANALYSIS_SELECT_MEAL_EVENT";

// Glucose Upload
export const GLUCOSE_UPLOAD_REQUEST = "GLUCOSE_UPLOAD_REQUEST";
export const GLUCOSE_UPLOAD_SUCCESS = "GLUCOSE_UPLOAD_SUCCESS";
export const GLUCOSE_UPLOAD_ERROR = "GLUCOSE_UPLOAD_ERROR";
