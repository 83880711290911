// @flow
import * as React from "react";
import sortBy from "lodash/sortBy";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import withStyles from "@material-ui/core/styles/withStyles";

import { selectTrace } from "../../../actions/mealAnalysisActions";

import type { State } from "../../../reducers/types";
import { useTranslation } from "react-i18next";

const LegendColoredIcon = withStyles(theme => ({
  root: {
    color: (props: { color: string }) => props.color
  }
}))(ListItemIcon);
const StyledTextField = withStyles(theme => ({
  root: {
    "& .MuiSelect-root": {
      alignItems: "center",
      display: "flex"
    }
  }
}))(TextField);

const { useCallback } = React;

export default function TracePicker(props: *) {
  const { selectedTraceDate, traceColors, tracesDates } = useSelector(
    ({ mealAnalysis }: State) => {
      const { selectedTraceDate, traceColors, tracesDates } = mealAnalysis;
      return {
        selectedTraceDate,
        traceColors,
        tracesDates
      };
    },
    shallowEqual
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orderedDates = sortBy(
    tracesDates,
    dateString => 0 - new Date(dateString).getTime()
  );

  const handleTraceChange = useCallback(
    (evt: SyntheticInputEvent<*>) => {
      dispatch(selectTrace(evt.target.value));
    },
    [dispatch]
  );

  return (
    <StyledTextField
      select
      label={t("pages.mealAnalysis.trace")}
      variant="outlined"
      margin="normal"
      fullWidth
      disabled={!orderedDates.length}
      value={selectedTraceDate}
      onChange={handleTraceChange}
    >
      {orderedDates.map(dateString => (
        <MenuItem value={dateString} key={dateString}>
          <LegendColoredIcon color={traceColors[dateString]}>
            <CircleIcon />
          </LegendColoredIcon>
          <Typography variant="inherit">{dateString}</Typography>
        </MenuItem>
      ))}
    </StyledTextField>
  );
}
