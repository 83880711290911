// @flow
import * as React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

type Props = $ReadOnly<{
  ...React.ElementConfig<typeof Button>,
  displayName: string
}>;

const useButtonStyles = makeStyles(theme => ({
  label: {
    textTransform: "capitalize",
    fontSize: 16,
    fontWeight: "normal"
  }
}));
const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(2)
  }
}));

function ProfileButton(props: Props, ref: React.Ref<typeof Button>) {
  const buttonClasses = useButtonStyles(props);
  const classes = useStyles(props);
  const { displayName, ...buttonProps } = props;

  return (
    <Button ref={ref} classes={buttonClasses} {...buttonProps}>
      {displayName}
      <AccountCircleIcon className={classes.icon} fontSize="large" />
    </Button>
  );
}

export default React.forwardRef<Props, React.ElementRef<"button">>(
  ProfileButton
);
