// @flow
import * as React from "react";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableSortLabel from "@material-ui/core/TableSortLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import { ASC } from "../../constants/sorting";

import type { WithStyles } from "@material-ui/core";
import type { HeadCellProps } from "./types";

const useStyles = withStyles(theme => {
  const alternativeColor = "#757575";

  return {
    label: {
      color: alternativeColor,
      lineHeight: "1.17rem"
    },
    alignLeft: {
      justifyContent: "left"
    },
    alignRight: {
      justifyContent: "right"
    },
    alignCenter: {
      justifyContent: "center"
    },
    sortLabel: {
      flexFlow: "row",
      color: alternativeColor,
      ".ReactVirtualized__Table__sortableHeaderColumn:hover &": {
        color: theme.palette.text.primary
      },
      ".ReactVirtualized__Table__sortableHeaderColumn:hover & $sortIcon": {
        color: alternativeColor,
        opacity: 1
      },
      "&:hover $sortIcon": {
        color: alternativeColor
      }
    },
    sortIcon: {
      color: alternativeColor
    }
  };
});

type Props = {|
  ...$Exact<WithStyles>,
  ...HeadCellProps
|};

class TableHeadCell extends React.Component<Props> {
  static defaultProps = {
    sortDirection: ASC,
    sortBy: "",
    sortable: false,
    dataKey: ""
  };

  handleClick = (event: SyntheticEvent<HTMLElement, MouseEvent>) => {
    const { onClick, dataKey, columnData } = this.props;

    if (onClick) {
      onClick({ dataKey, event, columnData });
    }
  };

  handleSortClick = (event: SyntheticEvent<HTMLElement, MouseEvent>) => {
    const { onSortClick, dataKey, sortable, columnData } = this.props;

    if (sortable && onSortClick && dataKey) {
      onSortClick({ dataKey, event, columnData });
    }
  };

  render() {
    const {
      sortable,
      dataKey,
      sortBy,
      sortDirection,
      label,
      align,
      classes,
      className,
      dataQa,
      style
    } = this.props;

    const isSorted = sortable && sortBy === dataKey;

    const cellContent = sortable ? (
      <MuiTableSortLabel
        classes={{ root: classes.sortLabel, icon: classes.sortIcon }}
        active={isSorted}
        direction={isSorted ? sortDirection : ASC}
        data-qa={dataQa}
        onClick={this.handleSortClick}
      >
        {label}
      </MuiTableSortLabel>
    ) : (
      label
    );

    return (
      <MuiTableCell
        component="div"
        variant="head"
        className={className}
        classes={{
          root: classes.label,
          alignLeft: classes.alignLeft,
          alignRight: classes.alignRight,
          alignCenter: classes.alignCenter
        }}
        style={style}
        align={align}
        sortDirection={isSorted ? sortDirection : false}
        onClick={this.handleClick}
      >
        {cellContent}
      </MuiTableCell>
    );
  }
}

export default useStyles(TableHeadCell);
