// flow
import MuiTabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import withStyles from "@material-ui/core/styles/withStyles";

export const Tabs = withStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1)
  },
  indicator: {
    backgroundColor: theme.palette.primary.main
  }
}))(MuiTabs);

export const Tab = withStyles(theme => ({
  root: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: theme.typography.pxToRem(14)
  },
  selected: {
    color: theme.palette.primary.main
  }
}))(MuiTab);
