// @flow
import * as React from "react";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";

import {
  FOOD,
  DRINK,
  BREAKFAST,
  LUNCH,
  DINNER,
  SNACK,
  mealCategories,
  mealTypes
} from "../../../constants/meal";

import type { MealType, MealCategory } from "../../../constants/meal";
import { useTranslation } from "react-i18next";

type Value = {|
  mealType: MealType | null,
  mealCategory: MealCategory | null
|};

type Props = {
  className?: string,
  value: Value,
  onChange(value: Value): void
};

const useStyles = makeStyles(theme => ({
  foodItem: {
    paddingLeft: theme.spacing(4)
  }
}));

const mealFilterOptions: Array<{
  ...Value,
  label: string,
  key: string,
  disabled?: boolean
}> = [
  {
    label: "pages.mealMenuList.filter.typesAll",
    mealType: null,
    mealCategory: null,
    key: "all"
  },
  {
    label: "mealCategories.beverage",
    mealType: null,
    mealCategory: DRINK,
    key: "beverage"
  },
  {
    label: "mealCategories.meal",
    disabled: true,
    mealType: null,
    mealCategory: null,
    key: "food_group"
  },
  {
    label: "pages.mealMenuList.filter.mealAll",
    mealType: null,
    mealCategory: FOOD,
    key: "food_all"
  },
  {
    label: "mealTypes.breakfast",
    mealType: BREAKFAST,
    mealCategory: FOOD,
    key: "food_breakfast"
  },
  {
    label: "mealTypes.lunch",
    mealType: LUNCH,
    mealCategory: FOOD,
    key: "food_lunch"
  },
  {
    label: "mealTypes.dinner",
    mealType: DINNER,
    mealCategory: FOOD,
    key: "food_dinner"
  },
  {
    label: "mealTypes.snack",
    mealType: SNACK,
    mealCategory: FOOD,
    key: "food_snack"
  }
];

function renderValue(value: string, t): string {
  const optionIndex = parseInt(value, 10);
  const option = mealFilterOptions[optionIndex];
  const { mealCategory, mealType } = option || mealFilterOptions[0];

  if (mealCategory === null && mealType === null) {
    return t("pages.mealMenuList.filter.typesAll");
  }

  if (mealCategory === FOOD) {
    const foodGroup = t("pages.mealMenuList.filter.mealSubtitle");
    const foodType =
      typeof mealType === "number"
        ? t(mealTypes.get(mealType) || "")
        : t("pages.mealMenuList.filter.mealAll");

    return `${foodGroup} - ${foodType}`;
  }

  if (mealCategory === DRINK) {
    return t(mealCategories.get(DRINK) || "");
  }

  return "";
}

function getMealFilterValue({ mealType, mealCategory }: $Shape<Value>): number {
  return mealFilterOptions.findIndex(
    option =>
      !option.disabled &&
      option.mealCategory === mealCategory &&
      option.mealType === mealType
  );
}

function MealFilter(props: Props) {
  const { className, value, onChange } = props;
  const { t } = useTranslation();
  const classes = useStyles(props);

  function handleChange(evt: SyntheticInputEvent<*>) {
    const optionIndex = parseInt(evt.target.value, 10);
    const option = Number.isNaN(optionIndex)
      ? mealFilterOptions[0]
      : mealFilterOptions[optionIndex];
    const { mealType, mealCategory } = option || {};

    if (option) {
      onChange({ mealType, mealCategory });
    }
  }

  return (
    <TextField
      label={t("pages.mealMenuList.filter.title")}
      select
      margin="dense"
      variant="outlined"
      className={className}
      value={getMealFilterValue(value)}
      onChange={handleChange}
      SelectProps={{
        renderValue: value => renderValue(value, t)
      }}
    >
      {mealFilterOptions.map((option, i) => (
        <MenuItem
          key={option.key}
          disabled={option.disabled}
          value={option.disabled ? undefined : i}
          className={clsx({ [classes.foodItem]: option.mealCategory === FOOD })}
        >
          {t(option.label)}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default MealFilter;
