// @flow
import * as React from "react";
import clsx from "clsx";
import { useSelector, shallowEqual } from "react-redux";
import {
  FlexibleWidthXYPlot as XYPlot,
  VerticalRectSeries as RectSeries,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  Crosshair,
  Borders
} from "react-vis";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import EmptyView from "../../../components/Table/EmptyView";
import HintBox from "../../../components/Hint";
import {
  getSelectedGlucoseReading,
  getStepsData
} from "../../../selectors/mealAnalysis";
import { useEmptyViewStyles } from "./styles";
import { useDataLoadState } from "./hooks";
import {
  AROUND_MEAL,
  HOURS_24,
  ABS_X_DOMAIN,
  DELTA_X_DOMAIN,
  ABS_X_TICK_VALUES,
  DELTA_X_TICK_VALUES,
  xTickFormatAbs,
  xTickFormatDelta,
  xAccessor,
  yStepsAccessor as yAccessor,
  chartBordersStyle,
  chartMargins,
  getAccessor
} from "./chartHelpers";

import type {
  StepItem,
  GlucoseReadingWithMealEvent
} from "../../../reducers/types";
import { useTranslation } from "react-i18next";

type Props = {
  xAxisMode: typeof AROUND_MEAL | typeof HOURS_24
};
type RectItem = { x: number, x0: number, y: number, y0: number };

const { useState, useCallback, useMemo } = React;
const useStyles = makeStyles(theme => ({
  emptyContainer: {
    height: 150
  }
}));
const crosshairStyle = { line: { background: "#ff6d00" } };

export default function StepsChart(props: Props) {
  const { xAxisMode } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  const emptyViewClasses = useEmptyViewStyles();
  const [hoveredBar, setHoveredBar] = useState<RectItem | null>(null);
  const glucoseReading = useSelector(getSelectedGlucoseReading);
  const stepsData = useSelector(getStepsData, shallowEqual);
  const { loading, error } = useDataLoadState();

  const xDeltaMode = xAxisMode === AROUND_MEAL;
  const getX = getAccessor<StepItem | GlucoseReadingWithMealEvent>(
    xAccessor,
    xDeltaMode,
    glucoseReading
  );
  const xDomain = xDeltaMode ? DELTA_X_DOMAIN : ABS_X_DOMAIN;
  const xTickValues = xDeltaMode ? DELTA_X_TICK_VALUES : ABS_X_TICK_VALUES;
  const hoveredX = hoveredBar ? hoveredBar.x : Number.POSITIVE_INFINITY;
  const data = useMemo(
    () =>
      stepsData.map(stepItem => {
        const x0 = getX(stepItem);
        const y = yAccessor(stepItem);

        return {
          x0,
          x: x0 + 1,
          y,
          y0: 0
        };
      }),
    [getX, stepsData]
  );
  const isEmpty = !data.length;

  const handleHover = useCallback((d: RectItem) => {
    setHoveredBar(d);
  }, []);

  const handleBlur = useCallback(() => {
    setHoveredBar(null);
  }, []);

  if (isEmpty) {
    return (
      <EmptyView
        classes={{
          ...emptyViewClasses,
          root: clsx(emptyViewClasses.root, classes.emptyContainer)
        }}
        loading={loading}
        error={!!error}
        emptyMessage={t("pages.mealAnalysis.emptyStepsData")}
        errorMessage={t("pages.mealAnalysis.unableToDrawStepsChart")}
      />
    );
  }

  const crosshair =
    hoveredBar &&
    hoveredBar.y &&
    hoveredX >= xDomain[0] &&
    hoveredX <= xDomain[1] ? (
      <Crosshair style={crosshairStyle} values={[hoveredBar]}>
        <HintBox>
          <Typography variant="inherit" component="span">
            {hoveredBar.y}&nbsp;{t("pages.mealAnalysis.steps").toLowerCase()}
          </Typography>
        </HintBox>
      </Crosshair>
    ) : null;

  return (
    <XYPlot
      height={150}
      xType="linear"
      margin={chartMargins}
      xDomain={xDomain}
      onMouseLeave={handleBlur}
    >
      <VerticalGridLines tickValues={xTickValues} />
      <HorizontalGridLines />
      <RectSeries color="#00b8d6" data={data} onNearestX={handleHover} />
      {crosshair}
      <Borders style={chartBordersStyle} />
      <XAxis
        title={t("pages.mealAnalysis.time")}
        position="start"
        tickValues={xTickValues}
        tickFormat={
          xAxisMode === AROUND_MEAL ? xTickFormatDelta : xTickFormatAbs
        }
      />
      <YAxis title={t("pages.mealAnalysis.steps")} />
    </XYPlot>
  );
}
