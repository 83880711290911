// @flow
import { useEffect } from "react";
import { APIError } from "../../utils/errors";

import type { FormikBag } from "formik";
import type { APIErrors } from "../../utils/errors";

export function useValidationErrors(
  formik: FormikBag<*, *> | null,
  error: ?APIErrors<*>
) {
  useEffect(() => {
    if (formik && error instanceof APIError) {
      formik.setErrors(error.fieldErrors);
    }
  }, [error, formik]);
}
