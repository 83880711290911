// @flow
import * as React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  AutoSizer,
  Column,
  Table as VirtualizedTable,
  InfiniteLoader,
  defaultTableRowRenderer
} from "react-virtualized";
import clsx from "clsx";
import find from "lodash/find";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import TableHeadCell from "./TableHeadCell";
import TableCell from "./TableCell";
import EmptyView from "./EmptyView";
import LoadingRow from "./LoadingRow";
import { ASC } from "../../constants/sorting";

import type { WithStyles } from "@material-ui/core";
import type { SortOrderShortEnum } from "../../constants/sorting";
import type {
  RowData,
  CellProps,
  HeadCellProps,
  VirtualizedColumnProps,
  HeadCellRendererProps,
  CellRendererProps,
  RowRendererProps,
  InfiniteLoaderProps,
  AutoSizerProps
} from "./types";

type DefaultProps = {|
  defaultHeight: number,
  defaultWidth: number,
  width: number,
  height: number,
  rowHeight?: number,
  headerHeight?: number,
  sortDirection: SortOrderShortEnum,
  sortBy: string,
  rowKey: string,
  onSort?: (sortBy: string, sortDirection: SortOrderShortEnum) => any,
  onColumnClick?: (props: CellProps) => any,
  onRowClick?: ({
    event: SyntheticEvent<HTMLElement, MouseEvent>,
    index: number,
    rowData: RowData
  }) => any,
  onHeaderClick?: ({
    columnData: any,
    dataKey: string,
    evt: SyntheticEvent<HTMLElement, MouseEvent>
  }) => any,
  noRowsRenderer: React.ComponentType<React.ElementConfig<typeof EmptyView>>,
  loading?: boolean,
  error?: boolean,
  autoSize?: boolean,
  infiniteLoad?: boolean,
  ...InfiniteLoaderProps,
  ...AutoSizerProps
|};

type Props = {|
  ...DefaultProps,
  ...$Exact<WithStyles>,
  ...$Exact<WithTranslation>,
  columns: Array<VirtualizedColumnProps>,
  rowCount: number,
  rowGetter: ({ index: number }) => any
|};

const styles = theme => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box"
  },
  table: {
    "& .ReactVirtualized__Grid": {
      "&:focus": {
        outline: "none"
      }
    },
    "& .ReactVirtualized__Table__headerColumn": {
      "&:focus": {
        outline: "none"
      }
    }
  },
  tableRow: {
    cursor: "pointer"
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200]
    }
  },
  tableCell: {
    flex: 1
  },
  headCell: {},
  noClick: {
    cursor: "initial"
  }
});
const useStyles = withStyles(styles);

export class MuiVirtualizedTable extends React.Component<Props> {
  static defaultProps: DefaultProps = {
    defaultHeight: 300,
    defaultWidth: 200,
    width: 300,
    height: 200,
    rowHeight: 48,
    headerHeight: 48,
    sortDirection: ASC,
    sortBy: "",
    rowKey: "id",
    noRowsRenderer: EmptyView,
    loading: false,
    error: false,
    autoSize: true,
    infiniteLoad: false,
    loadingRowRenderer: LoadingRow
  };

  tableRef: VirtualizedTable | null = null;
  infiniteLoaderRef: InfiniteLoader | null = null;

  handleSort = (sortEvent: {
    defaultSortDirection: string,
    event: SyntheticEvent<HTMLElement, MouseEvent>,
    sortBy: string,
    sortDirection: "ASC" | "DESC"
  }) => {
    const { sortBy, sortDirection } = sortEvent;
    const { onSort } = this.props;
    const newSortDirection = sortDirection.toLowerCase();

    if (onSort) {
      onSort(sortBy, newSortDirection);
    }
  };

  getRowClassName = ({ index }: { index: number }) => {
    const { classes, onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null
    });
  };

  setRef = (ref: VirtualizedTable | null) => {
    this.tableRef = ref;
  };

  setInfiniteLoaderRef = (ref: InfiniteLoader | null) => {
    this.infiniteLoaderRef = ref;
  };

  scrollToRow = (rowIndex: number) => {
    if (this.tableRef) {
      this.tableRef.scrollToRow(rowIndex);
    }
  };

  resetLoadMoreRowsCache = () => {
    if (this.infiniteLoaderRef) {
      this.infiniteLoaderRef.resetLoadMoreRowsCache();
    }
  };

  renderEmpty = () => {
    const { noRowsRenderer: NoRowsRenderer, loading, error } = this.props;

    return <NoRowsRenderer loading={!!loading} error={error} />;
  };

  renderCell = (cellProps: CellRendererProps) => {
    const {
      dataKey,
      cellData,
      columnData,
      columnIndex,
      rowData,
      rowIndex,
      isScrolling
    } = cellProps;
    const { columns, classes, rowHeight, onRowClick } = this.props;
    const column = columns[columnIndex];

    if (!column) return null;

    const { cellRenderer: CellRenderer, align } = column;
    const props: CellProps = {
      dataKey,
      cellData,
      rowData,
      columnData,
      columnIndex,
      rowIndex,
      isScrolling,
      align,
      className: clsx(classes.tableCell, classes.flexContainer, {
        [classes.noClick]: !onRowClick
      }),
      style: { height: rowHeight }
    };

    return CellRenderer ? (
      <CellRenderer key={dataKey} {...props} />
    ) : (
      <TableCell key={dataKey} {...props} />
    );
  };

  renderHeadCell = (headerCellProps: HeadCellRendererProps) => {
    const {
      disableSort,
      dataKey,
      sortBy,
      sortDirection,
      label,
      columnData
    } = headerCellProps;
    const { headerHeight, columns, classes, t } = this.props;
    const column = find(columns, { dataKey });

    if (!column) return null;

    const { headerRenderer: HeadCellRenderer, align, dataQa } = column;
    const props: HeadCellProps = {
      align,
      dataKey,
      dataQa,
      label: t(label),
      columnData,
      sortable: !disableSort,
      sortBy,
      sortDirection: sortDirection.toLowerCase(),
      className: clsx(
        classes.headCell,
        classes.tableCell,
        classes.flexContainer,
        {
          [classes.noClick]: disableSort
        }
      ),
      style: { height: headerHeight }
    };

    return HeadCellRenderer ? (
      <HeadCellRenderer key={dataKey} {...props} />
    ) : (
      <TableHeadCell key={dataKey} {...props} />
    );
  };

  customRowRenderer = (props: RowRendererProps) => {
    const { rowData } = props;

    if (rowData.loading) {
      return <LoadingRow {...props} />;
    }

    return defaultTableRowRenderer(props);
  };

  renderTable = (
    injectedProps: {
      width?: number,
      height?: number,
      onRowsRendered?: Function,
      registerChild?: Object | Function
    } = {}
  ) => {
    const {
      columns,
      classes,
      sortBy,
      sortDirection,
      rowGetter,
      rowCount,
      rowHeight,
      headerHeight,
      autoSize,
      infiniteLoad,
      width,
      height,
      onRowClick
    } = this.props;

    const { onRowsRendered, registerChild } = injectedProps;
    const size = autoSize
      ? {
          width: injectedProps.width,
          height: injectedProps.height
        }
      : { width, height };
    const infiniteLoaderProps = infiniteLoad
      ? {
          onRowsRendered,
          ref: (ref: VirtualizedTable) => {
            registerChild && registerChild(ref);
            this.setRef(ref);
          },
          rowRenderer: this.customRowRenderer
        }
      : {};

    return (
      <VirtualizedTable
        rowClassName={this.getRowClassName}
        sort={this.handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection.toUpperCase()}
        rowGetter={rowGetter}
        rowCount={rowCount}
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        noRowsRenderer={this.renderEmpty}
        className={classes.table}
        ref={this.setRef}
        onRowClick={onRowClick}
        {...size}
        {...infiniteLoaderProps}
      >
        {columns.map(column => {
          const {
            dataKey,
            cellRenderer,
            headerRenderer,
            sortable,
            ...rest
          } = column;

          return (
            <Column
              key={dataKey}
              disableSort={!sortable}
              headerRenderer={this.renderHeadCell}
              className={classes.flexContainer}
              cellRenderer={this.renderCell}
              dataKey={dataKey}
              {...rest}
            />
          );
        })}
      </VirtualizedTable>
    );
  };

  withInfiniteLoader = (renderFn: (props: *) => React.Node) => {
    const {
      loadMoreRows,
      isRowLoaded,
      threshold,
      minimumBatchSize,
      rowCount
    } = this.props;

    return (props: *) => (
      <InfiniteLoader
        {...{
          loadMoreRows,
          isRowLoaded,
          threshold,
          minimumBatchSize,
          rowCount
        }}
        ref={this.setInfiniteLoaderRef}
      >
        {({ onRowsRendered, registerChild }) =>
          renderFn({ ...props, onRowsRendered, registerChild })
        }
      </InfiniteLoader>
    );
  };

  withAutosizer = (renderFn: (props: *) => React.Node) => {
    const {
      defaultHeight,
      defaultWidth,
      disableHeight,
      disableWidth,
      nonce,
      onResize
    } = this.props;
    return (props: *) => (
      <AutoSizer
        {...{
          defaultHeight,
          defaultWidth,
          disableHeight,
          disableWidth,
          nonce,
          onResize
        }}
      >
        {({ width, height }) => renderFn({ ...props, width, height })}
      </AutoSizer>
    );
  };

  render() {
    const { autoSize, infiniteLoad } = this.props;

    let renderTable = this.renderTable;

    if (autoSize) {
      renderTable = this.withAutosizer(renderTable);
    }

    if (infiniteLoad) {
      renderTable = this.withInfiniteLoader(renderTable);
    }

    return renderTable();
  }
}

export default withTranslation([], { withRef: true })(
  useStyles(MuiVirtualizedTable)
);
