// @flow
import * as React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

import PageLayout from "../../../components/PageLayout";
import { formatDate } from "../../../utils/time";
import HcpBreadcrumbs from "../HcpBreadcrumbs";
import ResearcherBreadcrumbs from "../../Researcher/ResearcherBreadcrumbs";
import DosingStrategyForm from "./DosingStrategyForm";
import { usePatientDataEffect } from "../hooks";
import {
  getDosingStrategy,
  saveDosingStrategy
} from "../../../actions/dosingStrategyActions";
import {
  selectStrategyId,
  selectStrategyValues,
  selectStrategyCreatedDate
} from "../../../selectors/dosingStrategy";
import { isHcp } from "../../../selectors/user";

import type { ContextRouter } from "react-router-dom";
import type { State } from "../../../reducers/types";
import { useTranslation } from "react-i18next";

type Props = {
  ...$Exact<ContextRouter>
};

const useStyles = makeStyles(theme => ({
  page: {
    display: "flex",
    flex: "1 0 100%",
    flexDirection: "column"
  },
  header: {
    marginBottom: theme.spacing(3)
  },
  pageContainer: {
    minHeight: 200,
    marginBottom: theme.spacing(4)
  }
}));

const { useEffect, useCallback, useMemo } = React;

export default function DosingStrategy(props: Props) {
  const classes = useStyles(props);
  const params = props.match.params;
  const patientId = params.patientId || "";
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const readOnly = useSelector(({ user }: State) => !isHcp(user));
  const pageLabel = useSelector(({ patients }: State) => {
    const patient = patients.patientsEntities[patientId];

    return t("pages.dosingStrategy.title") + (patient ? ` #${patient.subjectNumber}` : "");
  });
  const dosingStrategyId = useSelector(selectStrategyId);
  const createdAt = useSelector(selectStrategyCreatedDate);
  const dosingStrategyValues = useSelector(selectStrategyValues, shallowEqual);
  const { loading, saving, fixedDoseError, doseAdjustmentError } = useSelector(
    ({ dosingStrategy }: State) => {
      const {
        fixedDoseError,
        doseAdjustmentError,
        saving,
        loading
      } = dosingStrategy;
      return {
        saving,
        loading,
        fixedDoseError,
        doseAdjustmentError
      };
    },
    shallowEqual
  );
  const breadcrumbsItems = useMemo(() => [{ label: pageLabel }], [pageLabel]);
  const handleSubmit = useCallback(
    function(values, actions) {
      actions.setSubmitting(false);
      dispatch(saveDosingStrategy({ patientId, ...values }));
    },
    [dispatch, patientId]
  );

  usePatientDataEffect(patientId);
  useEffect(() => {
    dispatch(getDosingStrategy(patientId));
  }, [dispatch, patientId]);

  return (
    <PageLayout wide className={classes.page}>
      <HcpBreadcrumbs items={breadcrumbsItems} />
      <ResearcherBreadcrumbs items={breadcrumbsItems} />
      <Grid container className={classes.header}>
        <Grid item xs={6}>
          <Typography component="h1" variant="h5">
            {pageLabel}
          </Typography>
        </Grid>
        <Grid item container xs={6} justify="flex-end">
          <Typography component="span" variant="body1">
            {createdAt
              ? `${t("pages.dosingStrategy.strategyCreated")}: ${formatDate(createdAt, "p P")}`
              : ""}
          </Typography>
        </Grid>
      </Grid>
      <DosingStrategyForm
        dosingStrategy={
          !loading && dosingStrategyId ? dosingStrategyValues : undefined
        }
        loading={loading || saving}
        readOnly={readOnly}
        fixedDoseError={fixedDoseError}
        doseAdjustmentError={doseAdjustmentError}
        onSubmit={handleSubmit}
      />
    </PageLayout>
  );
}
