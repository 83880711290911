// @flow
import * as React from "react";
import noop from "lodash/noop";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Formik,
  Form,
  Field,
  TextField,
  PasswordField,
  SelectField
} from "../../components/Form";
import When from "../../components/When";
import { HCP_ROLE, ADMIN_ROLE, RESEARCHER_ROLE } from "../../constants/roles";
import { Yup, validationLocale } from "../../utils/validation";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";

import type { FormikHelpers } from "formik";
import type { RoleEnum } from "../../constants/roles";
import type { UserEntity } from "../../reducers/types";

type FormFields = {|
  role: RoleEnum,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  confirmPassword: string
|};

type DefaultProps = {|
  user: $Shape<UserEntity>,
  editMode: boolean,
  readOnly: boolean
|};

type Props = {|
  ...$Exact<WithTranslation>,
  ...DefaultProps,
  className?: string,
  formRef?: React.Ref<React.ComponentType<React.Config<typeof Formik>>>,
  onSubmit: (fields: FormFields, formikActions: FormikHelpers<*>) => any,
  children?: React.Node
|};

type State = {|
  showPassword: boolean
|};

const UserSchema = Yup.object().shape({
  role: Yup.mixed()
    .oneOf([HCP_ROLE, ADMIN_ROLE, RESEARCHER_ROLE], "Please select a user role")
    .required(),
  firstName: Yup.string()
    .trim()
    .min(2)
    .max(30)
    .required(),
  lastName: Yup.string()
    .trim()
    .min(2)
    .max(30)
    .required(),
  email: Yup.string()
    .trim()
    .email()
    .max(70)
    .required(),
  password: Yup.string()
    .min(8)
    .required(),
  confirmPassword: Yup.string().required()
});

const validateForm = (values: FormFields) => {
  let errors = {};

  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = validationLocale.equality("Password");
  }

  return errors;
};

class UserForm extends React.Component<Props, State> {
  static defaultProps: DefaultProps = {
    user: {
      userId: "",
      firstName: "",
      lastName: "",
      userName: "",
      role: -1,
      userPicture: "",
      password: ""
    },
    editMode: false,
    readOnly: false
  };

  state = {
    showPassword: false
  };

  togglePassword = (evt: any, showPassword: boolean) =>
    this.setState({ showPassword });

  render() {
    const { showPassword } = this.state;
    const {
      formRef,
      className,
      editMode,
      readOnly,
      user,
      onSubmit,
      children,
      t
    } = this.props;
    const { firstName, lastName, email, role, password } = user || {};
    const initialValues = {
      firstName,
      lastName,
      email: email || "",
      role: role > -1 ? role : "",
      password: !editMode || !password ? "" : password,
      confirmPassword: !editMode || !password ? "" : password
    };

    return (
      <Formik
        ref={formRef}
        initialValues={initialValues}
        enableReinitialize={editMode || readOnly}
        onSubmit={!readOnly ? onSubmit : noop}
        validate={!readOnly ? validateForm : noop}
        validationSchema={!readOnly ? UserSchema : null}
        render={props => (
          <Form className={className} blurOnSubmit noValidate>
            <Field
              component={TextField}
              name="email"
              variant="outlined"
              margin="normal"
              required
              readOnly={readOnly}
              fullWidth
              id="email"
              label={t("fields.common.userId")}
              autoComplete="email"
              color="secondary"
            />
            <Field
              component={TextField}
              name="firstName"
              variant="outlined"
              margin="normal"
              required
              readOnly={readOnly}
              fullWidth
              id="firstName"
              label={t("fields.common.firstName")}
              autoComplete="firstName"
              color="secondary"
            />
            <Field
              component={TextField}
              name="lastName"
              variant="outlined"
              margin="normal"
              required
              readOnly={readOnly}
              fullWidth
              id="lastName"
              label={t("fields.common.lastName")}
              autoComplete="lastName"
              color="secondary"
            />
            <Field
              component={SelectField}
              name="role"
              id="role"
              label={t("fields.common.role")}
              autoComplete="off"
              required
              readOnly={readOnly}
              disabled={editMode}
              variant="outlined"
              margin="normal"
              fullWidth
              color="secondary"
            >
              <MenuItem disabled value={-1}>
                {t("pages.userForm.chooseRole")}
              </MenuItem>
              <MenuItem value={ADMIN_ROLE}>{t(`roles.admin`)}</MenuItem>
              <MenuItem value={HCP_ROLE}>{t(`roles.hcp`)}</MenuItem>
              <MenuItem value={RESEARCHER_ROLE}>
                {t(`roles.researcher`)}
              </MenuItem>
            </Field>

            <When condition={!readOnly}>
              <Field
                component={PasswordField}
                name="password"
                id="password"
                label={t("fields.common.password")}
                autoComplete="new-password"
                required
                readOnly={readOnly}
                disabled={readOnly}
                variant="outlined"
                margin="normal"
                fullWidth
                color="secondary"
                showPasswordDataQa="showPassword"
                showPassword={showPassword}
                onShowClick={this.togglePassword}
              />
              <Field
                component={PasswordField}
                name="confirmPassword"
                id="confirmPassword"
                label={t("fields.common.confirmPassword")}
                autoComplete="new-password"
                required
                readOnly={readOnly}
                disabled={readOnly}
                variant="outlined"
                margin="normal"
                fullWidth
                color="secondary"
                showPasswordDataQa="showConfirmPassword"
                showPassword={showPassword}
                onShowClick={this.togglePassword}
              />
            </When>
            {children}
          </Form>
        )}
      />
    );
  }
}

export default withTranslation()(UserForm);
