// @flow
import {
  GLUCOSE_UPLOAD_REQUEST,
  GLUCOSE_UPLOAD_SUCCESS,
  GLUCOSE_UPLOAD_ERROR
} from "../constants/actionTypes";
import type {
  GlucoseUploadPayload,
  GlucoseUploadRequestAction,
  GlucoseUploadSuccessAction,
  GlucoseUploadErrorAction
} from "./types/glucoseUploadActions";
import type { APIErrors } from "../utils/errors";

export const uploadGlucose = (
  payload: GlucoseUploadPayload
): GlucoseUploadRequestAction => ({
  type: GLUCOSE_UPLOAD_REQUEST,
  payload
});
export const uploadGlucoseSuccess = (): GlucoseUploadSuccessAction => ({
  type: GLUCOSE_UPLOAD_SUCCESS
});
export const uploadGlucoseError = (
  error: APIErrors<*>
): GlucoseUploadErrorAction => ({
  type: GLUCOSE_UPLOAD_ERROR,
  payload: { error }
});
