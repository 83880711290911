// @flow
import * as React from "react";
import noop from "lodash/noop";
import { withFormik } from "formik";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import {
  FastField,
  NumberField,
  RadioGroupField
} from "../../../components/Form";
import When from "../../../components/When";
import { Tabs, Tab } from "../../../components/Tabs";
import { HcpFragment } from "../../withRestriction";
import StrategyPanel from "./StrategyPanel";
import OkButton from "./OkButton";
import SlidingScaleForm from "./SlidingScaleForm";
import CorrectionFactorForm from "./CorrectionFactorForm";
import { DosingStrategySchema, EmptySchema, validateForm } from "./validation";
import { mapPropsToValues, handleSubmit } from "./helpers";
import {
  NO_ADJUSTMENT,
  SLIDING_SCALE,
  CORRECTION_FACTOR
} from "../../../constants/dosing";

import type { InjectedFormikProps } from "formik";
import type { Values, FormConfig, OwnFormProps } from "./types";

type Props = {|
  ...OwnFormProps,
  ...InjectedFormikProps<OwnFormProps, Values>,
  ...$Exact<WithTranslation>
|};

type State = {
  tabIndex: number
};

const useStyles = withStyles(theme => ({
  pageContainer: {
    minHeight: 200,
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3)
  },
  divider: {
    margin: theme.spacing(0, -3)
  },
  subContainer: {
    padding: 0
  },
  radioGroup: {
    margin: theme.spacing(4, 0)
  },
  loadingBar: {
    marginBottom: theme.spacing(2)
  }
}));

const doseAdjustmentOptions = [
  {
    value: SLIDING_SCALE.toString(),
    label: "doseAdjustments.slidingScale",
    RadioProps: {
      color: "primary"
    }
  },
  {
    value: CORRECTION_FACTOR.toString(),
    label: "doseAdjustments.correctionFactor",
    RadioProps: {
      color: "primary"
    }
  },
  {
    value: NO_ADJUSTMENT.toString(),
    label: "doseAdjustments.noAdjustment",
    RadioProps: {
      color: "primary"
    }
  }
];

class DosingForm extends React.Component<Props, State> {
  static defaultProps = {
    loading: false,
    readOnly: false,
    fixedDoseError: null,
    doseAdjustmentError: null
  };

  state = {
    tabIndex: 0
  };

  handleTabChange = (evt, tabIndex) => this.setState({ tabIndex });

  render() {
    const {
      classes,
      values,
      dirty,
      submitCount,
      isValid,
      readOnly,
      handleReset,
      handleSubmit,
      loading,
      fixedDoseError,
      doseAdjustmentError,
      t
    } = this.props;
    const { tabIndex } = this.state;
    const doseAdjustmentType = parseInt(values.doseAdjustmentType, 10);
    const saveButtonAppearance = dirty
      ? { variant: "contained", color: "primary" }
      : {};

    return (
      <form noValidate onSubmit={handleSubmit} onReset={handleReset}>
        <Paper className={classes.pageContainer}>
          <Container className={classes.subContainer} maxWidth={false}>
            <FastField
              component={NumberField}
              name="morningBasalDose"
              type="number"
              step={0.1}
              min={0}
              precision={1}
              label={t("pages.dosingStrategy.morningBasalDose")}
              required
              readOnly={readOnly}
              variant="outlined"
              margin="normal"
              color="secondary"
              fullWidth
              autoComplete="new-value"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t("units.insulinUnits")}
                  </InputAdornment>
                )
              }}
            />
            <FastField
              component={NumberField}
              name="eveningBasalDose"
              type="number"
              step={0.1}
              min={0}
              precision={1}
              label={t("pages.dosingStrategy.eveningBasalDose")}
              required
              readOnly={readOnly}
              variant="outlined"
              margin="normal"
              fullWidth
              autoComplete="new-value"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t("units.insulinUnits")}
                  </InputAdornment>
                )
              }}
            />
          </Container>
          <Tabs value={tabIndex} onChange={this.handleTabChange}>
            <Tab label={t("pages.dosingStrategy.fixedDose")} />
            <Tab label={t("pages.dosingStrategy.adjustmentDose")} />
          </Tabs>
          <Divider className={classes.divider} orientation="horizontal" />
          <StrategyPanel
            className={classes.subContainer}
            value={0}
            currentTab={tabIndex}
            unmountOnHide
          >
            <FastField
              component={NumberField}
              name="breakfast"
              type="number"
              step={0.1}
              min={0}
              precision={1}
              label={t("mealTypes.breakfast")}
              required
              readOnly={readOnly}
              variant="outlined"
              margin="normal"
              color="secondary"
              fullWidth
              autoComplete="new-value"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t("units.insulinUnits")}
                  </InputAdornment>
                )
              }}
            />
            <FastField
              component={NumberField}
              name="lunch"
              type="number"
              step={0.1}
              min={0}
              precision={1}
              label={t("mealTypes.lunch")}
              required
              readOnly={readOnly}
              variant="outlined"
              margin="normal"
              color="secondary"
              fullWidth
              autoComplete="new-value"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t("units.insulinUnits")}
                  </InputAdornment>
                )
              }}
            />
            <FastField
              component={NumberField}
              name="dinner"
              type="number"
              step={0.1}
              min={0}
              precision={1}
              label={t("mealTypes.dinner")}
              required
              readOnly={readOnly}
              variant="outlined"
              margin="normal"
              color="secondary"
              fullWidth
              autoComplete="new-value"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t("units.insulinUnits")}
                  </InputAdornment>
                )
              }}
            />
            <FastField
              component={NumberField}
              name="snack"
              type="number"
              step={0.1}
              min={0}
              precision={1}
              label={t("mealTypes.snack")}
              required
              readOnly={readOnly}
              variant="outlined"
              margin="normal"
              color="secondary"
              fullWidth
              autoComplete="new-value"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t("units.insulinUnits")}
                  </InputAdornment>
                )
              }}
            />
          </StrategyPanel>
          <StrategyPanel
            className={classes.subContainer}
            value={1}
            currentTab={tabIndex}
            unmountOnHide
          >
            <RadioGroupField
              row
              className={classes.radioGroup}
              name="doseAdjustmentType"
              items={doseAdjustmentOptions}
              disabled={readOnly}
            />
            {doseAdjustmentType === SLIDING_SCALE && (
              <SlidingScaleForm readOnly={readOnly} />
            )}
            {doseAdjustmentType === CORRECTION_FACTOR && (
              <CorrectionFactorForm readOnly={readOnly} />
            )}
          </StrategyPanel>
          <When condition={submitCount > 0 && !isValid}>
            <Typography variant="body2" color="error">
              {t("pages.dosingStrategy.fieldsInvalidMessage")}
            </Typography>
          </When>
          <When condition={!!fixedDoseError}>
            {() => (
              <Typography variant="body2" color="error">
                {t("pages.dosingStrategy.saveFixedDoseError")}{" "}
                {fixedDoseError && fixedDoseError.message}
              </Typography>
            )}
          </When>
          <When condition={!!doseAdjustmentError}>
            {() => (
              <Typography variant="body2" color="error">
                {t("pages.dosingStrategy.saveDoseAdjustmentError")}{" "}
                {doseAdjustmentError && doseAdjustmentError.message}
              </Typography>
            )}
          </When>
        </Paper>
        <When condition={loading}>
          <LinearProgress className={classes.loadingBar} />
        </When>
        <Grid container justify="flex-end">
          <Grid item xs={6} container justify="flex-end" spacing={3}>
            <HcpFragment>
              <Grid item>
                <Button disabled={!dirty || loading || readOnly} type="reset">
                  {t("actions.cancel")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={!dirty || loading || readOnly}
                  type="submit"
                  {...saveButtonAppearance}
                >
                  {t("actions.save")}
                </Button>
              </Grid>
            </HcpFragment>
            <Grid item>
              <OkButton disabled={dirty} />
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default useStyles(
  withFormik<FormConfig, Values>({
    mapPropsToValues,
    handleSubmit,
    validationSchema: (
      props: FormConfig
    ): typeof DosingStrategySchema | typeof EmptySchema =>
      !props.readOnly ? DosingStrategySchema : EmptySchema,
    validate: (values, props) =>
      !props.readOnly ? validateForm(values) : noop(),
    enableReinitialize: true
  })(withTranslation()(DosingForm))
);
