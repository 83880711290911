// @flow
import {
  PATIENT_CREATE_REQUEST,
  PATIENT_UPDATE_REQUEST,
  PATIENT_DELETE_REQUEST,
  PATIENT_CREATE_SUCCESS,
  PATIENT_UPDATE_SUCCESS,
  PATIENT_DELETE_SUCCESS,
  PATIENT_CREATE_ERROR,
  PATIENT_UPDATE_ERROR,
  PATIENT_DELETE_ERROR,
  PATIENT_DELETE_CONFIRM_DIALOG,
  PATIENT_GET_REQUEST,
  PATIENTS_LIST_REQUEST,
  PATIENTS_LIST_MORE_REQUEST,
  PATIENT_GET_SUCCESS,
  PATIENTS_LIST_SUCCESS,
  PATIENTS_LIST_MORE_SUCCESS,
  PATIENT_GET_ERROR,
  PATIENTS_LIST_ERROR,
  PATIENTS_LIST_MORE_ERROR,
  CLEAR_PATIENT_FORM_ERRORS,
  PATIENT_REMOVE_DEVICE_REQUEST,
  PATIENT_REMOVE_DEVICE_SUCCESS,
  PATIENT_REMOVE_DEVICE_ERROR,
  REMOVE_DEVICE_CONFIRM_DIALOG,
  PATIENT_FETCH_ANSWERS_REQUEST,
  PATIENT_FETCH_ANSWERS_SUCCESS,
  PATIENT_FETCH_ANSWERS_ERROR,
  PATIENT_FETCH_ACTIVITIES_REQUEST,
  PATIENT_FETCH_ACTIVITIES_SUCCESS,
  PATIENT_FETCH_ACTIVITIES_ERROR,
  DOWNLOAD_SUBJECT_DATA_DIALOG,
  PATIENT_FETCH_BOLUS_INSULIN_REQUEST,
  PATIENT_FETCH_BOLUS_INSULIN_SUCCESS,
  PATIENT_FETCH_BOLUS_INSULIN_ERROR,
  PATIENT_FETCH_BASAL_INSULIN_REQUEST,
  PATIENT_FETCH_BASAL_INSULIN_SUCCESS,
  PATIENT_FETCH_BASAL_INSULIN_ERROR,
  PATIENT_FETCH_GLUCOSE_REQUEST,
  PATIENT_FETCH_GLUCOSE_SUCCESS,
  PATIENT_FETCH_GLUCOSE_ERROR,
  PATIENT_FETCH_HEART_RATE_REQUEST,
  PATIENT_FETCH_HEART_RATE_SUCCESS,
  PATIENT_FETCH_HEART_RATE_ERROR,
  PATIENT_FETCH_STEP_COUNTS_REQUEST,
  PATIENT_FETCH_STEP_COUNTS_SUCCESS,
  PATIENT_FETCH_STEP_COUNTS_ERROR
} from "../constants/actionTypes";

import type {
  CreatePatientPayload,
  UpdatePatientPayload,
  PatientsPaginationPayload,
  PatientsPaginatedResponse,
  PatientCreateRequestAction,
  PatientUpdateRequestAction,
  PatientDeleteRequestAction,
  PatientCreateSuccessAction,
  PatientUpdateSuccessAction,
  PatientDeleteSuccessAction,
  PatientCreateErrorAction,
  PatientUpdateErrorAction,
  PatientDeleteErrorAction,
  PatientDeleteConfirmDialogAction,
  PatientGetRequestAction,
  PatientsListRequestAction,
  PatientsListMoreRequestAction,
  PatientGetSuccessAction,
  PatientsListSuccessAction,
  PatientsListMoreSuccessAction,
  PatientGetErrorAction,
  PatientsListErrorAction,
  PatientsListMoreErrorAction,
  ClearPatientFormErrorsAction,
  RemoveDeviceRequestAction,
  RemoveDeviceSuccessAction,
  RemoveDeviceErrorAction,
  RemoveDeviceConfirmDialogAction,
  PatientFetchAnswersRequestAction,
  PatientFetchAnswersSuccessAction,
  PatientFetchAnswersErrorAction,
  PatientFetchActivitiesRequestAction,
  PatientFetchActivitiesSuccessAction,
  PatientFetchActivitiesErrorAction,
  PatientAnswersResponse,
  PatientActiviesResponse,
  DownloadSubjectDataDialogAction,
  PatientFetchBolusInsulinRequestAction,
  PatientFetchBolusInsulinSuccessAction,
  PatientInsulinResponse,
  PatientFetchBolusInsulinErrorAction,
  PatientFetchBasalInsulinRequestAction,
  PatientFetchBasalInsulinErrorAction,
  PatientFetchBasalInsulinSuccessAction,
  PatientFetchGlucoseRequestAction,
  PatientGlucoseResponse,
  PatientFetchGlucoseSuccessAction,
  PatientFetchGlucoseErrorAction,
  PatientFetchHeartRateRequestAction,
  PatientHeartRateResponse,
  PatientFetchHeartRateSuccessAction,
  PatientFetchHeartRateErrorAction,
  PatientFetchStepCountsRequestAction,
  PatientStepCountsResponse,
  PatientFetchStepCountsSuccessAction, PatientFetchStepCountsErrorAction
} from "./types/patientsActions";
import type { PatientEntity } from "../reducers/types";
import type { APIErrors } from "../utils/errors";

export const createPatient = (
  payload: CreatePatientPayload
): PatientCreateRequestAction => ({
  type: PATIENT_CREATE_REQUEST,
  payload
});
export const updatePatient = (
  payload: UpdatePatientPayload
): PatientUpdateRequestAction => ({
  type: PATIENT_UPDATE_REQUEST,
  payload
});
export const deletePatient = (
  patientId: string,
  confirmed: boolean = false
): PatientDeleteRequestAction => ({
  type: PATIENT_DELETE_REQUEST,
  payload: {
    patientId,
    confirmed
  }
});
export const createPatientSuccess = (
  payload: PatientEntity
): PatientCreateSuccessAction => ({
  type: PATIENT_CREATE_SUCCESS,
  payload
});
export const updatePatientSuccess = (
  payload: PatientEntity
): PatientUpdateSuccessAction => ({
  type: PATIENT_UPDATE_SUCCESS,
  payload
});
export const deletePatientSuccess = (
  patientId: string
): PatientDeleteSuccessAction => ({
  type: PATIENT_DELETE_SUCCESS,
  payload: { patientId }
});
export const createPatientError = (error: *): PatientCreateErrorAction => ({
  type: PATIENT_CREATE_ERROR,
  payload: { error }
});
export const updatePatientError = (error: *): PatientUpdateErrorAction => ({
  type: PATIENT_UPDATE_ERROR,
  payload: { error }
});
export const deletePatientError = (
  error: *,
  patientId: string
): PatientDeleteErrorAction => ({
  type: PATIENT_DELETE_ERROR,
  payload: { error, patientId }
});

export const openDeletePatientDialog = (
  patientId: string
): PatientDeleteConfirmDialogAction => ({
  type: PATIENT_DELETE_CONFIRM_DIALOG,
  payload: {
    show: true,
    patientId
  }
});
export const closeDeletePatientDialog = (
  patientId: string
): PatientDeleteConfirmDialogAction => ({
  type: PATIENT_DELETE_CONFIRM_DIALOG,
  payload: {
    show: false,
    patientId
  }
});

export const getPatient = (patientId: string): PatientGetRequestAction => ({
  type: PATIENT_GET_REQUEST,
  payload: { patientId }
});
export const getPatientsList = (
  payload: PatientsPaginationPayload
): PatientsListRequestAction => ({
  type: PATIENTS_LIST_REQUEST,
  payload
});
export const getMorePatientsList = (
  payload: PatientsPaginationPayload
): PatientsListMoreRequestAction => ({
  type: PATIENTS_LIST_MORE_REQUEST,
  payload
});
export const getPatientSuccess = (
  payload: PatientEntity
): PatientGetSuccessAction => ({
  type: PATIENT_GET_SUCCESS,
  payload
});
export const getPatientsListSuccess = (
  payload: PatientsPaginatedResponse
): PatientsListSuccessAction => ({
  type: PATIENTS_LIST_SUCCESS,
  payload
});
export const getMorePatientsListSuccess = (
  payload: PatientsPaginatedResponse
): PatientsListMoreSuccessAction => ({
  type: PATIENTS_LIST_MORE_SUCCESS,
  payload
});
export const getPatientError = (
  error: APIErrors<*>
): PatientGetErrorAction => ({
  type: PATIENT_GET_ERROR,
  payload: { error }
});
export const getPatientsListError = (
  error: APIErrors<*>
): PatientsListErrorAction => ({
  type: PATIENTS_LIST_ERROR,
  payload: { error }
});
export const getMorePatientsListError = (
  error: APIErrors<*>
): PatientsListMoreErrorAction => ({
  type: PATIENTS_LIST_MORE_ERROR,
  payload: { error }
});

export const clearPatientFormErrors = (): ClearPatientFormErrorsAction => ({
  type: CLEAR_PATIENT_FORM_ERRORS
});

export const removeDeviceId = (
  subjectNumber: string,
  confirmed: boolean = false
): RemoveDeviceRequestAction => ({
  type: PATIENT_REMOVE_DEVICE_REQUEST,
  payload: { subjectNumber, confirmed }
});
export const removeDeviceIdSuccess = (): RemoveDeviceSuccessAction => ({
  type: PATIENT_REMOVE_DEVICE_SUCCESS
});
export const removeDeviceIdError = (
  error: APIErrors<*>
): RemoveDeviceErrorAction => ({
  type: PATIENT_REMOVE_DEVICE_ERROR,
  payload: { error }
});

export const openForgetDeviceDialog = (
  subjectNumber: string
): RemoveDeviceConfirmDialogAction => ({
  type: REMOVE_DEVICE_CONFIRM_DIALOG,
  payload: {
    show: true,
    subjectNumber
  }
});
export const closeForgetDeviceDialog = (
  subjectNumber: string
): RemoveDeviceConfirmDialogAction => ({
  type: REMOVE_DEVICE_CONFIRM_DIALOG,
  payload: {
    show: false,
    subjectNumber
  }
});

export const fetchPatientAnswers = (
  subjectNumber: string
): PatientFetchAnswersRequestAction => ({
  type: PATIENT_FETCH_ANSWERS_REQUEST,
  payload: { subjectNumber }
});
export const fetchPatientAnswersSuccess = (
  payload: PatientAnswersResponse
): PatientFetchAnswersSuccessAction => ({
  type: PATIENT_FETCH_ANSWERS_SUCCESS,
  payload
});
export const fetchPatientAnswersError = (
  error: APIErrors<*>
): PatientFetchAnswersErrorAction => ({
  type: PATIENT_FETCH_ANSWERS_ERROR,
  payload: { error }
});
export const fetchPatientActivities = (
  subjectNumber: string
): PatientFetchActivitiesRequestAction => ({
  type: PATIENT_FETCH_ACTIVITIES_REQUEST,
  payload: { subjectNumber }
});
export const fetchPatientActivitiesSuccess = (
  payload: PatientActiviesResponse
): PatientFetchActivitiesSuccessAction => ({
  type: PATIENT_FETCH_ACTIVITIES_SUCCESS,
  payload
});
export const fetchPatientActivitiesError = (
  error: APIErrors<*>
): PatientFetchActivitiesErrorAction => ({
  type: PATIENT_FETCH_ACTIVITIES_ERROR,
  payload: { error }
});
export const fetchPatientBolusInsulin = (
  subjectNumber: string
): PatientFetchBolusInsulinRequestAction => ({
  type: PATIENT_FETCH_BOLUS_INSULIN_REQUEST,
  payload: { subjectNumber }
});
export const fetchPatientBolusInsulinSuccess = (
  payload: PatientInsulinResponse
): PatientFetchBolusInsulinSuccessAction => ({
  type: PATIENT_FETCH_BOLUS_INSULIN_SUCCESS,
  payload
});
export const fetchPatientBolusInsulinError = (
  error: APIErrors<*>
): PatientFetchBolusInsulinErrorAction => ({
  type: PATIENT_FETCH_BOLUS_INSULIN_ERROR,
  payload: { error }
});
export const fetchPatientBasalInsulin = (
  subjectNumber: string
): PatientFetchBasalInsulinRequestAction => ({
  type: PATIENT_FETCH_BASAL_INSULIN_REQUEST,
  payload: { subjectNumber }
});
export const fetchPatientBasalInsulinSuccess = (
  payload: PatientInsulinResponse
): PatientFetchBasalInsulinSuccessAction => ({
  type: PATIENT_FETCH_BASAL_INSULIN_SUCCESS,
  payload
});
export const fetchPatientBasalInsulinError = (
  error: APIErrors<*>
): PatientFetchBasalInsulinErrorAction => ({
  type: PATIENT_FETCH_BASAL_INSULIN_ERROR,
  payload: { error }
});
export const fetchPatientGlucose = (
  subjectNumber: string
): PatientFetchGlucoseRequestAction => ({
  type: PATIENT_FETCH_GLUCOSE_REQUEST,
  payload: { subjectNumber }
});
export const fetchPatientGlucoseSuccess = (
  payload: PatientGlucoseResponse
): PatientFetchGlucoseSuccessAction => ({
  type: PATIENT_FETCH_GLUCOSE_SUCCESS,
  payload
});
export const fetchPatientGlucoseError = (
  error: APIErrors<*>
): PatientFetchGlucoseErrorAction => ({
  type: PATIENT_FETCH_GLUCOSE_ERROR,
  payload: { error }
});
export const fetchPatientHeartRate = (
  subjectNumber: string
): PatientFetchHeartRateRequestAction => ({
  type: PATIENT_FETCH_HEART_RATE_REQUEST,
  payload: { subjectNumber }
});
export const fetchPatientHeartRateSuccess = (
  payload: PatientHeartRateResponse
): PatientFetchHeartRateSuccessAction => ({
  type: PATIENT_FETCH_HEART_RATE_SUCCESS,
  payload
});
export const fetchPatientHeartRateError = (
  error: APIErrors<*>
): PatientFetchHeartRateErrorAction => ({
  type: PATIENT_FETCH_HEART_RATE_ERROR,
  payload: { error }
});
export const fetchPatientStepCounts = (
  subjectNumber: string
): PatientFetchStepCountsRequestAction => ({
  type: PATIENT_FETCH_STEP_COUNTS_REQUEST,
  payload: { subjectNumber }
});
export const fetchPatientStepCountsSuccess = (
  payload: PatientStepCountsResponse
): PatientFetchStepCountsSuccessAction => ({
  type: PATIENT_FETCH_STEP_COUNTS_SUCCESS,
  payload
});
export const fetchPatientStepCountsError = (
  error: APIErrors<*>
): PatientFetchStepCountsErrorAction => ({
  type: PATIENT_FETCH_STEP_COUNTS_ERROR,
  payload: { error }
});
export const showDownloadDataDialog = (
  subjectNumber: string
): DownloadSubjectDataDialogAction => ({
  type: DOWNLOAD_SUBJECT_DATA_DIALOG,
  payload: {
    show: true,
    subjectNumber
  }
});
export const closeDownloadDataDialog = (): DownloadSubjectDataDialogAction => ({
  type: DOWNLOAD_SUBJECT_DATA_DIALOG,
  payload: {
    show: false,
    subjectNumber: ""
  }
});
