// @flow
import React, { useState } from "react";
import { pdfjs, Document, Page } from 'react-pdf';
import mobileLegalNotices from "../assets/legal/mobile-legal-notices.pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function LegacyNoticePage() {
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1);
  
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  
  function getScale(page) {
    const parentDiv = document && document.querySelector('#pdfDocument')
    if (parentDiv) {
      let pageScale = parentDiv.clientWidth / page.originalWidth
      if (scale !== pageScale) {
        setScale(pageScale)
      }
    }
  }
  
  console.log(scale);
  
  return (
    <div id="pdfDocument">
      <Document
        file={mobileLegalNotices}
        onLoadSuccess={onDocumentLoadSuccess}
        renderMode="svg"
      >
        {Array.apply(null, Array(numPages))
          .map((x, i)=> i + 1)
          .map((page, index) => <Page scale={scale} onLoadSuccess={getScale} key={index} pageNumber={page}/>)}
      </Document>
    </div>
  );
}
