// @flow
import * as React from "react";
import debounce from "lodash/debounce";
import Grid from "@material-ui/core/Grid";
import PanePaper from "./PanePaper";
import MealTimeFilter from "./MealTimeFilter";
import MealSizeFilter from "./MealSizeFilter";
import TimeRangeFilter from "./TimeRangeFilter";
import OtherNotesFilter from "./OtherNotesFilter";

import type { MealTime, MealSize } from "../../../constants/meal";
import type { OtherNotesEnum } from "../../../constants/otherNotes";
import type { MealEventsFiltersPayload } from "../../../actions/types/mealAnalysisActions";

type FiltersState = $Shape<MealEventsFiltersPayload>;

type Props = {
  initialFilters: FiltersState,
  onChange: (filters: FiltersState) => any
};

class MealAnalysisFilters extends React.Component<Props, FiltersState> {
  constructor(props: Props) {
    super(props);
    this.state = { ...props.initialFilters };
  }

  componentWillUnmount() {
    this.handleFilterChange.cancel();
  }

  handleMealTimeChange = (value: MealTime | null) => {
    this.setState({ mealTime: value }, this.handleFilterChange);
  };
  handleMealSizeChange = (value: MealSize | null) => {
    this.setState({ mealSize: value }, this.handleFilterChange);
  };
  handleTimeRangeChange = (value: number | null) => {
    this.setState({ timeRange: value }, this.handleFilterChange);
  };
  handleOtherNotesChange = (value: OtherNotesEnum[]) => {
    this.setState({ otherNotesType: value }, this.handleFilterChange);
  };

  handleFilterChange = debounce(() => {
    const { mealTime, mealSize, timeRange, otherNotesType } = this.state;
    const { onChange } = this.props;

    onChange({
      mealTime,
      mealSize,
      timeRange,
      otherNotesType
    });
  }, 250);

  render() {
    const { mealTime, mealSize, timeRange, otherNotesType } = this.state;

    return (
      <PanePaper>
        <Grid container spacing={2}>
          <Grid item md={3} xs={6}>
            <MealTimeFilter
              value={mealTime}
              onChange={this.handleMealTimeChange}
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <MealSizeFilter
              value={mealSize}
              onChange={this.handleMealSizeChange}
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <TimeRangeFilter
              value={timeRange}
              onChange={this.handleTimeRangeChange}
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <OtherNotesFilter
              value={otherNotesType}
              onChange={this.handleOtherNotesChange}
            />
          </Grid>
        </Grid>
      </PanePaper>
    );
  }
}

export default MealAnalysisFilters;
