// @flow
// Types
export const FOOD = 0;
export const DRINK = 1;

// Categories
export const BREAKFAST = 0;
export const LUNCH = 1;
export const DINNER = 2;
export const SNACK = 3;

// Sizes
export const LESS = 0;
export const NORMAL = 1;
export const LARGE = 2;

// Meal Times
export const MORNING = 0;
export const MID_DAY = 1;
export const EVENING = 2;
export const NIGHT = 3;

export type MealCategory = typeof FOOD | typeof DRINK;
export type MealType =
  | typeof BREAKFAST
  | typeof LUNCH
  | typeof DINNER
  | typeof SNACK;
export type MealSize = typeof LESS | typeof NORMAL | typeof LARGE;
export type MealTime =
  | typeof MORNING
  | typeof MID_DAY
  | typeof EVENING
  | typeof NIGHT;

export const mealCategories = new Map<MealCategory, string>([
  [FOOD, "mealCategories.meal"],
  [DRINK, "mealCategories.beverage"]
]);
export const mealTypes = new Map<MealType, string>([
  [BREAKFAST, "mealTypes.breakfast"],
  [LUNCH, "mealTypes.lunch"],
  [DINNER, "mealTypes.dinner"],
  [SNACK, "mealTypes.snack"]
]);
export const mealSizes = new Map<MealSize, string>([
  [LESS, "mealSizes.less"],
  [NORMAL, "mealSizes.normal"],
  [LARGE, "mealSizes.large"]
]);
export const mealTimes = new Map<
  MealTime,
  {
    name: string,
    from: string,
    till: string,
    comparator: (hours: number) => boolean
  }
>([
  [
    MORNING,
    {
      name: "Morning",
      from: "4:00 AM",
      till: "9:59 AM",
      comparator: (hours: number) => hours >= 4 && hours < 10
    }
  ],
  [
    MID_DAY,
    {
      name: "Mid-day",
      from: "10:00 AM",
      till: "3:59 PM",
      comparator: (hours: number) => hours >= 10 && hours < 16
    }
  ],
  [
    EVENING,
    {
      name: "Evening",
      from: "4:00 PM",
      till: "9:59 PM",
      comparator: (hours: number) => hours >= 16 && hours < 22
    }
  ],
  [
    NIGHT,
    {
      name: "Night",
      from: "10:00 PM",
      till: "3:59 AM",
      comparator: (hours: number) => (hours >= 22 && hours < 24) || (hours >= 0 && hours < 4)
    }
  ]
]);
