import * as React from "react";
import { useSelector } from "react-redux";
import { formatDate } from "../../../utils/time";
import fromUnixTime from "date-fns/fromUnixTime";
import Typography from "@material-ui/core/Typography";
import HintBox from "../../../components/Hint";

import type {
  State,
  GlucoseReadingWithMealEvent
} from "../../../reducers/types";
import { useTranslation } from "react-i18next";

export default function GlucoseHint(props: {
  reading: GlucoseReadingWithMealEvent
}) {
  const { reading } = props;
  const { t } = useTranslation();
  const mealEvent = useSelector(({ mealAnalysis }: State) => {
    const { mealEvents } = mealAnalysis;
    return mealEvents[reading.mealEventId || ""];
  });
  return (
    <HintBox>
      <Typography variant="inherit" display="block">
        {t("pages.mealAnalysis.glucose")}: {reading.value} mg/dL
      </Typography>
      <Typography variant="inherit" display="block">
        {formatDate(fromUnixTime(reading.time), "p P")}
      </Typography>
      {mealEvent && (
        <Typography variant="inherit" display="block">
          {mealEvent.mealOption.name}
        </Typography>
      )}
    </HintBox>
  );
}
