// @flow
import PatientListActions from "./PatientListActions";

import type { VirtualizedColumnProps as ColumnProps } from "../../components/Table/types";

export default ([
  {
    dataKey: "subjectNumber",
    sortable: true,
    label: "fields.common.subjectNumber",
    dataQa: "subjectNumberSorting",
    align: "left",
    width: 100,
    flexGrow: 1,
    cellDataGetter: ({ dataKey, rowData }) => `#${rowData[dataKey]}`
  },
  {
    dataKey: "avgBolusDose",
    sortable: true,
    label: "pages.patientList.avgBolusDose",
    dataQa: "avBolusSorting",
    width: 50,
    flexGrow: 1
  },
  {
    dataKey: "avgBasalDose",
    sortable: true,
    label: "pages.patientList.percentBasalDosesTaken",
    dataQa: "avBasalSorting",
    width: 50,
    flexGrow: 1,
    cellDataGetter: ({ dataKey, rowData }) => `${rowData[dataKey] === -1 ? 'N/A' : rowData[dataKey]}`
  },
  {
    dataKey: "questionsAnswered",
    sortable: true,
    label: "pages.patientList.questionsAnswered",
    align: "left",
    width: 50,
    flexGrow: 1
  },
  {
    dataKey: "daysWith75QuestionsAnswered",
    sortable: true,
    label: "pages.patientList.daysWith75QuestionsAnswered",
    align: "left",
    width: 50,
    flexGrow: 1
  },
  {
    dataKey: "glucoseCapture",
    sortable: true,
    label: "pages.patientList.glucoseCapture",
    dataQa: "glucoseCaptSorting",
    align: "left",
    width: 50,
    flexGrow: 1
  },
  {
    dataKey: "doseChangedTimes",
    sortable: true,
    label: "pages.patientList.doseChangedTimes",
    dataQa: "hcpChangedSorting",
    align: "left",
    width: 50,
    flexGrow: 1
  },
  {
    dataKey: "daysInStudy",
    sortable: true,
    label: "pages.patientList.firstGlucoseReadingRecordDate",
    dataQa: "numberDaysSorting",
    align: "left",
    width: 50,
    flexGrow: 1
  },
  {
    dataKey: "patientActions",
    width: 50,
    flexGrow: 1,
    cellRenderer: PatientListActions
  }
]: ColumnProps[]);
