// @flow
import * as React from "react";
import { connect } from "formik";

import type { FormikProps } from "formik";

type OwnProps = {
  ...React.ElementConfig<React.ElementType>,
  blurOnSubmit?: boolean
};

type Props = {
  ...OwnProps,
  formik: FormikProps<*>
};

function Form(props: Props) {
  const {
    formik: { handleReset, handleSubmit },
    blurOnSubmit,
    ...restProps
  } = props;

  function handleFormSubmit(evt: SyntheticEvent<HTMLFormElement>) {
    const { currentTarget } = evt;

    if (blurOnSubmit) {
      const focused = currentTarget.querySelector("*:focus");

      if (focused) {
        focused.blur();
      }

      currentTarget.blur();
    }

    handleSubmit(evt);
  }

  return (
    <form onSubmit={handleFormSubmit} onReset={handleReset} {...restProps} />
  );
}

export default connect<OwnProps, _>(Form);
