// @flow
import without from "lodash/without";
import {
  MEAL_OPTION_CREATE_REQUEST,
  MEAL_OPTION_UPDATE_REQUEST,
  MEAL_OPTION_DELETE_REQUEST,
  MEAL_OPTION_CREATE_SUCCESS,
  MEAL_OPTION_UPDATE_SUCCESS,
  MEAL_OPTION_DELETE_SUCCESS,
  MEAL_OPTION_CREATE_ERROR,
  MEAL_OPTION_UPDATE_ERROR,
  MEAL_OPTION_DELETE_ERROR,
  MEAL_OPTION_DELETE_CONFIRM_DIALOG,
  MEAL_OPTION_GET_REQUEST,
  MEAL_OPTIONS_LIST_REQUEST,
  MEAL_OPTIONS_LIST_MORE_REQUEST,
  MEAL_OPTION_GET_SUCCESS,
  MEAL_OPTIONS_LIST_SUCCESS,
  MEAL_OPTIONS_LIST_MORE_SUCCESS,
  MEAL_OPTION_GET_ERROR,
  MEAL_OPTIONS_LIST_ERROR,
  MEAL_OPTIONS_LIST_MORE_ERROR,
  CLEAR_MEAL_OPTION_FORM_ERRORS,
  LOGOUT_DONE
} from "../constants/actionTypes";
import { ASC } from "../constants/sorting";
import type { Action } from "../actions/types";
import type { MealOptionsState } from "./types";

const initialState: MealOptionsState = {
  total: 0,
  idsList: [],
  entities: {},
  sortDirection: ASC,
  sortBy: "name",
  mealTypeFilter: null,
  mealCategoryFilter: null,
  listLoading: false,
  moreRequested: false,
  loading: false,
  listError: null,
  createError: null,
  updateError: null,
  deleteError: null,
  getMealError: null,
  creating: null,
  updating: null,
  deleting: [],
  confirmDeleteFor: "",
  showDeleteConfirm: false
};

export default function mealOptionsReducer(
  state: MealOptionsState = initialState,
  action: Action
): MealOptionsState {
  switch (action.type) {
    case MEAL_OPTION_CREATE_REQUEST: {
      const {
        patientId,
        name,
        mealCategory,
        mealType,
        notes,
        carbohydrates,
        highProtein,
        highFat
      } = action.payload;

      return {
        ...state,
        createError: null,
        creating: {
          id: "",
          patientId,
          name,
          mealCategory,
          mealType,
          notes,
          carbohydrates,
          highProtein,
          highFat,
          lastTime: "",
          count: 0
        }
      };
    }
    case MEAL_OPTION_CREATE_SUCCESS: {
      const { id } = action.payload;

      return {
        ...state,
        creating: null,
        total: state.total + 1,
        entities: {
          [id]: {
            ...action.payload
          }
        }
      };
    }
    case MEAL_OPTION_CREATE_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        creating: null,
        createError: error
      };
    }
    case MEAL_OPTION_UPDATE_REQUEST: {
      const {
        id,
        name,
        mealCategory,
        mealType,
        notes,
        carbohydrates,
        highProtein,
        highFat
      } = action.payload;
      const entity = state.entities[id];

      return {
        ...state,
        updateError: null,
        updating: {
          ...entity,
          name,
          mealCategory,
          mealType,
          notes,
          carbohydrates,
          highProtein,
          highFat
        }
      };
    }
    case MEAL_OPTION_UPDATE_SUCCESS: {
      const { entities } = state;
      const { id } = action.payload;
      const entity = entities[id];

      return {
        ...state,
        updating: null,
        entities: {
          ...entities,
          [id]: {
            ...entity,
            ...action.payload
          }
        }
      };
    }
    case MEAL_OPTION_UPDATE_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        updating: null,
        updateError: error
      };
    }
    case CLEAR_MEAL_OPTION_FORM_ERRORS: {
      const { createError, updateError } = initialState;

      return { ...state, createError, updateError };
    }
    case MEAL_OPTION_DELETE_REQUEST: {
      const { deleting } = state;
      const { mealOptionId } = action.payload;

      return {
        ...state,
        deleteError: null,
        deleting: deleting.includes(mealOptionId)
          ? deleting
          : [...deleting, mealOptionId]
      };
    }
    case MEAL_OPTION_DELETE_SUCCESS: {
      const { deleting, idsList, total } = state;
      const { mealOptionId } = action.payload;
      const entities = {
        ...state.entities
      };

      delete entities[mealOptionId];

      return {
        ...state,
        total: Math.max(total - 1, 0),
        entities,
        idsList: without(idsList, mealOptionId),
        deleting: without(deleting, mealOptionId)
      };
    }
    case MEAL_OPTION_DELETE_ERROR: {
      const { deleting } = state;
      const { error, mealOptionId } = action.payload;

      return {
        ...state,
        deleting: without(deleting, mealOptionId),
        deleteError: error
      };
    }
    case MEAL_OPTION_DELETE_CONFIRM_DIALOG: {
      const { deleting } = state;
      const { show, mealOptionId } = action.payload;

      return {
        ...state,
        deleting: without(deleting, mealOptionId),
        confirmDeleteFor: mealOptionId,
        showDeleteConfirm: show
      };
    }
    case MEAL_OPTION_GET_REQUEST: {
      return {
        ...state,
        getMealError: null,
        loading: true
      };
    }
    case MEAL_OPTION_GET_SUCCESS: {
      const { entities } = state;
      const entity = action.payload;

      return {
        ...state,
        loading: false,
        entities: {
          ...entities,
          [entity.id]: entity
        }
      };
    }
    case MEAL_OPTION_GET_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        getMealError: error
      };
    }
    case MEAL_OPTIONS_LIST_REQUEST: {
      let {
        sortBy,
        sortDirection,
        mealTypeFilter,
        mealCategoryFilter
      } = action.payload;

      sortBy = sortBy ? sortBy : state.sortBy;
      sortDirection = sortDirection
        ? sortDirection.toLowerCase()
        : state.sortDirection;

      return {
        ...state,
        sortBy,
        sortDirection,
        mealTypeFilter,
        mealCategoryFilter,
        total: 0,
        idsList: [],
        entities: {},
        listLoading: true
      };
    }
    case MEAL_OPTIONS_LIST_SUCCESS: {
      const { entities, result } = action.payload;
      const {
        items,
        total,
        sortBy,
        sortDirection,
        mealTypeFilter,
        mealCategoryFilter
      } = result;
      const idsList = [...items, ...Array(total).slice(items.length)];

      return {
        ...state,
        total,
        idsList,
        entities: {
          ...state.entities,
          ...entities.mealOptions
        },
        sortBy: sortBy ? sortBy : state.sortBy,
        sortDirection: sortDirection
          ? sortDirection.toLowerCase()
          : state.sortDirection,
        mealTypeFilter,
        mealCategoryFilter,
        listLoading: false
      };
    }
    case MEAL_OPTIONS_LIST_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        listLoading: false,
        listError: error
      };
    }
    case MEAL_OPTIONS_LIST_MORE_REQUEST: {
      return {
        ...state,
        moreRequested: true
      };
    }
    case MEAL_OPTIONS_LIST_MORE_SUCCESS: {
      const { result, entities } = action.payload;
      const { items, total, skip, take } = result;
      const idsList = [
        ...state.idsList.slice(0, skip),
        ...items,
        ...state.idsList.slice(skip + take)
      ];

      return {
        ...state,
        idsList,
        entities: {
          ...state.entities,
          ...entities.mealOptions
        },
        total,
        moreRequested: false
      };
    }
    case MEAL_OPTIONS_LIST_MORE_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        moreRequested: false,
        listError: error
      };
    }
    case LOGOUT_DONE:
      return initialState;
    default:
      return state;
  }
}
