// @flow
import * as React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default withStyles(theme => ({
  root: {
    backgroundColor: fade(theme.palette.grey[700], 0.9),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    padding: "4px 8px",
    fontSize: theme.typography.pxToRem(10),
    lineHeight: `1.4em`,
    maxWidth: 300,
    wordWrap: "break-word",
    fontWeight: theme.typography.fontWeightMedium
  }
}))(({ classes, ...props }) => <div className={classes.root} {...props} />);
