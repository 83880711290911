// @flow
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import NegativeButton from "../../components/NegativeButton";

import {
  removeDeviceId,
  closeForgetDeviceDialog
} from "../../actions/patientsActions";

import type { State } from "../../reducers/types";
import { useTranslation } from "react-i18next";

const { useCallback } = React;

export default function ForgetDeviceConfirmModal(props: *) {
  const { t } = useTranslation();
  const open = useSelector(
    ({ patients }: State) => patients.showForgetDeviceConfirm
  );
  const subjectNumber = useSelector(
    ({ patients }: State) => patients.confirmForgetDeviceFor
  );
  const dispatch = useDispatch();
  const closeModal = useCallback(() => {
    dispatch(closeForgetDeviceDialog(subjectNumber));
  }, [dispatch, subjectNumber]);
  const confirmForgetDevice = useCallback(() => {
    closeModal();
    dispatch(removeDeviceId(subjectNumber, true));
  }, [dispatch, closeModal, subjectNumber]);

  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle>
        {t("pages.forgetDevice.title")} {subjectNumber ? `#${subjectNumber}` : ""}
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="body2" color="textSecondary" component="p">
          {t("pages.forgetDevice.confirmation")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} data-qa="cancelButton">{t("actions.cancel")}</Button>
        <NegativeButton color="primary" onClick={confirmForgetDevice} data-qa="confirmButton">
          {t("actions.confirm")}
        </NegativeButton>
      </DialogActions>
    </Dialog>
  );
}
