// @flow
import find from "lodash/find";
import sortBy from "lodash/sortBy";
import isEqual from "lodash/isEqual";

import type { State } from "../reducers/types";

export const selectGlucoseChartState = ({ mealAnalysis }: State) => {
  const {
    mealEvents,
    mealOptionId,
    traceColors,
    tracesDates,
    selectedTraceDate
  } = mealAnalysis;
  const orderedDates = sortBy<string>(
    tracesDates,
    (dateStr: string) => dateStr === selectedTraceDate
  );

  return {
    mealOptionId,
    mealEvents,
    orderedDates,
    traceColors,
    selectedTraceDate
  };
};

type GlucoseChartState = $Call<typeof selectGlucoseChartState, State>;

export const isGlucoseChartStateEqual = (
  state: GlucoseChartState,
  prevState: GlucoseChartState
): boolean =>
  state.mealOptionId === prevState.mealOptionId &&
  state.selectedTraceDate === prevState.selectedTraceDate &&
  isEqual(state.orderedDates, prevState.orderedDates);

export const selectDataLoadState = ({ mealAnalysis }: State) => ({
  loading: mealAnalysis.loading,
  error: mealAnalysis.error
});

export const getSelectedGlucoseReading = ({ mealAnalysis }: State) => {
  const { selectedTraceDate, selectedMealEvent, glucoseSeries } = mealAnalysis;

  return find(glucoseSeries[selectedTraceDate], glucoseReading => {
    return glucoseReading.mealEventId === selectedMealEvent;
  });
};

export const getHeartRateData = ({ mealAnalysis }: State) => {
  const { heartRateSeries, selectedTraceDate } = mealAnalysis;
  return heartRateSeries[selectedTraceDate] || [];
};

export const getStepsData = ({ mealAnalysis }: State) => {
  const { selectedTraceDate, stepsSeries } = mealAnalysis;
  return stepsSeries[selectedTraceDate] || [];
};
