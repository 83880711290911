// @flow
import { combineReducers } from "redux";
import user from "./userReducer";
import admin from "./adminReducer";
import patients from "./patientsReducer";
import hcp from "./hcpReducer";
import mealOptions from "./mealOptionsReducer";
import mealAnalysis from "./mealAnalysisReducer";
import notifications from "./notificationsReducer";
import dosingStrategy from "./dosingStrategyReducer";
import glucoseUpload from "./glucoseUploadReducer";

import type { Action } from "../actions/types";
import type { State } from "./types";

const reducers: { [reducer: $Keys<State>]: Function } = {
  user,
  admin,
  patients,
  hcp,
  mealOptions,
  mealAnalysis,
  notifications,
  dosingStrategy,
  glucoseUpload
};

const rootReducer = combineReducers<typeof reducers, Action>(reducers);

export default rootReducer;
