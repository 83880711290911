// @flow
import * as React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import makeStyles from "@material-ui/core/styles/makeStyles";
import i18n from "i18next";
import { useDispatch } from "react-redux";
import { setProfileLanguage } from "../actions/userActions";

type Props = {
  className?: string
};

const useStyles: Function = makeStyles(theme => ({
  languageSelect: {
    color: theme.palette.primary.contrastText,
    margin: theme.spacing(0, 1),
    minWidth: 128,
    fontSize: "14px",
    lineHeight: 1.71,
    '& fieldset': {
      borderColor: theme.palette.primary.contrastText,
    },
  },
  languageMenuItem: {
    fontSize: "14px"
  },
  languageSelectIcon: {
    fill: theme.palette.primary.contrastText
  },
  selectInputRoot: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

const { useCallback } = React;
const availableLanguages = {
  'en-US': 'English (US)',
  'en-GB': 'English (GB)',
  'de': 'Deutsch',
  'fr': 'Français'
}

function getCurrentLanguage () {
  if (!Object.keys(availableLanguages).includes(i18n.language)) {
    return 'en-US';
  }
  
  return i18n.language;
}

export default function ChangeLanguageButton(props: Props) {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const onChange = useCallback(
    ({ target: {value} }) => {
      dispatch(setProfileLanguage({ locale: value }));
    },
    [dispatch]
  );

  return (
    <Select
      variant="outlined"
      className={classes.languageSelect}
      value={getCurrentLanguage()}
      onChange={onChange}
      classes={{
        root: classes.selectInputRoot,
        icon: classes.languageSelectIcon,
      }}
    >
      <MenuItem className={classes.languageMenuItem} value="en-US">
        English (US)
      </MenuItem>
      <MenuItem className={classes.languageMenuItem} value="en-GB">
        English (GB)
      </MenuItem>
      <MenuItem className={classes.languageMenuItem} value="de">
        Deutsch
      </MenuItem>
      <MenuItem className={classes.languageMenuItem} value="fr">
        Français
      </MenuItem>
    </Select>
  );
};
