// @flow
import * as React from "react";
import uniqBy from "lodash/uniqBy";
import { connect, getIn } from "formik";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from 'react-i18next';
import type { WithTranslation } from 'react-i18next';

import type { FormikProps } from "formik";

type CheckboxGroupProps = WithTranslation & React.ElementConfig<typeof FormControl> & {
  groupLabel?: string,
  helperText?: string,
  row?: boolean,
  disabled?: boolean,
  fields: Array<{
    name: string,
    label: string,
    labelPlacement?: string,
    CheckboxProps?: React.ElementConfig<typeof Checkbox>
  }>,
  formik: $ReadOnly<{
    ...FormikProps<{ [key: string]: any }>,
    handleChange(field: string): (e: SyntheticInputEvent<*>) => void,
    handleBlur(field: string): (e: SyntheticEvent<*>) => void
  }>,
  groupLabelClasses?: { [key: string]: string },
  groupClasses?: { [key: string]: string },
  helperTextClasses?: { [key: string]: string },
  controlLabelClasses?: { [key: string]: string },
  checkboxClasses?: { [key: string]: string }
};

const useStyles = withStyles(theme => ({
  groupLabel: {
    color: theme.palette.text.disabled,
    marginBottom: 10
  }
}));

class CheckboxGroup extends React.Component<CheckboxGroupProps> {
  static defaultProps: $Shape<CheckboxGroupProps> = {
    row: false,
    helperText: ""
  };

  render() {
    const {
      row,
      groupLabel,
      helperText,
      fields,
      formik,
      groupLabelClasses,
      groupClasses,
      helperTextClasses,
      controlLabelClasses,
      checkboxClasses,
      classes,
      disabled,
      t,
      ...restProps
    } = this.props;
    const { errors, touched, values, handleChange, handleBlur } = formik;
    const uniqFields = uniqBy(fields, "name");
    const groupChildren = uniqFields.reduce(
      (result, fieldItem) => {
        const {
          name: fieldName,
          label,
          labelPlacement,
          CheckboxProps
        } = fieldItem;
        const error = getIn(errors, fieldName);
        const touch = getIn(touched, fieldName);
        const value = getIn(values, fieldName);

        result.controls.push(
          <FormControlLabel
            key={`control_${fieldName}`}
            name={fieldName}
            value={fieldName}
            disabled={disabled}
            control={
              <Checkbox
                classes={checkboxClasses}
                checked={value}
                onChange={handleChange(fieldName)}
                onBlur={handleBlur(fieldName)}
                {...CheckboxProps}
              />
            }
            label={t(label)}
            labelPlacement={labelPlacement}
            classes={controlLabelClasses}
          />
        );

        if (touch && error) {
          result.errors.push(
            <FormHelperText
              classes={helperTextClasses}
              key={`error_${fieldName}`}
            ></FormHelperText>
          );
        }

        return result;
      },
      {
        controls: [],
        errors: []
      }
    );
    const hasErrors = groupChildren.errors.length > 0;
    const helperTextChildren = hasErrors ? (
      groupChildren.errors
    ) : helperText ? (
      <FormHelperText classes={helperTextClasses}>{helperText}</FormHelperText>
    ) : null;

    return (
      <FormControl {...restProps} component="fieldset" error={hasErrors}>
        {groupLabel ? (
          <FormLabel
            className={classes && classes.groupLabel}
            classes={groupLabelClasses}
            component="legend"
          >
            {groupLabel}
          </FormLabel>
        ) : null}
        <FormGroup classes={groupClasses} row={row}>
          {groupChildren.controls}
        </FormGroup>
        {helperTextChildren}
      </FormControl>
    );
  }
}

export const CheckboxGroupField = useStyles(connect(withTranslation()(CheckboxGroup)));
