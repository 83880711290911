// @flow
import * as React from "react";
import { getIn } from "formik";

import type { FieldProps } from "formik";
import { useTranslation } from "react-i18next";

export default function wrapWithField<Value, Config: Object>(
  MuiInputComponent: React.AbstractComponent<Config>
): React.AbstractComponent<{| ...Config, ...FieldProps<Value> |}> {
  return function InputFieldComponent(props: {|
    ...Config,
    ...FieldProps<Value>
  |}) {
    const {
      field, // { name, value, onChange, onBlur }
      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      helperText: helperTextProp,
      readOnly,
      InputProps,
      ...restProps
    } = props;
    const { t } = useTranslation();
    const error = getIn(errors, field.name);
    const touch = getIn(touched, field.name);
    const hasError = Boolean(touch && error);
    const helperText = hasError ? error : helperTextProp;

    return (
      <MuiInputComponent
        error={hasError}
        helperText={t(helperText)}
        {...field}
        InputProps={{
          ...InputProps,
          readOnly
        }}
        {...restProps}
      />
    );
  };
}
