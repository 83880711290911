// @flow
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import transform from "lodash/transform";
import first from "lodash/first";

import type { $AxiosError } from "axios";

export type ErrorItem = {
  field: string | null,
  type: string,
  messages: string[]
};

export type ErrorResponse = {
  type: string,
  status: number,
  title: string,
  detail: string,
  errors: Array<ErrorItem>
};

export type FieldErrorsHash = { [field: string]: string };

export class APIError<T> extends Error {
  _axiosError: $Shape<$AxiosError<T, ErrorResponse>> | null;
  fieldErrors: FieldErrorsHash; // Hash of field names and corresponded error messages

  type: string;
  status: number;
  data: ErrorResponse | void;
  title: string;
  detail: string;

  constructor(error: $AxiosError<T, ErrorResponse> | Error) {
    const { message, name, stack } = error;
    const isAxiosError =
      error.hasOwnProperty("response") &&
      error.hasOwnProperty("request") &&
      error.hasOwnProperty("config");

    super(message);

    this._axiosError = isAxiosError ? error : null;
    this.name = name;
    this.stack = stack;
  }

  get axiosError() {
    return this._axiosError || {};
  }

  get status() {
    const { request, response } = this.axiosError;

    if (response) {
      return response.status;
    }

    if (request) {
      return typeof request.status === "number" ? request.status : 0;
    }

    return 0;
  }

  get type() {
    const { response, request } = this.axiosError;

    if (response) {
      return response.data.type;
    }

    if (request) {
      return "NetworkError";
    }

    return "UnknownError";
  }

  get data() {
    const { response } = this.axiosError;

    return response ? response.data : undefined;
  }

  get title() {
    return this.data ? this.data.title : this.message;
  }

  get detail() {
    return this.data ? this.data.detail : "";
  }

  get errorMessage() {
    if (this.errors.length) {
      const firstErrorItem = first(this.errors);

      return first(firstErrorItem.messages);
    }

    if (this.detail) return `${this.title}: ${this.detail}`;

    return this.title;
  }

  get errors() {
    return this.data ? this.data.errors : [];
  }

  get fieldErrors() {
    return transform(
      groupBy(this.errors, "field"),
      (result: FieldErrorsHash, fieldErrors: ErrorItem[], field: string) => {
        const fieldError = first(fieldErrors);

        result[field] = fieldError
          ? first(fieldError.messages)
          : `Field ${field} is invalid`;
      },
      {}
    );
  }

  get hasFieldErrors() {
    return Boolean(this.errors.length);
  }
}

export type APIErrors<T> = APIError<T>;

export function errorsFactory<T: *>(
  error: $AxiosError<T, ErrorResponse> | Error
): APIErrors<T> {
  const type = get(error, "response.data.type", error.constructor.name);

  switch (type) {
    // Use type cases for extended error classes
    default:
      return new APIError<T>(error);
  }
}
