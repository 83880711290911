// @flow
import * as React from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import Breadcrumbs from "../../components/Breadcrumbs";
import withRestriction from "../withRestriction";
import { useHcpDataEffect } from "./hooks";
import { usePatientDataEffect } from "../HCP/hooks";
import { isResearcher } from "../../selectors/user";
import { HCP_LIST_PATH, getHcpPatientsPath } from "../../constants/routes";

import type { ContextRouter } from "react-router-dom";
import type { State } from "../../reducers/types";
import { useTranslation } from "react-i18next";

type OwnProps = React.ElementConfig<typeof Breadcrumbs>;
type Props = {
  ...$Exact<OwnProps>,
  ...ContextRouter
};

const { useMemo } = React;

function ResearcherBreadcrumbs(props: Props) {
  const { items: propItems, match, history, location, ...rest } = props;
  const { t } = useTranslation();
  const patientId = match.params.patientId || "";
  const selectedPatient = useSelector(
    ({ patients }: State) => patients.patientsEntities[patientId]
  );
  const hcpId =
    match.params.hcpId || (selectedPatient ? selectedPatient.hcpId : "");
  const selectedHcp = useSelector(({ hcp }: State) => hcp.entities[hcpId]);
  const items = useMemo(
    () => [
      { label: "pages.hcpList.title", path: HCP_LIST_PATH },
      {
        label: selectedHcp
          ? `${selectedHcp.name} ${t("pages.patientList.title")}`
          : t("pages.patientList.title"),
        path: getHcpPatientsPath(hcpId)
      },
      ...(propItems || [])
    ],
    [hcpId, propItems, selectedHcp, t]
  );

  usePatientDataEffect(patientId);
  useHcpDataEffect(hcpId);

  return <Breadcrumbs items={items} {...rest} />;
}

ResearcherBreadcrumbs.defaultProps = {
  items: []
};

export default withRouter(
  withRestriction<Props>(ResearcherBreadcrumbs, isResearcher)
);
