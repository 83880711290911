// @flow
import format from "date-fns/format";
import fromUnixTime from "date-fns/fromUnixTime";
import i18n from "i18next";
import { enGB, enUS, fr, de } from "date-fns/locale";

const locales = { "en-GB": enGB, "en-US": enUS, fr, de };

export const formatDate = (date: Date, formatStr: string = "PP") =>
  format(date, formatStr, {
    locale: locales[i18n.language]
  });

const MS_IN_HOUR = 60 * 60 * 1000;

export const msToHours = (ms: number) => ms / MS_IN_HOUR;
export const hoursToMs = (hour: number) => hour * MS_IN_HOUR;

export const getDateString = (dateLike: Date | number | string) =>
  formatDate(new Date(dateLike), "P");

export const getDateStringFromUnixTime = (unixTime: number) =>
  getDateString(fromUnixTime(unixTime));

export function getDateWithOffset(unixTime: number, tzOffset: number): Date {
  const d = fromUnixTime(unixTime);

  return new Date(
    d.getFullYear(),
    d.getMonth(),
    d.getDate(),
    d.getHours(),
    d.getMinutes(),
    d.getSeconds() + d.getTimezoneOffset() * 60 + tzOffset
  );
}
