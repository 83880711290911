// @flow
import * as React from "react";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import TableCell from "@material-ui/core/TableCell";
import Link from "@material-ui/core/Link";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import makeStyles from "@material-ui/core/styles/makeStyles";
import When from "../../components/When";

import { deleteUser } from "../../actions/adminActions";
import { getUserEditPath } from "../../constants/routes";
import { HCP_ROLE } from "../../constants/roles";

import type { CellProps } from "../../components/Table/types";
import type { State, UserEntity } from "../../reducers/types";
import { useTranslation } from "react-i18next";

type Props = {
  ...CellProps,
  rowData: $Shape<UserEntity>
};

function stopPropagation(e: SyntheticEvent<*, MouseEvent>) {
  e.stopPropagation();
}
const useStyles = makeStyles(theme => ({
  cell: {
    "&.MuiTableCell-root": {
      paddingRight: 0
    }
  },
  deleteItem: {
    color: theme.palette.error.main
  }
}));

const { useState, useCallback } = React;

export default function UserListActions(props: Props) {
  const { className, style, rowData } = props;
  const { t } = useTranslation();
  const { userId, role, deletable } = rowData;

  // Hooks
  const dispatch = useDispatch();
  const { isDeleting, isDeletable } = useSelector(({ admin, user }: State) => {
    const isDeleting = admin.usersDeleting.includes(userId);
    return {
      isDeleting,
      isDeletable: user.userId !== userId && deletable
    };
  }, shallowEqual);
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = useCallback(
    (evt: SyntheticEvent<HTMLButtonElement>) => setAnchorEl(evt.currentTarget),
    [setAnchorEl]
  );
  const closeMenu = useCallback(() => setAnchorEl(null), [setAnchorEl]);
  const handleDeleteClick = useCallback(() => {
    closeMenu();
    dispatch(deleteUser(userId));
  }, [closeMenu, dispatch, userId]);

  const deleteButton = (
    <MenuItem
      className={classes.deleteItem}
      disabled={!isDeletable || isDeleting}
      onClick={handleDeleteClick}
      data-qa="deleteItem"
    >
      {t("actions.delete")}
    </MenuItem>
  );
  const wrappedDeleteButton = !isDeletable ? (
    <Tooltip
      title={
        role === HCP_ROLE
          ? t("pages.userListActions.disabledDeleteTooltipHCP")
          : t("pages.userListActions.disabledDeleteTooltip")
      }
      data-qa="deleteHint"
    >
      <div>{deleteButton}</div>
    </Tooltip>
  ) : (
    deleteButton
  );

  return (
    <TableCell
      component="div"
      align="right"
      variant="body"
      className={clsx(classes.cell, className)}
      style={style}
      onClick={stopPropagation}
    >
      <When condition={!isDeleting}>
        <IconButton color="primary" onClick={openMenu} data-qa="menuButton">
          <MoreVertIcon fontSize="small" />
        </IconButton>
        <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={closeMenu}>
          {wrappedDeleteButton}
        </Menu>
        <Link
          component={RouterLink}
          variant="body2"
          to={getUserEditPath(rowData.userId)}
          data-qa="editButton"
        >
          {t("actions.edit")}
        </Link>
      </When>
    </TableCell>
  );
}
