// @flow
import {
  USERS_LIST_REQUEST,
  USERS_LIST_SUCCESS,
  USERS_LIST_ERROR,
  USERS_LIST_MORE_REQUEST,
  USERS_LIST_MORE_SUCCESS,
  USERS_LIST_MORE_ERROR,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_ERROR,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  CLEAR_USER_FORM_ERRORS,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  DELETE_USER_CONFIRM_DIALOG
} from "../constants/actionTypes";

import type { PaginationPayload, PaginatedResponse } from "./types/generic";
import type {
  UsersListRequestAction,
  UsersListSuccessAction,
  UsersListErrorAction,
  UsersListMoreRequestAction,
  UsersListMoreSuccessAction,
  UsersListMoreErrorAction,
  CreateUserPayload,
  UserCreatedPayload,
  CreateUserAction,
  CreateUserSuccessAction,
  CreateUserErrorAction,
  UpdateUserPayload,
  UpdateUserAction,
  UpdateUserSuccessAction,
  UpdateUserErrorAction,
  GetUserAction,
  GetUserSuccessAction,
  GetUserErrorAction,
  ClearUserFormErrorsAction,
  DeleteUserAction,
  DeleteUserSuccessAction,
  DeleteUserErrorAction,
  DeleteUserDialogAction
} from "./types/adminActions";

import type { UserEntity } from "../reducers/types";
import { APIError } from "../utils/errors";

export const getUsersList = (
  payload: PaginationPayload
): UsersListRequestAction => ({
  type: USERS_LIST_REQUEST,
  payload
});

export const getMoreUsers = (
  payload: PaginationPayload
): UsersListMoreRequestAction => ({
  type: USERS_LIST_MORE_REQUEST,
  payload
});

export const usersListLoaded = (
  payload: PaginatedResponse<"users", UserEntity>
): UsersListSuccessAction => ({
  type: USERS_LIST_SUCCESS,
  payload
});

export const moreUsersLoaded = (
  payload: PaginatedResponse<"users", UserEntity>
): UsersListMoreSuccessAction => ({
  type: USERS_LIST_MORE_SUCCESS,
  payload
});

export const usersListFailed = (error: *): UsersListErrorAction => ({
  type: USERS_LIST_ERROR,
  payload: { error }
});

export const moreUsersFailed = (error: *): UsersListMoreErrorAction => ({
  type: USERS_LIST_MORE_ERROR,
  payload: { error }
});

export const createUser = (payload: CreateUserPayload): CreateUserAction => ({
  type: USER_CREATE_REQUEST,
  payload
});

export const createUserSuccess = (
  payload: UserCreatedPayload
): CreateUserSuccessAction => ({
  type: USER_CREATE_SUCCESS,
  payload
});

export const createUserFailed = (
  error: APIError<*>
): CreateUserErrorAction => ({
  type: USER_CREATE_ERROR,
  payload: { error }
});

export const updateUser = (payload: UpdateUserPayload): UpdateUserAction => ({
  type: USER_UPDATE_REQUEST,
  payload
});

export const updateUserSuccess = (
  payload: UserEntity
): UpdateUserSuccessAction => ({
  type: USER_UPDATE_SUCCESS,
  payload
});

export const updateUserFailed = (error: *): UpdateUserErrorAction => ({
  type: USER_UPDATE_ERROR,
  payload: { error }
});

export const getUser = (userId: string): GetUserAction => ({
  type: GET_USER_REQUEST,
  payload: {
    userId
  }
});

export const getUserSuccess = (payload: UserEntity): GetUserSuccessAction => ({
  type: GET_USER_SUCCESS,
  payload
});

export const getUserError = (error: APIError<*>): GetUserErrorAction => ({
  type: GET_USER_ERROR,
  payload: {
    error
  }
});

export const clearUserFormErrors = (): ClearUserFormErrorsAction => ({
  type: CLEAR_USER_FORM_ERRORS
});

export const deleteUser = (
  userId: string,
  confirmed?: boolean = false
): DeleteUserAction => ({
  type: DELETE_USER_REQUEST,
  payload: {
    userId,
    confirmed
  }
});

export const deleteUserSuccess = (userId: string): DeleteUserSuccessAction => ({
  type: DELETE_USER_SUCCESS,
  payload: { userId }
});

export const deleteUserError = (
  error: *,
  userId: string
): DeleteUserErrorAction => ({
  type: DELETE_USER_ERROR,
  payload: {
    error,
    userId
  }
});

export const openDeleteConfirmDialog = (
  userId: string
): DeleteUserDialogAction => ({
  type: DELETE_USER_CONFIRM_DIALOG,
  payload: {
    show: true,
    userId
  }
});

export const closeDeleteConfirmDialog = (
  userId: string
): DeleteUserDialogAction => ({
  type: DELETE_USER_CONFIRM_DIALOG,
  payload: {
    show: false,
    userId
  }
});
