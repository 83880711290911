// @flow
import qs from "qs";
import { call, put, select } from "redux-saga/effects";
import client from "../service/client";
import {
  requestStart,
  requestSuccess,
  requestError,
  requestUnauthorized,
  requestForbidden
} from "../actions/fetchActions";
import { errorsFactory } from "../utils/errors";

import type { Saga } from "redux-saga";
import type { $AxiosXHR, $AxiosXHRConfig } from "axios";
import type { State } from "../reducers/types";
import type { APIErrors } from "../utils/errors";

export type Result<T, R> = {
  +response?: $AxiosXHR<T, R>,
  +error?: APIErrors<T>
};

const apiKey = process.env.REACT_APP_BACKEND_API_KEY || "";

export function* fetchData<T, R>(
  params: $Shape<$AxiosXHRConfig<T, R>>,
  accessToken?: string
): Saga<Result<T, R>> {
  let result = {};

  yield put(requestStart(params));

  try {
    const token: string =
      (yield select((state: State): string => state.user.token)) || accessToken;
    const config: $Shape<$AxiosXHRConfig<T, R>> = {
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: "repeat", skipNulls: true });
      },
      ...params,
      headers: {
        ...params.headers,
        Authorization: token ? `Bearer ${token}` : undefined,
        key: apiKey.length ? apiKey : undefined
      }
    };
    const response: $AxiosXHR<T, R> = yield call(client.request, config);

    yield put(requestSuccess(response));

    result.response = response;
  } catch (error) {
    const apiError = errorsFactory<T>(error);

    if (apiError.status === 401) {
      yield put(requestUnauthorized(apiError));
    }

    if (apiError.status === 403) {
      yield put(requestForbidden(apiError));
    }

    yield put(requestError(apiError));

    result.error = apiError;

    console.error(error);
  }

  return result;
}
