// @flow
import * as React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

import When from "../../components/When";
import HcpBreadcrumbs from "./HcpBreadcrumbs";
import PageLayout from "../../components/PageLayout";
import { useValidationErrors } from "../../components/Form/hooks";
import PatientForm from "./PatientForm";
import { PATIENTS_LIST_PATH } from "../../constants/routes";
import { updatePatient } from "../../actions/patientsActions";
import { usePatientDataEffect, useClearFormErrors } from "./hooks";
import { APIError } from "../../utils/errors";

import type { ContextRouter } from "react-router-dom";
import type { State } from "../../reducers/types";
import { useTranslation } from "react-i18next";

type Props = { ...ContextRouter };

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(3)
  },
  actions: {
    padding: theme.spacing(2, 0, 2)
  }
}));
const { useRef, useCallback } = React;
const breadcrumbsItems = [{ label: "pages.editPatient.breadcrumb" }];

export default function EditPatient(props: Props) {
  const { params } = props.match;
  const { t } = useTranslation();
  const patientId = params.patientId || "";

  // Hooks
  const classes = useStyles(props);
  const {
    patient,
    isUpdating,
    patientDataError,
    patientDataLoading,
    error
  } = useSelector(
    ({ patients }: State) => ({
      patient: patients.patientsEntities[patientId],
      isUpdating: Boolean(patients.patientUpdating),
      patientDataError: patients.getPatientError,
      error: patients.updatePatientError,
      patientDataLoading: patients.patientDataLoading
    }),
    shallowEqual
  );
  const isPatientLoaded = Boolean(patient);
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const isValidationError = error instanceof APIError && error.hasFieldErrors;
  const onSubmit = useCallback(
    (values, actions) => {
      const {
        subjectNumber,
        password,
        confirmPassword,
      } = values;

      actions.setSubmitting(false);

      dispatch(
        updatePatient({
          patientId,
          subjectNumber,
          password,
          confirmPassword,
        })
      );
    },
    [dispatch, patientId]
  );

  useClearFormErrors();
  usePatientDataEffect(patientId);
  useValidationErrors(formRef.current, error);

  return (
    <PageLayout>
      <HcpBreadcrumbs items={breadcrumbsItems} />
      <Typography component="h1" variant="h5" className={classes.header}>
        {t("pages.editPatient.title")}
      </Typography>
      <Container maxWidth="sm">
        <PatientForm
          editMode
          patient={patient}
          onSubmit={onSubmit}
          formRef={formRef}
        >
          <When condition={isUpdating}>
            <LinearProgress />
          </When>
          <When condition={!!error}>
            {() => (
              <Typography variant="body2" color="error">
                <When condition={!isValidationError}>
                  {t("pages.editPatient.updateErrorMessage")} {error && error.title}
                </When>
              </Typography>
            )}
          </When>
          <When condition={!!patientDataError}>
            <Typography variant="body2" color="error">
              {t("pages.editPatient.getDataError")}
            </Typography>
          </When>
          <Grid
            container
            justify="flex-end"
            spacing={2}
            className={classes.actions}
          >
            <Grid item>
              <Button
                color="inherit"
                component={RouterLink}
                to={PATIENTS_LIST_PATH}
                data-qa="cancelButton"
              >
                {t("actions.cancel")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isUpdating || !isPatientLoaded || patientDataLoading}
                data-qa="saveButton"
              >
                {t("actions.save")}
              </Button>
            </Grid>
          </Grid>
        </PatientForm>
      </Container>
    </PageLayout>
  );
}
