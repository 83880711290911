// @flow
import * as React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

import When from "../../../components/When";
import HcpBreadcrumbs from "../HcpBreadcrumbs";
import PageLayout from "../../../components/PageLayout";
import { useValidationErrors } from "../../../components/Form/hooks";
import MealForm from "./MealForm";
import { getPatientMealMenuPath } from "../../../constants/routes";
import { createMealOption } from "../../../actions/mealOptionActions";
import { useClearFormErrors } from "./hooks";
import { usePatientDataEffect } from "../hooks";
import { APIError } from "../../../utils/errors";

import type { ContextRouter } from "react-router-dom";
import type { State } from "../../../reducers/types";
import { useTranslation } from "react-i18next";

type Props = { ...ContextRouter };

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(3)
  },
  actions: {
    padding: theme.spacing(2, 0, 2)
  }
}));
const { useRef, useCallback, useMemo } = React;
const pageLabel = "pages.addNewMeal.title";

export default function CreateMeal(props: Props) {
  const patientId = props.match.params.patientId || "";
  const { t } = useTranslation();
  const classes = useStyles(props);
  const { isCreating, error, mealMenuLabel } = useSelector(
    ({ mealOptions, patients }: State) => {
      const patient = patients.patientsEntities[patientId];

      return {
        isCreating: Boolean(mealOptions.creating),
        error: mealOptions.createError,
        mealMenuLabel:
          t("pages.mealMenuList.title") + (patient ? ` #${patient.subjectNumber}` : "")
      };
    },
    shallowEqual
  );
  const mealMenuPath = getPatientMealMenuPath(patientId);
  const breadcrumbsItems = useMemo(
    () => [
      {
        label: mealMenuLabel,
        path: mealMenuPath
      },
      { label: pageLabel }
    ],
    [mealMenuLabel, mealMenuPath]
  );
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const isValidationError = error instanceof APIError && error.hasFieldErrors;

  const onSubmit = useCallback(
    (values, actions) => {
      actions.setSubmitting(false);

      dispatch(createMealOption({ ...values, patientId }));
    },
    [dispatch, patientId]
  );

  usePatientDataEffect(patientId);
  useClearFormErrors();
  useValidationErrors(formRef.current, error);

  return (
    <PageLayout>
      <HcpBreadcrumbs items={breadcrumbsItems} />
      <Typography component="h1" variant="h5" className={classes.header}>
        {t(pageLabel)}
      </Typography>
      <Container maxWidth="sm">
        <MealForm onSubmit={onSubmit} formRef={formRef}>
          <When condition={isCreating}>
            <LinearProgress />
          </When>
          <When condition={!!error}>
            {() => (
              <Typography variant="body2" color="error">
                <When condition={isValidationError}>
                  {t("pages.addNewMeal.errors.fieldsInvalid")}
                </When>
                <When condition={!isValidationError}>
                  {t("pages.addNewMeal.errors.unknown")}: {error && error.title}
                </When>
              </Typography>
            )}
          </When>
          <Grid
            container
            justify="flex-end"
            spacing={2}
            className={classes.actions}
          >
            <Grid item>
              <Button color="inherit" component={RouterLink} to={mealMenuPath}>
                {t("actions.cancel")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isCreating}
              >
                {t("actions.save")}
              </Button>
            </Grid>
          </Grid>
        </MealForm>
      </Container>
    </PageLayout>
  );
}
