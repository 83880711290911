// @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { Link as RouterLink, withRouter } from "react-router-dom";
import Link from "@material-ui/core/Link";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import ProfileButton from "../components/ProfileButton";
import LogoIcon from "../components/LogoIcon";
import { logout } from "../actions/userActions";
import { HOME_PATH, WEBAPP_LEGAL_NOTICES_PATH } from "../constants/routes";
import ChangeLanguageButton from "./ChangeLanguageButton";

import type { ContextRouter } from "react-router-dom";
import type { Dispatch } from "redux";
import type { WithStyles } from "@material-ui/core";
import type { State } from "../reducers/types";
import When from "../components/When";

type OwnProps = {|
  ...$Exact<WithTranslation>,
  ...$Exact<WithStyles>,
  ...ContextRouter
|};

type Props = {
  ...OwnProps,
  displayName: string,
  authorized: boolean,
  logout: Dispatch<$ReturnValue<typeof logout>>
};

type ComponentState = {
  profileMenuOpen: boolean
};

type OwnConfig = React.Config<OwnProps, {||}>;
type Config = React.Config<Props, {||}>;

const useStyles: Function = withStyles(theme => ({
  toolbar: {
    paddingRight: 24
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: theme.palette.secondary.main
  },
  appLogo: {
    marginRight: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  profileButton: {
    color: theme.palette.primary.contrastText
  },
  title: {
    flexGrow: 1
  }
}));

const mapStateToProps = ({ user }: State) => ({
  displayName: `${user.firstName} ${user.lastName}`,
  authorized: Boolean(user.token),
});

class AppBar extends React.Component<Props, ComponentState> {
  profileButtonRef = React.createRef();

  state: ComponentState = {
    profileMenuOpen: false
  };

  handleMenuOpen = () =>
    this.setState({
      profileMenuOpen: true
    });

  handleMenuClose = () =>
    this.setState({
      profileMenuOpen: false
    });

  render() {
    const { classes, displayName, authorized, logout, history, t } = this.props;
    const { profileMenuOpen } = this.state;

    return (
      <MuiAppBar position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Link
            component={RouterLink}
            to={HOME_PATH}
            color="inherit"
            className={classes.appLogo}
            data-qa="logo"
          >
            <LogoIcon fontSize="large" />
          </Link>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {t("appTitle")}
          </Typography>
          <When condition={authorized}>
            <ChangeLanguageButton />
            <ProfileButton
              ref={this.profileButtonRef}
              onClick={this.handleMenuOpen}
              className={classes.profileButton}
              displayName={displayName}
              data-qa="adminIcon"
            />
          </When>
          <Menu
            id="profile-menu"
            open={profileMenuOpen}
            anchorEl={this.profileButtonRef.current}
            getContentAnchorEl={null}
            onClose={this.handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
          >
            <MenuItem onClick={() => {
              this.setState({profileMenuOpen: false})
              history.push(WEBAPP_LEGAL_NOTICES_PATH);
            }}>
              {t("profileActions.legalNotices")}
            </MenuItem>
            <MenuItem onClick={logout} data-qa="logout">
              {t("actions.logout")}
            </MenuItem>
          </Menu>
        </Toolbar>
      </MuiAppBar>
    );
  }
}

export default compose(
  useStyles,
  withTranslation(),
  withRouter,
  connect<Config, OwnConfig, _, _, _, _>(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        logout,
      },
      dispatch
    )
  )
)(AppBar);
