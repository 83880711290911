// @flow
import * as Yup from "yup";
import i18next from "i18next";
import {
  NO_ADJUSTMENT,
  SLIDING_SCALE,
  CORRECTION_FACTOR
} from "../../../constants/dosing";

import type { Values } from "./types";

export const EmptySchema = Yup.mixed();

const DosingAdjustmentTypeSchema = Yup.number().oneOf(
  [NO_ADJUSTMENT, SLIDING_SCALE, CORRECTION_FACTOR],
  "Please choose dose adjustment type"
);

const CorrectionFactorSchema = Yup.object().shape({
  targetGlucose: Yup.number()
    .integer()
    .positive()
    .max(999),
  correctionFactor: Yup.number()
    .positive()
    .max(999)
});

const SlidingScaleSchema = Yup.array()
  .of(
    Yup.object().shape({
      glucoseRangeLow: Yup.number()
        .integer()
        .positive()
        .max(999),
      glucoseRangeHigh: Yup.number()
        .integer()
        .positive()
        .max(999),
      breakfastDose: Yup.number()
        .min(0)
        .max(999),
      lunchDose: Yup.number()
        .min(0)
        .max(999),
      dinnerDose: Yup.number()
        .min(0)
        .max(999)
    })
  )
  .min(1, "Minimum one sliding scale adjustment needed");

export const DosingStrategySchema = Yup.object({
  morningBasalDose: Yup.number()
    .min(0)
    .max(999)
    .required(),
  eveningBasalDose: Yup.number()
    .min(0)
    .max(999)
    .required(),
  breakfast: Yup.number()
    .min(0)
    .max(999)
    .required(),
  lunch: Yup.number()
    .min(0)
    .max(999)
    .required(),
  dinner: Yup.number()
    .min(0)
    .max(999)
    .required(),
  snack: Yup.number()
    .min(0)
    .max(999)
    .required(),
  doseAdjustmentType: DosingAdjustmentTypeSchema,
  correctionFactor: Yup.mixed().when("doseAdjustmentType", {
    is: CORRECTION_FACTOR,
    then: CorrectionFactorSchema.required(
      "Please set correction factor values"
    ),
    otherwise: Yup.mixed()
      .nullable()
      .notRequired()
  }),
  slidingScale: Yup.mixed().when("doseAdjustmentType", {
    is: SLIDING_SCALE,
    then: SlidingScaleSchema.required("Please set sliding scale values"),
    otherwise: Yup.array().notRequired()
  })
});

export function validateForm(values: Values) {
  let errors = {};
  let { slidingScale, doseAdjustmentType } = values;

  doseAdjustmentType = parseInt(doseAdjustmentType, 10);

  if (slidingScale.length && doseAdjustmentType === SLIDING_SCALE) {
    let hasErrors = false;

    const slidingScaleErrors = slidingScale.map<{ [name: string]: string }>(
      (item, i, list) => {
        const itemErrors = {};
        const prevItem = list[i - 1];
        const glucoseRangeLow = parseFloat(item.glucoseRangeLow);
        const glucoseRangeHigh = parseFloat(item.glucoseRangeHigh);

        if (i > 0 && prevItem) {
          const prevGlucoseRangeHigh = parseFloat(prevItem.glucoseRangeHigh);

          if (glucoseRangeLow <= prevGlucoseRangeHigh) {
            const message = i18next.t('pages.dosingStrategy.slidingScaleForm.errors.lowThreshold', { prevGlucoseRangeHigh });

            itemErrors["glucoseRangeLow"] = message;
          }
        }

        if (glucoseRangeHigh <= glucoseRangeLow) {
          const message = i18next.t('pages.dosingStrategy.slidingScaleForm.errors.highThreshold');

          itemErrors["glucoseRangeHigh"] = message;
        }

        if (!hasErrors) {
          hasErrors = Object.keys(itemErrors).length > 0;
        }

        return itemErrors;
      }
    );

    if (hasErrors) {
      errors.slidingScale = slidingScaleErrors;
    }
  }

  return errors;
}
