// @flow
import * as React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";

type Props = {|
  ...$Exact<WithTranslation>,
  className?: string,
  value: number,
  onChange(value: number): void
|};

const timePeriodOptions: Array<{
  value: number,
  label: string,
  key: string
}> = [
  { label: "timePeriods.all", value: 0, key: "all" },
  { label: "timePeriods.1d", value: 1, key: "1d" },
  { label: "timePeriods.7d", value: 7, key: "7d" },
  { label: "timePeriods.15d", value: 15, key: "15d" },
  { label: "timePeriods.30d", value: 30, key: "30d" }
];

function TimePeriodFilter(props: Props) {
  const { className, value, onChange, t } = props;

  function handleChange(evt: SyntheticInputEvent<*>) {
    let value = parseInt(evt.target.value, 10);

    value = Math.max(Number.isNaN(value) ? 0 : value, 0);

    onChange(value);
  }

  return (
    <TextField
      label={t("fields.patientList.timePeriod")}
      select
      margin="normal"
      variant="outlined"
      className={className}
      value={value >= 0 ? value.toString() : "0"}
      onChange={handleChange}
      data-qa="timePeriodDropdown"
    >
      {timePeriodOptions.map(({ label, value, key }, i) => (
        <MenuItem key={key} value={value.toString()}>
          {t(label)}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default withTranslation()(TimePeriodFilter);
