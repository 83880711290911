// @flow
import {
  REQUEST_START,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
  REQUEST_UNAUTHORIZED,
  REQUEST_FORBIDDEN
} from "../constants/actionTypes";

import { APIError } from "../utils/errors";
import type {
  RequestStartAction,
  RequestSuccessAction,
  RequestErrorAction,
  RequestUnauthorizedAction,
  RequestForbiddenAction
} from "./types/fetchActions";

export const requestStart = (payload: any): RequestStartAction => ({
  type: REQUEST_START,
  payload
});
export const requestSuccess = (response: any): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  payload: {
    response
  }
});
export function requestError(error: APIError<*>): RequestErrorAction {
  return {
    type: REQUEST_ERROR,
    payload: {
      error
    }
  };
}
export function requestUnauthorized(
  error: APIError<*>
): RequestUnauthorizedAction {
  return {
    type: REQUEST_UNAUTHORIZED,
    payload: {
      error
    }
  };
}
export function requestForbidden(error: APIError<*>): RequestForbiddenAction {
  return {
    type: REQUEST_FORBIDDEN,
    payload: {
      error
    }
  };
}
