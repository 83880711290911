// @flow
import * as React from "react";
import noop from "lodash/noop";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import wrapWithField from "./decorateWithField";
import { useTranslation } from "react-i18next";

const { useState, useEffect } = React;

type Props = {
  ...React.ElementConfig<typeof TextField>,
  showPassword: boolean,
  showPasswordDataQa: string,
  onShowClick: (
    evt: SyntheticEvent<HTMLButtonElement, MouseEvent>,
    showState: boolean
  ) => any
};

export function PasswordField(props: Props) {
  const {
    showPassword: showPasswordProp,
    onShowClick,
    InputProps,
    showPasswordDataQa,
    ...restProps
  } = props;
  const [showPassword, setShowPassword] = useState<boolean>(showPasswordProp);
  const { t } = useTranslation();

  useEffect(() => {
    setShowPassword(showPasswordProp);
  }, [showPasswordProp]);

  function handleShowClick(evt: SyntheticEvent<HTMLButtonElement, MouseEvent>) {
    const nextState = !showPassword;

    setShowPassword(nextState);
    onShowClick(evt, nextState);
  }

  return (
    <TextField
      type={showPassword ? "text" : "password"}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label={t("components.passwordField.ariaLabel")}
              onClick={handleShowClick}
              data-qa={showPasswordDataQa}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...restProps}
    />
  );
}

PasswordField.defaultProps = {
  showPassword: false,
  onShowClick: noop
};

export default wrapWithField<string, Props>(PasswordField);
