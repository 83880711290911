// @flow
import * as React from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import compact from "lodash";
import { Link as RouterLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";

import VirtualizedTable, {
  MuiVirtualizedTable
} from "../../../components/Table/VirtualizedTable";
import PageLayout from "../../../components/PageLayout";
import HcpBreadcrumbs from "../HcpBreadcrumbs";
import ResearcherBreadcrumbs from "../../Researcher/ResearcherBreadcrumbs";
import mealOptionsColumns from "./mealOptionsColumns";
import MealFilter from "./MealFilter";
import withRestriction from "../../withRestriction";
import { getPatient } from "../../../actions/patientsActions";
import {
  getMealOptionsList,
  getMoreMealOptionsList
} from "../../../actions/mealOptionActions";
import {
  getPatientMealCreatePath,
  getPatientMealDetailsPath,
  HOME_PATH
} from "../../../constants/routes";
import { isHcp } from "../../../selectors/user";

import type { ContextRouter } from "react-router-dom";
import type { WithTranslation } from "react-i18next";
import type { WithStyles } from "@material-ui/core";
import type { SortOrderShortEnum } from "../../../constants/sorting";
import type { MealType, MealCategory } from "../../../constants/meal";
import type { MealOptionsPaginationPayload } from "../../../actions/types/mealOptionActions";
import type {
  State as AppState,
  MealOptionEntity
} from "../../../reducers/types";

type MealOptionItem = MealOptionEntity;
type OwnProps = {|
  ...$Exact<WithStyles>,
  ...ContextRouter,
  ...$Exact<WithTranslation>
|};

type ConnectedProps = $ReadOnly<{|
  loading: boolean,
  moreLoading: boolean,
  mealTypeFilter: MealType | null,
  mealCategoryFilter: MealCategory | null,
  sortBy: string,
  sortDirection: SortOrderShortEnum,
  idsList: (?string)[],
  entities: {
    [mealOptionId: string]: MealOptionEntity
  },
  total: number,
  error: string | null,
  deleting: string[],
  breadcrumbsPageTitle: string,
  shouldFetchPatient: boolean
|}>;

type ConnectedActions = {|
  +getPatient: typeof getPatient,
  +getMealOptionsList: typeof getMealOptionsList,
  +getMoreMealOptionsList: typeof getMoreMealOptionsList
|};

type Props = {|
  ...OwnProps,
  ...ConnectedProps,
  ...ConnectedActions
|};

type Config = React.Config<Props, {||}>;
type OwnConfig = React.Config<OwnProps, {||}>;

const useStyles: Function = withStyles(theme => ({
  page: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column"
  },
  header: {
    marginBottom: theme.spacing(3)
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  tableHeader: {
    fontSize: theme.typography.pxToRem(10),
    lineHeight: 1.1
  },
  tableContainer: {
    flex: "1",
    minHeight: 200
  },
  filterDropdown: {
    width: 180,
    marginBottom: theme.spacing(2)
  }
}));

const mapStateToProps = (
  { patients, mealOptions }: AppState,
  ownProps: $ReadOnly<OwnProps>
): ConnectedProps => {
  const {
    listLoading: loading,
    idsList,
    entities,
    total,
    moreRequested: moreLoading,
    sortBy,
    sortDirection,
    mealTypeFilter,
    mealCategoryFilter,
    listError: error,
    deleting
  } = mealOptions;
  const { t } = ownProps;
  const { patientId } = ownProps.match.params;
  const patient = patients.patientsEntities[patientId || ""];
  const shouldFetchPatient = !patients.patientDataLoading && !patient;

  return {
    loading,
    idsList,
    entities,
    total,
    moreLoading,
    sortBy,
    sortDirection,
    mealTypeFilter,
    mealCategoryFilter,
    error: error ? error.title : "",
    deleting,
    breadcrumbsPageTitle:
      t("pages.mealMenuList.title") +
      (patient ? ` #${patient.subjectNumber}` : ""),
    shouldFetchPatient
  };
};

const HcpFab = withRestriction(Fab, isHcp);

export class MealOptionsList extends React.Component<Props> {
  static pageSize = 30;

  tableRef = React.createRef<MuiVirtualizedTable>();

  componentDidMount() {
    const { history, match, shouldFetchPatient, getPatient } = this.props;
    const { patientId } = match.params;

    if (!patientId) {
      return history.push(HOME_PATH);
    }

    if (shouldFetchPatient) {
      getPatient(patientId);
    }
    this.getMealOptions();
  }

  handleSort = (sortBy: string, sortDirection: SortOrderShortEnum) => {
    const tableRef = this.tableRef.current;

    if (tableRef) {
      tableRef.scrollToRow(0);
      tableRef.resetLoadMoreRowsCache();
    }
    this.getMealOptions({ sortBy, sortDirection });
  };

  handleRowClick = (args: {
    event: SyntheticEvent<HTMLElement, MouseEvent>,
    index: number,
    rowData: $Shape<MealOptionEntity>
  }) => {
    const { id: mealOptionId } = args.rowData ? args.rowData : {};
    const { patientId } = this.props.match.params;

    if (
      patientId &&
      this.isRowLoaded(args) &&
      !this.isMealOptionDeleting(mealOptionId)
    ) {
      this.props.history.push(
        getPatientMealDetailsPath(patientId, mealOptionId)
      );
    }
  };

  loadMoreRows = ({
    startIndex,
    stopIndex
  }: {
    startIndex: number,
    stopIndex: number
  }) => {
    const {
      loading,
      getMoreMealOptionsList,
      idsList,
      total,
      sortBy,
      sortDirection,
      mealTypeFilter,
      mealCategoryFilter,
      match
    } = this.props;

    const { patientId } = match.params;

    if (!patientId || loading || compact(idsList).length >= total) {
      return;
    }

    getMoreMealOptionsList({
      take: stopIndex - startIndex + 1,
      skip: startIndex,
      sortBy,
      sortDirection,
      patientId,
      mealTypeFilter,
      mealCategoryFilter
    });
  };

  rowGetter = ({
    index
  }: {
    index: number
  }): MealOptionItem | { loading: boolean } => {
    const { idsList, entities } = this.props;
    const mealOptionId = idsList[index];
    const mealOption = mealOptionId ? entities[mealOptionId] : undefined;

    if (mealOption) {
      return mealOption;
    }

    return { loading: true };
  };

  isRowLoaded = ({ index }: { index: number }) => {
    return !!this.props.idsList[index];
  };

  isMealOptionDeleting(mealOptionId: string) {
    return this.props.deleting.includes(mealOptionId);
  }

  handleMealTypeChange = (filterValue: {
    mealType: MealType | null,
    mealCategory: MealCategory | null
  }) => {
    const {
      mealType: mealTypeFilter,
      mealCategory: mealCategoryFilter
    } = filterValue;

    this.getMealOptions({ mealTypeFilter, mealCategoryFilter });
  };

  getMealOptions(payload?: $Shape<MealOptionsPaginationPayload> = {}) {
    const {
      sortBy,
      sortDirection,
      mealTypeFilter,
      mealCategoryFilter,
      match
    } = this.props;
    const { patientId } = match.params;

    this.props.getMealOptionsList({
      skip: 0,
      take: MealOptionsList.pageSize,
      sortBy: payload.hasOwnProperty("sortBy") ? payload.sortBy : sortBy,
      sortDirection: payload.hasOwnProperty("sortDirection")
        ? payload.sortDirection
        : sortDirection,
      mealTypeFilter: payload.hasOwnProperty("mealTypeFilter")
        ? payload.mealTypeFilter
        : mealTypeFilter,
      mealCategoryFilter: payload.hasOwnProperty("mealCategoryFilter")
        ? payload.mealCategoryFilter
        : mealCategoryFilter,
      patientId: patientId || ""
    });
  }

  render() {
    const {
      classes,
      loading,
      error,
      total,
      sortBy,
      sortDirection,
      breadcrumbsPageTitle,
      match,
      mealCategoryFilter,
      mealTypeFilter,
      t
    } = this.props;
    const { patientId } = match.params;
    const breadcrumbsItems = [{ label: breadcrumbsPageTitle }];

    return (
      <PageLayout className={classes.page}>
        <Grid container alignItems="flex-end" spacing={2}>
          <Grid item xs={6}>
            <HcpBreadcrumbs items={breadcrumbsItems} />
            <ResearcherBreadcrumbs items={breadcrumbsItems} />
            <Typography component="h1" variant="h5" className={classes.header}>
              {t("pages.mealMenuList.title")}
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <MealFilter
              className={classes.filterDropdown}
              value={{
                mealCategory: mealCategoryFilter,
                mealType: mealTypeFilter
              }}
              onChange={this.handleMealTypeChange}
            />
          </Grid>
        </Grid>
        <Paper className={classes.tableContainer}>
          <VirtualizedTable
            classes={{ headCell: classes.tableHeader }}
            ref={this.tableRef}
            loading={loading}
            error={!!error}
            infiniteLoad
            minimumBatchSize={MealOptionsList.pageSize}
            threshold={MealOptionsList.pageSize + 10}
            loadMoreRows={this.loadMoreRows}
            isRowLoaded={this.isRowLoaded}
            rowCount={total}
            rowGetter={this.rowGetter}
            rowKey="id"
            columns={mealOptionsColumns}
            sortDirection={sortDirection}
            sortBy={sortBy}
            onSort={this.handleSort}
            onRowClick={this.handleRowClick}
          />
        </Paper>
        {patientId && (
          <HcpFab
            component={RouterLink}
            className={classes.fab}
            color="primary"
            to={getPatientMealCreatePath(patientId)}
          >
            <AddIcon />
          </HcpFab>
        )}
      </PageLayout>
    );
  }
}

export default compose(
  withTranslation(),
  connect<Config, OwnConfig, _, _, _, _>(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        getPatient,
        getMealOptionsList,
        getMoreMealOptionsList
      },
      dispatch
    )
  ),
  useStyles
)(MealOptionsList);
