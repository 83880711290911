// @flow
import * as React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

import When from "../../../components/When";
import HcpBreadcrumbs from "../HcpBreadcrumbs";
import ResearcherBreadcrumbs from "../../Researcher/ResearcherBreadcrumbs";
import PageLayout from "../../../components/PageLayout";
import MealForm from "./MealForm";
import DeleteMealModal from "./DeleteMealModal";
import { HcpFragment } from "../../withRestriction";
import { useMealOptionDataEffect } from "./hooks";
import { usePatientDataEffect } from "../hooks";
import {
  getPatientMealMenuPath,
  getPatientMealEditPath
} from "../../../constants/routes";
import { deleteMealOption } from "../../../actions/mealOptionActions";

import type { ContextRouter } from "react-router-dom";
import type { State } from "../../../reducers/types";
import { useTranslation } from "react-i18next";

type Props = { ...ContextRouter };

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(3)
  },
  actions: {
    padding: theme.spacing(2, 0, 2)
  }
}));

const pageLabel = "pages.mealDetails.title";
const { useMemo, useCallback } = React;

export default function CreateUser(props: Props) {
  const { t } = useTranslation();
  const params = props.match.params;
  const patientId = params.patientId || "";
  const mealOptionId = params.mealId || "";
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const {
    mealOption,
    loading,
    error,
    isDeleting,
    isEditable,
    isDeletable,
    mealMenuLabel
  } = useSelector(({ mealOptions, patients }: State) => {
    const { loading, getMealError, deleting, entities } = mealOptions;
    const patient = patients.patientsEntities[patientId];
    const mealOption = entities[mealOptionId];
    const mealOptionLoaded = Boolean(mealOption);

    return {
      loading: loading,
      error: getMealError,
      mealOption,
      isDeleting: deleting.includes(mealOptionId),
      isDeletable: mealOptionLoaded && mealOption.count === 0,
      isEditable: mealOptionLoaded,
      mealMenuLabel: t("pages.mealMenuList.title") + (patient ? ` #${patient.subjectNumber}` : "")
    };
  }, shallowEqual);
  const mealMenuPath = getPatientMealMenuPath(patientId);
  const breadcrumbsItems = useMemo(
    () => [
      {
        label: mealMenuLabel,
        path: mealMenuPath
      },
      { label: pageLabel }
    ],
    [mealMenuLabel, mealMenuPath]
  );
  const handleDeleteClick = useCallback(() => {
    dispatch(deleteMealOption(mealOptionId));
  }, [dispatch, mealOptionId]);

  usePatientDataEffect(patientId);
  useMealOptionDataEffect(mealOptionId);

  return (
    <PageLayout>
      <HcpBreadcrumbs items={breadcrumbsItems} />
      <ResearcherBreadcrumbs items={breadcrumbsItems} />
      <Grid container className={classes.header}>
        <Grid item xs={8}>
          <Typography component="h1" variant="h5">
            {t(pageLabel)}
          </Typography>
        </Grid>
        <HcpFragment>
          <Grid item xs={4} container justify="flex-end">
            <Button
              disabled={isDeleting || !isDeletable}
              onClick={handleDeleteClick}
            >
              {t("actions.delete")}
            </Button>
            <When condition={!!patientId}>
              <Button
                color="primary"
                component={RouterLink}
                to={getPatientMealEditPath(patientId, mealOptionId)}
                disabled={isDeleting || !isEditable}
              >
                {t("actions.edit")}
              </Button>
            </When>
          </Grid>
        </HcpFragment>
      </Grid>
      <Container maxWidth="sm">
        <MealForm readOnly mealOption={mealOption}>
          <When condition={loading || isDeleting}>
            <LinearProgress />
          </When>
          <When condition={!!error}>
            {() => (
              <Typography variant="body2" color="error">
                {t("pages.mealMenuList.getDataError")} {error && error.message}
              </Typography>
            )}
          </When>
          <Grid
            container
            justify="flex-end"
            spacing={2}
            className={classes.actions}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to={mealMenuPath}
              >
                {t("actions.ok")}
              </Button>
            </Grid>
          </Grid>
        </MealForm>
      </Container>
      <DeleteMealModal />
    </PageLayout>
  );
}
