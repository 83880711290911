// @flow
import getUnixTime from "date-fns/getUnixTime";
import randomColor from "randomcolor";
import get from "lodash/get";
import find from "lodash/find";
import findKey from "lodash/findKey";
import last from "lodash/last";
import sortBy from "lodash/sortBy";
import groupBy from "lodash/groupBy";
import mapValues from "lodash/mapValues";
import keyBy from "lodash/keyBy";
import minBy from "lodash/minBy";
import {
  MEAL_ANALYSIS_GET_REQUEST,
  MEAL_ANALYSIS_GET_SUCCESS,
  MEAL_ANALYSIS_GET_ERROR,
  MEAL_ANALYSIS_SELECT_TRACE,
  MEAL_ANALYSIS_SELECT_MEAL_EVENT,
  LOGOUT_DONE
} from "../constants/actionTypes";
import { getDateString, getDateStringFromUnixTime } from "../utils/time";

import type { Action } from "../actions/types";
import type { MealAnalysisState, GlucoseReading } from "./types";

const initialState: MealAnalysisState = {
  offset: 0,
  loading: false,
  error: null,
  mealOptionId: "",
  selectedTraceDate: "",
  selectedMealEvent: "",
  traceColors: {},
  tracesDates: [],
  mealEventsSeries: {},
  glucoseSeries: {},
  heartRateSeries: {},
  stepsSeries: {},
  otherNotesByDate: {},
  mealEvents: {},
  otherNotesItems: {}
};

const standardColors = [
  "#254abf",
  "#fec700",
  "#41ca19",
  "#12939a",
  "#1fb0ee",
  "#d11e6f",
  "#ff6d00",
  "#f64900"
];

export default function mealAnalysisReducer(
  state: MealAnalysisState = initialState,
  action: Action
): MealAnalysisState {
  switch (action.type) {
    case MEAL_ANALYSIS_GET_REQUEST: {
      const { mealOptionId } = action.payload;
      const {
        mealEvents,
        otherNotesItems,
        mealEventsSeries,
        glucoseSeries,
        heartRateSeries,
        stepsSeries,
        otherNotesByDate,
        tracesDates,
        selectedTraceDate,
        selectedMealEvent
      } = initialState;
      return {
        ...state,
        mealOptionId,
        mealEvents,
        otherNotesItems,
        mealEventsSeries,
        glucoseSeries,
        heartRateSeries,
        stepsSeries,
        otherNotesByDate,
        tracesDates,
        selectedTraceDate,
        selectedMealEvent,
        error: null,
        loading: true
      };
    }
    case MEAL_ANALYSIS_GET_SUCCESS: {
      const { result, entities } = action.payload;
      const { mealEvents = {}, otherNotes = {} } = entities;
      const analyzedMealOptionId = result.mealOptionId;

      const mealEventsLines = groupBy(result.mealEvents, id =>
        getDateString(mealEvents[id].date)
      );
      const glucoseReadingsLines: $PropertyType<
        MealAnalysisState,
        "glucoseSeries"
      > = mapValues(
        groupBy<string, GlucoseReading>(result.glucoseReadings, reading =>
          getDateStringFromUnixTime(reading.time)
        ),
        (readings: GlucoseReading[], dateString: string) => {
          let mealEventsSet = new Set<string>(
            mealEventsLines[dateString] || []
          );

          return readings.map((reading, i, list) => {
            // Finding closest glucose reading to attach meal event
            const leftReading = list[i - 1];
            const rightReading = list[i + 1];
            const leftBound = leftReading
              ? reading.time - Math.abs(reading.time - leftReading.time) / 2
              : Number.NEGATIVE_INFINITY;
            const rightBound = rightReading
              ? reading.time + Math.abs(reading.time - rightReading.time) / 2
              : Number.POSITIVE_INFINITY;
            const nearestEventsMap = new Map<string, number>();
            let mealEventId = null;

            for (let eventId of mealEventsSet) {
              const mealEvent = mealEvents[eventId];
              const mealEventTime = mealEvent
                ? getUnixTime(new Date(mealEvent.date))
                : null;
              if (
                mealEventTime !== null &&
                leftBound <= mealEventTime &&
                mealEventTime < rightBound
              ) {
                nearestEventsMap.set(
                  eventId,
                  Math.abs(mealEventTime - reading.time)
                );
                mealEventsSet.delete(eventId);
              }
            }
            const nearestEventIds = [...nearestEventsMap.keys()];

            if (nearestEventIds.length > 1) {
              const analyzedEvent = nearestEventIds.find(eventId => {
                return (
                  mealEvents[eventId].mealOption.id === analyzedMealOptionId
                );
              });

              mealEventId = analyzedEvent
                ? analyzedEvent
                : minBy(nearestEventIds, id => nearestEventsMap.get(id));
            } else if (nearestEventIds.length === 1) {
              mealEventId = nearestEventIds[0];
            }

            return {
              ...reading,
              mealEventId: mealEventId
            };
          });
        }
      );
      const stepsSeriesLines = groupBy(result.stepsSeries, step =>
        getDateStringFromUnixTime(step.time)
      );
      const heartRateSeriesLines = groupBy(result.heartRateSeries, heartRate =>
        getDateStringFromUnixTime(heartRate.time)
      );
      const otherNotesByDate = keyBy(result.otherNotes, id =>
        getDateStringFromUnixTime(otherNotes[id].time)
      );
      const tracesDates = sortBy(
        Object.keys(glucoseReadingsLines).filter(dateString =>
          get(mealEventsLines, dateString, []).some(
            id =>
              get(mealEvents, [id, "mealOption", "id"], "") ===
              analyzedMealOptionId
          )
        ),
        dateString => new Date(dateString)
      );

      const selectedTraceDate = last(tracesDates) || "";
      const selectedMealEvent =
        get(
          find(glucoseReadingsLines[selectedTraceDate], reading => {
            const mealEventId = reading.mealEventId || "";
            const mealEvent = mealEvents[mealEventId];

            return mealEvent
              ? mealEvent.mealOption.id === analyzedMealOptionId
              : false;
          }),
          "mealEventId"
        ) || "";

      return {
        ...state,
        offset: result.offset,
        mealOptionId: analyzedMealOptionId,
        mealEvents: mealEvents,
        otherNotesItems: otherNotes,
        mealEventsSeries: mealEventsLines,
        glucoseSeries: glucoseReadingsLines,
        heartRateSeries: heartRateSeriesLines,
        stepsSeries: stepsSeriesLines,
        otherNotesByDate: otherNotesByDate,
        tracesDates,
        selectedTraceDate,
        selectedMealEvent,
        traceColors: tracesDates.reduceRight((result, date, i, list) => {
          const colorIndex = list.length - 1 - i;

          result[date] = standardColors[colorIndex]
            ? standardColors[colorIndex]
            : randomColor({ luminosity: "bright" });

          return result;
        }, {}),
        loading: false
      };
    }
    case MEAL_ANALYSIS_GET_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    }
    case MEAL_ANALYSIS_SELECT_TRACE: {
      const { mealEvents, mealOptionId, mealEventsSeries } = state;
      const { selectedTrace: selectedTraceDate } = action.payload;
      const selectedMealEvent = find(
        mealEventsSeries[selectedTraceDate],
        eventId => {
          const mealEvent = mealEvents[eventId];

          return mealEvent ? mealEvent.mealOption.id === mealOptionId : false;
        }
      );

      return {
        ...state,
        selectedTraceDate,
        selectedMealEvent
      };
    }
    case MEAL_ANALYSIS_SELECT_MEAL_EVENT: {
      const { mealEventId } = action.payload;
      const { mealEventsSeries } = state;
      const selectedTraceDate =
        findKey(mealEventsSeries, mealEventIds =>
          mealEventIds.some(id => id === mealEventId)
        ) || "";

      return { ...state, selectedMealEvent: mealEventId, selectedTraceDate };
    }
    case LOGOUT_DONE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
