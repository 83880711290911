// @flow
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMealOption,
  clearMealOptionFormErrors
} from "../../../actions/mealOptionActions";

import type { State } from "../../../reducers/types";

export function useMealOptionDataEffect(mealOptionId: string) {
  const dispatch = useDispatch();
  const shouldFetchMealOption = useSelector(({ mealOptions }: State) => {
    const { entities, loading, getMealError: error } = mealOptions;
    const loaded = Boolean(entities[mealOptionId]);

    return !error && !loading && !loaded && Boolean(mealOptionId);
  });

  useEffect(() => {
    if (shouldFetchMealOption) {
      dispatch(getMealOption(mealOptionId));
    }
  }, [dispatch, mealOptionId, shouldFetchMealOption]);
}

export function useClearFormErrors() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMealOptionFormErrors());
  }, [dispatch]);
}
