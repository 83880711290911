// @flow
import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import history from "./history";
import theme from "./theme";
import RouteComponentStub from "./components/RouteComponentStub";
import AppLayout from "./containers/AppLayout";
import LoginPage from "./containers/LoginPage";
import NotFoundPage from "./containers/NotFoundPage";
import HomePage from "./containers/HomePage";
import UsersList from "./containers/Admin/UsersList";
import CreateUser from "./containers/Admin/CreateUser";
import EditUser from "./containers/Admin/EditUser";
import PatientsList from "./containers/HCP/PatientsList";
import CreatePatient from "./containers/HCP/CreatePatient";
import EditPatient from "./containers/HCP/EditPatient";
import MealOptionsList from "./containers/HCP/MealMenu/MealOptionsList";
import CreateMeal from "./containers/HCP/MealMenu/CreateMeal";
import EditMeal from "./containers/HCP/MealMenu/EditMeal";
import MealDetails from "./containers/HCP/MealMenu/MealDetails";
import MealAnalysis from "./containers/HCP/MealAnalysis/MealAnalysis";
import DosingStrategy from "./containers/HCP/DosingStrategy/DosingStrategy";
import HcpList from "./containers/Researcher/HcpList";
import GlucoseUploadPage from "./containers/GlucoseUploadPage";
import { AuthenticatedRoute, RoleRoute, MobileRestrictedRoute } from "./containers/RestrictedRoute";
import { ADMIN_ROLE, HCP_ROLE, RESEARCHER_ROLE } from "./constants/roles";

import {
  HOME_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  MOBILE_LEGAL_NOTICES_PATH,
  WEBAPP_LEGAL_NOTICES_PATH,
  ADMIN_HOME_PATH,
  ADMIN_USER_CREATE_PATH,
  ADMIN_USER_EDIT_PATH,
  HCP_LIST_PATH,
  PATIENTS_LIST_PATH,
  PATIENT_DOSING_PATH,
  PATIENT_MEAL_MENU_PATH,
  PATIENT_MEAL_DETAILS_PATH,
  PATIENT_MEAL_ANALYSIS_PATH,
  HCP_PATIENTS_LIST_PATH,
  PATIENT_CREATE_PATH,
  PATIENT_EDIT_PATH,
  PATIENT_MEAL_CREATE_PATH,
  PATIENT_MEAL_EDIT_PATH,
  GLUCOSE_EMULATION_PATH
} from "./constants/routes";

import type { ContextRouter } from "react-router-dom";
import LegalNoticePage from "./containers/LegalNoticePage";
import MobileLegalNoticePage from "./containers/MobileLegalNoticePage";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <CssBaseline />
        <Route
          render={(routeProps: ContextRouter) => (
            <AppLayout {...routeProps}>
              <Switch>
                <AuthenticatedRoute
                  exact
                  path={HOME_PATH}
                  component={HomePage}
                />
                <MobileRestrictedRoute exact path={LOGIN_PATH} component={LoginPage}/>
                <MobileRestrictedRoute
                  exact
                  path={LOGOUT_PATH}
                  component={RouteComponentStub}
                />
                <Route
                  exact
                  path={MOBILE_LEGAL_NOTICES_PATH}
                  component={MobileLegalNoticePage}
                />
                <MobileRestrictedRoute
                  exact
                  path={WEBAPP_LEGAL_NOTICES_PATH}
                  component={LegalNoticePage}
                />
                <AuthenticatedRoute
                  render={() => (
                    <Switch>
                      {/* Admin Routes */}
                      <RoleRoute
                        userRole={ADMIN_ROLE}
                        exact
                        path={[ADMIN_HOME_PATH]}
                        component={UsersList}
                      />
                      <RoleRoute
                        userRole={ADMIN_ROLE}
                        exact
                        path={ADMIN_USER_CREATE_PATH}
                        component={CreateUser}
                      />
                      <RoleRoute
                        userRole={ADMIN_ROLE}
                        exact
                        path={ADMIN_USER_EDIT_PATH}
                        component={EditUser}
                      />
            
                      {/* Researcher/HCP Routes */}
                      <RoleRoute
                        userRole={RESEARCHER_ROLE}
                        exact
                        path={HCP_LIST_PATH}
                        component={HcpList}
                      />
                      <RoleRoute
                        userRole={HCP_ROLE}
                        exact
                        path={PATIENTS_LIST_PATH}
                        component={PatientsList}
                        redirectTo={HOME_PATH}
                      />
                      <RoleRoute
                        userRole={RESEARCHER_ROLE}
                        exact
                        path={HCP_PATIENTS_LIST_PATH}
                        component={PatientsList}
                        redirectTo={HOME_PATH}
                      />
                      <RoleRoute
                        userRole={HCP_ROLE}
                        exact
                        path={PATIENT_CREATE_PATH}
                        component={CreatePatient}
                      />
                      <RoleRoute
                        userRole={HCP_ROLE}
                        exact
                        path={PATIENT_EDIT_PATH}
                        component={EditPatient}
                      />
                      <RoleRoute
                        userRole={[HCP_ROLE, RESEARCHER_ROLE]}
                        exact
                        path={PATIENT_DOSING_PATH}
                        component={DosingStrategy}
                      />
                      <RoleRoute
                        userRole={[HCP_ROLE, RESEARCHER_ROLE]}
                        exact
                        path={PATIENT_MEAL_MENU_PATH}
                        component={MealOptionsList}
                      />
                      <RoleRoute
                        userRole={HCP_ROLE}
                        exact
                        path={PATIENT_MEAL_CREATE_PATH}
                        component={CreateMeal}
                      />
                      <RoleRoute
                        userRole={[HCP_ROLE, RESEARCHER_ROLE]}
                        exact
                        path={PATIENT_MEAL_DETAILS_PATH}
                        component={MealDetails}
                      />
                      <RoleRoute
                        userRole={HCP_ROLE}
                        exact
                        path={PATIENT_MEAL_EDIT_PATH}
                        component={EditMeal}
                      />
                      <RoleRoute
                        userRole={[HCP_ROLE, RESEARCHER_ROLE]}
                        exact
                        path={PATIENT_MEAL_ANALYSIS_PATH}
                        component={MealAnalysis}
                      />
                      {process.env.REACT_APP_GLUCOSE_EMULATION === "true" && (
                        <RoleRoute
                          exact
                          userRole={[ADMIN_ROLE, HCP_ROLE]}
                          path={GLUCOSE_EMULATION_PATH}
                          component={GlucoseUploadPage}
                        />
                      )}
                      {/* Not Found Page */}
                      <MobileRestrictedRoute component={NotFoundPage}/>
                    </Switch>
                  )}
                />
              </Switch>
            </AppLayout>
          )}
        />
      </Router>
    </ThemeProvider>
  );
}

export default App;
