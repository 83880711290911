// @flow
import * as React from "react";
import noop from "lodash/noop";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import {
  Formik,
  Form,
  Field,
  PasswordField,
  UppercaseTextField
} from "../../components/Form";
import When from "../../components/When";
import {
  Yup,
  validationLocale,
  SubjectNumberSchema
} from "../../utils/validation";

import type { FormikHelpers } from "formik";
import type { PatientEntity } from "../../reducers/types";

type FormFields = {|
  subjectNumber: string,
  password: string,
  confirmPassword: string,
|};

type DefaultProps = {|
  patient: $Shape<PatientEntity>,
  editMode: boolean,
  readOnly: boolean
|};

type Props = {|
  ...DefaultProps,
  ...$Exact<WithTranslation>,
  className?: string,
  formRef?: React.Ref<React.ComponentType<React.Config<typeof Formik>>>,
  onSubmit: (fields: FormFields, formikActions: FormikHelpers<*>) => any,
  children?: React.Node
|};

type State = {|
  showPassword: boolean
|};

const PatientSchema = Yup.object().shape({
  subjectNumber: SubjectNumberSchema.required(),
  password: Yup.string()
    .min(8)
    .required(),
  confirmPassword: Yup.string().required(),
});

const validateForm = (values: FormFields) => {
  let errors = {};

  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = validationLocale.equality("Password");
  }

  return errors;
};

class PatientForm extends React.Component<Props, State> {
  static defaultProps: DefaultProps = {
    patient: {
      subjectNumber: "",
      password: "",
    },
    editMode: false,
    readOnly: false
  };

  state = {
    showPassword: false
  };

  togglePassword = (evt: any, showPassword: boolean) =>
    this.setState({ showPassword });

  render() {
    const { showPassword } = this.state;
    const {
      formRef,
      className,
      editMode,
      readOnly,
      patient,
      onSubmit,
      children,
      t
    } = this.props;
    const {
      subjectNumber,
      password,
      daysInStudy,
      canEditSubjectNumber
    } = patient || {};
    
    let isSubjectNumberDisabled = false;
    if (typeof daysInStudy === "number") {
      isSubjectNumberDisabled = daysInStudy > 0;
    }
    if (editMode && !canEditSubjectNumber) {
      isSubjectNumberDisabled = true;
    }
    
    const validationSchema = PatientSchema;
    const initialValues = {
      subjectNumber,
      password: password || "",
      confirmPassword: password || "",
    };

    return (
      <Formik
        ref={formRef}
        initialValues={initialValues}
        enableReinitialize={editMode || readOnly}
        onSubmit={!readOnly ? onSubmit : noop}
        validate={!readOnly ? validateForm : noop}
        validationSchema={!readOnly ? validationSchema : null}
        render={props => (
          <Form className={className} blurOnSubmit noValidate>
            <Field
              component={UppercaseTextField}
              type="text"
              name="subjectNumber"
              id="subjectNumber"
              label={t("fields.common.subjectNumber")}
              required
              readOnly={readOnly || isSubjectNumberDisabled}
              disabled={isSubjectNumberDisabled}
              variant="outlined"
              margin="normal"
              fullWidth
              color="secondary"
            />
            <When condition={!readOnly}>
              {() => [
                <Field
                  key="password"
                  component={PasswordField}
                  name="password"
                  id="password"
                  label={t("fields.common.password")}
                  showPasswordDataQa="showPassword"
                  autoComplete="new-password"
                  required
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  color="secondary"
                  showPassword={showPassword}
                  onShowClick={this.togglePassword}
                />,
                <Field
                  key="confirmPassword"
                  component={PasswordField}
                  name="confirmPassword"
                  id="confirmPassword"
                  label={t("fields.common.confirmPassword")}
                  showPasswordDataQa="showConfirmPassword"
                  autoComplete="new-password"
                  required
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  color="secondary"
                  showPassword={showPassword}
                  onShowClick={this.togglePassword}
                />,
              ]}
            </When>
            {children}
          </Form>
        )}
      />
    );
  }
}

export default withTranslation()(PatientForm);
