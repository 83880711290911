// @flow
import * as React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

import { ILLNESS, STRESS } from "../../../constants/otherNotes";

import type { OtherNotesEnum } from "../../../constants/otherNotes";
import { useTranslation } from "react-i18next";

type Props = {
  value: OtherNotesEnum[],
  onChange: (value: OtherNotesEnum[]) => any
};

const otherNotesMap = {
  [ILLNESS]: "Illness",
  [STRESS]: "Stress"
};
const otherNotesOptions = [
  { label: `pages.mealAnalysis.otherNotesFilter.${otherNotesMap[ILLNESS].toLowerCase()}Indicated`, value: ILLNESS },
  { label: `pages.mealAnalysis.otherNotesFilter.${otherNotesMap[STRESS].toLowerCase()}Indicated`, value: STRESS }
];

function renderValue(selected: OtherNotesEnum[], t) {
  return selected.map(value => t(`pages.mealAnalysis.otherNotesFilter.${otherNotesMap[value].toLowerCase()}`)).join(", ");
}

const { useCallback } = React;

function OtherNotesFilter(props: Props) {
  const { value, onChange } = props;
  const { t } = useTranslation();
  const handleChange = useCallback(
    function(evt: { +target: { value: OtherNotesEnum[] } }) {
      const { value } = evt.target;

      onChange(value);
    },
    [onChange]
  );

  return (
    <TextField
      select
      label={t("pages.mealAnalysis.otherNotesFilter.title")}
      margin="dense"
      variant="outlined"
      fullWidth
      SelectProps={{
        multiple: true,
        value,
        onChange: handleChange,
        renderValue: (selected) => renderValue(selected, t)
      }}
    >
      {otherNotesOptions.map((option, i) => (
        <MenuItem key={i} value={option.value}>
          <Checkbox checked={value.indexOf(option.value) > -1} />
          <ListItemText primary={t(option.label)} />
        </MenuItem>
      ))}
    </TextField>
  );
}

OtherNotesFilter.defaultProps = {
  value: []
};

export default OtherNotesFilter;
