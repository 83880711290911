// @flow
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Container from "@material-ui/core/Container";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import AppLogo from "../components/AppLogo";
import {
  Formik,
  Form,
  Field,
  TextField,
  PasswordField
} from "../components/Form";
import { Yup } from "../utils/validation";
import { loginRequest } from "../actions/userActions";

import type { WithStyles } from "@material-ui/core";
import type { FormikHelpers } from "formik";
import type { State as AppState } from "../reducers/types";

const useStyles: Function = withStyles(theme => ({
  "@global": {
    body: {
      background: `linear-gradient(to bottom, ${theme.palette.secondary.main}, ${theme.palette.secondary.dark})`,
      height: "100vh"
    }
  },
  container: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center"
  },
  appLogo: {
    marginTop: theme.spacing(10)
  },
  card: {
    width: 460,
    height: 370,
    marginTop: theme.spacing(8),
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    textAlign: "center"
  },
  cardContent: {
    padding: theme.spacing(3, 5, 3),
    width: "100%"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  errorMessage: {
    color: theme.palette.error.main
  }
}));

type OwnProps = {|
  ...$Exact<WithTranslation>,
  ...$Exact<WithStyles>
|};

type Props = {
  ...OwnProps,
  login: typeof loginRequest,
  authenticating: boolean,
  errorMessage: string | null
};

type Config = React.Config<Props, {||}>;
type OwnConfig = React.Config<OwnProps, {||}>;

type FormFields = {|
  username: string,
  password: string
|};

const LoginSchema = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string().required()
});

const mapStateToProps = (state: AppState, ownProps: $ReadOnly<OwnProps>) => {
  const { authenticating, loginError } = state.user;
  const { t } = ownProps;
  const errorMessage = loginError
    ? loginError.status === 401
      ? t("pages.login.wrongUserIdOrPassword")
      : loginError.title
    : "";
  return {
    authenticating,
    errorMessage: errorMessage
  };
};

const initialValues = { username: "", password: "" };

export class LoginPage extends React.Component<Props> {
  static defaultProps = {
    classes: {},
    authenticating: false,
    errorMessage: ""
  };

  handleSubmit = (data: FormFields, actions: FormikHelpers<FormFields>) => {
    const { username, password } = data;

    actions.setSubmitting(false);
    this.props.login(username, password);
  };

  render() {
    const { classes, authenticating, errorMessage, t } = this.props;

    return (
      <Container className={classes.container}>
        <AppLogo className={classes.appLogo} />
        <Card raised className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography component="h1" variant="h5">
              {t("pages.login.welcome")}!
            </Typography>
            <Formik
              initialValues={initialValues}
              onSubmit={this.handleSubmit}
              validationSchema={LoginSchema}
              render={({ handleSubmit }) => (
                <Form className={classes.form} noValidate>
                  <Field
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="username"
                    label={t("fields.common.userId")}
                    name="username"
                    autoComplete="email"
                    color="secondary"
                    autoFocus
                  />
                  <Field
                    component={PasswordField}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="password"
                    label={t("fields.common.password")}
                    color="secondary"
                    id="password"
                    autoComplete="current-password"
                    showPasswordDataQa="showPassword"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    data-qa="loginButton"
                  >
                    {t("actions.login")}
                  </Button>
                  {authenticating && <LinearProgress />}
                  {errorMessage && (
                    <Typography
                      variant="body2"
                      className={classes.errorMessage}
                      data-qa="wrongCredsError"
                    >
                      {errorMessage}
                    </Typography>
                  )}
                </Form>
              )}
            ></Formik>
          </CardContent>
        </Card>
      </Container>
    );
  }
}

export default compose(
  useStyles,
  withTranslation(),
  connect<Config, OwnConfig, _, _, _, _>(mapStateToProps, {
    login: loginRequest
  })
)(withTranslation()(LoginPage));
