// @flow
import {
  GLUCOSE_UPLOAD_REQUEST,
  GLUCOSE_UPLOAD_SUCCESS,
  GLUCOSE_UPLOAD_ERROR,
  LOGOUT_DONE
} from "../constants/actionTypes";

import type { GlucoseUploadState } from "./types";
import type { Action } from "../actions/types";

const initialState: GlucoseUploadState = {
  uploading: false,
  error: null,
  succeed: false
};

export default function glucoseUploadReducer(
  state: GlucoseUploadState = initialState,
  action: Action
): GlucoseUploadState {
  switch (action.type) {
    case GLUCOSE_UPLOAD_REQUEST: {
      return { ...state, uploading: true, error: null, succeed: false };
    }
    case GLUCOSE_UPLOAD_SUCCESS: {
      return { ...state, uploading: false, succeed: true };
    }
    case GLUCOSE_UPLOAD_ERROR: {
      return {
        ...state,
        uploading: false,
        error: action.payload.error,
        succeed: false
      };
    }
    case LOGOUT_DONE: {
      return initialState;
    }
    default:
      return state;
  }
}
