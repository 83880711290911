// @flow
export const setItem = (key: string, item: string | Object) => {
  try {
    sessionStorage.setItem(
      key,
      typeof item !== "string" ? JSON.stringify(item) : item
    );
  } catch (error) {
    console.error(error);
  }
};

export const getItem = (
  key: string,
  parse?: boolean = false
): ?(string | Object) => {
  try {
    const itemString = sessionStorage.getItem(key);
    if (typeof itemString === "string") {
      const item = parse ? JSON.parse(itemString) : itemString;

      return item;
    }

    return itemString;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const removeItem = (key: string) => {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    console.error(error);
  }
};

export const clear = () => {
  try {
    sessionStorage.clear();
  } catch (error) {
    console.error(error);
  }
};
