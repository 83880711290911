// @flow
import * as React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import HcpBreadcrumbs from "../HcpBreadcrumbs";
import ResearcherBreadcrumbs from "../../Researcher/ResearcherBreadcrumbs";
import PageLayout from "../../../components/PageLayout";
import MealAnalysisFilters from "./MealAnalysisFilters";
import MealOptionPane from "./MealOptionPane";
import MealEventPane from "./MealEventPane";
import GlucoseTracePane from "./GlucoseTracePane";
import OtherNotesPane from "./OtherNotesPane";
import { useMealAnalysisEffect } from "./hooks";
import { usePatientDataEffect } from "../hooks";
import { useMealOptionDataEffect } from "../MealMenu/hooks";
import { getPatientMealMenuPath } from "../../../constants/routes";
import { useStyles } from "./styles";

import type { ContextRouter } from "react-router-dom";
import type { MealEventsFiltersPayload } from "../../../actions/types/mealAnalysisActions";
import { useTranslation } from "react-i18next";

type Props = {|
  ...ContextRouter
|};

type FiltersState = $Shape<MealEventsFiltersPayload>;

const { useState, useMemo, useCallback } = React;

const defaultFilters: FiltersState = {
  mealTime: null,
  mealSize: null,
  timeRange: 7,
  otherNotesType: []
};

export default function MealAnalysis(props: Props) {
  const { match } = props;
  const classes = useStyles(props);
  const [filters, setFilters] = useState<FiltersState>(defaultFilters);
  const { t } = useTranslation();

  const patientId = match.params.patientId || "";
  const mealOptionId = match.params.mealId || "";
  const breadcrumbsItems = useMemo(
    () => [
      { label: t("pages.mealMenuList.title"), path: getPatientMealMenuPath(patientId) },
      { label: t("pages.mealAnalysis.title") }
    ],
    [patientId, t]
  );
  const handleFiltersChange = useCallback(
    function(filters) {
      setFilters(filters);
    },
    [setFilters]
  );

  usePatientDataEffect(patientId);
  useMealOptionDataEffect(mealOptionId);
  useMealAnalysisEffect(mealOptionId, filters);

  return (
    <PageLayout>
      <HcpBreadcrumbs items={breadcrumbsItems} />
      <ResearcherBreadcrumbs items={breadcrumbsItems} />
      <Typography
        component="h1"
        variant="h5"
        className={classes.headerContainer}
      >
        {t("pages.mealAnalysis.title")}
      </Typography>
      <MealOptionPane patientId={patientId} mealOptionId={mealOptionId} />
      <MealAnalysisFilters
        initialFilters={defaultFilters}
        onChange={handleFiltersChange}
      />
      <Grid container spacing={3}>
        <Grid item md={4} xs={12} container>
          <MealEventPane fullHeight />
        </Grid>
        <Grid item md={8} xs={12} container>
          <GlucoseTracePane fullHeight />
        </Grid>
      </Grid>
      <OtherNotesPane />
    </PageLayout>
  );
}
