// @flow
import {
  take,
  takeLatest,
  call,
  put,
  fork,
  cancel,
  cancelled
} from "redux-saga/effects";
import { saveAs } from "file-saver";
import { CancelToken } from "axios";
import { parseAsync } from "json2csv";
import i18next from "../i18n";
import { fetchData } from "./fetchSaga";
import {
  fetchPatientAnswersSuccess,
  fetchPatientActivitiesSuccess,
  fetchPatientAnswersError,
  fetchPatientActivitiesError,
  fetchPatientBolusInsulinSuccess,
  fetchPatientBolusInsulinError,
  fetchPatientBasalInsulinSuccess,
  fetchPatientBasalInsulinError,
  fetchPatientGlucoseSuccess,
  fetchPatientGlucoseError,
  fetchPatientHeartRateSuccess,
  fetchPatientHeartRateError,
  fetchPatientStepCountsSuccess,
  fetchPatientStepCountsError
} from "../actions/patientsActions";
import { APIError } from "../utils/errors";
import {
  DOWNLOAD_SUBJECT_DATA_DIALOG,
  PATIENT_FETCH_ANSWERS_REQUEST,
  PATIENT_FETCH_ACTIVITIES_REQUEST,
  PATIENT_FETCH_BASAL_INSULIN_REQUEST,
  PATIENT_FETCH_BOLUS_INSULIN_REQUEST,
  PATIENT_FETCH_GLUCOSE_REQUEST,
  PATIENT_FETCH_HEART_RATE_REQUEST,
  PATIENT_FETCH_STEP_COUNTS_REQUEST
} from "../constants/actionTypes";

import type { Saga } from "redux-saga";
import type {
  PatientFetchAnswersRequestAction,
  PatientFetchActivitiesRequestAction,
  PatientAnswersResponse,
  PatientActiviesResponse,
  PatientFetchBolusInsulinRequestAction,
  PatientInsulinResponse,
  PatientFetchBasalInsulinRequestAction,
  PatientGlucoseResponse,
  PatientFetchGlucoseRequestAction,
  PatientFetchHeartRateRequestAction,
  PatientHeartRateResponse,
  PatientFetchStepCountsRequestAction,
  PatientStepCountsResponse,
} from "../actions/types/patientsActions";
import type { Result } from "./fetchSaga";

export const answersFields = [
  {
    value: "subjectNumber",
    label: i18next.t("fields.common.subjectNumber")
  },
  {
    value: "name",
    label: i18next.t("fields.subjectData.name")
  },
  {
    value: "formattedDate",
    label: i18next.t("fields.subjectData.time")
  },
  {
    value: "responseText",
    label: i18next.t("fields.subjectData.responseText")
  }
];
export const activitiesFields = [
  {
    value: "subjectNumber",
    label: i18next.t("fields.common.subjectNumber")
  },
  {
    value: "description",
    label: i18next.t("fields.subjectData.description")
  },
  {
    value: "formattedDate",
    label: i18next.t("fields.subjectData.time")
  },
  {
    value: "duration",
    label: i18next.t("fields.subjectData.duration")
  }
];
export const insulinFields = [
  {
    value: "subjectNumber",
    label: i18next.t("fields.common.subjectNumber")
  },
  {
    value: "typeCode",
    label: i18next.t("fields.subjectData.typeCode")
  },
  {
    value: "formattedDate",
    label: i18next.t("fields.subjectData.time")
  },
  {
    value: "amount",
    label: i18next.t("fields.subjectData.amount")
  }
];
export const glucoseFields = [
  {
    value: "subjectNumber",
    label: i18next.t("fields.common.subjectNumber")
  },
  {
    value: "typeCode",
    label: i18next.t("fields.subjectData.typeCode")
  },
  {
    value: "formattedTimestamp",
    label: i18next.t("fields.subjectData.timestamp")
  },
  {
    
    value: "formattedLocalTimestamp",
    label: i18next.t("fields.subjectData.localTimestamp")
  },
  {
    value: "amount",
    label: i18next.t("fields.subjectData.amount")
  }
];
export const heartRateFields = [
  {
    value: "subjectNumber",
    label: i18next.t("fields.common.subjectNumber")
  },
  {
    value: "formattedDate",
    label: i18next.t("fields.subjectData.time")
  },
  {
    value: "avgHeartRate",
    label: i18next.t("fields.subjectData.avgHeartRate")
  }
];
export const stepCountsFields = [
  {
    value: "subjectNumber",
    label: i18next.t("fields.common.subjectNumber")
  },
  {
    value: "formattedDate",
    label: i18next.t("fields.subjectData.time")
  },
  {
    value: "steps",
    label: i18next.t("fields.subjectData.steps")
  }
];


export function saveAsCSV(content: string, filename: string) {
  const blob = new Blob([content], { type: "text/csv;charset=utf-8" });

  return saveAs(blob, filename);
}

export function* fetchAnswersData(
  action: PatientFetchAnswersRequestAction
): Saga<void> {
  const cancelSource = CancelToken.source();
  const cancelToken: CancelToken = cancelSource.token;

  try {
    const { subjectNumber } = action.payload;

    const { response, error }: Result<*, PatientAnswersResponse> = yield call(
      fetchData,
      {
        method: "get",
        url: "patient-answers",
        cancelToken: cancelToken,
        params: {
          subjectNumber
        }
      }
    );

    if (response) {
      try {
        const parsedCSV: string = yield call(parseAsync, response.data, {
          excelStrings: false,
          fields: answersFields,
          withBOM: true
        });
        
        const fileNamePrefix = i18next.t("pages.downloadSubjectData.reconciliationAnswers").split(' ').join('_');
        const fileName = `${fileNamePrefix}_#${subjectNumber}.csv`;

        yield call(
          saveAsCSV,
          parsedCSV,
          fileName
        );
        yield put(fetchPatientAnswersSuccess(response.data));
      } catch (error) {
        yield put(fetchPatientAnswersError(new APIError(error)));
      }
    }

    if (error) {
      yield put(fetchPatientAnswersError(error));
    }
  } finally {
    if (yield cancelled()) {
      cancelSource.cancel();
      yield put(fetchPatientAnswersError(new APIError(Error("Cancelled"))));
    }
  }
}
export function* fetchActivitiesData(
  action: PatientFetchActivitiesRequestAction
): Saga<void> {
  const cancelSource = CancelToken.source();
  const cancelToken: CancelToken = cancelSource.token;

  try {
    const { subjectNumber } = action.payload;

    const { response, error }: Result<*, PatientActiviesResponse> = yield call(
      fetchData,
      {
        method: "get",
        url: "health/activities",
        cancelToken: cancelToken,
        params: {
          subjectNumber
        }
      }
    );
    if (response) {
      try {
        const parsedCSV = yield call(parseAsync, response.data, {
          excelStrings: false,
          fields: activitiesFields,
          withBOM: true
        });
  
        const fileNamePrefix = i18next.t("pages.downloadSubjectData.activity").split(' ').join('_');
        const fileName = `${fileNamePrefix}_#${subjectNumber}.csv`;
  
        yield call(saveAsCSV, parsedCSV, fileName);
        yield put(fetchPatientActivitiesSuccess(response.data));
      } catch (error) {
        yield put(fetchPatientActivitiesError(new APIError(error)));
        return;
      }
    }

    if (error) {
      yield put(fetchPatientActivitiesError(error));
    }
  } finally {
    if (yield cancelled()) {
      cancelSource.cancel();
      yield put(fetchPatientActivitiesError(new APIError(Error("Cancelled"))));
    }
  }
}

export function* fetchBolusInsulinData(
  action: PatientFetchBolusInsulinRequestAction
): Saga<void> {
  const cancelSource = CancelToken.source();
  const cancelToken: CancelToken = cancelSource.token;
  
  try {
    const { subjectNumber } = action.payload;
    
    const { response, error }: Result<*, PatientInsulinResponse> = yield call(
      fetchData,
      {
        method: "get",
        url: "insulin-dose",
        cancelToken: cancelToken,
        params: {
          subjectNumber,
          type: 1,
        }
      }
    );
    if (response) {
      try {
        const parsedCSV = yield call(parseAsync, response.data, {
          excelStrings: false,
          fields: insulinFields,
          withBOM: true
        });
        
        const fileNamePrefix = i18next.t("pages.downloadSubjectData.bolusInsulin").split(' ').join('_');
        const fileName = `${fileNamePrefix}_#${subjectNumber}.csv`;
        
        yield call(saveAsCSV, parsedCSV, fileName);
        yield put(fetchPatientBolusInsulinSuccess(response.data));
      } catch (error) {
        yield put(fetchPatientBolusInsulinError(new APIError(error)));
        return;
      }
    }
    
    if (error) {
      yield put(fetchPatientBolusInsulinError(error));
    }
  } finally {
    if (yield cancelled()) {
      cancelSource.cancel();
      yield put(fetchPatientBolusInsulinError(new APIError(Error("Cancelled"))));
    }
  }
}

export function* fetchBasalInsulinData(
  action: PatientFetchBasalInsulinRequestAction
): Saga<void> {
  const cancelSource = CancelToken.source();
  const cancelToken: CancelToken = cancelSource.token;
  
  try {
    const { subjectNumber } = action.payload;
    
    const { response, error }: Result<*, PatientInsulinResponse> = yield call(
      fetchData,
      {
        method: "get",
        url: "insulin-dose",
        cancelToken: cancelToken,
        params: {
          subjectNumber,
          type: 0,
        }
      }
    );
    if (response) {
      try {
        const parsedCSV = yield call(parseAsync, response.data, {
          excelStrings: false,
          fields: insulinFields,
          withBOM: true
        });
        
        const fileNamePrefix = i18next.t("pages.downloadSubjectData.basalInsulin").split(' ').join('_');
        const fileName = `${fileNamePrefix}_#${subjectNumber}.csv`;
        
        yield call(saveAsCSV, parsedCSV, fileName);
        yield put(fetchPatientBasalInsulinSuccess(response.data));
      } catch (error) {
        yield put(fetchPatientBasalInsulinError(new APIError(error)));
        return;
      }
    }
    
    if (error) {
      yield put(fetchPatientBasalInsulinError(error));
    }
  } finally {
    if (yield cancelled()) {
      cancelSource.cancel();
      yield put(fetchPatientBasalInsulinError(new APIError(Error("Cancelled"))));
    }
  }
}

export function* fetchGlucoseData(
  action: PatientFetchGlucoseRequestAction
): Saga<void> {
  const cancelSource = CancelToken.source();
  const cancelToken: CancelToken = cancelSource.token;
  
  try {
    const { subjectNumber } = action.payload;
    
    const { response, error }: Result<*, PatientGlucoseResponse> = yield call(
      fetchData,
      {
        method: "get",
        url: "glucose-reading/patient",
        cancelToken: cancelToken,
        params: {
          subjectNumber,
        }
      }
    );
    if (response) {
      try {
        const parsedCSV = yield call(parseAsync, response.data, {
          excelStrings: false,
          fields: glucoseFields,
          withBOM: true
        });
        
        const fileNamePrefix = i18next.t("pages.downloadSubjectData.glucose").split(' ').join('_');
        const fileName = `${fileNamePrefix}_#${subjectNumber}.csv`;
        
        yield call(saveAsCSV, parsedCSV, fileName);
        yield put(fetchPatientGlucoseSuccess(response.data));
      } catch (error) {
        yield put(fetchPatientGlucoseError(new APIError(error)));
        return;
      }
    }
    
    if (error) {
      yield put(fetchPatientGlucoseError(error));
    }
  } finally {
    if (yield cancelled()) {
      cancelSource.cancel();
      yield put(fetchPatientGlucoseError(new APIError(Error("Cancelled"))));
    }
  }
}

export function* fetchHeartRateData(
  action: PatientFetchHeartRateRequestAction
): Saga<void> {
  const cancelSource = CancelToken.source();
  const cancelToken: CancelToken = cancelSource.token;
  
  try {
    const { subjectNumber } = action.payload;
    
    const { response, error }: Result<*, PatientHeartRateResponse> = yield call(
      fetchData,
      {
        method: "get",
        url: "health/heart-rate",
        cancelToken: cancelToken,
        params: {
          subjectNumber,
        }
      }
    );
    if (response) {
      try {
        const parsedCSV = yield call(parseAsync, response.data, {
          excelStrings: false,
          fields: heartRateFields,
          withBOM: true
        });
        
        const fileNamePrefix = i18next.t("pages.downloadSubjectData.heartRate").split(' ').join('_');
        const fileName = `${fileNamePrefix}_#${subjectNumber}.csv`;
        
        yield call(saveAsCSV, parsedCSV, fileName);
        yield put(fetchPatientHeartRateSuccess(response.data));
      } catch (error) {
        yield put(fetchPatientHeartRateError(new APIError(error)));
        return;
      }
    }
    
    if (error) {
      yield put(fetchPatientHeartRateError(error));
    }
  } finally {
    if (yield cancelled()) {
      cancelSource.cancel();
      yield put(fetchPatientHeartRateError(new APIError(Error("Cancelled"))));
    }
  }
}

export function* fetchStepCountsData(
  action: PatientFetchStepCountsRequestAction
): Saga<void> {
  const cancelSource = CancelToken.source();
  const cancelToken: CancelToken = cancelSource.token;
  
  try {
    const { subjectNumber } = action.payload;
    
    const { response, error }: Result<*, PatientStepCountsResponse> = yield call(
      fetchData,
      {
        method: "get",
        url: "health/steps",
        cancelToken: cancelToken,
        params: {
          subjectNumber,
        }
      }
    );
    if (response) {
      try {
        const parsedCSV = yield call(parseAsync, response.data, {
          excelStrings: false,
          fields: stepCountsFields,
          withBOM: true
        });
        
        const fileNamePrefix = i18next.t("pages.downloadSubjectData.stepCounts").split(' ').join('_');
        const fileName = `${fileNamePrefix}_#${subjectNumber}.csv`;
        
        yield call(saveAsCSV, parsedCSV, fileName);
        yield put(fetchPatientStepCountsSuccess(response.data));
      } catch (error) {
        yield put(fetchPatientStepCountsError(new APIError(error)));
        return;
      }
    }
    
    if (error) {
      yield put(fetchPatientStepCountsError(error));
    }
  } finally {
    if (yield cancelled()) {
      cancelSource.cancel();
      yield put(fetchPatientStepCountsError(new APIError(Error("Cancelled"))));
    }
  }
}

export function* downloadPatientDataFlow(): Saga<void> {
  while (true) {
    const answersTask = yield takeLatest(
      PATIENT_FETCH_ANSWERS_REQUEST,
      fetchAnswersData
    );
    const activitiesTask = yield takeLatest(
      PATIENT_FETCH_ACTIVITIES_REQUEST,
      fetchActivitiesData
    );
    const bolusInsulinTask = yield takeLatest(
      PATIENT_FETCH_BOLUS_INSULIN_REQUEST,
      fetchBolusInsulinData
    );
    const basalInsulinTask = yield takeLatest(
      PATIENT_FETCH_BASAL_INSULIN_REQUEST,
      fetchBasalInsulinData
    );
    const glucoseTask = yield takeLatest(
      PATIENT_FETCH_GLUCOSE_REQUEST,
      fetchGlucoseData
    );
    const heartRateTask = yield takeLatest(
      PATIENT_FETCH_HEART_RATE_REQUEST,
      fetchHeartRateData
    );
    const stepCountsTask = yield takeLatest(
      PATIENT_FETCH_STEP_COUNTS_REQUEST,
      fetchStepCountsData
    );
    yield take(DOWNLOAD_SUBJECT_DATA_DIALOG);
    yield cancel(answersTask);
    yield cancel(activitiesTask);
    yield cancel(bolusInsulinTask);
    yield cancel(basalInsulinTask);
    yield cancel(glucoseTask);
    yield cancel(heartRateTask);
    yield cancel(stepCountsTask);
  }
}

export function* subjectDataSaga(): Saga<void> {
  yield fork(downloadPatientDataFlow);
}
