// @flow
import { takeLatest, call, put, select } from "redux-saga/effects";
import { normalize, schema } from "normalizr";
import { fetchData } from "./fetchSaga";
import {
  createMealOptionSuccess,
  updateMealOptionSuccess,
  getMealOptionSuccess,
  deleteMealOptionSuccess,
  createMealOptionError,
  updateMealOptionError,
  getMealOptionError,
  deleteMealOptionError,
  getMealOptionsListSuccess,
  getMoreMealOptionsListSuccess,
  getMealOptionsListError,
  getMoreMealOptionsListError,
  openDeleteMealOptionDialog
} from "../actions/mealOptionActions";
import history from "../history";

import type { Saga } from "redux-saga";
import type {
  CreateMealOptionPayload,
  UpdateMealOptionPayload,
  MealOptionsPaginatedResponse,
  MealOptionCreateRequestAction,
  MealOptionUpdateRequestAction,
  MealOptionGetRequestAction,
  MealOptionDeleteRequestAction,
  MealOptionsListRequestAction,
  MealOptionsListMoreRequestAction,
  MealOptionsPaginationPayload
} from "../actions/types/mealOptionActions";
import type { RawPaginatedResponse } from "../actions/types/generic";
import type { State, MealOptionEntity } from "../reducers/types";
import type { Result } from "./fetchSaga";
import type { APIError } from "../utils/errors";

import {
  MEAL_OPTION_CREATE_REQUEST,
  MEAL_OPTION_UPDATE_REQUEST,
  MEAL_OPTION_GET_REQUEST,
  MEAL_OPTION_DELETE_REQUEST,
  MEAL_OPTIONS_LIST_REQUEST,
  MEAL_OPTIONS_LIST_MORE_REQUEST
} from "../constants/actionTypes";
import {
  getPatientMealMenuPath,
  getPatientMealDetailsPath
} from "../constants/routes";
import { ASC } from "../constants/sorting";

type PaginatedResult = {
  response?: MealOptionsPaginatedResponse,
  error?: APIError<*>
};

const ENDPOINT = "meal-option";
const mealOptionSchema = new schema.Entity("mealOptions");
const resultSchema = { items: new schema.Array(mealOptionSchema) };

export function selectMealOption({ mealOptions }: State, id: string) {
  return mealOptions.entities[id];
}

export function* createMealOption(
  action: MealOptionCreateRequestAction
): Saga<void> {
  const {
    name,
    notes,
    mealCategory,
    mealType,
    patientId,
    carbohydrates,
    highFat,
    highProtein
  } = action.payload;

  const {
    response,
    error
  }: Result<CreateMealOptionPayload, MealOptionEntity> = yield call(fetchData, {
    method: "put",
    url: ENDPOINT,
    data: {
      name,
      notes,
      mealCategory,
      mealType,
      patientId,
      carbohydrates,
      highFat,
      highProtein
    }
  });

  if (response) {
    yield put(createMealOptionSuccess(response.data));
    yield call(history.push, getPatientMealMenuPath(patientId));
  }

  if (error) {
    yield put(createMealOptionError(error));
  }
}

export function* updateMealOption(
  action: MealOptionUpdateRequestAction
): Saga<void> {
  const {
    id,
    patientId,
    name,
    notes,
    mealCategory,
    mealType,
    carbohydrates,
    highFat,
    highProtein
  } = action.payload;

  const {
    response,
    error
  }: Result<UpdateMealOptionPayload, MealOptionEntity> = yield call(fetchData, {
    method: "post",
    url: ENDPOINT,
    data: {
      id,
      patientId,
      name,
      notes,
      mealCategory,
      mealType,
      carbohydrates,
      highFat,
      highProtein
    }
  });

  if (response) {
    yield put(updateMealOptionSuccess(response.data));
    yield call(history.push, getPatientMealDetailsPath(patientId, id));
  }

  if (error) {
    yield put(updateMealOptionError(error));
  }
}

export function* deleteMealOption(
  action: MealOptionDeleteRequestAction
): Saga<void> {
  const { mealOptionId, confirmed } = action.payload;
  const mealOption: MealOptionEntity = yield select(
    selectMealOption,
    mealOptionId
  );

  if (!confirmed) {
    yield put(openDeleteMealOptionDialog(mealOptionId));
    return;
  }

  const { response, error }: Result<*, *> = yield call(fetchData, {
    method: "delete",
    url: `${ENDPOINT}/${mealOptionId}`
  });

  if (response) {
    yield call(history.push, getPatientMealMenuPath(mealOption.patientId));
    yield put(deleteMealOptionSuccess(mealOptionId));
  }

  if (error) {
    yield put(deleteMealOptionError(error, mealOptionId));
  }
}

export function* getMealOption(action: MealOptionGetRequestAction): Saga<void> {
  const { mealOptionId } = action.payload;

  const { response, error }: Result<*, MealOptionEntity> = yield call(
    fetchData,
    {
      method: "get",
      url: `${ENDPOINT}/${mealOptionId}`
    }
  );

  if (response) {
    yield put(getMealOptionSuccess(response.data));
  }

  if (error) {
    yield put(getMealOptionError(error));
  }
}

export function* fetchMealOptionsList(
  payload: MealOptionsPaginationPayload
): Saga<PaginatedResult> {
  let {
    take = 30,
    sortDirection: direction = ASC.toUpperCase(),
    sortBy: orderBy,
    skip = 0,
    patientId,
    mealTypeFilter,
    mealCategoryFilter
  } = payload;

  const result: Result<
    MealOptionsPaginationPayload,
    RawPaginatedResponse<MealOptionEntity>
  > = yield call(fetchData, {
    method: "get",
    url: ENDPOINT,
    params: {
      take,
      skip,
      orderBy: orderBy || undefined,
      direction: direction ? direction.toUpperCase() : undefined,
      patientId,
      mealType: typeof mealTypeFilter === "number" ? mealTypeFilter : undefined,
      mealCategory:
        typeof mealCategoryFilter === "number" ? mealCategoryFilter : undefined
    }
  });

  const { response, error } = result;
  let normalizedResponse;

  if (response) {
    normalizedResponse = normalize(
      { ...response.data, ...payload },
      resultSchema
    );
  }

  return { response: normalizedResponse, error };
}

export function* getMealOptions(
  action: MealOptionsListRequestAction
): Saga<void> {
  const { response, error }: PaginatedResult = yield call(
    fetchMealOptionsList,
    action.payload
  );

  if (response) {
    yield put(getMealOptionsListSuccess(response));
  }

  if (error) {
    yield put(getMealOptionsListError(error));
  }
}

export function* getMoreMealOptions(
  action: MealOptionsListMoreRequestAction
): Saga<void> {
  const { response, error }: PaginatedResult = yield call(
    fetchMealOptionsList,
    action.payload
  );

  if (response) {
    yield put(getMoreMealOptionsListSuccess(response));
  }

  if (error) {
    yield put(getMoreMealOptionsListError(error));
  }
}

export function* mealOptionsSaga(): Saga<void> {
  yield takeLatest(MEAL_OPTION_CREATE_REQUEST, createMealOption);
  yield takeLatest(MEAL_OPTION_UPDATE_REQUEST, updateMealOption);
  yield takeLatest(MEAL_OPTION_DELETE_REQUEST, deleteMealOption);
  yield takeLatest(MEAL_OPTION_GET_REQUEST, getMealOption);
  yield takeLatest(MEAL_OPTIONS_LIST_REQUEST, getMealOptions);
  yield takeLatest(MEAL_OPTIONS_LIST_MORE_REQUEST, getMoreMealOptions);
}
