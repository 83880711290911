// @flow
import * as React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  ADMIN_HOME_PATH,
  PATIENTS_LIST_PATH,
  HCP_LIST_PATH,
  LOGIN_PATH,
  HOME_PATH
} from "../constants/routes";
import { HCP_ROLE, RESEARCHER_ROLE, ADMIN_ROLE } from "../constants/roles";

import type { ContextRouter } from "react-router-dom";
import type { State } from "../reducers/types";

type Props = {
  ...ContextRouter,
  role: string,
  profileLoaded: boolean
};

const mapStateToProps = ({ user }: State) => ({
  role: user.role,
  profileLoaded: user.profileLoaded
});

function HomePage(props: Props) {
  const { role, profileLoaded } = props;
  let to = LOGIN_PATH;

  if (!profileLoaded) {
    return null;
  }

  if (role === ADMIN_ROLE) {
    to = ADMIN_HOME_PATH;
  }
  if (role === HCP_ROLE) {
    to = PATIENTS_LIST_PATH;
  }
  if (role === RESEARCHER_ROLE) {
    to = HCP_LIST_PATH;
  }

  return <Redirect from={HOME_PATH} to={to} />;
}

export default connect<React.Config<Props, *>, {||}, _, _, _, _>(
  mapStateToProps
)(HomePage);
