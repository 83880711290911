// @flow
import * as React from "react";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";

type Props = {
  value: number | string,
  currentTab: number | string,
  unmountOnHide?: boolean,
  className?: string,
  children: React.Node
};

const useStyles = makeStyles(theme => ({
  container: {
    display: ({ value, currentTab, unmountOnHide }: Props) =>
      unmountOnHide || value === currentTab ? "inherit" : "none"
  }
}));

export default function StrategyPanel(props: Props) {
  const { className, children, unmountOnHide, value, currentTab } = props;
  const classes = useStyles(props);
  const attached = unmountOnHide ? value === currentTab : true;

  return attached ? (
    <Container maxWidth={false} className={clsx(className, classes.container)}>
      {children}
    </Container>
  ) : null;
}
