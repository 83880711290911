// @flow
import * as React from "react";

type Props = {
  children: React.Node | (() => React.Node),
  condition: boolean,
  fallback: React.Node
};

function When(props: Props) {
  const { condition, children, fallback } = props;

  if (condition) {
    return typeof children === "function" ? children() : children;
  }

  return fallback;
}

When.defaultProps = {
  fallback: null
};

export default When;
