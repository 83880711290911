// @flow
import * as React from "react";
import noop from "lodash/noop";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import {
  Formik,
  Form,
  Field,
  TextField,
  CheckboxGroupField,
  SelectField
} from "../../../components/Form";
import {
  BREAKFAST,
  LUNCH,
  DINNER,
  SNACK,
  FOOD,
  DRINK
} from "../../../constants/meal";
import { Yup } from "../../../utils/validation";

import type { FormikHelpers } from "formik";
import type { MealCategory, MealType } from "../../../constants/meal";
import type { MealOptionEntity } from "../../../reducers/types";

type FormFields = {|
  name: string,
  mealCategory: MealCategory,
  mealType: MealType | null,
  notes: string,
  carbohydrates: number | null,
  highProtein: boolean,
  highFat: boolean
|};

type DefaultProps = {|
  mealOption: $Shape<MealOptionEntity>,
  editMode: boolean,
  readOnly: boolean,
  onSubmit: (fields: FormFields, formikActions: FormikHelpers<*>) => any
|};

type Props = {|
  ...DefaultProps,
  ...$Exact<WithTranslation>,
  className?: string,
  formRef?: React.Ref<React.ComponentType<React.Config<typeof Formik>>>,
  children?: React.Node
|};

const MealSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(3)
    .max(45)
    .required(),
  mealCategory: Yup.number()
    .required()
    .oneOf([FOOD, DRINK], "Please, choose a meal category from the selection"),
  mealType: Yup.mixed().when("mealCategory", {
    is: FOOD,
    then: Yup.number()
      .required()
      .oneOf(
        [BREAKFAST, LUNCH, DINNER, SNACK],
        "Please, choose a meal type from the selection"
      ),
    otherwise: Yup.mixed()
      .nullable()
      .notRequired()
  }),
  notes: Yup.string().trim(),
  carbohydrates: Yup.number()
    .positive()
    .notRequired()
    .nullable(),
  highProtein: Yup.boolean(),
  highFat: Yup.boolean()
});

const initialFieldValues = {
  name: "",
  mealCategory: "",
  mealType: "",
  notes: "",
  carbohydrates: "",
  highProtein: false,
  highFat: false
};

const checkboxFields = [
  {
    label: "fields.meal.highFat",
    name: "highFat",
    CheckboxProps: { color: "primary" }
  },
  {
    label: "fields.meal.highProtein",
    name: "highProtein",
    CheckboxProps: { color: "primary" }
  }
];

class MealForm extends React.Component<Props> {
  static defaultProps: DefaultProps = {
    mealOption: {},
    editMode: false,
    readOnly: false,
    onSubmit: noop
  };

  handleSubmit = (values: FormFields, actions: FormikHelpers<*>) => {
    let { mealCategory, mealType, carbohydrates } = values;

    carbohydrates = parseFloat(carbohydrates);

    this.props.onSubmit(
      {
        ...values,
        mealType: mealCategory === FOOD ? mealType : null,
        carbohydrates: !Number.isNaN(carbohydrates) ? carbohydrates : null
      },
      actions
    );
  };

  isMealTypeEditable() {
    const { count = 0 } = this.props.mealOption;

    return count === 0;
  }

  getInitialValues() {
    const { mealOption, editMode, readOnly } = this.props;

    if (editMode || readOnly) {
      const {
        name,
        mealCategory,
        mealType,
        notes,
        carbohydrates,
        highProtein = false,
        highFat = false
      } = mealOption;

      return {
        name: name || "",
        mealCategory: typeof mealCategory === "number" ? mealCategory : "",
        mealType: typeof mealType === "number" ? mealType : "",
        notes: notes || "",
        carbohydrates: typeof carbohydrates === "number" ? carbohydrates : "",
        highProtein: highProtein || false,
        highFat: highFat || false
      };
    }

    return initialFieldValues;
  }

  render() {
    const { formRef, className, editMode, readOnly, children, t } = this.props;
    const initialValues = this.getInitialValues();
    const isMealTypeEditable = this.isMealTypeEditable();

    return (
      <Formik
        ref={formRef}
        initialValues={initialValues}
        enableReinitialize={editMode || readOnly}
        onSubmit={!readOnly ? this.handleSubmit : noop}
        validationSchema={!readOnly ? MealSchema : null}
        render={({ values }) => (
          <Form className={className} blurOnSubmit noValidate>
            <Field
              component={TextField}
              id="mealName"
              name="name"
              label={t("fields.meal.name")}
              type="text"
              required
              margin="normal"
              variant="outlined"
              color="secondary"
              fullWidth
              readOnly={readOnly || !isMealTypeEditable}
            />
            <Field
              component={SelectField}
              id="mealCategory"
              name="mealCategory"
              label={t("fields.meal.category")}
              required
              margin="normal"
              variant="outlined"
              color="secondary"
              fullWidth
              readOnly={readOnly || !isMealTypeEditable}
            >
              <MenuItem value={FOOD}>{t(`mealCategories.meal`)}</MenuItem>
              <MenuItem value={DRINK}>{t(`mealCategories.beverage`)}</MenuItem>
            </Field>
            {values.mealCategory === FOOD && (
              <Field
                component={SelectField}
                id="mealType"
                name="mealType"
                label={t("fields.meal.type")}
                required
                margin="normal"
                variant="outlined"
                color="secondary"
                fullWidth
                readOnly={readOnly || !isMealTypeEditable}
              >
                <MenuItem value={BREAKFAST}>
                  {t(`mealTypes.breakfast`)}
                </MenuItem>
                <MenuItem value={LUNCH}>{t(`mealTypes.lunch`)}</MenuItem>
                <MenuItem value={DINNER}>{t(`mealTypes.dinner`)}</MenuItem>
                <MenuItem value={SNACK}>{t(`mealTypes.snack`)}</MenuItem>
              </Field>
            )}
            <Field
              component={TextField}
              id="carbohydrates"
              name="carbohydrates"
              label={t("fields.meal.carbohydrates")}
              type="text"
              margin="normal"
              variant="outlined"
              color="secondary"
              fullWidth
              readOnly={readOnly}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t("units.grams")}
                  </InputAdornment>
                )
              }}
            />
            <CheckboxGroupField
              groupLabel={t("fields.meal.composition")}
              fields={checkboxFields}
              margin="normal"
              fullWidth
              disabled={readOnly}
            />
            <Field
              component={TextField}
              id="notes"
              name="notes"
              label={t("fields.meal.note")}
              type="text"
              multiline
              rowsMax={8}
              margin="normal"
              variant="outlined"
              color="secondary"
              fullWidth
              readOnly={readOnly}
            />
            {children}
          </Form>
        )}
      />
    );
  }
}

export default withTranslation()(MealForm);
