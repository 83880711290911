// @flow
import * as Yup from "yup";
import i18next from "i18next";

import type { StringSchema } from "yup/lib/string";
import type { LocaleObject } from "yup/lib/setLocale";

/* eslint-disable no-template-curly-in-string */
export const customYupLocale: LocaleObject = {
  mixed: {
    default: ({ path }) => i18next.t("validation.mixed.default", { path }),
    required: "validation.mixed.required",
    oneOf: ({ values }) => i18next.t("validation.mixed.oneOf", { values }),
    notOneOf: ({ values }) =>
      i18next.t("validation.mixed.notOneOf", { values }),
    notType: ({ type }) => i18next.t("validation.mixed.default", { path: type })
  },
  string: {
    length: ({ length }) => i18next.t("validation.string.length", { length }),
    min: ({ min }) => i18next.t("validation.string.min", { min }),
    max: ({ max }) => i18next.t("validation.string.max", { max }),
    matches: ({ regex }) => i18next.t("validation.string.matches", { regex }),
    email: "validation.string.email",
    url: "validation.string.url",
    trim: "validation.string.trim",
    lowercase: "validation.string.lowercase",
    uppercase: "validation.string.uppercase"
  },
  number: {
    min: ({ min }) => i18next.t("validation.number.min", { min }),
    max: ({ max }) => i18next.t("validation.number.max", { max }),
    lessThan: ({ less }) => i18next.t("validation.number.lessThan", { less }),
    moreThan: ({ more }) => i18next.t("validation.number.moreThan", { more }),
    notEqual: ({ notEqual }) =>
      i18next.t("validation.number.notEqual", { notEqual }),
    positive: "validation.number.positive",
    negative: "validation.number.negative",
    integer: "validation.number.integer"
  },
  date: {
    min: ({ min }) => i18next.t("validation.date.min", { min }),
    max: ({ max }) => i18next.t("validation.date.max", { max })
  }
};
/* eslint-enable no-template-curly-in-string */

export const validationLocale = {
  ...customYupLocale,
  equality: (path: string) => i18next.t("validation.equality", { path })
};

Yup.setLocale(customYupLocale);

export const SubjectNumberSchema: StringSchema<string> = Yup.string()
  .matches(/^[A-Z0-9]+$/, "validation.subjectNumber")
  .min(3)
  .max(45)
  .trim();

export { Yup };
