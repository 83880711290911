// @flow
import { takeLatest, call, put, select } from "redux-saga/effects";
import { normalize, schema } from "normalizr";
import { fetchData } from "./fetchSaga";
import {
  createPatientSuccess,
  createPatientError,
  updatePatientSuccess,
  updatePatientError,
  openDeletePatientDialog,
  deletePatientSuccess,
  deletePatientError,
  getPatientSuccess,
  getPatientError,
  getPatientsListSuccess,
  getPatientsListError,
  getMorePatientsListSuccess,
  getMorePatientsListError,
  removeDeviceIdError,
  removeDeviceIdSuccess,
  openForgetDeviceDialog
} from "../actions/patientsActions";
import { APIError } from "../utils/errors";
import history from "../history";

import type { Saga } from "redux-saga";
import type {
  PatientsPaginationPayload,
  PatientCreateRequestAction,
  PatientUpdateRequestAction,
  PatientDeleteRequestAction,
  PatientGetRequestAction,
  PatientsListRequestAction,
  PatientsListMoreRequestAction,
  RemoveDeviceRequestAction,
  PatientsPaginatedResponse
} from "../actions/types/patientsActions";
import type { RawPaginatedResponse } from "../actions/types/generic";
import type { State, PatientEntity } from "../reducers/types";
import type { Result } from "./fetchSaga";

import {
  PATIENT_CREATE_REQUEST,
  PATIENT_UPDATE_REQUEST,
  PATIENT_DELETE_REQUEST,
  PATIENT_GET_REQUEST,
  PATIENTS_LIST_REQUEST,
  PATIENTS_LIST_MORE_REQUEST,
  PATIENT_REMOVE_DEVICE_REQUEST
} from "../constants/actionTypes";
import { PATIENTS_LIST_PATH } from "../constants/routes";
import { ASC } from "../constants/sorting";

type PaginatedResult = {
  response?: PatientsPaginatedResponse,
  error?: APIError<*>
};

const patientEntity = new schema.Entity("patients");
const resultSchema = { items: new schema.Array(patientEntity) };

export function selectHcpId({ user }: State) {
  return user.hcpId;
}

export function* createPatient(action: PatientCreateRequestAction): Saga<void> {
  const {
    subjectNumber,
    password,
  } = action.payload;
  const hcpId = yield select(selectHcpId);

  const {
    response,
    error
  }: Result<
    {
      subjectNumber: number,
      hcpId: string,
      password: string,
    },
    PatientEntity
  > = yield call(fetchData, {
    method: "post",
    url: "patient/create",
    data: {
      subjectNumber,
      password,
      hcpId
    }
  });

  if (response) {
    yield put(createPatientSuccess(response.data));
    yield call(history.push, PATIENTS_LIST_PATH);
  }

  if (error) {
    yield put(createPatientError(error));
  }
}

export function* updatePatient(action: PatientUpdateRequestAction): Saga<void> {
  const {
    patientId: id,
    subjectNumber,
    password,
    confirmPassword,
  } = action.payload;
  const hcpId = yield select(selectHcpId);

  const {
    response,
    error
  }: Result<
    {
      id: string,
      subjectNumber: string,
      hcpId: string,
      password: string,
      confirmPassword: string,
    },
    PatientEntity
  > = yield call(fetchData, {
    method: "post",
    url: "patient",
    data: {
      id,
      subjectNumber,
      hcpId,
      password,
      confirmPassword,
    }
  });

  if (response) {
    yield put(updatePatientSuccess(response.data));
    yield call(history.push, PATIENTS_LIST_PATH);
  }

  if (error) {
    yield put(updatePatientError(error));
  }
}

export function* deletePatient(action: PatientDeleteRequestAction): Saga<void> {
  const { patientId, confirmed } = action.payload;

  if (!confirmed) {
    yield put(openDeletePatientDialog(patientId));
    return;
  }

  const { response, error }: Result<*, *> = yield call(fetchData, {
    method: "delete",
    url: `patient/${patientId}`
  });

  if (response) {
    yield call(history.push, PATIENTS_LIST_PATH);
    yield put(deletePatientSuccess(patientId));
  }

  if (error) {
    yield put(deletePatientError(error, patientId));
  }
}

export function* getPatient(action: PatientGetRequestAction): Saga<void> {
  const { patientId } = action.payload;

  const { response, error }: Result<*, PatientEntity> = yield call(fetchData, {
    method: "get",
    url: `patient/${patientId}`
  });

  if (response) {
    yield put(getPatientSuccess(response.data));
  }

  if (error) {
    yield put(getPatientError(error));
  }
}

export function* fetchPatientsList(
  payload: PatientsPaginationPayload
): Saga<PaginatedResult> {
  let {
    take = 30,
    sortDirection: direction = ASC.toUpperCase(),
    sortBy: orderBy,
    skip = 0,
    hcpId,
    period
  } = payload;

  if (!hcpId) {
    hcpId = (yield select(selectHcpId): string);
  }

  const result: Result<
    PatientsPaginationPayload,
    RawPaginatedResponse<PatientEntity>
  > = yield call(fetchData, {
    method: "get",
    url: "patient",
    params: {
      take,
      skip,
      orderBy: orderBy || undefined,
      direction: direction ? direction.toUpperCase() : undefined,
      hcpId,
      period
    }
  });

  const { response, error } = result;
  let normalizedResponse;

  if (response) {
    normalizedResponse = normalize(
      { ...response.data, ...payload },
      resultSchema
    );
  }

  return { response: normalizedResponse, error };
}

export function* getPatients(action: PatientsListRequestAction): Saga<void> {
  const { response, error }: PaginatedResult = yield call(
    fetchPatientsList,
    action.payload
  );

  if (response) {
    yield put(getPatientsListSuccess(response));
  }

  if (error) {
    yield put(getPatientsListError(error));
  }
}

export function* getMorePatients(
  action: PatientsListMoreRequestAction
): Saga<void> {
  const { response, error }: PaginatedResult = yield call(
    fetchPatientsList,
    action.payload
  );

  if (response) {
    yield put(getMorePatientsListSuccess(response));
  }

  if (error) {
    yield put(getMorePatientsListError(error));
  }
}

export function* removePatientDeviceId(
  action: RemoveDeviceRequestAction
): Saga<void> {
  const { subjectNumber, confirmed } = action.payload;

  if (!confirmed) {
    yield put(openForgetDeviceDialog(subjectNumber));
    return;
  }

  const { response, error }: Result<*, *> = yield call(fetchData, {
    method: "post",
    url: "patient/remove-device-id",
    params: {
      subjectNumber
    }
  });

  if (response) {
    yield put(removeDeviceIdSuccess());
  }

  if (error) {
    yield put(removeDeviceIdError(error));
  }
}

export function* patientsSaga(): Saga<void> {
  yield takeLatest(PATIENT_CREATE_REQUEST, createPatient);
  yield takeLatest(PATIENT_UPDATE_REQUEST, updatePatient);
  yield takeLatest(PATIENT_DELETE_REQUEST, deletePatient);
  yield takeLatest(PATIENT_GET_REQUEST, getPatient);
  yield takeLatest(PATIENTS_LIST_REQUEST, getPatients);
  yield takeLatest(PATIENTS_LIST_MORE_REQUEST, getMorePatients);
  yield takeLatest(PATIENT_REMOVE_DEVICE_REQUEST, removePatientDeviceId);
}
