// @flow
import * as React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

import When from "../../../components/When";
import HcpBreadcrumbs from "../HcpBreadcrumbs";
import PageLayout from "../../../components/PageLayout";
import { useValidationErrors } from "../../../components/Form/hooks";
import MealForm from "./MealForm";
import {
  getPatientMealDetailsPath,
  getPatientMealMenuPath
} from "../../../constants/routes";
import { updateMealOption } from "../../../actions/mealOptionActions";
import { useClearFormErrors, useMealOptionDataEffect } from "./hooks";
import { usePatientDataEffect } from "../hooks";
import { APIError } from "../../../utils/errors";

import type { ContextRouter } from "react-router-dom";
import type { State } from "../../../reducers/types";
import { useTranslation } from "react-i18next";

type Props = { ...ContextRouter };

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(3)
  },
  actions: {
    padding: theme.spacing(2, 0, 2)
  }
}));
const { useRef, useCallback, useMemo } = React;
const pageLabel = "pages.editMealDetails.title";

export default function EditMeal(props: Props) {
  const { match } = props;
  const { t } = useTranslation();
  const params = match.params;
  const patientId = params.patientId || "";
  const mealOptionId = params.mealId || "";
  const classes = useStyles(props);
  const {
    mealOption,
    isUpdating,
    error,
    updateError,
    mealMenuLabel
  } = useSelector(({ mealOptions, patients }: State) => {
    const { loading, getMealError, entities } = mealOptions;
    const patient = patients.patientsEntities[patientId];
    const mealOption = entities[mealOptionId];

    return {
      loading,
      error: getMealError,
      mealOption,
      isUpdating: Boolean(mealOptions.updating),
      updateError: mealOptions.updateError,
      mealMenuLabel: t("pages.mealMenuList.title") + (patient ? ` #${patient.subjectNumber}` : "")
    };
  }, shallowEqual);
  const mealMenuPath = getPatientMealDetailsPath(patientId, mealOptionId);
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const isValidationError =
    updateError instanceof APIError && updateError.hasFieldErrors;
  const breadcrumbsItems = useMemo(
    () => [
      {
        label: mealMenuLabel,
        path: getPatientMealMenuPath(patientId)
      },
      { label: pageLabel }
    ],
    [mealMenuLabel, patientId]
  );

  const onSubmit = useCallback(
    (values, actions) => {
      actions.setSubmitting(false);

      if (!mealOption) return;

      const { id, patientId } = mealOption;

      dispatch(updateMealOption({ ...values, id, patientId }));
    },
    [dispatch, mealOption]
  );

  usePatientDataEffect(patientId);
  useMealOptionDataEffect(mealOptionId);
  useClearFormErrors();
  useValidationErrors(formRef.current, updateError);

  return (
    <PageLayout>
      <HcpBreadcrumbs items={breadcrumbsItems} />
      <Typography component="h1" variant="h5" className={classes.header}>
        {t(pageLabel)}
      </Typography>
      <Container maxWidth="sm">
        <MealForm
          onSubmit={onSubmit}
          editMode
          mealOption={mealOption}
          formRef={formRef}
        >
          <When condition={isUpdating}>
            <LinearProgress />
          </When>
          <When condition={!!error}>
            {() => (
              <Typography variant="body2" color="error">
                {t("pages.editMealDetails.getDataError")} {error && error.message}
              </Typography>
            )}
          </When>
          <When condition={!!updateError}>
            {() => (
              <Typography variant="body2" color="error">
                <When condition={isValidationError}>
                  {t("pages.editMealDetails.fieldsInvalidMessage")}
                </When>
                <When condition={!isValidationError}>
                  {t("pages.editMealDetails.updateDataError")}{" "}
                  {updateError && updateError.message}
                </When>
              </Typography>
            )}
          </When>
          <Grid
            container
            justify="flex-end"
            spacing={2}
            className={classes.actions}
          >
            <Grid item>
              <Button color="inherit" component={RouterLink} to={mealMenuPath}>
                {t("actions.cancel")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isUpdating}
              >
                {t("actions.save")}
              </Button>
            </Grid>
          </Grid>
        </MealForm>
      </Container>
    </PageLayout>
  );
}
