// @flow
import without from "lodash/without";
import {
  USERS_LIST_REQUEST,
  USERS_LIST_SUCCESS,
  USERS_LIST_ERROR,
  USERS_LIST_MORE_REQUEST,
  USERS_LIST_MORE_SUCCESS,
  USERS_LIST_MORE_ERROR,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_ERROR,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  LOGOUT_DONE,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  CLEAR_USER_FORM_ERRORS,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  DELETE_USER_CONFIRM_DIALOG
} from "../constants/actionTypes";
import { ASC } from "../constants/sorting";

import type { AdminState } from "./types";
import type { Action } from "../actions/types";

const initialState: AdminState = {
  userIdsList: [],
  userEntities: {},
  usersLoading: false,
  moreUsersRequested: false,
  usersError: null,
  usersTotal: 0,
  sortDirection: ASC,
  sortBy: "",
  createUserError: null,
  updateUserError: null,
  userCreating: null,
  userUpdating: null,
  userDataLoading: false,
  userDataError: null,
  usersDeleting: [],
  userDeleteError: null,
  confirmDeleteFor: "",
  showDeleteConfirm: false
};

export default function adminReducer(
  state: AdminState = initialState,
  action: Action
): AdminState {
  switch (action.type) {
    case USERS_LIST_REQUEST: {
      let { sortBy, sortDirection } = action.payload;

      sortBy = sortBy ? sortBy : state.sortBy;
      sortDirection = sortDirection
        ? sortDirection.toLowerCase()
        : state.sortDirection;

      return {
        ...state,
        sortBy,
        sortDirection,
        usersTotal: 0,
        userIdsList: [],
        userEntities: {},
        usersLoading: true
      };
    }
    case USERS_LIST_SUCCESS: {
      const { result, entities } = action.payload;
      let { items, total, sortBy, sortDirection } = result;
      const userIdsList = [...items, ...Array(total).slice(items.length)];

      sortBy = sortBy ? sortBy : state.sortBy;
      sortDirection = sortDirection
        ? sortDirection.toLowerCase()
        : state.sortDirection;

      return {
        ...state,
        sortBy,
        sortDirection,
        userIdsList,
        userEntities: {
          ...state.userEntities,
          ...entities.users
        },
        usersTotal: total,
        usersLoading: false
      };
    }
    case USERS_LIST_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        usersLoading: false,
        usersError: error
      };
    }
    case USERS_LIST_MORE_REQUEST: {
      return {
        ...state,
        moreUsersRequested: true
      };
    }
    case USERS_LIST_MORE_SUCCESS: {
      const { result, entities } = action.payload;
      const { items, total, skip, take } = result;
      const userIdsList = [
        ...state.userIdsList.slice(0, skip),
        ...items,
        ...state.userIdsList.slice(skip + take)
      ];

      return {
        ...state,
        userIdsList,
        userEntities: {
          ...state.userEntities,
          ...entities.users
        },
        usersTotal: total,
        moreUsersRequested: false
      };
    }
    case USERS_LIST_MORE_ERROR: {
      // const { error } = action.payload;
      return {
        ...state,
        moreUsersRequested: false
      };
    }
    case USER_CREATE_REQUEST: {
      const { email, firstName, lastName, role, password } = action.payload;

      return {
        ...state,
        createUserError: null,
        userCreating: {
          userName: email,
          email,
          firstName,
          lastName,
          role,
          userId: "",
          hcpId: null,
          userPicture: null,
          deletable: true,
          password
        }
      };
    }
    case USER_CREATE_SUCCESS: {
      const { userId, hcpId } = action.payload;
      const { userCreating } = state;
      const newUserEntity = {
        ...userCreating,
        userId,
        hcpId: hcpId || null
      };
      return {
        ...state,
        userCreating: null,
        userEntities: {
          ...state.userEntities,
          [userId]: newUserEntity
        }
      };
    }
    case USER_CREATE_ERROR: {
      return {
        ...state,
        userCreating: null,
        createUserError: action.payload.error
      };
    }
    case USER_UPDATE_REQUEST: {
      const { userId } = action.payload;
      return {
        ...state,
        updateUserError: null,
        userUpdating: userId
      };
    }
    case USER_UPDATE_SUCCESS: {
      const userEntity = { ...action.payload };
      return {
        ...state,
        userUpdating: null,
        userEntities: {
          ...state.userEntities,
          [userEntity.userId]: userEntity
        }
      };
    }
    case USER_UPDATE_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        updateUserError: error,
        userUpdating: null
      };
    }
    case GET_USER_REQUEST: {
      return { ...state, userDataError: null, userDataLoading: true };
    }
    case GET_USER_SUCCESS: {
      const user = { ...action.payload };
      const { userId } = user;
      return {
        ...state,
        userEntities: { ...state.userEntities, [userId]: user },
        userDataLoading: false
      };
    }
    case GET_USER_ERROR: {
      const { error: userDataError } = action.payload;
      return { ...state, userDataError, userDataLoading: false };
    }
    case CLEAR_USER_FORM_ERRORS: {
      const { createUserError, updateUserError } = initialState;
      return { ...state, createUserError, updateUserError };
    }
    case DELETE_USER_REQUEST: {
      const { usersDeleting } = state;
      const { userId } = action.payload;
      return {
        ...state,
        usersDeleting: usersDeleting.includes(userId)
          ? usersDeleting
          : [...usersDeleting, userId],
        userDeleteError: null
      };
    }
    case DELETE_USER_CONFIRM_DIALOG: {
      const { userId, show } = action.payload;
      return {
        ...state,
        usersDeleting: without(state.usersDeleting, userId),
        confirmDeleteFor: userId,
        showDeleteConfirm: show
      };
    }
    case DELETE_USER_SUCCESS: {
      const { userId } = action.payload;
      const userEntities = {
        ...state.userEntities
      };

      delete userEntities[userId];

      return {
        ...state,
        userEntities,
        usersTotal: Math.max(state.usersTotal - 1, 0),
        userIdsList: without(state.userIdsList, userId),
        usersDeleting: without(state.usersDeleting, userId)
      };
    }
    case DELETE_USER_ERROR: {
      const { error, userId } = action.payload;
      return {
        ...state,
        usersDeleting: without(state.usersDeleting, userId),
        userDeleteError: error
      };
    }
    case LOGOUT_DONE:
      return initialState;
    default:
      return state;
  }
}
