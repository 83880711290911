// @flow
import * as React from "react";
import { useSelector, shallowEqual } from "react-redux";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DotIcon from "@material-ui/icons/FiberManualRecord";
import makeStyles from "@material-ui/core/styles/makeStyles";
import When from "../../../components/When";
import EmptyView from "../../../components/Table/EmptyView";
import PanePaper from "./PanePaper";
import SelectedTrace from "./SelectedTrace";
import { useStyles as useCommonStyles, useEmptyViewStyles } from "./styles";
import { mealSizes, mealTypes, FOOD } from "../../../constants/meal";
import { formatDate } from "../../../utils/time";

import type { State } from "../../../reducers/types";
import { useTranslation } from "react-i18next";

type Props = {
  fullHeight?: boolean
};

const InputProps = {
  readOnly: true
};

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2)
  },
  reasonsTitle: {
    color: theme.palette.text.disabled
  },
  container: {
    marginBottom: theme.spacing(4)
  },
  listItem: {
    paddingLeft: 0
  },
  listIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(4)
  },
  listBullet: {
    fontSize: theme.typography.pxToRem(9)
  }
}));

export default function MealEventPane(props: Props) {
  const commonClasses = useCommonStyles(props);
  const emptyViewClasses = useEmptyViewStyles();
  const { t } = useTranslation();
  const classes = useStyles(props);
  const {
    loading,
    error,
    mealEvent,
    mealCategory,
    isDataAvailable
  } = useSelector(({ mealAnalysis, mealOptions }: State) => {
    const { loading, error, mealEvents, selectedMealEvent } = mealAnalysis;
    const mealEvent = mealEvents[selectedMealEvent];
    const isDataAvailable = Boolean(mealEvent);
    const mealOption = isDataAvailable
      ? mealOptions.entities[mealEvent.mealOption.id]
      : null;
    const mealCategory = mealOption ? mealOption.mealCategory : null;

    return {
      loading,
      error,
      mealEvent,
      mealCategory,
      isDataAvailable
    };
  }, shallowEqual);
  const diffReasons =
    isDataAvailable && mealEvent.diffReasons ? mealEvent.diffReasons : [];

  return (
    <PanePaper fullHeight={props.fullHeight}>
      <Grid container className={classes.header}>
        <Grid item xs={6}>
          <Typography
            className={commonClasses.paneHeader}
            component="h2"
            variant="h6"
            color="textSecondary"
          >
            {t("pages.mealAnalysis.mealEventPane")}
          </Typography>
        </Grid>
        <Grid item xs={6} container alignItems="center" justify="flex-end">
          <SelectedTrace />
        </Grid>
      </Grid>
      <When condition={!isDataAvailable}>
        <EmptyView
          classes={emptyViewClasses}
          loading={loading}
          error={Boolean(error)}
          errorMessage={t(
            "pages.mealAnalysis.errors.unableToDisplayMealEventData"
          )}
          emptyMessage={t("pages.mealAnalysis.errors.empty")}
        />
      </When>
      <When condition={!loading && isDataAvailable}>
        {() => (
          <>
            <Box className={classes.container}>
              <TextField
                type="text"
                label={t("pages.mealAnalysis.mealTime")}
                fullWidth
                margin="normal"
                InputProps={InputProps}
                value={formatDate(new Date(mealEvent.date), "p P")}
              />
              <TextField
                type="text"
                label={t("pages.mealAnalysis.mealSize")}
                fullWidth
                margin="normal"
                InputProps={InputProps}
                value={t(mealSizes.get(mealEvent.mealSize) || "")}
              />
              {mealCategory === FOOD && (
                <TextField
                  type="text"
                  label={t("pages.mealAnalysis.mealType")}
                  fullWidth
                  margin="normal"
                  InputProps={InputProps}
                  value={t(mealTypes.get(mealEvent.mealType) || "")}
                />
              )}
              <TextField
                type="number"
                label={t("pages.mealAnalysis.insulinDose")}
                fullWidth
                margin="normal"
                InputProps={InputProps}
                value={Math.round(mealEvent.insulinDose * 10) / 10}
              />
              <TextField
                type="number"
                label={t("pages.mealAnalysis.expectedDose")}
                fullWidth
                margin="normal"
                InputProps={InputProps}
                value={Math.round(mealEvent.expectedDose * 10) / 10}
              />
            </Box>
            <When condition={diffReasons.length > 0}>
              <Typography variant="body1" className={classes.reasonsTitle}>
                {t("pages.mealAnalysis.reasonWhyDifferent")}
              </Typography>
              <List component="ul">
                {diffReasons.map((reason, i) => (
                  <ListItem key={i} component="li" className={classes.listItem}>
                    <ListItemIcon className={classes.listIcon}>
                      <DotIcon className={classes.listBullet} />
                    </ListItemIcon>
                    <ListItemText primary={reason}></ListItemText>
                  </ListItem>
                ))}
              </List>
            </When>
          </>
        )}
      </When>
    </PanePaper>
  );
}
