// @flow
import * as React from "react";
import { useSelector, shallowEqual } from "react-redux";
import Typography from "@material-ui/core/Typography";
import SquareIcon from "@material-ui/icons/Stop";
import makeStyles from "@material-ui/core/styles/makeStyles";

import type { State } from "../../../reducers/types";
import { useTranslation } from "react-i18next";

type Props = {
  className?: string
};

const useStyles = makeStyles(theme => ({
  traceColor: {
    color: props => props.color || "#000",
    verticalAlign: "middle"
  }
}));

function SelectedTrace(props: Props) {
  const { color, isTraceExist } = useSelector(({ mealAnalysis }: State) => {
    const { selectedTraceDate, traceColors, tracesDates } = mealAnalysis;

    return {
      color: traceColors[selectedTraceDate] || "#000",
      isTraceExist: tracesDates.indexOf(selectedTraceDate) > -1
    };
  }, shallowEqual);
  const { t } = useTranslation();
  const classes = useStyles({ color });

  return isTraceExist ? (
    <span className={props.className}>
      <Typography className={classes.traceColor} component="span">
        {t("pages.mealAnalysis.trace")}
      </Typography>
      <SquareIcon className={classes.traceColor} fontSize="large" />
    </span>
  ) : null;
}

export default SelectedTrace;
