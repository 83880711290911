// @flow
import * as React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import MuiTableCell from "@material-ui/core/TableCell";
import Link from "@material-ui/core/Link";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from 'react-i18next';
import When from "../../components/When";
import withRestriction from "../../containers/withRestriction";
import { isHcp } from "../../selectors/user";
import {
  deletePatient,
  removeDeviceId,
  showDownloadDataDialog
} from "../../actions/patientsActions";
import {
  getPatientDosingPath,
  getPatientMealMenuPath,
  getPatientEditPath
} from "../../constants/routes";

import type { CellProps } from "../../components/Table/types";
import type { State } from "../../reducers/types";

type Props = {
  ...CellProps
};

function stopPropagation(e: SyntheticEvent<*>) {
  e.stopPropagation();
}

const HCPLink = withRestriction<React.ElementConfig<typeof Link>>(Link, isHcp);
const HCPMenuItem = withRestriction(MenuItem, isHcp);

const { useState, useCallback } = React;
const useStyles = makeStyles(theme => ({
  cell: {
    "&.MuiTableCell-root": {
      paddingRight: 0
    }
  },
  deleteItem: {
    color: theme.palette.error.main
  }
}));

export default function PatientListActions(props: Props) {
  const { className, style, rowData } = props;
  const { id: patientId, subjectNumber } = rowData;

  // Hooks
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const { isDeletable, isDeleting } = useSelector(
    ({ patients, user }: State) => {
      const patientEntity = patients.patientsEntities[patientId];
      const isPatientLoaded = Boolean(patientEntity);

      return {
        isDeletable:
          isPatientLoaded &&
          patientEntity.hcpId === user.hcpId &&
          patientEntity.deletable,
        isDeleting: patients.patientsDeleting.includes(patientId)
      };
    },
    shallowEqual
  );
  const dispatch = useDispatch();
  const openMenu = useCallback(
    (evt: SyntheticEvent<HTMLButtonElement>) => setAnchorEl(evt.currentTarget),
    []
  );
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const handleDownloadDataClick = useCallback(() => {
    closeMenu();
    dispatch(showDownloadDataDialog(subjectNumber));
  }, [closeMenu, dispatch, subjectNumber]);
  const handleForgetDeviceClick = useCallback(() => {
    closeMenu();
    dispatch(removeDeviceId(subjectNumber));
  }, [dispatch, subjectNumber, closeMenu]);
  const handleDeleteClick = useCallback(() => {
    closeMenu();
    dispatch(deletePatient(patientId));
  }, [dispatch, patientId, closeMenu]);

  const deleteButton = (
    <HCPMenuItem
      className={classes.deleteItem}
      disabled={!isDeletable || isDeleting}
      onClick={handleDeleteClick}
      data-qa="deleteItem"
    >
      {t("actions.delete")}
    </HCPMenuItem>
  );
  const wrappedDeleteButton = !isDeletable ? (
    <Tooltip
      title={
        t("pages.patientListActions.disabledDeleteTooltip")
      }
      data-qa="deleteHint"
    >
      <div>{deleteButton}</div>
    </Tooltip>
  ) : (
    deleteButton
  );

  return (
    <MuiTableCell
      component="div"
      align="right"
      variant="body"
      className={clsx(className, classes.cell)}
      style={style}
      onClick={stopPropagation}
    >
      <When condition={!isDeleting}>
        <IconButton color="primary" onClick={openMenu} data-qa="menuButton">
          <MoreVertIcon fontSize="small" />
        </IconButton>
        <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={closeMenu}>
          <MenuItem
            component={RouterLink}
            to={getPatientDosingPath(patientId)}
            data-qa="dosingStrategiesItem"
          >
            {t('pages.patientListActions.dosingStrategies')}
          </MenuItem>
          <MenuItem
            component={RouterLink}
            to={getPatientMealMenuPath(patientId)}
            data-qa="mealMenuItem"
          >
            {t('pages.patientListActions.mealMenu')}
          </MenuItem>
          <HCPMenuItem
            disabled={isDeleting}
            onClick={handleDownloadDataClick}
            data-qa="downloadDataItem"
          >
            {t('pages.patientListActions.downloadSubjectData')}
          </HCPMenuItem>
          <HCPMenuItem
            disabled={isDeleting}
            onClick={handleForgetDeviceClick}
            data-qa="forgetDeviceItem"
          >
            {t('pages.patientListActions.forgetDevice')}
          </HCPMenuItem>
          {wrappedDeleteButton}
        </Menu>
        <HCPLink
          component={RouterLink}
          variant="body2"
          to={getPatientEditPath(patientId)}
          data-qa="editButton"
        >
          {t('actions.edit')}
        </HCPLink>
      </When>
    </MuiTableCell>
  );
}
