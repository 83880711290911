// @flow
import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
} from "../constants/actionTypes";

import type {
  ShowNotificationAction,
  HideNotificationAction,
  ShowNotificationPayload,
} from "./types/notificationsActions";

export const showNotification = (
  payload: ShowNotificationPayload
): ShowNotificationAction => ({
  type: SHOW_NOTIFICATION,
  payload
});

export const hideNotification = (
  payload: { key: string }
): HideNotificationAction => ({
  type: HIDE_NOTIFICATION,
  payload
});
