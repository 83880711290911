// @flow

import * as React from "react";
import { useDispatch } from "react-redux";
import { getUser, clearUserFormErrors } from "../../actions/adminActions";

const { useEffect } = React;

export function useGetUserEffect(userId: string, userLoaded: boolean) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userLoaded && userId) {
      dispatch(getUser(userId));
    }
  }, [dispatch, userId, userLoaded]);
}

export function useClearFormErrors() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearUserFormErrors());
  }, [dispatch]);
}
